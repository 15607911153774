import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import { useNotifications } from 'components/notifications';

const url = process.env.REACT_APP_API_HOST;

function CopyUrlButton(props) {
  const notifications = useNotifications();

  return (
    <CopyToClipboard 
      text={`${url}${props.path}`}
      onCopy={() => notifications.success('Copied to clipboard')}
    >
      <IconButton color="primary" {...props}>
        <LinkIcon />
      </IconButton>
    </CopyToClipboard>
  );
}

export default CopyUrlButton;

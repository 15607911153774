import React from 'react';
import {
  Grid,
  Typography,
  Modal
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import { Close } from '@material-ui/icons';
import { Link, Redirect } from 'react-router-dom';
import { activityRoute } from "../../utils/routes";

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '94%',
    margin: '30px 3%',
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 150,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    left: '50%',
    height: '90vh',
    overflowY: 'auto',
    top: '50%',
    transform: `translate(-50%, -50%)`,
    '& a': {
      textDecoration: 'none'
    }
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  },
  alignRight: {
    textAlign: 'right'
  },
  data: {
    '& h4': {
      marginBottom: '0'
    }
  }
});


class ItemView extends React.Component {
  state = {
    open: true,
    item: null
  };

  componentDidMount() {
    if (this.props.item){
      this.setState({item: this.props.item});
    }else{
      this.props.find(this.props.id).then(x => {
        if (x) {this.setState({item: x}); }
      })
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  renderRedirect = () => {
      if (!this.state.open) {
        return <Redirect to='/maillog' />
      }
  }

  render() {
      const { classes, item } = this.props;

      if (!item) return '';
      return (
        <>
          {this.renderRedirect()}
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div className="modal">
              <a href className="close" onClick={this.handleClose}>
                <Close/>
              </a>
              <Grid container spacing={24} alignItems='center'>
                <Grid item xs={6}>
                  <Typography gutterBottom variant="h2" component="h2">
                    Maillog {item.id}
                  </Typography>
                </Grid>
              </Grid>
              <Typography className={classes.data}>
                <h4>Notification Time</h4>
                <p>{item.createdAt}</p>
                <h4>Type</h4>
                <p>{item.notificationType}</p>
                <h4>Activity</h4>
                <p><Link to={`${activityRoute}/view/${item.activityId}`}>{item.activityName}</Link></p>
                <h4>User</h4>
                <p><Link to={`/user/view/${item.userId}`}>{item.userLogin}</Link></p>
              </Typography>
            </div>
          </Modal>
        </>
      );
  }
}

export default withRoot(withStyles(styles)(ItemView));

import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { useUserStore } from "../stores/useUserStore";
import { useRouteMatch } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
  },
   img: {
    width: "100%",
  },
});

const getLogoPath = (projectCode) => {
  switch (projectCode) {
    default:
      return "/images/footer-default.jpg";
  }
};

export const GIZFooter = withStyles(styles)(
  observer(({ classes }) => {
    const userStore = useUserStore();
    const logo = getLogoPath(userStore.currentProjectCode);
    const logoClassName = classes[userStore.currentProjectCode] ?? "";
    const match = useRouteMatch('/public/surveys/:name');

    return match === null && (
      <footer className={classNames(classes.root)}>
        <img src={logo} alt="Footer logo" className={classes.img} />
      </footer>
    );
  })
);

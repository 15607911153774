import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from 'components/AppBarWithBackButton';
import DataSource from 'components/DataSource';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { StoreContext } from 'stores/context';
import SupportResultsDialog from './supportResults/Dialog';
import AddOrganizationButton from './components/AddOrganizationButton';
import { useNotifications } from 'components/notifications';
import { useApi } from 'support/api';

function Row(props) {
  const { impactIndicators } = props;
  const [ open, setOpen ] = useState(false);
  const [ deleted, setDeleted ] = useState(false);
  const [ participation, setParticipation ] = useState(props.participation);
  const organization = participation.partakable;
  const { userStore } = useContext(StoreContext);
  const notifications = useNotifications();
  const api = useApi();
  const { id: activityId } = useParams();

  async function handleDelete() {
    notifications.info('Deleting', null);

    await api.activities(activityId).participations(participation.id).delete();

    setDeleted(true);
    notifications.success('Deleted');
  }

  return !deleted && organization && (
    <TableRow>
      <TableCell>{organization.name}</TableCell>
      <TableCell>{organization.region?.name}</TableCell>
      <TableCell>{organization.address}</TableCell>
      <TableCell>{organization.economicActivityType?.name}</TableCell>
      {!userStore.isCurrentProject('AZP') && (
        <TableCell>{organization.size?.name}</TableCell>
      )}
      {userStore.isCurrentProject('AZP') && (
        <TableCell>{organization.numberOfEmployees}</TableCell>
      )}
      {userStore.isCurrentProject('AZP') && (
        <TableCell>{organization.relocated ? 'Yes' : 'No'}</TableCell>
      )}
      <TableCell>{organization.email}</TableCell>
      <TableCell>{organization.phone}</TableCell>
      <TableCell>
        {organization.url && (
          <a 
            href={organization.url}
            target="_blank"
            rel="noreferrer"
          >
            Open
          </a>
        )}
      </TableCell>
      <TableCell>
        <IconButton 
          onClick={() => setOpen(true)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <SupportResultsDialog 
          open={open}  
          onClose={() => setOpen(false)}
          participation={participation}
          fields={impactIndicators}
          onUpdate={setParticipation}
        />
        <IconButton onClick={handleDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function OrganizationsList(props) {
  const { id } = useParams();
  const { userStore } = useContext(StoreContext);

  return (
    <>
      <AppBar>
        <AddOrganizationButton />
      </AppBar>
      <DataSource path={`activities/${id}/impact_indicators`}>
        {(impactIndicators) => (
          <DataSource path={`activities/${id}/participations`}>
            {(participations) => (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Region</TableCell>
                    <TableCell>City/Municipality</TableCell>
                    <TableCell>Type of Economic Activities</TableCell>
                    {!userStore.isCurrentProject('AZP') && (
                      <TableCell>Size</TableCell>
                    )}
                    {userStore.isCurrentProject('AZP') && (
                      <TableCell>Number of Employees</TableCell>
                    )}
                    {userStore.isCurrentProject('AZP') && (
                      <TableCell>Relocated?</TableCell>
                    )}
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Website/Social media</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!participations.length && (
                    <TableRow>
                      <TableCell
                        colspan={userStore.isCurrentProject('AZP') ? 10 : 9}
                        align="center"
                      >
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                  {participations.map((participation, index) => (
                    <Row 
                      key={index} 
                      participation={participation} 
                      impactIndicators={impactIndicators}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </DataSource>
        )}
      </DataSource>
    </>
  );
}

export default OrganizationsList;

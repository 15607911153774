import Card from './Card';

function List(props) {
  const { cards, onMove, children, itemType } = props;

  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        onMove={onMove}
        type={itemType}
      >
        {children(card, index)}
      </Card>
    );
  };

  return (
    <>
      {cards.map((card, i) => renderCard(card, i))}
    </>
  );
}

export default List;


import { Field } from 'react-final-form';
import {
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

function BooleanField(props) {
  const { name, label } = props;

  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...input}
            />
          }
          label={label}
        />
      )}
    </ Field>
  );
}

export default BooleanField;

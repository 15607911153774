const optional = (value) => undefined;
const required = (value) => (value ? undefined : 'Required');
const atLeastOne = (value) => (value && value.length ? undefined : 'At least one');

export {
  optional,
  required,
  atLeastOne
};


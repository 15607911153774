import React from "react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table/index.js";
import Item from "./item";
import View from "./view";
import { I18n } from "../../i18n";
import ActivityService from "../../services/ActivityService";
import { activityRoute } from "../../utils/routes";

class Activities extends React.Component {
  render() {
    const path = activityRoute.slice(1);
    const i18nProps = {
      i18n: I18n[this.props.lang].activities,
      i18nCommon: I18n[this.props.lang].common,
    };

    return (
      <IndexTable
        {...this.props}
        {...i18nProps}
        serviceClass={ActivityService}
        path={path}
        columns={[
          { id: "id", title: "id" },
          {
            id: this.props.lang === "Eng" ? "nameEn" : "nameUa",
            title: "name",
          },
          { id: "startDate", title: "startDate" },
          { id: "endDate", title: "endDate" },
          {
            id: this.props.lang === "Eng" ? "oblastEn" : "obkastUa",
            sort: this.props.lang === "Eng" ? "regionNameEn" : "regionNameUa",
            title: "oblast",
            displayFunc: (row) => Array.isArray(row.regions) && row.regions.length ? row.regions.map((r) => r.name).join(', ') : row.oblastEn
          },
        ]}
        onEditItem={(itemId, item, handleItemSave) => {
          return (
            <Item
              itemId={itemId}
              serviceClass={ActivityService}
              indexPath={path}
              handleItemSave={handleItemSave}
              isEditable={true}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
        onViewItem={(itemId, item) => {
          return (
            <View
              item={item}
              id={itemId}
              indexPath={path}
              isEditable={false}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
      />
    );
  }
}

export default Activities;

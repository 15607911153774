export const variables = {
  get environment() {
    const { host } = window.location;
    switch (host) {
      case "mne.giz.de":
        return "production";
      case "mne.archer-soft.com":
        return "staging";
      default:
        return "development";
    }
  },

  get forumHost() {
    return this.environment === "production"
      ? "mne-forum.giz.de"
      : "mne-forum.archer-soft.com";
  },
};

import React from 'react';
import withRoot from '../../../withRoot';
import BaseGraph from '../base-graph';


const datasetOptions = [{
  label: 'Number of AH established per year',
  type:'bar',
  data: [51, 65, 40, 49, 60, 37, 40],
  fill: false,
  borderColor: '#6495ed',
  backgroundColor: '#6495ed',
  pointBorderColor: '#6495ed',
  pointBackgroundColor: '#6495ed',
  pointHoverBackgroundColor: '#6495ed',
  pointHoverBorderColor: '#6495ed',
  yAxisID: 'y-axis-1'
},{
  type: 'line',
  label: 'Total number of AHs established',
  data: [200, 185, 590, 621, 250, 400, 95],
  fill: false,
  lineTension: 0,
  backgroundColor: '#fdbb2d',
  borderColor: '#fdbb2d',
  hoverBackgroundColor: '#fdbb2d',
  hoverBorderColor: '#fdbb2d',
  yAxisID: 'y-axis-1'
}]

const options = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false
        },
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        gridLines: {
          display: false
        },
        labels: {
          show: true
        },
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
};

const plugins = [{
  afterDatasetDraw: (chartInstance, easing) => {
    const ctx = chartInstance.chart.ctx;
    ctx.font = chartInstance.scales.font;
    ctx.fillStyle = '#555';
    ctx.textAlign = "center";
    ctx.textBaseline = "bottom";

    chartInstance.data.datasets.forEach(function (dataset, i) {
      var meta = chartInstance.controller.getDatasetMeta(i);
      meta.data.forEach(function (bar, index) {
          var data = dataset.data[index];
          ctx.fillText(data, bar._model.x, bar._model.y - 5);
      });
    })
  }
}];

class AmalgamationProgressChart extends React.Component {

  render() {
    return (
      <BaseGraph
        type='bar'
        tableTitle="Progress of Amalgamation in Ukraine"
        showTitle='hidden'
        url='/graph/hromada_stats'
        chartOptions={options}
        datasetOptions={datasetOptions}
        filter={this.props.filter}
        plugins={plugins}
        noActions={this.props.noActions}
      />
    );
  }
};


export default withRoot(AmalgamationProgressChart);

import React from 'react';
import {
  Grid,
  Button,
  Typography,
  Modal,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Redirect, withRouter } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import Loader from '../loader';
import { I18n } from '../../i18n';
import styles from '../../style/styles';
import notify from '../../utils/notifier';
import classNames from 'classnames';

class Edit extends React.Component {
  constructor(props) {
    super(props);

    // bindings
    this.service = new props.serviceClass(this.handleUpdateState, props.lang, props.user);
    this.validateItem = this.service.ValidateItem.bind(this.service);
    this.onFieldChanged = this.service.Action.bind(this.service);
    this.onCancel = this.handleCancel.bind(this);

    if (props.handleGetData) {
      this.handleGetData = props.handleGetData.bind(this);
    }

    if (props.onAfterGetData) {
      this.onAfterGetData = props.onAfterGetData.bind(this);
    }

    if (props.handleItemSave) {
      this.handleItemSave = props.handleItemSave.bind(this);
    }

    this.state = this.service.GetInitialState(props.user ? props.user.authorities : undefined);
  }

  componentDidMount() {
    this.onLoad();
  }

  async onLoad() {
    // if only id is parsed, load item
    // if no id is parsed create
    this.item = this.props.item
      ? this.props.item
      : this.props.itemId
      ? await this.service.GetItem(this.props.itemId)
      : {};

    // update state from item
    const stateFromItem = this.service.UpdateStateFromItem(this.state, this.item);

    if (this.handleGetData) {
      // handleGetData can load lists and returns a state object with them
      this.handleGetData(this.service, stateFromItem)
        .then((stateFromGetData) => {
          let mergedState = { ...stateFromItem, ...stateFromGetData };

          // onAfterGetData can prepare statemodification that depend on loaded lists
          if (this.onAfterGetData) {
            // onAfterGetData returns new state
            const newStateAfterGetData = this.onAfterGetData(this.service, mergedState);

            mergedState = { ...mergedState, ...newStateAfterGetData };
          }

          // apply new state
          this.setState(mergedState);
        })
        .catch((e) => {
          console.log('error', e);
          notify('Error while loading data from server!');
        })
        .finally(() => {
          this.stopLoader();
        });
    } else {
      // in case handleGetData was not implemented
      this.setState(stateFromItem, () => this.stopLoader());
    }
  }

  handleUpdateState = (state) => {
    this.setState(state, this.handleMessages);
  };

  handleCancel = (e) => {
    if (!this.props.isEditable || !this.state.hasChanges) {
      this.setState({ open: false });
    } else {
      this.setState({ showConfirmCancellation: true });
    }
  };

  handleSubmit = (values) => {
    this.startLoader();
    this.service.TrySave(values, this.state);
  };

  handleValidate = (values) => {
    return this.service.ValidateItem(values, this.state);
  };

  handleCancelCancellation = (e) => {
    this.setState({ showConfirmCancellation: false });
  };

  handleConfirmCancellation = (e) => {
    this.setState({ showConfirmCancellation: false }, () => {
      this.setState({ open: false });
    });
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to={`/${this.props.indexPath}`} />;
    }
  };

  startLoader = () => {
    this.setState({ loading: true });
  };

  stopLoader = () => {
    this.setState({ loading: false });
  };

  handleFormChanged = (props) => {
    if (props.pristine !== this.state.pristine) {
      this.setState({ pristine: props.pristine });
    }
  };

  action = (event, sync) => {
    // react controlled input does not like async state updates
    // cursor will jump to the end after async setState
    if (sync) {
      this.setState({ [event.fieldName]: event.value });
    }

    this.service.Action(event, this.state).then((newState) => {
      this.setState(newState, this.handleMessages);
    });
  };

  isValid = (fieldName) => {
    return !(this.service.ValidateItem(this.state)[fieldName] === undefined);
  };

  handleMessages = () => {
    for (let message of this.state.messages.filter((m) => !m.handled)) {
      message.handled = true;
      switch (message.type) {
        case 'triggerSaveSucceeded':
          // on save succeeded
          if (this.handleItemSave) {
            this.handleItemSave(this.state.returnedItem);
          }
          break;
        case 'message':
          notify(message.payload);
          break;
        case 'dialog':
          this.genericDialog(message.payload);
          break;
        case 'redirect':
          this.props.history.push(message.payload);
          break;
        case 'close':
          this.setState({ open: false });
          break;
        default:
      }
    }
  };

  getTitle = () => {
    if (this.state.loading) {
      return 'loading ...';
    } else if (this.props.title) {
      return this.props.title;
    } else if (!this.props.isEditable) {
      // view item
      return `${this.props.i18nCommon.view} ${this.props.i18n.header} ${this.item.id}`;
    } else if (!this.item.id) {
      // create item
      return `${this.props.i18n.create}`;
    } else {
      // edit item
      return `${this.props.i18n.edit} ${this.item.id}`;
    }
  };

  navigateEdit = () => {
    this.props.history.push(`/${this.props.indexPath}/edit/${this.state.id}`);
  };

  genericDialog(payload) {
    this.setState({
      showGenericDialog: true,
      genericDialogTitle: payload.title,
      genericDialogText: payload.text,
    });
  }

  handleCloseGenericDialog() {
    this.setState({ showGenericDialog: false });
  }

  render() {
    const { classes, lang, i18n, i18nCommon } = this.props;
    const fieldProps = {
      action: this.action,
      isValid: this.isValid,
      formState: this.state,
      classes,
      lang,
      i18n,
      i18nCommon,
      isEditable: this.props.isEditable,
      title: this.getTitle(),
      navigateEdit: this.navigateEdit,
    };

    return (
      <>
        {this.renderRedirect()}
        {this.state.loading && <Loader />}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className="modal">
            <Grid container direction="column" spacing={16}>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Close className="close" onClick={this.handleCancel.bind(this)} />
                </Grid>
                <Grid item xs={12}>
                  {this.props.header ? (
                    this.props.header(fieldProps)
                  ) : (
                    <Grid
                      container
                      spacing={24}
                      alignItems="center"
                      direction="row"
                      justify="space-between"
                    >
                      <Grid item xs={6}>
                        <Typography gutterBottom variant="h2" component="h2">
                          {fieldProps.title}
                        </Typography>
                      </Grid>

                      {!fieldProps.isEditable && (
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            className={classNames('btn', 'btn-edit', classes.buttonDialogHeader)}
                            color="primary"
                            onClick={fieldProps.navigateEdit}
                          >
                            <img src="/images/icons/icon-action-edit.svg" alt="edit" width="17" />
                            <div style={{ height: 'auto' }}>{fieldProps.i18nCommon.edit}</div>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Form
                  validate={this.handleValidate}
                  onSubmit={this.handleSubmit}
                  initialValues={this.state}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container item direction="column" xs={12} justify="space-between">
                        <Grid item>{this.props.render(fieldProps)}</Grid>
                        <Grid item>
                          {this.props.controllButtons ? (
                            this.props.controllButtons(fieldProps)
                          ) : (
                            <Grid container item direction="row" xs={12} justify="flex-end">
                              <Button
                                variant="contained"
                                className={classNames('button-cancel', classes.controllButtons)}
                                onClick={this.handleCancel.bind(this)}
                              >
                                <i></i>
                                {this.props.isEditable
                                  ? I18n[lang].common.cancel
                                  : I18n[lang].common.close}
                              </Button>
                              {this.props.isEditable && !this.props.hideSaveButton && (
                                <FormSpy subscription={{ invalid: true }}>
                                  {(props) => (
                                    <Button
                                      variant="contained"
                                      className={classNames(
                                        'button-submit',
                                        classes.controllButtons
                                      )}
                                      type="submit"
                                      color="primary"
                                      disabled={props.invalid}
                                    >
                                      <i></i>
                                      {I18n[lang].common.save}
                                    </Button>
                                  )}
                                </FormSpy>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  )}
                />
                <Dialog
                  open={this.state.showConfirmCancellation}
                  onClose={this.handleCancelCancellation}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{'Close without saving data?'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      All entered data will be lost!
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleCancelCancellation} color="primary">
                      Return
                    </Button>
                    <Button onClick={this.handleConfirmCancellation} color="primary" autoFocus>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.showGenericDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{this.state.genericDialogTitle}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {this.state.genericDialogText}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleCloseGenericDialog.bind(this)} color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(Edit));

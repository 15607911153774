import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form from './Form';
import { useApi } from 'support/api';
import { withNotifications } from 'components/notifications';

function Create(props) {
  const { notifications } = props;
  const { surveyId } = useParams();
  const history = useHistory();
  const api = useApi();

  const handleCreate = async (values) => {
    notifications.info('Saving', null);

    const { name, type, optional } = values;
    const response = await api.surveys(surveyId).questions.create({ name, type, optional });
    const question = await response.json();

    props.onCreate(question);

    if (Array.isArray(values.options)) {
      const options = values.options.map(
        (option, weight) => ({ ...option, weight })
      );

      await api.surveys(surveyId).questions(question.id).options.put({ options });
    }

    notifications.success('Saved');

    history.replace(`/surveys/${surveyId}/questions/${question.id}/edit`);
  }

  return (
    <Form onSubmit={handleCreate} />
  );
}

export default withNotifications(Create);

import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import fetchData from '../../utils/fetch';
import { Redirect } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { I18n } from '../../i18n';

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '94%',
    margin: '30px 3%',
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 150,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    left: '50%',
    height: '90vh',
    overflowY: 'auto',
    top: '50%',
    transform: `translate(-50%, -50%)`,
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  },
  alignRight: {
    textAlign: 'right'
  },
  data: {
    '& h4': {
      marginBottom: '0'
    }
  },
  buttonCancel: {
    marginRight: '20px'
  }
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

class ItemEdit extends React.Component {
  state = {
    open: true,
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  renderRedirect = () => {
      if (!this.state.open) {
        return <Redirect to='/donor-projects' />
      }
  }

  saveItem = async (values) => {
    if (values.id) {
      await fetchData('put', `/donor_projects/${values.id}`, values);
    } else {
      await fetchData('post', '/donor_projects', values);
    }
    this.setState({ open: false });

    this.props.handleItemSave();
  }

  onSubmit = async values => {
    await sleep(300)
    this.saveItem(values);
  }

  render() {
      const { classes, item, lang } = this.props;
      return (
        <>
          {this.renderRedirect()}

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div className="modal">
              <a href className="close" onClick={this.handleClose}>
                <Close/>
              </a>

              <Grid container spacing={24} alignItems='center'>
                <Grid item xs={6}>
                  <Typography gutterBottom variant="h2" component="h2">
                    { item ? `${I18n[lang].donorProjects.edit} ${item.id}` : I18n[lang].donorProjects.create }
                  </Typography>
                </Grid>
              </Grid>
              <Typography className={classes.data}>
                <Form
                  onSubmit={this.onSubmit}
                  initialValues={item}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>

                    <Field name="title">
                      {({ input }) => <TextField label={I18n[lang].donorProject.title} margin="normal" className='text-field' variant="outlined" {...input} />}
                    </Field>

                    <Field name="donor">
                      {({ input }) => <TextField label={I18n[lang].donorProject.donor} margin="normal" className='text-field' variant="outlined" {...input} />}
                    </Field>

                      <Grid container spacing={24} alignItems='center'>
                        <Grid item xs={12} className={classes.alignRight}>
                          <Button variant="contained" className='button-cancel' onClick={this.handleClose}>
                            <i></i>
                            {I18n[lang].common.cancel}
                          </Button>
                          <Button variant="contained" className='button-submit' type="submit" color="primary">
                            <i></i>
                            {I18n[lang].common.save}
                          </Button>
                        </Grid>
                      </Grid>

                    </form>
                  )}
                />
              </Typography>
            </div>
          </Modal>
        </>
      );
  }
}

export default withRoot(withStyles(styles)(ItemEdit));

import QuantitativeField from './QuantitativeField';
import QualitativeField from './QualitativeField';
import TextualField from './TextualField';

function Field(props) {
  const { type, name, label, scale } = props;

  switch(type) {
    case 'quantitative':
      return (
        <QuantitativeField
          name={name}
          label={label}
        />
      );
    case 'qualitative':
      return (
        <QualitativeField
          name={name}
          label={label}
          scale={scale}
        />
      );
    case 'textual':
      return (
        <TextualField
          name={name}
          label={label}
        />
      );
    default:
      return null;
  }
}

export default Field;

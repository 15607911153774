import { Field } from 'react-final-form';

function FormCondition({ when, is, children }) {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => value === is && children}
    </Field>
  );
}

export default FormCondition;

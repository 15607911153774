import React, { createRef } from "react";
import { observer } from "mobx-react";
import {
  Grid,
  Button,
  Typography,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Close, InsertDriveFile } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import GIZButton from "../../components/GIZButton";
import withRoot from "../../withRoot";
import { StoreContext } from "../../stores/context";
import fetchData from "../../utils/fetch";
import downloadFile from "../../utils/fetchFile";
import fetchBase64 from "../../utils/fetchBase64";
import { Link, Redirect } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { each, orderBy, isInteger } from "lodash";
import Loader from "../../components/loader";
import { I18n } from "../../i18n";
import classNames from "classnames";
import categoryCaptions from "../../utils/categoryCaptions";
import notify from "../../utils/notifier";
import { activityRoute } from "../../utils/routes";
import { ACTIVITY_ENDPOINT } from "../../utils/DataFetcher";
import FormattedTime from '../../components/v2/FormattedTime';
import ProjectCondition from 'components/conditions/ProjectCondition';

const styles = (theme) => ({
  root: {
    textAlign: "left",
    width: "94%",
    margin: "30px 3%",
  },
  button: {
    "&.disabled": {
      opacity: "0.3",
    },
  },
  modal: {
    position: "relative",
    maxWidth: theme.spacing.unit * 150,
    width: "94%",
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    margin: "3% auto",
    height: "90vh",
    overflowY: "auto",
    backgroundColor: "#f7f9fb",
    borderRadius: "15px",
    border: "0",
    outline: "0",
    "& a": {
      textDecoration: "none",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "20px",
  },
  alignRight: {
    textAlign: "right",
  },
  data: {
    "& h4": {
      color: "#6f6f6f",
      fontWeight: "300",
      fontSize: "19px",
      marginBottom: "0",
      marginTop: "10px",
    },
    "& p": {
      color: "#000000",
      fontSize: "13px",
      marginTop: "0",
    },
  },
  imageFullScreen: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    left: 0,
    top: 0,
    zIndex: "99999",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      maxHeight: "100vh",
      maxWidth: "90vw",
    },
    "& a": {
      position: "absolute",
      top: "20px",
      right: "20px",
      fontSize: "30px",
      fontWeight: "bold",
      color: "#fff",
    },
  },
  image: {
    width: "250px",
    cursor: "pointer",
  },
  table: {
    maxWidth: "100%",
    minWidth: 700,
    textAlign: "center",
    "& thead th:first-child": {
      paddingLeft: "15px",
    },
    "& thead th": {
      fontSize: "22px",
    },
    "& tbody td:first-child": {
      paddingLeft: "15px",
    },
    "& tbody tr": {
      verticalAlign: "top",
    },
    "& tbody td": {
      paddingTop: "20px",
    },
  },
  charts: {
    padding: "0 0 40px",
    "& h4": {
      textAlign: "center",
      marginBottom: "30px",
    },
  },
  buttons: {
    "& svg": {
      fontSize: "16px",
    },
    "& button": {
      fontSize: "12px",
      marginRight: "10px",
      marginBottom: "9px",
      padding: "8px",
      borderRadius: "4px",
    },
    "& button span": {
      minWidth: "auto",
      justifyContent: "flex-start",
    },
  },
  buttonYellow: {
    backgroundColor: "#f0ad4e",
    "&:hover": {
      backgroundColor: "#f0ad4e",
      opacity: "0.9",
    },
  },
  blockLink: {
    fontSize: "14px",
    color: "#5f6f80",
    fontWeight: "700",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  hromada: {
    marginBottom: "50px",
    "& table": {
      minWidth: "0",
      marginTop: "15px",
    },
  },
  titleBlue: {
    fontSize: "40px",
    fontWeight: "700",
    color: "#88adf0",
    textTransform: "uppercase",
    lineHeight: "1",
    margin: "0",
  },
  titleYellow: {
    fontSize: "40px",
    fontWeight: "700",
    textTransform: "uppercase",
    lineHeight: "1",
    margin: "0",
  },
  participantsRegistered: {
    padding: "30px",
    backgroundColor: "#fff",
    marginBottom: "30px",
  },
  linkReport: {
    display: "flex",
    alignItems: "center",
    color: "#5f6f80",
    lineHeight: "1",
    "& svg": {
      fontSize: "18px",
      color: "#0275d8",
      marginRight: "3px",
    },
  },
});

const genderDistribution = {
  labels: ["Male", "Female", "Unknown"],
  datasets: [
    {
      data: [],
      backgroundColor: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(88, 84, 215, 0.2)",
      ],
      borderColor: [
        "rgba(54, 162, 235, 1)",
        "rgba(255,99,132,1)",
        "rgba(88, 84, 215, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const employerLevelDistribution = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    },
  ],
};

const employerTypeDistribution = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    },
  ],
};

class ItemView extends React.Component {
  static contextType = StoreContext;

  state = {
    loading: true,
    item: null,
    open: true,
    isPhotoFull: false,
    photoFullBase64: null,
    photoPreviewBase64: null,
    list_participants: true,
    list_empty_participants: true,
    print_info: true,
  };

  dynamicColor = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return [r, g, b];
  };

  constructor(props) {
    super(props);
    this.fileInputRef = createRef();
  }

  componentDidMount() {
    const { item } = this.props;
    if (item && item.id) {
      this.onItemLoad(item);
    }
    this.getData(this.props.id);
  }

  onItemLoad(item) {
    if (!item || item.error || isInteger(item)) {
      this.setState({ open: false, loading: false });
      alert("Activity not found");
      return;
    }
    this.setActivityStats(item);
    this.setState({ item: item, loading: false });

    if (item.activityFotoPreviewUrl) {
      fetchBase64(item.activityFotoUrl, (data) => {
        this.setState({ photoPreviewBase64: data });
      });
    }
  }

  isTrainingPackeVisible(item) {
    const trainingPackageTypes = item.trainingPackageTypes
      ? item.trainingPackageTypes[0]?.id
      : 0;
    const thematicModules = item.thematicModules
      ? item.thematicModules[0]?.id
      : 0;

    return (
      (trainingPackageTypes > 0 && thematicModules > 0) ||
      trainingPackageTypes === 1
    );
  }

  isThematicModuleVisible(item) {
    const trainingPackageTypes = item.trainingPackageTypes
      ? item.trainingPackageTypes[0]?.id
      : 0;
    const thematicModules = item.thematicModules
      ? item.thematicModules[0]?.id
      : 0;
    return trainingPackageTypes !== 1 && thematicModules > 0;
  }

  setActivityStats(item) {
    if (!item.genderDistribution) {
      return;
    }

    genderDistribution.datasets[0].data = item.genderDistribution.map(
      (data) => data[1]
    );
    genderDistribution.labels.forEach((label, i) => {
      const dist = item.genderDistribution.find(
        (x) => x[0].toLowerCase() === label.toLowerCase()
      );
      genderDistribution.datasets[0].data[i] = dist ? dist[1] : 0;
    });

    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this);
        }
      };
    }

    [
      [item.employerLevelDistribution, employerLevelDistribution],
      [item.employerTypeDistribution, employerTypeDistribution],
    ].forEach((element) => {
      let [dataset, donut] = element;
      donut.labels = [];
      donut.datasets[0].data = [];
      donut.datasets[0].backgroundColor = [];
      donut.datasets[0].borderColor = [];
      each(dataset, (data) => {
        const [label, value] = data;
        donut.labels.push(label.en);
        donut.datasets[0].data.push(value);
        let color = this.dynamicColor();
        color = `${color[0]},${color[1]},${color[2]}`;
        donut.datasets[0].backgroundColor.push(`rgba(${color},0.2)`);
        donut.datasets[0].borderColor.push(`rgba(${color},1)`);
      });
    });
  }

  async getData(id) {
    const data = await fetchData("get", `${ACTIVITY_ENDPOINT}/${id}`);
    this.onItemLoad(data[0]);
  }

  handleDelete = async (id) => {
    const { lang } = this.props;
    if (
      window.confirm(
        `${I18n[lang].common.deleteConfirm} ${I18n[lang].activities.activity} ${id}?`
      )
    ) {
      await fetchData("delete", `${ACTIVITY_ENDPOINT}/${id}`);
      this.setState({ open: false, loading: false, item: null });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    this.renderRedirect();
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to={activityRoute} />;
    }
  };

  handlePDFDownload = async (fileName) => {
    if (this.state[fileName]) {
      const withLang = this.props.lang ? "?lang=" + this.props.lang : "";
      this.setState({ [fileName]: false });
      await downloadFile(
        `${ACTIVITY_ENDPOINT}/${this.state.item.id}/${fileName}.pdf${withLang}`
      );
      this.setState({ [fileName]: true });
    }
  };

  handleDownload = (file) => {
    return async (e) => {
      e.preventDefault();
      downloadFile(file);
    };
  };

  handleImportParticipantsClick = () => {
    this.fileInputRef.current?.click();
  };

  handleImportParticipantsChange = async () => {
    if (this.fileInputRef.current?.files?.length === 0) {
      return;
    }

    const activityId = this.state.item.id;
    const { activityItemStore } = this.context;
    try {
      await activityItemStore.uploadParticipants(
        activityId,
        this.fileInputRef.current.files[0]
      );
      this.getData(activityId);

      notify(
        `File was uploaded successfully. Added ${activityItemStore.importedRows} row(s). ${activityItemStore.importedErrors} error(s) found.`
      );
    } catch {
      notify("Failed to upload data to server.");
    }
  };

  handleParticipantTemplateDownload = async () => {
    await this.context.activityItemStore.downloadParticipantsTemplate();
  };

  normalizeMediaLink = (item) => {
    let link = {};
    const url_regexp = /^(https?|ftp):\/\//;
    const domain_regexp = /^(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}(\/)?/;
    let url = item.url.trim();
    let title = item.title.trim();
    if (url.length && title.length) {
      if (!url.match(url_regexp) && url.match(domain_regexp)) {
        url = "http://" + url;
      } else if (title.match(url_regexp)) {
        let title1 = title;
        title = url;
        url = title1;
      } else if (!url.match(url_regexp)) {
        return "";
      }
      link.url = url;
      link.title = title;
    }
    if (link.url) {
      return (
        <>
          <a href={link.url} target="_blank" rel="noreferrer">
            {link.title}
          </a>
          <br />
        </>
      );
    } else {
      return "";
    }
  };

  handleFullScreen = () => {
    if (this.state.photoFullBase64) {
      this.setState({ isPhotoFull: true });
    } else {
      fetchBase64(this.state.item.activityFotoUrl, (data) => {
        this.setState({
          isPhotoFull: true,
          photoFullBase64: data,
        });
      });
    }
  };

  closeFull = (e) => {
    e.preventDefault();
    this.setState({ isPhotoFull: false });
  };

  async deleteParticipant(participant) {
    const { id } = this.state.item;
    const { lang } = this.props;
    if (
      window.confirm(
        `${I18n[lang].common.deleteConfirm} ${I18n[lang].participants.participant} ${participant.id} ${I18n[lang].common.fromActivity}?`
      )
    ) {
      await fetchData(
        "delete",
        `/participants/disconnect/${participant.id}/${id}`
      );
      this.getData(id);
    }
  }

  // “Trainers” should not appear in the activity view window for
  // `working meetings/consultations`, `study trips`, `visibility/press activities`,
  // `conference/forum`, `dialogue activities`
  // ────────────────────────────────────────────────────────────────────────────────
  // Change https://archer-soft.atlassian.net/browse/ME-104
  // Now trainers will be shown ONLY when format is 3 (Trainers and Semintras)
  showTrainers() {
    const specificEventFormats = [3];
    const expectedId = specificEventFormats.includes(
      this.state?.item?.format?.id
    );
    const hasTrainers = Array.isArray(this.state?.item?.trainers) &&
      this.state?.item?.trainers?.length !== 0;
    return expectedId && hasTrainers;
  }

  // Facilitators should appear format is "dialogue activity with civil society"
  showFacilitators() {
    const specificEventFormats = [4, 5];
    const expectedId = specificEventFormats.includes(
      this.state?.item?.format?.id
    );
    const haveFacilitators =
      Array.isArray(this.state?.item?.facilitators) &&
      this.state?.item?.facilitators?.length !== 0;
    return expectedId && haveFacilitators;
  }

  showPlace() {
    const { id } = this.state.item.format;
    return id === 3 || id === 4 || id === 10 || id === 11;
  }

  isTraining() {
    return this.state.item.format.id === 3;
  }

  isDigitalTool() {
    return this.state.item.format.id === 9;
  }

  isGrantVoucher() {
    return this.state.item.format.id === 12;
  }

  communityNiceTitle(title) {
    const subString = title.trimRight().split(" ").splice(-3).join(" ");
    return title.replace(subString, this.props.lang === "Eng" ? "AH" : "ОТГ");
  }

  render() {
    const { classes, user } = this.props;
    const { langStore, userStore } = this.context;
    const { lang } = langStore;
    const {
      item,
      isPhotoFull,
      loading,
      photoFullBase64,
      photoPreviewBase64,
    } = this.state;

    if ((!item || !item.id) && loading) {
      return "";
    }
    if (!loading && !item) {
      return this.renderRedirect();
    }
    const catCaptions = categoryCaptions(user);

    return (
      <>
        {this.state.loading && <Loader />}

        {this.renderRedirect()}

        {isPhotoFull && photoFullBase64 && (
          <div className={classes.imageFullScreen}>
            <img src={photoFullBase64} alt="" />
            <Button onClick={this.closeFull}>
              X
            </Button>
          </div>
        )}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classes.modal}>
            <Button className="close" onClick={this.handleClose}>
              <Close />
            </Button>
            <Grid container spacing={24} alignItems="center">
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="h2"
                  className={classes.titleYellow}
                >
                  {I18n[lang].activities.activity} {item.id}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={24}
              alignItems="center"
              className={classes.buttons}
            >
              <Grid item xs={12}>
                {!item.restrictedView && !item.readOnly && (
                  <>
                    <Link to={`${activityRoute}/edit/${item.id}`}>
                      <GIZButton color="primary" icon="Edit">
                        {I18n[lang].common.edit}
                      </GIZButton>
                    </Link>
                    <GIZButton
                      color="secondary"
                      icon="Delete"
                      onClick={this.handleDelete.bind(this, item.id)}
                    >
                      {I18n[lang].common.delete}
                    </GIZButton>

                    {!userStore.isCurrentProject('AZP') && (
                      <>
                        <Link to={`/activity/${item.id}/participants/add`}>
                          <GIZButton color="tertiary" icon="Add">
                            {I18n[lang].activities.addParticipant}
                          </GIZButton>
                        </Link>
                        <Link to={`/activity/${item.id}/participants/import`}>
                          <GIZButton color="tertiary" icon="Add">
                            Import participants
                          </GIZButton>
                        </Link>
                      </>
                    )}

                    {userStore.isCurrentProject('AZP') && (
                      item.partakableType === 'organization' ? (
                        <Link to={`/activity/${item.id}/organizations`}>
                          <GIZButton color="tertiary" icon="Reorder">
                            Organizations List
                          </GIZButton>
                        </Link>
                      ) : (
                        <Link to={`/activity/${item.id}/participants`}>
                          <GIZButton color="tertiary" icon="Reorder">
                            Participants List
                          </GIZButton>
                        </Link>
                      )
                    )}
                  </>
                )}
                {!item.restrictedView && (
                  <>
                    <GIZButton
                      color="quaternary"
                      icon="List"
                      onClick={this.handlePDFDownload.bind(
                        this,
                        "list_participants"
                      )}
                      disabled={!this.state.list_participants}
                    >
                      {I18n[lang].activities.printList}
                    </GIZButton>
                    <GIZButton
                      color="quaternary"
                      icon="Reorder"
                      onClick={this.handlePDFDownload.bind(
                        this,
                        "list_empty_participants"
                      )}
                      disabled={!this.state.list_empty_participants}
                    >
                      {I18n[lang].activities.printEmptyList}
                    </GIZButton>
                  </>
                )}
                <GIZButton
                  color="quaternary"
                  icon="Info"
                  onClick={this.handlePDFDownload.bind(this, "print_info")}
                  disabled={!this.state.print_info}
                >
                  {I18n[lang].activities.printActivityInfo}
                </GIZButton>
              </Grid>
            </Grid>
            <Typography className={classes.data} component="div">
              <Grid container spacing={24} alignItems="flex-start">
                <Grid item xs={6}>
                  <h4>{I18n[lang].activities.nameEn}</h4>
                  <p>{item.nameEn}</p>
                  <h4>{I18n[lang].activities.descEn}</h4>
                  <p>{item.descEn}</p>
                  <h4>{String(I18n[lang].activities.startDate)}</h4>
                  <p>{String(item.startDate)}</p>

                  <h4>{I18n[lang].activities.startTime}</h4>
                  <p>
                    <FormattedTime value={item.startTime} />
                  </p>

                  <h4>{I18n[lang].activities.internalFeedback}</h4>
                  <p>{item.internalFeedback}</p>
                  <h4>{I18n[lang].activities.expectedParticipants}</h4>
                  <p>{item.expectedNumberOfParticipants}</p>
                  <h4>{I18n[lang].activities.actualParticipants}</h4>
                  <p>{item.actualNumberOfParticipants || 0}</p>
                  <h4>{I18n[lang].activities.activityReport}</h4>
                  <p>
                    {item.activityReportUrl ? (
                      <Link
                        to="#"
                        className={classes.linkReport}
                        onClick={this.handleDownload(item.activityReportUrl)}
                      >
                        <InsertDriveFile />
                        {item.activityReportSize}
                      </Link>
                    ) : (
                      I18n[lang].activities.reportMissing
                    )}
                  </p>

                  <ProjectCondition not abbr="AZP">
                    <h4>{I18n[lang].activities.region}</h4>
                    <p>{lang === "Ukr" ? item.oblastUa : item.oblastEn}</p>
                  </ProjectCondition>

                  <ProjectCondition abbr="AZP">
                    <h4>Regions</h4>
                    {item.regions.map((region) => (
                      <div key={region.id}>
                        {region.name}
                      </div>
                    ))}
                  </ProjectCondition>

                  <h4>{I18n[lang].activities.createdBy}</h4>
                  <p>{item.ownerLogin}</p>
                  <h4>{I18n[lang].activities.implementedVia}</h4>
                  <p>{item.csoGrant?.projectTitle}</p>

                  {this.isTraining() && (
                    <>
                      <h4>{I18n[lang].activities.kind}</h4>
                      <p>{item.kind}</p>
                      <h4>{I18n[lang].activities.characteristic}</h4>
                      <p>
                        {lang === "Ukr"
                          ? item.characteristic?.titleUa
                          : item.characteristic?.titleEn}
                      </p>
                    </>
                  )}

                  {this.showPlace() && (
                    <>
                      <h4>{I18n[lang].activities.place}</h4>
                      <p>{item.place}</p>
                    </>
                  )}

                  {this.isDigitalTool() && (
                    <>
                      <h4>{I18n[lang].activities.targetedPersons}</h4>
                      <p>{item.targetedPersons}</p>
                      <h4>{I18n[lang].activities.uniqueUsers}</h4>
                      <p>{item.uniqueUsers}</p>
                    </>
                  )}

                  {this.isGrantVoucher() && (
                    <>
                      <h4>{I18n[lang].activities.advisoryType}</h4>
                      <p>{item.advisoryType}</p>
                      <h4>{I18n[lang].activities.grantValue}</h4>
                      <p>{item.grantValue}</p>
                      <h4>{I18n[lang].activities.grantCurrency}</h4>
                      <p>{item.grantCurrency}</p>
                      {item.organization && (
                        <>
                          <h4>{I18n[lang].activities.organization}</h4>
                          <p>
                            {langStore.isEng
                              ? item.organization.nameEn
                              : item.organization.nameUa}
                          </p>
                        </>
                      )}
                    </>
                  )}

                  {this.showTrainers() &&
                    (!item.restrictedView || item.specialRights) && (
                      <>
                        <h4>{I18n[lang].activities.trainers}</h4>
                        <p>
                          {item.trainers &&
                            item.trainers.map((trainer, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Link
                                    className={classes.blockLink}
                                    to={`/trainers/view/${trainer.id}`}
                                  >
                                    {trainer.name} | {trainer.nameTranscription}
                                  </Link>
                                  <br />
                                  {trainer.info && trainer.info.length && (
                                    <>
                                      <span className={classes.textSmall}>
                                        {trainer.info}
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                        </p>
                      </>
                    )}

                  {this.showFacilitators() && (
                    <>
                      <h4>{I18n[lang].activities.facilitators}</h4>
                      <p>
                        {item.facilitators &&
                          item.facilitators.map((facilitator, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Link
                                  className={classes.blockLink}
                                  to={`/facilitators/view/${facilitator.id}`}
                                >
                                  {facilitator.name}
                                </Link>
                                <br />
                              </React.Fragment>
                            );
                          })}
                      </p>
                    </>
                  )}

                  <h4>{catCaptions.categories}</h4>
                  <p>
                    {item.categories &&
                      item.categories.map((category, index) => {
                        if (category.langKey === "not_applicable") {
                          return "";
                        }
                        return (
                          <React.Fragment key={index}>
                            {lang === "Ukr" ? category.ua : category.en}
                            <br />
                          </React.Fragment>
                        );
                      })}
                  </p>
                  {item.categoryResults?.length > 0 && (
                    <>
                      <h4>{I18n[lang].activities.results}</h4>
                      <p>
                        {item.categoryResults.map((result, index) => {
                          return (
                            <React.Fragment key={index}>
                              {result.nameEn}
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </p>
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <h4>{I18n[lang].activities.endDate}</h4>
                  <p>{item.endDate}</p>

                  <h4>{I18n[lang].activities.endTime}</h4>
                  <p>
                    <FormattedTime value={item.endTime} />
                  </p>

                  <p>
                    {item.linkToRegistration &&
                      this.normalizeMediaLink({
                        url: item.linkToRegistration,
                        title: I18n[lang].activities.linkToRegistration
                      })
                    }
                  </p>

                  <p>
                    {item.linkToDescription &&
                      this.normalizeMediaLink({
                        url: item.linkToDescription,
                        title: I18n[lang].activities.linkToDescription
                      })
                    }
                  </p>

                  <h4>{I18n[lang].activities.mediaReferences}</h4>
                  <p>
                    {item.mediaLinks &&
                      item.mediaLinks.map((item, index) => (
                        <React.Fragment key={index}>
                          {this.normalizeMediaLink(item)}
                        </React.Fragment>
                      ))}
                  </p>
                  <h4>{I18n[lang].activities.fundingSources}</h4>
                  <p>
                    {item.fundingSources &&
                      item.fundingSources.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {lang === "Ukr" ? item.ua : item.en}
                            <br />
                          </React.Fragment>
                        );
                      })}
                  </p>

                  <h4>{I18n[lang].activities.activityAddress}</h4>
                  <p>{item.activityAddress}</p>

                  <h4>{I18n[lang].activities.activityFoto}</h4>
                  <p>
                    {photoPreviewBase64 && (
                      <img
                        className={classNames(classes.image)}
                        onClick={this.handleFullScreen}
                        src={photoPreviewBase64}
                        alt=""
                      />
                    )}
                  </p>

                  <h4>{I18n[lang].activities.targetGroups}</h4>
                  <p>
                    {item.targetGroups &&
                      item.targetGroups.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {lang === "Ukr" ? item.ua : item.en}
                            <br />
                          </React.Fragment>
                        );
                      })}
                  </p>
                  {item.targetLevels && item.targetLevels.length && (
                    <>
                      <h4>{I18n[lang].activities.targetLevels}</h4>
                      <p>
                        {item.targetLevels.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              {lang === "Ukr" ? item.ua : item.en}
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </p>
                    </>
                  )}
                  <h4>{I18n[lang].activities.activityFormat}</h4>
                  <p>{lang === "Ukr" ? item.format.ua : item.format.en}</p>

                  <h4>{I18n[lang].activities.responsiblePerson}</h4>
                  <p>{item.responsiblePerson}</p>

                  {this.isTrainingPackeVisible(item) &&
                    !this.isThematicModuleVisible(item) && (
                      <>
                        {" "}
                        <h4>{I18n[lang].activities.thematicSupportPackage}</h4>
                        <p>{item.trainingPackageTypes[0].nameEn}</p>
                      </>
                    )}

                  {this.isTrainingPackeVisible(item) &&
                    this.isThematicModuleVisible(item) && (
                      <>
                        {" "}
                        <h4>{I18n[lang].activities.thematicSupportPackage}</h4>
                        <p>
                          {item.trainingPackageTypes[0].nameEn}:{" "}
                          {item.thematicModules[0].nameEn}
                        </p>
                      </>
                    )}

                  {item.europeActions && item.europeActions[0] && (
                    <>
                      <h4>{I18n[lang].activities.regionalInitiatives}</h4>
                      <p>
                        {lang === "Ukr"
                          ? item.europeActions[0].nameUa
                          : item.europeActions[0].nameEn}
                      </p>
                    </>
                  )}

                  {(item.activityCharacters?.length && (
                    <>
                      <h4>{I18n[lang].activities.activityCharacters}</h4>
                      <p>
                        {item.activityCharacters.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              {lang === "Ukr" ? item.ua : item.en}
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </p>
                    </>
                  )) ||
                    ""}

                  <h4>Tags</h4>
                  <p>{item.tags && item.tags.map((tag) => tag.name).join(', ')}</p>
                </Grid>
              </Grid>

              {!userStore.isCurrentProject('AZP') && (
                ((item.participantsCount ||
                  (item.participants && item.participants.length)) && (
                  <div className={classes.participantsRegistered}>
                    <Grid container spacing={24} alignItems="flex-start">
                      <Grid item xs={12}>
                        <h2 className={classes.titleBlue}>
                          {I18n[lang].activities.participantRegistered}:{" "}
                          {item.participantsCount || item.participants.length}
                        </h2>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={24}
                      alignItems="flex-start"
                      className={classes.charts}
                    >
                      <Grid item xs={4}>
                        <h4>{I18n[lang].activities.genderDistribution}</h4>
                        <Doughnut
                          data={genderDistribution}
                          options={{ legend: { display: false } }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <h4>{I18n[lang].activities.employerLevelDistribution}</h4>
                        <Doughnut
                          data={employerLevelDistribution}
                          options={{ legend: { display: false } }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <h4>{I18n[lang].activities.employerTypeDistribution}</h4>
                        <Doughnut
                          data={employerTypeDistribution}
                          options={{ legend: { display: false } }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )) ||
                  ""
              )}

              {item.numberOfParticipantsByCommunity && (
                <Grid
                  container
                  spacing={24}
                  alignItems="flex-start"
                  className={classes.hromada}
                >
                  {Object.keys(item.numberOfParticipantsByCommunity).map(
                    (community, index) => {
                      return (
                        <Grid item xs={4} key={index}>
                          <h4>{community}</h4>
                          <Paper>
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    {I18n[lang].activities.location}
                                  </TableCell>
                                  <TableCell>
                                    {I18n[lang].activities.participants}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {item.numberOfParticipantsByCommunity[
                                  community
                                ].map((item, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        {this.communityNiceTitle(
                                          item[lang === "Eng" ? "en" : "ua"]
                                        )}
                                      </TableCell>
                                      <TableCell>{item.count}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Paper>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              )}

              {!userStore.isCurrentProject('AZP') && (!item.restrictedView || item.specialRights) && (
                <>
                  <Grid container spacing={24} alignItems="flex-start">
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        variant="h2"
                        component="h3"
                        className={classes.titleYellow}
                      >
                        {I18n[lang].activities.participants}
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                  <Paper>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>{I18n[lang].activities.name}</TableCell>
                          <TableCell>{I18n[lang].activities.gender}</TableCell>
                          <TableCell>
                            {I18n[lang].activities.cellPhone}
                          </TableCell>
                          <TableCell>
                            {I18n[lang].participants.region}
                          </TableCell>
                          <TableCell>
                            {I18n[lang].participants.organization}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.participants &&
                          orderBy(
                            item.participants,
                            lang === "Eng" ? "nameTranscription" : "name",
                            "asc"
                          ).map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`/participants/view/${row.id}`}
                                  >
                                    {lang === "Eng"
                                      ? row.nameTranscription
                                      : row.name}
                                  </a>
                                </TableCell>
                                <TableCell>
                                  {I18n[lang].common[row.gender] ?? ""}
                                </TableCell>
                                <TableCell>{row.cellPhone}</TableCell>
                                <TableCell>
                                  {row.communityId ? (
                                    <a
                                      href={`/communities/view/${row.communityId}`}
                                    >
                                      {this.communityNiceTitle(
                                        lang === "Eng"
                                          ? row.communityNameEn
                                          : row.communityNameUa
                                      )}
                                    </a>
                                  ) : lang === "Eng" ? (
                                    row.oblastEn
                                  ) : (
                                    row.oblastUa
                                  )}
                                </TableCell>
                                <TableCell>
                                  {lang === "Eng"
                                    ? row.employerName
                                    : row.employerNameUa}
                                </TableCell>
                                <TableCell>
                                  {!item.restrictedView && (
                                    <Button
                                      size="small"
                                      variant="contained"
                                      className="btn-delete"
                                      color="primary"
                                      onClick={() =>
                                        this.deleteParticipant(row)
                                      }
                                    >
                                      {I18n[lang].common.delete}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Paper>
                </>
              )}
            </Typography>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(observer(ItemView)));

import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import DefaultLoader from 'components/loader';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px'
  },
  compact: {
    height: 'auto'
  }
});

function Loader(props) {
  const { compact, classes } = props;

  return (
    <div className={classNames(classes.root, { [classes.compact]: compact })}>
      <CircularProgress />
    </div>
  );
}

export default withStyles(styles)(Loader);

function withLoader(loader) {
  return (Component) => {
    function WithLoader(props) {
      const [ loading, setLoading ] = useState(true);
      const Loader = loader || DefaultLoader;

      return (
        <>
          {loading && <Loader />}
          <Component {...props} setLoading={setLoading} />
        </>
      );
    }

    return WithLoader;
  };
}

export { withLoader };

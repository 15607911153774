import SingleChoiceAnswerField from './SingleChoiceAnswerField';
import MultipleChoiceAnswerField from './MultipleChoiceAnswerField';
import TextAnswerField from './TextAnswerField';

function AnswerField(props) {
  const { question } = props;

  switch (question.type) {
    case 'radios':
      return <SingleChoiceAnswerField question={question} />;
    case 'checkboxes':
      return <MultipleChoiceAnswerField question={question} />;
    case 'text':
    default:
      return <TextAnswerField question={question} />;
  }
}

export default AnswerField;

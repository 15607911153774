import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from './Paper';

function Page(props) {
  const { children, navigation } = props;

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar>
          {navigation}
        </Toolbar>
      </AppBar>
      <Paper>
        {children}
      </Paper>
    </>
  );
}

export default Page;

import React from 'react';
import PropTypes from 'prop-types';
import Edit from '../../components/dialog/edit.js';
import { FormTextField, FormSelectField } from '../../components/dialog/formField';
import { Paper, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../style/styles';

const Item = (props) => {
  const { i18n, serviceClass } = { ...props };
  const { classes, ...rest } = { i18n, ...props };

  return (
    <Edit
      i18n={i18n}
      serviceClass={serviceClass}
      {...rest}
      render={(fieldProps) => (
        <Paper className={classes.paper}>
          <Grid container={true} direction="column" spacing={16}>
            <Grid item xs={6}>
              <FormTextField fieldName="name" i18n={i18n} {...fieldProps} />
            </Grid>
            <Grid item xs={6}>
              <FormTextField fieldName="nameTranscription" i18n={i18n} {...fieldProps} />
            </Grid>
            <Grid item xs={2}>
              <FormSelectField
                selectList={fieldProps.formState.genderOptions}
                fieldName="gender"
                label={i18n.result}
                i18n={i18n}
                {...fieldProps}
              />
            </Grid>
            <Grid item xs={2}>
              <FormTextField fieldName="email" i18n={i18n} {...fieldProps} />
            </Grid>
            <Grid item xs={2}>
              <FormTextField fieldName="cellPhone" i18n={i18n} {...fieldProps} />
            </Grid>
          </Grid>
        </Paper>
      )}
    />
  );
};

Item.propTypes = {
  i18n: PropTypes.object.isRequired,
  serviceClass: PropTypes.any.isRequired,
};

export default withStyles(styles)(Item);

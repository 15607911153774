import { MapControl, withLeaflet } from "react-leaflet";
import { GeoSearchControl, GoogleProvider } from "leaflet-geosearch";

class AddressControl extends MapControl {
  constructor(props) {
    super(props);

    this.props = props;
  }

  createLeafletElement() {
    const provider = new GoogleProvider({
      params: {
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        region: "ua",
      },
    });
    return GeoSearchControl({
      provider: provider,
      style: "bar",
      popupFormat: ({ query, result }) => {
        this.props.onCoordinatesChange(
          [result.y, result.x],
          result.raw.address_components,
          query
        );
      },
      autoClose: true,
      showMarker: true,
      keepResult: true,
    });
  }
}

export default withLeaflet(AddressControl);

import { config } from "./config";
export const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    fontFamily: config.fontFamily,
    fontSize: config.input.fontSize,
    color: config.input.color,
  }),
  container: (provided) => ({
    ...provided,
    borderColor: "transparent",
    borderRadius: config.input.borderRadius,
    borderTopLeftRadius: config.input.borderRadius,
    borderBottomLeftRadius: config.input.borderRadius,
    fontFamily: config.input.fontFamily,
    fontSize: config.input.fontSize,
    color: config.input.color,
    "&.errorSelect": {
      borderLeftStyle: "solid",
      borderLeftWidth: 7,
      borderColor: " #c80f0f",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontFamily: "roboto, san-serif",
    fontSize: 14,
    color: "#778899 ",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: config.input.fontFamily,
    fontSize: config.input.fontSize,
    color: config.input.color,
  }),
  control: (provided) => ({
    ...provided,
    background: config.input.background,
    minHeight: "inherit",
    borderTopLeftRadius: config.input.borderRadius,
    borderBottomLeftRadius: config.input.borderRadius,
    fontFamily: config.input.fontFamily,
    fontSize: config.input.fontSize,
    color: config.input.color,
    borderStyle: "none",
    "&:hover": {
      boxShadow: "inset 0 0 0px 2px #c80f0f",
    },
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (provided) => ({
    borderTopRightRadius: config.input.borderRadius,
    borderBottomRightRadius: config.input.borderRadius,
    height: "100%",
    width: "28px",
    backgroundColor: "#B4C3D1",
    background:
      "#c80f0f url(/images/icons/icon-arrBot.png) no-repeat center center / 20px 20px",
    color: "transparent",
  }),
};

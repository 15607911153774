import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'components/loader';
import MessageBox from 'components/MessageBox';
import { useApi } from 'support/api';

const styles = {
  root: {
    margin: '0 auto',
    padding: '20px 0',
    maxWidth: '400px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  upload: {
    padding: '20px 0',
    width: '100%',
    textAlign: 'center'
  }
};

function ParticipantsImport(props) {
  const { classes } = props; 
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const api = useApi();
  const { id } = useParams();
  
  async function upload(event) {
    const files = event.target.files;

    if (!files.length) {
      return;
    }

    const payload = new FormData();

    payload.append('list[document]', files[0]);

    setLoading(true);

    let response = await api.activities(id).lists.create(payload);
    const list = await response.json();

    response = await api.activities(id).lists(list.id).import.create();
    const result = await response.json();

    const partakerIds = result.data.map((p) => p.id);

    response = await api.activities(id).participations.create({ 
      partakable_ids: partakerIds
    });

    setResult(result);
    setLoading(false);
  }

  return (
    <div className={classes.root}>
      {loading && <Loader />}
      {!result ? (
        <>
          <MessageBox variant="info">Upload partakers list in Excel format</MessageBox>
          <div className={classes.upload}>
            <input 
              type="file"
              accept=".xlsx"
              onChange={upload}
            />
          </div>
        </>
      ) : (
        <>
          <MessageBox variant="success">Partakers imported: {result.data.length}</MessageBox>
          {result.errors && result.errors.length > 0 && (
            <MessageBox variant="error">
              Following partakers have not been imported, due to missing data:
              <ul>
                {result.errors.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </MessageBox>
          )}
        </>
      )}

      <Button 
        component={Link}
        to={`/legacy/activities/view/${id}`}
        color="primary" 
        variant="outlined"
      >
        Go back to activity
      </Button>

    </div>
  );
}

export default withStyles(styles)(ParticipantsImport);

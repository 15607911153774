const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

const DEFAULT_HEADERS = {
  accept: 'application/json'
}

class ResourceDispatcher extends Function {

  constructor(uri, headers) {
    super();

    this.uri = uri;
    this.root = uri;
    this.headers = {
      ...DEFAULT_HEADERS,
      ...headers,
      Authorization: localStorage.getItem('TOKEN')
    };

    const proxy = new Proxy(this, {
      get: (target, property) => {
        if (property in target) {
          return target[property];
        }

        target.appendUri(property);

        return proxy;
      },
      apply: (target, thisArg, args) => { 
        target.appendUri(...args);

        return proxy;
      }
    });

    return proxy;
  }

  appendUri(path) {
    this.uri += '/' + path;

    return this;
  }

  get(format) {
    return this.send(this.uri + (format ? '.' + format : ''), HTTP_METHODS.GET, this.headers);
  }

  create(body) {
    return this.send(this.uri, HTTP_METHODS.POST, this.headers, body);
  }

  update(body) {
    return this.send(this.uri, HTTP_METHODS.PUT, this.headers, body);
  }

  put(body) {
    return this.send(this.uri, HTTP_METHODS.PUT, this.headers, body);
  }

  patch(body) {
    return this.send(this.uri, HTTP_METHODS.PATCH, this.headers, body);
  }

  delete() {
    return this.send(this.uri, HTTP_METHODS.DELETE, this.headers);
  }

  send(uri, method, headers, body) {
    this.uri = this.root;

    headers['Content-Type'] = 'application/json';

    body = typeof body === 'object' ? JSON.stringify(body) : body;

    return fetch(uri, { method, headers, body })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 422) {
            return response;
          }

          if (response.status === 404) {
            throw new Error('Not found.');
          }

          throw new Error('Something went wrong...');
        }

        return response;
      })
      .catch((error) => {
        console.log(error.message)
        throw error;
      });
  }
}

export default ResourceDispatcher;

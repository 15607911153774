import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteButton from 'components/buttons/DeleteButton';

function ListView(props) {
  const { items } = props;

  return (
    <List>
      { items.map((item, index) => (
        <ListItem key={index} button component={Link} to={`/tags/${item.id}/edit`}>
          <ListItemText>{item.name}</ListItemText>
          <ListItemSecondaryAction>
            <DeleteButton to={`/tags/${item.id}/delete`} />
          </ListItemSecondaryAction>
        </ListItem>
      )) }
    </List>
  );
}

export default ListView;

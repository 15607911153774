import React from "react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table/index.js";
import Item from "./item";
import ParticipantService from "../../services/ParticipantService";
import { I18n } from "../../i18n";

const Participants = (props) => {
  const path = "participants";
  const i18nProps = {
    i18n: I18n[props.lang].participants,
    i18nCommon: I18n[props.lang].common,
  };
  const isEng = props.lang === "Eng";
  const query = new URLSearchParams(document.location.search);
  const activityId = query.get('activityId');

  return (
    <IndexTable
      {...props}
      {...i18nProps}
      serviceClass={ParticipantService}
      path={path}
      showCreate={() => true}
      showView={(i) => {
        return true;
      }}
      showEdit={(i) => {
        return true;
      }}
      showDelete={(i) => {
        return true;
      }}
      columns={[
        {
          id: 'name',
          displayFunc: (row) => (isEng ? row.nameTranscription : row.name),
          sort: isEng ? 'nameTranscription' : 'name'
        },
        { id: "position" },
        { id: "employerName" },
        { id: "contactAddress" },
        {
          id: "oblast",
          displayFunc: (row) => (isEng ? row.oblastEn : row.oblastUa),
          sort: isEng ? "oblastEn" : "oblastUa",
        },
        {
          id: "employerType",
          displayFunc: (row) =>
            isEng ? row.employerTypeEn : row.employerTypeUa,
          sort: isEng ? "employerTypeEn" : "employerTypeUa",
        }
      ]}
      onEditItem={(itemId, item, handleItemSave) => {
        return (
          <Item
            activityId={activityId}
            item={item}
            itemId={itemId}
            indexPath={path}
            handleItemSave={handleItemSave}
            isEditable={true}
            {...i18nProps}
            {...props}
          />
        );
      }}
      onViewItem={(itemId, item) => {
        return (
          <Item
            item={item}
            itemId={itemId}
            indexPath={path}
            isEditable={false}
            {...i18nProps}
            {...props}
          />
        );
      }}
    />
  );
};

export default Participants;

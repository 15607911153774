import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import {
  Button,
  Grid,
  TextField 
} from '@material-ui/core';
import Field from 'components/form/Field';

function Form(props) {
  return (
    <FinalForm 
      onSubmit={props.onSubmit} 
      initialValues={props.initialValues}
    >
      {({ handleSubmit, submitting, submitSucceded, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} md={6}>
              <Field name="title">
                {({ input, meta }) => (
                  <TextField 
                    label="Title"
                    fullWidth
                    {...input}
                    error={meta.submitError && meta.touched}
                    helperText={meta.submitError}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field name="source">
                {({ input: { value, onChange, ...input }, meta }) => (
                  <>
                    <input 
                      type="file"
                      accept="video/mp4"
                      {...input}
                      onChange={({ target }) => onChange(target.files[0])}
                    />
                    {meta.submitError && meta.touched && <span>{meta.submitError}</span>}
                  </>
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field name="description">
                {({ input, meta }) => (
                  <TextField
                    label="Description"
                    fullWidth
                    multiline
                    {...input}
                    error={meta.submitError && meta.touched}
                    helperText={meta.submitError}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Button 
                color="primary" 
                type="submit" 
                disabled={submitting || pristine} 
                variant="outlined"
              >
                Save
              </Button>
            </Grid>

          </Grid>
        </form>
      )}
    </FinalForm>
  );
}

export default Form;

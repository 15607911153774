import React from 'react';
import { 
  Link,
  useParams
} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Form from './Form';
import { useApi } from 'support/api';
import DataSource from 'components/DataSource';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from './components/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useNotifications } from 'components/notifications';

const styles = {
  toolbar: {
    justifyContent: 'space-between'
  }
};

function Edit(props) {
  const { classes } = props;
  const { id } = useParams();
  const api = useApi();
  const notifications = useNotifications();

  async function handleUpdate(values) {
    const response = await api.surveys(id).put(values);
    const survey = await response.json();

    if (survey.errors) {
      return survey.errors;
    }

    notifications.success('Updated');
  }

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar className={classes.toolbar}>
          <IconButton
            component={Link}
            to="/surveys"
          >
            <ArrowBack />
          </IconButton>
          <Button 
            component={Link}
            to={`/surveys/${id}/questions`}
          >
            Questions
          </Button> 
        </Toolbar>
      </AppBar>
      <Paper>
        <DataSource path={`/surveys/${id}`}>
          {(survey) => (
            <Form initialValues={survey} onSubmit={handleUpdate} />
          )}
        </DataSource>
      </Paper>
    </>
  );
}

export default withStyles(styles)(Edit);

import {
  Card,
  CardHeader,
  IconButton
} from '@material-ui/core';
import {
  Folder as FolderIcon,
  MoreVert as MoreIcon 
} from '@material-ui/icons';

function Folder(props) {
  const { id, name } = props;

  function handleDoubleClick() {
    console.log(id);
  }

  return (
    <Card
      onDoubleClick={handleDoubleClick}
    >
      <CardHeader
        avatar={
          <FolderIcon />
        }
        title={name}
        action={
          <IconButton>
            <MoreIcon />
          </IconButton>
        }
      />
    </Card>
  );
}

export default Folder;

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Paper from './components/Paper';
import Form from './Form';
import { useApi } from 'support/api';
import { useNotifications } from 'components/notifications';

const styles = {
  toolbar: {
    justifyContent: 'space-between'
  }
};

function Create(props) {
  const { classes } = props;
  const api = useApi();
  const history = useHistory();
  const notifications = useNotifications();

  async function handleSubmit(values) {
    const response = await api.surveys.create(values);

    const survey = await response.json();

    if (survey.errors) {
      return survey.errors;
    }

    history.replace(`/surveys/${survey.id}/edit`);

    notifications.success('Created');
  }

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar className={classes.toolbar}>
          <IconButton
            component={Link}
            to="/surveys"
          >
            <ArrowBack />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Paper>
        <Form onSubmit={handleSubmit} />
      </Paper>
    </>
  );
}

export default withStyles(styles)(Create);

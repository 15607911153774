const layers = {
  total_activities: {
    label: 'Total activities',
    type: 'number'
  },
  activity_formats: {
    label: 'Activity Formats',
    type: 'distribution',
    entitiesPath: '/formats'
  },
  total_participations: {
    label: 'Total participations',
    type: 'number',
    participants: true
  },
  total_participants: {
    label: 'Total participants',
    type: 'number',
    participants: true
  },
  employer_types: {
    label: 'Type',
    type: 'distribution',
    entitiesPath: '/employer_types',
    participants: true
  },
  employer_levels: {
    label: 'Level',
    type: 'distribution',
    entitiesPath: '/employer_levels',
    participants: true
  },
  activity_results: {
    label: 'Components by activities',
    type: 'distribution',
    entitiesPath: '/category_results'
  },
  participant_results: {
    label: 'Components by activity participants',
    type: 'distribution',
    entitiesPath: '/category_results',
    participants: true
  },
  so1d: {
    label: 'SO1d – Adjustment of internal procedures',
    type: 'number'
  },
  r2a: {
    label: 'R2a – Introduction of taxes/fees',
    type: 'number'
  },
  r3b: {
    label: 'R3b – Good practices',
    type: 'entity',
    entityPath: '/good-practices/view'
  },
  r6a: {
    label: 'R6a – Dialogue Activities with Civil Society',
    type: 'number'
  },
  r6b: {
    label: 'R6b – Satisfaction % of dialogue activities',
    type: 'number'
  },
  startup: {
    label: 'Start-up package',
    type: 'number'
  },
  leader_steps: {
    label: 'Steps for Leaders',
    type: 'distribution',
    entitiesPath: '/thematic_modules?without_package=true'
  },
  special_steps: {
    label: 'Steps for Specialist',
    type: 'distribution',
    entitiesPath: '/thematic_modules?without_package=true'
  },
  sector_decentr_ah_outreach: {
    label: 'AH outreach',
    legendLabel: '[Sector Decentralization] AH outreach',
    type: 'distribution',
    entitiesPath: '/target_groups/education_or_healthcare'
  },
  sector_decentr_activities: {
    label: 'Activities',
    legendLabel: '[Sector Decentralization] Activities',
    type: 'distribution',
    entitiesPath: '/target_groups/education_or_healthcare'
  },
  sector_decentr_participants: {
    label: 'Participants',
    legendLabel: '[Sector Decentralization] Participants',
    type: 'distribution',
    entitiesPath: '/employer_types/education_or_healthcare',
    participants: true
  }
}

export default layers;

import React from 'react';
import { isNaN } from 'lodash';
export const stateLabels = {
  off_track: [ 'off', 'Off Track' ],
  on_track: [ 'on', 'On Track' ],
  minor_deviated: [ 'minor', 'Minor Deviation' ],
  major_deviated: [ 'major', 'Major Deviation' ],
  not_assessed: [ 'not', 'Not assessed' ]
}

export const stateLabel = (state) => {
  let [klass, title] = state ? stateLabels[state] : ['not', 'N/A']
  return (
    <div className={`selected-value ${klass}`}>{title}</div>
  )
}

export const metricArray = (indicator) => {
  return Array(indicator.measurementCount).fill(0)
}

export const metricValue = (indicator, value, metricIndex) => {
  const num = metricIndex + 1
  if (value && !isNaN(value)){
    value = parseFloat(value).toFixed(indicator[`measurementPrecision${num}`])
    value = value.replace(/(\.0|\.00)$/, '')
    if (indicator['measurementDescription'+(num)].indexOf('%') === 0) {
      value += '%'
    }
    return value;
  } else {
    return 'N/A'
  }
}

export const milestoneDate = (milestone) => {
  const date = new Date(milestone.date)
  let month = '' + (date.getMonth()+1);
  if (month.length === 1) { month = '0' + month };
  return `${month}/${date.getYear()-100}`
}

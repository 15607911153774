import React from 'react';
import { Grid, Button, Typography, Modal } from '@material-ui/core';
import { Edit, Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import { I18n } from '../../i18n/';

import { Link, Redirect } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    textAlign: 'left',
    width: '94%',
    margin: '30px 3%',
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 150,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    left: '50%',
    height: '90vh',
    overflowY: 'auto',
    top: '50%',
    transform: `translate(-50%, -50%)`,
    '& a': {
      textDecoration: 'none',
    },
  },
  textField: {
    width: '100%',
    marginBottom: '20px',
  },
  alignRight: {
    textAlign: 'right',
  },
  data: {
    '& h4': {
      marginBottom: '0',
    },
  },
});

class ItemView extends React.Component {
  state = {
    open: true,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to="/team" />;
    }
  };

  render() {
    const { classes, item, lang } = this.props;
    return (
      <>
        {this.renderRedirect()}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className="modal">
            <Button className="close" onClick={this.handleClose}>
              <Close />
            </Button>

            <Grid container spacing={24} alignItems="center">
              <Grid item xs={6}>
                <Typography gutterBottom variant="h2" component="h2">
                  {I18n[lang].team.team} {item.id}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <Link to={`/team/edit/${item.id}`}>
                  <Button variant="contained" color="primary">
                    <Edit />
                    {I18n[lang].common.edit}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Typography variant="caption" className={classes.data}>
              <h4>{I18n[lang].common.name}</h4>
              <p>{item.name}</p>
              <h4>{I18n[lang].common.description}</h4>
              <p>{item.description}</p>
              <h4>{I18n[lang].common.memberId}</h4>
              {item.members.map((value, index) => (
                <span key={index}>
                  {value.login}
                  {index === item.members.length - 1 ? '' : ', '}
                </span>
              ))}
            </Typography>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(ItemView));

import { useState, useEffect } from 'react';
import { useNotifications } from 'components/notifications';
import Loader, { withLoader } from 'components/v2/Loader';
import { useApi } from 'support/api';

function DataSource(props) {
  const { path } = props;
  const [ data, setData ] = useState();
  const [ loading, setLoading ] = useState(true);
  const api = useApi(!props.public);
  const notifications = useNotifications();

  useEffect(() => {
    setLoading(true);

    api.appendUri(path).get()
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => notifications.error(error.message))
      .then(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return loading ? <Loader /> : props.children(data);
}

export default DataSource;

const DataSourceWithCustomLoader = withLoader(Loader)(DataSource);

export { DataSourceWithCustomLoader };

import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import DataSource from 'components/DataSourceWithLoadMore';
import DataTable from './components/DataTable';

const styles = {
  toolbar: {
    justifyContent: 'flex-end'
  }
};

function Surveys(props) {
  const { classes } = props;

  return (
    <Paper>
      <Toolbar className={classes.toolbar}>
        <Fab component={Link} to={`/surveys/create`} color="primary">
          <AddIcon />
        </Fab>
      </Toolbar>
      <DataSource path="surveys">
        {(surveys) => (
          <DataTable rows={surveys} />
        )}
      </DataSource>
    </Paper>
  );
}

export default withStyles(styles)(Surveys);

import { Field } from 'react-final-form';
import { TextField } from '@material-ui/core';

function QuantitativeField(props) {
  const { name, label } = props;

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          label={label}
          type="number"
          inputProps={{ min: 1 }}
          fullWidth
          {...input}
          error={meta.error && meta.touched}
        />
      )}
    </Field>
  );
}

export default QuantitativeField;

import React from "react";
import withRoot from "../../../withRoot";
import BaseGraph from "../base-graph";

const datasetOptions = [
  {
    label: "% Level",
    type: "bar",
    data: [51, 65, 40, 49, 60, 37, 40],
    fill: false,
    borderColor: "#6495ed",
    backgroundColor: "#6495ed",
    pointBorderColor: "#6495ed",
    pointBackgroundColor: "#6495ed",
    pointHoverBackgroundColor: "#6495ed",
    pointHoverBorderColor: "#6495ed",
    yAxisID: "y-axis-1",
  },
];

const options = {
  responsive: true,
  tooltips: {
    mode: "label",
  },
  legend: {
    display: false,
  },
  elements: {
    line: {
      fill: false,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
      },
    ],
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        id: "y-axis-1",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
        ticks: {
          callback: function (value, index, values) {
            return value + "%";
          },
          beginAtZero: true,
        },
      },
    ],
  },
};

const plugins = [
  {
    afterDatasetDraw: (chartInstance, easing) => {
      const ctx = chartInstance.chart.ctx;
      ctx.font = chartInstance.scales.font;
      ctx.fillStyle = "#555";
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";

      chartInstance.data.datasets.forEach(function (dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
          var data = dataset.data[index];
          ctx.fillText(
            "" + Math.round(data) + "%",
            bar._model.x,
            bar._model.y - 5
          );
        });
      });
    },
  },
];

class ParticipantEmployerLevelsChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type="bar"
        tableTitle="Level of participants for activities"
        url="/graph/participant_employer_levels"
        chartOptions={options}
        datasetOptions={datasetOptions}
        filter={this.props.filter}
        plugins={plugins}
        noActions={this.props.noActions}
      />
    );
  }
}

export default withRoot(ParticipantEmployerLevelsChart);

import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    marginTop: -8,
    marginRight: -8
  }
};

function ListItemActions(props) {
  const { classes, children } = props;

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
}

export default withStyles(styles)(ListItemActions);

import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from 'components/AppBarWithBackButton';
import DataSource from 'components/DataSource';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { StoreContext } from 'stores/context';
import SupportResultsDialog from './supportResults/Dialog';
import AddParticipantButton from './components/AddParticipantButton';
import { useNotifications } from 'components/notifications';
import { useApi } from 'support/api';

function Row(props) {
  const { impactIndicators } = props;
  const [ open, setOpen ] = useState(false);
  const [ deleted, setDeleted ] = useState(false);
  const [ participation, setParticipation ] = useState(props.participation);
  const participant = participation.partakable;
  const { userStore } = useContext(StoreContext);
  const notifications = useNotifications();
  const api = useApi();
  const { id: activityId } = useParams();

  async function handleDelete() {
    notifications.info('Deleting', null);

    await api.activities(activityId).participations(participation.id).delete();

    setDeleted(true);
    notifications.success('Deleted');
  }

  return !deleted && participant && (
    <TableRow>
      <TableCell>{participant.name}</TableCell>
      <TableCell>{participant.gender}</TableCell>
      <TableCell>{participant.cellphone}</TableCell>
      <TableCell>{participant.region?.name}</TableCell>
      <TableCell>{participant.organization?.name}</TableCell>
      <TableCell>
        <IconButton 
          onClick={() => setOpen(true)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <SupportResultsDialog 
          open={open}  
          onClose={() => setOpen(false)}
          participation={participation}
          fields={impactIndicators}
          onUpdate={setParticipation}
        />
        <IconButton onClick={handleDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function ParticipantsListView(props) {
  const { id } = useParams();
  const { userStore } = useContext(StoreContext);

  return (
    <>
      <AppBar>
        <AddParticipantButton />
      </AppBar>
      <DataSource path={`activities/${id}/impact_indicators`}>
        {(impactIndicators) => (
          <DataSource path={`activities/${id}/participations`}>
            {(participations) => (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Region</TableCell>
                    <TableCell>Organization</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!participations.length && (
                    <TableRow>
                      <TableCell
                        colspan={6}
                        align="center"
                      >
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                  {participations.map((participation, index) => (
                    <Row 
                      key={index} 
                      participation={participation} 
                      impactIndicators={impactIndicators}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </DataSource>
        )}
      </DataSource>
    </>
  );
}

export default ParticipantsListView;

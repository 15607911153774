const options = {
  hour12: false, 
  hour: '2-digit', 
  minute: '2-digit'
};

function FormattedTime(props) {
  const { value } = props;

  if (!value) {
    return '';
  }

  const time = new Date(value);

  return time.toLocaleTimeString([], options);
}

export default FormattedTime;

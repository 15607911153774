import React from "react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table/index.js";
import { I18n } from "../../i18n";
import CommunityService from "../../services/CommunityService";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import { Link, Route } from "react-router-dom";
import TablePaginationActions from "../../components/pagination/CustomPagination";
import CustomSearch from "../../components/search/index";
import CustomTitle from "../../components/title/index";
import Loader from "../../components/loader";
import Filters from "./filters";
import downloadFile from "../../utils/fetchFile";
import fetchData from "../../utils/fetch";
import Item from "./item";
import styles from "../../style/styles";

class Communities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDownload = (event, state) => {
    event.preventDefault();
    let filters = state.filteringGetParams || "";
    filters = filters.replace("?", "");
    this.getDownloadableResults(
      state.searchQuery
        ? `/communities.xlsx?query=${state.searchQuery}&${filters}`
        : "/communities.xlsx"
    );
  };

  handleUpdate = () => {
    this.setState({ loading: true });
    this.updateViaAPI();
  };

  async updateViaAPI() {
    const data = await fetchData("post", `/communities/resync`);
    this.setState({ status: await data[0], loading: false });
  }

  async getDownloadableResults(path) {
    this.setState({ loading: true });
    await downloadFile(path);
    this.setState({ loading: false });
  }

  render() {
    const path = "communities";
    const { classes, lang, user } = { ...this.props };
    const isEng = lang === "Eng";
    const status = this.state.status;
    const asAdmin = user && user.isAdmin;

    const i18nProps = {
      i18n: I18n[this.props.lang].communities,
      i18nCommon: I18n[this.props.lang].common,
    };

    let columns = [
      { id: "koatuu", sort: "koatuu" },
      { id: "title", sort: isEng ? "titleEn" : "title" },
      { id: "region", sort: isEng ? "regionEn" : "regionUa" },
    ];

    columns.push({
      id: "created",
      sort: "created",
    });
    columns.push({
      id: "population",
      sort: "population",
    });
    columns.push({
      id: "participants",
      sort: "participantsCount",
      center: true,
    });

    return (
      <IndexTable
        {...this.props}
        {...i18nProps}
        serviceClass={CommunityService}
        path={path}
        columns={[
          { id: "koatuu", sort: "koatuu" },
          { id: "title", sort: isEng ? "titleEn" : "title" },
          { id: "region", sort: isEng ? "regionEn" : "regionUa" },
          { id: "initiatives", translation: "regionalInitiative", sort: null },
          { id: "support", sort: "tspActivitiesCount" },
          { id: "participants", sort: "participantsCount", center: true },
        ]}
        onViewItem={(itemId, item) => {
          return (
            <Item
              itemId={itemId}
              indexPath={path}
              isEditable={false}
              {...this.props}
              {...i18nProps}
            />
          );
        }}
        render={(tableProps) => {
          const { state } = { ...tableProps };
          const { items, page, rowsPerPage, total, order, orderBy } = state;
          const tableTitles = tableProps.columns;
          return (
            <div className={classNames(classes.root, "hromadas-wrap")}>
              {state.loading && <Loader />}

              <Grid container direction="row" alignItems="center">
                <Grid item xs={12}>
                  <CustomTitle
                    title={I18n[lang].communities.communities}
                    props={tableProps}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" justify="space-around">
                <Grid item xs={12} sm={6} md={4}>
                  <CustomSearch
                    placeholder={""}
                    props={tableProps}
                    value={state.searchQuery}
                    updateInputValue={(item) =>
                      tableProps.updateInputValue(item)
                    }
                    submit={(event) => tableProps.handleSubmit(event)}
                    state={state}
                    clearSearchField={() => tableProps.clearSearchField()}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={8} className={classes.alignRight}>
                  {!status && user && (
                    <>
                      <Link
                        to="#"
                        className={classNames("btnIndex", "btn-download")}
                        onClick={(e) =>
                          this.handleDownload(e, tableProps.state)
                        }
                      >
                        Download AH Data <i></i>
                      </Link>
                      {asAdmin && (
                        <Button
                          onClick={this.handleUpdate}
                          variant="contained"
                          color="primary"
                          className={classNames(
                            "btn-edit",
                            classes.rightSpace,
                            classes.indexPageButton
                          )}
                        >
                          {I18n[lang].communities.update}
                        </Button>
                      )}
                    </>
                  )}
                  {status && (
                    <p className={classes.message}>
                      {lang === "Eng" ? status.message_en : status.message_ua}
                    </p>
                  )}
                </Grid>
              </Grid>
              {/* <Grid
                container
                alignItems="center"
                className={classNames("hromadas", classes.pageHead)}
              >
                <Grid item xs={8} className="title">
                  <CustomTitle
                    title={I18n[lang].communities.communities}
                    props={tableProps}
                    className={classes.title}
                  />
                </Grid>
                <div className={classes.navigationWrapp}>
                  <Grid item xs={6}>
                    <CustomSearch
                      placeholder={""}
                      props={tableProps}
                      value={state.searchQuery}
                      updateInputValue={(item) =>
                        tableProps.updateInputValue(item)
                      }
                      submit={(event) => tableProps.handleSubmit(event)}
                      state={state}
                      clearSearchField={() => tableProps.clearSearchField()}
                    />
                  </Grid>
                  {
                    <Grid item xs={5} className={classes.alignRight}>
                      {!status && user && (
                        <>
                          <Link
                            to="#"
                            className="btn btn-download"
                            onClick={this.handleDownload}
                          >
                            Download AH Data <i></i>
                          </Link>
                          {asAdmin && (
                            <Button
                              onClick={this.handleUpdate}
                              variant="contained"
                              color="primary"
                              className="btn-edit"
                            >
                              {I18n[lang].communities.update}
                            </Button>
                          )}
                        </>
                      )}
                      {status && (
                        <p className={classes.message}>
                          {lang === "Eng"
                            ? status.message_en
                            : status.message_ua}
                        </p>
                      )}
                    </Grid>
                  }
                </div>
              </Grid> */}
              <div className={classes.tableWrapper}>
                <Table className={classes.indexTable}>
                  <TableHead>
                    <TableRow>
                      {tableTitles.map((item) => {
                        return (
                          <TableCell key={item.id}>
                            {state.sortableColumns.includes(item.id) ? (
                              <Filters
                                id={item.id}
                                lang={lang}
                                resetFilters={state.resetColumnFilters}
                                handleFiltering={
                                  tableProps.handleColumnFilterChange
                                }
                                key={item.id}
                              >
                                <TableSortLabel
                                  active={state.orderBy === item.sort}
                                  direction={order === "" ? "asc" : order}
                                  onClick={tableProps.handleSort(item.sort)}
                                  key={item.id}
                                >
                                  {
                                    I18n[lang].communities[
                                      item.translation || item.id
                                    ]
                                  }
                                </TableSortLabel>
                              </Filters>
                            ) : (
                              <TableSortLabel
                                active={orderBy === item.sort}
                                direction={order === "" ? "asc" : order}
                                onClick={tableProps.handleSort(item.sort)}
                                key={item.id}
                              >
                                {
                                  I18n[lang].communities[
                                    item.translation || item.id
                                  ]
                                }
                              </TableSortLabel>
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map &&
                      items.map((row, key) => {
                        const sourceTitle = row[isEng ? "titleEn" : "title"];
                        const subString = sourceTitle
                          .split(" ")
                          .splice(-3)
                          .join(" ");
                        const resultTitle = sourceTitle.replace(
                          subString,
                          isEng ? "AH" : "ОТГ"
                        );
                        return (
                          <TableRow key={key}>
                            <TableCell>{row.koatuu}</TableCell>
                            <TableCell>
                              <Link to={`/communities/view/${row.id}`}>
                                {resultTitle}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {row.region
                                ? row.region[isEng ? "en" : "ua"]
                                : ""}
                            </TableCell>
                            <TableCell>
                              {isEng ? row.initiativesEn : row.initiativesUa}
                            </TableCell>
                            <TableCell>{row.tspActivitiesCount}</TableCell>
                            <TableCell>{row.participantsCount}</TableCell>
                            <TableCell className={(classes.actions, "actions")}>
                              <Link
                                title={I18n[lang].common.view}
                                to={`/communities/view/${row.id}`}
                                className={classNames("view", [
                                  classes.singleViewAction,
                                ])}
                              >
                                <i />
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  className={classes.pagination}
                  labelDisplayedRows={({ from, to, count }) =>
                    `Showing ${from} - ${to} of ${count} items`
                  }
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  page={page}
                  onChangePage={tableProps.handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
              <Route
                path="/communities/view/:id"
                render={tableProps.viewItem}
              />
            </div>
          );
        }}
      />
    );
  }
}

export default withStyles(styles)(Communities);

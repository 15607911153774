export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function getObjectValueByPath(object, string) {
  return string.split(".").reduce((prevValue, value) => {
    let data;

    try {
      data = prevValue[value];
    } catch (error) {}

    return data;
  }, object);
  // return string.split('.').reduce((prevValue, value) => prevValue[value], object);
}

function desc(a, b, path) {
  if (getObjectValueByPath(b, path) < getObjectValueByPath(a, path)) {
    return -1;
  }
  if (getObjectValueByPath(b, path) > getObjectValueByPath(a, path)) {
    return 1;
  }
  return 0;
}

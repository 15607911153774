import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../style/styles";

// const styles = (theme) => ({
//   title: {
//     "& div h2": {
//       textAlign: "left!important",
//       letterSpacing: "1.5px",
//       fontSize: "5rem",
//       color: "red",
//       [theme.breakpoints.down(1440)]: {
//         fontSize: "3.7rem",
//       },
//     },
//   },
//   fs: {
//     fonsSize: "5rem",
//   },
// });

const CustomTitle = ({ title, props, xs = 12 }) => {
  const { classes } = props;

  return (
    <Grid container alignItems="center" className={classes.title}>
      <Grid item xs={xs}>
        <Typography gutterBottom variant="h2" component="h2">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CustomTitle);

import React from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Select,
  Switch,
  TextField
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Options from './form/Options';
import MessageBox from 'components/MessageBox';

const styles = (theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit,
    gap: `${theme.spacing.unit * 2}px`
  }
});

const required = (value) => (value ? undefined : 'Required');

const Condition = ({ when, is, children }) => (
  <Field name={when} subcription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

function QuestionForm(props) {
  const { classes, onSubmit, values, onChange } = props;

  return (
    <Form 
      onSubmit={onSubmit} 
      mutators={{
        ...arrayMutators
      }}
      initialValues={values}
    >
      {({ 
        handleSubmit, 
        pristine,
        submitting, 
        submitSucceded,
        form,
        form: {
          mutators: { push, pop }
        },
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={16}>
            {values && values.showWhenChosenOptions?.length > 0 &&
              <Grid item xs={12}>
                <MessageBox
                  variant="info"
                >
                  The question is shown when:
                  <ul>
                    {values.showWhenChosenOptions.map((option) => 
                      <li key={option.id}>
                        {`${option.question.name} -> ${option.value}`}
                      </li>
                    )}
                  </ul>
                </MessageBox>
              </Grid>
            }
            <Grid item xs={12}>
              <Field name="name" validate={required}>
                {({ input, meta }) => (
                  <TextField 
                    label="Name"
                    fullWidth
                    {...input}
                    onChange={(e) => {
                      input.onChange(e);
                      onChange && onChange({ id: values && values.id, name: e.target.value });
                    }}
                    error={meta.error && meta.touched}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field name="type">
                {({ input, meta }) => (
                  <FormControl fullWidth>
                    <InputLabel shrink>Answer type</InputLabel>
                    <Select 
                      native
                      input={<Input {...input} />}
                    >
                      <option value="text">Text</option>
                      <option value="radios">Single choice</option>
                      <option value="checkboxes">Multiple choices</option>
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field 
                name="optional"
                type="checkbox"
              >
                {({ input }) => (
                  <FormControlLabel
                    {...input}
                    label="Optional"
                    control={<Switch />}
                  />
                )} 
              </Field>
            </Grid>

            <Condition when="type" is="checkboxes">
              <Grid item xs={12}>
                <Options mutators={{ push, pop }} variant="checkbox" />
              </Grid>
            </Condition>

            <Condition when="type" is="radios">
              <Grid item xs={12}>
                <Options mutators={{ push, pop }} variant="radio" />
              </Grid>
            </Condition>

          </Grid>

          <div className={classes.buttons}>
            <Button 
              disabled={pristine || submitting} 
              variant="outlined"
              onClick={form.reset}
            >
              Reset
            </Button>

            <Button 
              color="primary" 
              type="submit" 
              disabled={pristine || submitting} 
              variant="outlined"
            >
              Save
            </Button>
          </div>

          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}

        </form>
      )}
    </Form>
  );
}

export default withStyles(styles)(QuestionForm);


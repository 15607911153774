import React from "react";

import {
  Paper,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { I18n } from "../../i18n/";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "../../style/styles.js";
import "./chart.scss";
import Edit from "../../components/dialog/edit.js";
import CommunityService from "../../services/CommunityService";
import { stableSort, getSorting } from "../../utils/tables.js";
import { activityRoute } from "../../utils/routes";

import {
  FormTextField,
  FormMultiSelectField,
  FormLabel,
} from "../../components/dialog/formField";

class Item extends React.Component {
  async handleGetData(service, editorState) {
    const europeActionsList = await service.GetAllSelectOptions(
      "europe_actions"
    );

    const grantsList = await service.GetAllSelectOptions(
      "cso_grants",
      undefined,
      (item) => ({
        label: item.projectTitle,
      })
    );
    const projectsList = await service.GetAllSelectOptions(
      "donor_projects",
      undefined,
      (item) => ({
        label: `${item.title} (${item.donor})`,
      })
    );

    const state = {
      europeActionsList,
      grantsList,
      projectsList,
    };

    return state;
  }

  render() {
    const { classes, lang } = this.props;

    const i18n = I18n[this.props.lang].activities;
    const { ...rest } = { i18n, ...this.props };

    return (
      <Edit
        i18n={i18n}
        handleGetData={this.handleGetData}
        onAfterGetData={this.onAfterGetData}
        serviceClass={CommunityService}
        {...rest}
        hideSaveButton={true}
        controllButtons={() => <></>}
        header={(fieldProps) => (
          <Grid
            container
            spacing={24}
            alignItems="center"
            direction="row"
            justify="space-between"
          >
            <Grid item xs={6}>
              <Typography
                gutterBottom
                variant="h2"
                component="h2"
                className={classes.noCapitalize}
              >
                {fieldProps.formState.title}
              </Typography>
            </Grid>
            <Grid item>
              <Link
                to="#"
                className={classNames(classes.button, {
                  disabled: !fieldProps.formState.print_info,
                })}
                onClick={() => fieldProps.action({ fieldName: "downloadPdf" })}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classNames(
                    classes.buttonGrey,
                    classes.buttonDialogHeader
                  )}
                >
                  <i />
                  {I18n[lang].common.print}
                </Button>
              </Link>
            </Grid>
          </Grid>
        )}
        render={(fieldProps) => (
          <>
            <Grid container direction="row" spacing={24}>
              <Grid xs={12} item>
                {/* 1st Row */}
                <Grid
                  container
                  spacing={24}
                  direction="row"
                  alignItems="stretch"
                >
                  <Grid item xs={5}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={16}>
                        <Grid item>
                          <Typography variant="h3">
                            {I18n[lang].communities.generalInformation}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          container
                          xs={12}
                          direction="row"
                          spacing={16}
                        >
                          <Grid
                            container
                            item
                            xs={6}
                            direction="column"
                            spacing={16}
                          >
                            <Grid item>
                              <FormTextField
                                fieldName="ahCentre"
                                {...fieldProps}
                                isEditable={false}
                              />
                            </Grid>
                            <Grid item>
                              <FormTextField
                                fieldName="region"
                                {...fieldProps}
                                isEditable={false}
                              />
                            </Grid>
                            <Grid item>
                              <FormTextField
                                fieldName="councilSize"
                                {...fieldProps}
                                isEditable={false}
                              />
                            </Grid>
                            <Grid item>
                              <FormTextField
                                label={
                                  fieldProps.formState.isUrban
                                    ? fieldProps.i18n.establishment
                                    : fieldProps.i18n.created
                                }
                                fieldName="created"
                                {...fieldProps}
                                isEditable={false}
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            xs={6}
                            direction="column"
                            spacing={16}
                          >
                            <Grid item>
                              <FormTextField
                                fieldName="population"
                                {...fieldProps}
                                isEditable={false}
                              />
                            </Grid>
                            <Grid item>
                              <FormTextField
                                fieldName="square"
                                {...fieldProps}
                                isEditable={false}
                              />
                            </Grid>
                            <Grid item>
                              <FormTextField
                                fieldName="koatuu"
                                {...fieldProps}
                                isEditable={false}
                              />
                            </Grid>

                            {fieldProps.formState.mapLink && (
                              <Grid item>
                                <div className={classes.infoMap}>
                                  <Link
                                    to={`/map?coordinates=${fieldProps.formState.mapLink}`}
                                  >
                                    {fieldProps.i18n.showOnMap}
                                  </Link>
                                </div>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={7}>
                    <Paper className={classes.paper}>
                      <Grid container direction="column" spacing={16}>
                        {/* HEADER: */}
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                          >
                            <Grid item>
                              <Typography variant="h3">
                                {fieldProps.i18n.supportHromada}
                              </Typography>
                            </Grid>

                            <Grid item>
                              {fieldProps.formState.isAdmin && (
                                <>
                                  {fieldProps.formState.editMode ? (
                                    <Link to="#">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        className={classNames("btn", [
                                          classes.adjustTwoRight,
                                        ])}
                                        color="primary"
                                        onClick={() =>
                                          fieldProps.action({
                                            fieldName: "setSave",
                                          })
                                        }
                                      >
                                        <img
                                          src="/images/icons/button-save.svg"
                                          alt="save"
                                          width="17"
                                        />
                                        {fieldProps.i18nCommon.save}
                                      </Button>
                                    </Link>
                                  ) : (
                                    <Link to="#">
                                      <Button
                                        size="small"
                                        variant="contained"
                                        className={classNames(
                                          "btn",
                                          "btn-edit",
                                          [classes.adjustTwoRight]
                                        )}
                                        color="primary"
                                        onClick={() =>
                                          fieldProps.action({
                                            fieldName: "setEdit",
                                          })
                                        }
                                      >
                                        <img
                                          src="/images/icons/icon-action-edit.svg"
                                          alt="edit"
                                          width="17"
                                        />
                                        <div style={{ height: "auto" }}>
                                          {fieldProps.i18nCommon.edit}
                                        </div>
                                      </Button>
                                    </Link>
                                  )}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* CONTENT */}
                        <Grid item container direction="column" spacing={16}>
                          <Grid item container direction="row" spacing={16}>
                            <Grid item xs={3}>
                              <FormLabel
                                withColon={true}
                                className={classes.labelInGrid}
                                fieldName="goodPractice"
                                label={I18n[lang].goodPractice.goodPractice}
                                {...fieldProps}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              {fieldProps.formState.editMode ? (
                                <FormTextField
                                  fieldName="goodPractice"
                                  {...fieldProps}
                                  isEditable={true}
                                  hideLabel={true}
                                />
                              ) : (
                                <Link
                                  to={`/good-practices/view/${fieldProps.formState.selectedPracticeId}`}
                                >
                                  {fieldProps.formState.goodPracticeTitle}
                                </Link>
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              <FormLabel
                                withColon={true}
                                className={classes.labelInGrid}
                                fieldName="initiatives"
                                {...fieldProps}
                              />
                              {fieldProps.formState.selectedPracticeId && (
                                <Typography variant="body2">
                                  fieldProps.formState.goodPracticeInitiatives
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item container direction="row" spacing={16}>
                            <Grid item xs={3}>
                              <FormLabel
                                withColon={true}
                                className={classes.labelInGrid}
                                fieldName="projects"
                                label={fieldProps.i18n.otherDonorSupport}
                                {...fieldProps}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormMultiSelectField
                                fieldName="projects"
                                {...fieldProps}
                                hideLabel={true}
                                isEditable={fieldProps.formState.editMode}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {/* 2nd Row */}

                <Grid container spacing={24} direction="row">
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Typography variant="h3">
                        {I18n[lang].communities.uleadStats}
                      </Typography>

                      <Grid
                        container
                        item
                        xs={12}
                        direction="column"
                        justify="space-around"
                        spacing={24}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          direction="row"
                          justify="space-around"
                        >
                          <Grid
                            container
                            item
                            xs={3}
                            direction="column"
                            justify="space-around"
                          >
                            <Grid item>
                              <Typography variant="h4" align="center">
                                {fieldProps.i18n.totalParticipantsCount}:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" align="center">
                                {fieldProps.formState.participantsCount}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            item
                            xs={3}
                            direction="column"
                            justify="space-around"
                          >
                            <Grid item>
                              <Typography variant="h4" align="center">
                                {fieldProps.i18n.tspCount}:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" align="center">
                                {fieldProps.formState.relatedActivities?.length}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          item
                          xs={12}
                          direction="row"
                          justify="space-around"
                        >
                          <Grid
                            container
                            item
                            xs={3}
                            direction="column"
                            justify="space-around"
                            spacing={8}
                          >
                            <Grid item>
                              <Typography variant="h4" align="center">
                                {fieldProps.i18n.genderBalance}:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Doughnut
                                data={
                                  fieldProps.formState.genderDistributionChart
                                }
                                width={260}
                                height={80}
                                options={fieldProps.formState.doughnutOptions}
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            item
                            xs={3}
                            direction="column"
                            justify="space-around"
                            spacing={8}
                          >
                            <Grid item>
                              <Typography variant="h4" align="center">
                                {fieldProps.i18n.employerTypeCount}:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Doughnut
                                data={
                                  fieldProps.formState
                                    .employerTypeDistributionChart
                                }
                                width={260}
                                height={80}
                                options={fieldProps.formState.doughnutOptions}
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            item
                            xs={3}
                            direction="column"
                            justify="space-around"
                            spacing={8}
                          >
                            <Grid item>
                              <Typography variant="h4" align="center">
                                {fieldProps.i18n.formatCount}:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Doughnut
                                data={
                                  fieldProps.formState
                                    .activityFormatDistributionChart
                                }
                                width={260}
                                height={80}
                                options={fieldProps.formState.doughnutOptions}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      {I18n[lang].activities.activities}
                    </Typography>
                    <Table
                      className={classNames(
                        classes.inlineTable,
                        classes.detailTable,
                        classes.activityTable
                      )}
                    >
                      <TableHead>
                        <TableRow>
                          {fieldProps.formState.activitiesTableTitles.map(
                            (item, key) => {
                              return (
                                <TableCell key={key}>
                                  <TableSortLabel
                                    active={
                                      fieldProps.formState.orderBy === item.id
                                    }
                                    direction={fieldProps.formState.order}
                                    onClick={() =>
                                      fieldProps.action({
                                        fieldName: "setDetailSorting",
                                        data: {
                                          orderBy: item.id,
                                          entity: "activities",
                                        },
                                      })
                                    }
                                    className="activity-table__row"
                                  >
                                    {I18n[lang].communities[item.label]}
                                  </TableSortLabel>
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fieldProps.formState.relatedActivities &&
                          stableSort(
                            fieldProps.formState.relatedActivities,
                            getSorting(
                              fieldProps.formState.order,
                              fieldProps.formState.orderBy
                            )
                          ).map((row, key) => {
                            const getName = (item) => {
                              return item[
                                this.props.lang === "Eng" ? "nameEn" : "nameUa"
                              ]?.replace(/\r?\n|\r/g, "");
                            };
                            const tsp =
                              row.trainingPackageTypes &&
                              row.trainingPackageTypes[0];
                            const tmodule =
                              row.thematicModules && row.thematicModules[0];
                            return (
                              <TableRow key={key}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>
                                  {row.startDate}
                                  <br />
                                  {row.endDate}
                                </TableCell>
                                <TableCell>
                                  <a
                                    href={`${activityRoute}/view/${row.id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {getName(row)}
                                  </a>
                                </TableCell>
                                <TableCell>
                                  {lang === "Eng"
                                    ? row.format.en
                                    : row.format.ua}
                                </TableCell>
                                <TableCell>
                                  {tsp && tmodule && (
                                    <>
                                      {getName(tsp) + " : " + getName(tmodule)}
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Grid>
                  {!fieldProps.formState.isGuest && (
                    <Grid item xs={12}>
                      <Typography variant="h3">
                        {I18n[lang].participants.participants}
                      </Typography>
                      <Table
                        className={classNames(
                          classes.inlineTable,
                          classes.detailTable,
                          classes.activityTable
                        )}
                      >
                        <TableHead>
                          <TableRow>
                            {fieldProps.formState.participantsTableTitles.map(
                              (item, key) => {
                                return (
                                  <TableCell key={key}>
                                    <TableSortLabel
                                      active={
                                        fieldProps.formState
                                          .participantsOrderBy === item.id
                                      }
                                      direction={
                                        fieldProps.formState.participantsOrder
                                      }
                                      onClick={() =>
                                        fieldProps.action({
                                          fieldName: "setDetailSorting",
                                          data: {
                                            orderBy: item.id,
                                            entity: "participants",
                                          },
                                        })
                                      }
                                      className="activity-table__row"
                                    >
                                      {I18n[lang].communities[item.label]}
                                    </TableSortLabel>
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fieldProps.formState.relatedParticipants &&
                            stableSort(
                              fieldProps.formState.relatedParticipants,
                              getSorting(
                                fieldProps.formState.participantsOrder,
                                fieldProps.formState.participantsOrderBy
                              )
                            ).map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  {/* <TableCell>{row.id}</TableCell> */}
                                  <TableCell>
                                    <a
                                      href={`/participants/view/${row.id}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {lang === "Eng"
                                        ? row.nameTranscription
                                        : row.name}
                                    </a>
                                  </TableCell>
                                  <TableCell>{row.gender}</TableCell>
                                  <TableCell>{row.position}</TableCell>
                                  <TableCell>
                                    {
                                      row[
                                        `employerType${
                                          lang === "Eng" ? "En" : "Ua"
                                        }`
                                      ]
                                    }
                                  </TableCell>
                                  <TableCell>{row.employerName}</TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      />
    );
  }
}

export default withStyles(styles)(Item);

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { config } from "../style/config";
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';

const styles = () => ({
  root: {
    minWidth: "120px",
    maxHeight: "55px",
    marginRight: "15px",
    display: "flex",
    position: "relative",
    paddingTop: "5px",
    "& div div": {
      "&::before": {
        display: "none",
      },
      "& input": {
        height: "25px",
        paddingLeft: "15px",
        border: "0 !important",
        cursor: "pointer",
      },
    },
  },
  icon: {
    background: config.colors.primary,
    width: 51,
    height: 45,
    borderRadius: 7,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "2",
    position: "relative",
  },
  iconImg: {
    width: 28,
  },
  field: {
    width: "80px",
    top: "5px",
    left: "-5px",
  },
  clear: {
    top: "0px",
    right: "-3px",
    width: "20px",
    height: "20px",
    position: "absolute",
    background: "white",
    borderRadius: "50%",
    fill: "#9bbaf2",
    cursor: "pointer",
  },
});

const GIZDatePicker = ({ classes, name, value, onChange }) => {
  const handleChange = (date) => onChange(date.toISOString().slice(0, 7));
  const handleCancel = () => onChange(null);

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <img
          src="/images/icons/calendar.svg"
          alt="Date Picker"
          className={classes.iconImg}
        />
      </div>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <InlineDatePicker
          views={["year", "month"]}
          className={classes.field}
          value={value}
          name={name}
          onChange={handleChange}
          labelFunc={(date) => (date ? dayjs(date).format("MM.YYYY") : "")}
        />
      </MuiPickersUtilsProvider>
      {value && (
        <HighlightOffIcon className={classes.clear} onClick={handleCancel} />
      )}
    </div>
  );
};

export default withStyles(styles)(GIZDatePicker);

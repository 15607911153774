import React from "react";
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";
import { Grid, Button, InputLabel } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import { FORMAT_ENDPOINT } from "../../utils/DataFetcher";
import RegistryGraphs from "./registry";
import IndicatorGraphs from "./indicators";
import { Link } from "react-router-dom";
import { each } from "lodash";
import classNames from "classnames";
import { selectStyles } from "../../style/select-styles";
import { styles } from "./styles";
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';

class Graphs extends React.Component {
  state = {
    monthTo: "",
    monthFrom: "",
    regions: [],
    regionsSelected: [],
    owners: [],
    ownersSelected: [],
    formats: [],
    formatsSelected: [],
    graphsFilter: "",
    isVisibleDataPicker: false,
  };

  componentWillMount() {
    this.getFilterObjects();
  }

  async getFilterObjects() {
    const regionsData = await fetchData("get", "/regions?size=1000");
    const regions = regionsData[0];
    let regionsList = [];
    each(regions, (item) => {
      if (item.en === "N/A" || item.en === "Autonomous Republic of Crimea") {
        return;
      }
      regionsList.push({
        value: item.id,
        label: this.props.lang === "Eng" ? item.en : item.ua,
      });
    });
    regionsList = regionsList.sort(function (a, b) {
      var nameA = a.label.toUpperCase();
      var nameB = b.label.toUpperCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

    let list = [];
    if (this.props.user && this.props.user.isAdmin) {
      const data = await fetchData("get", "/users/activity_owners");
      list = data[0].map((item) => {
        return { value: item.id, label: item.name };
      });
    }

    const data2 = await fetchData("get", `${FORMAT_ENDPOINT}?size=200`);
    const list2 = data2[0].map((item) => {
      return { value: item.id, label: item.en };
    });

    this.setState({ regions: regionsList, owners: list, formats: list2 });
  }

  handleDate = (field, date = new Date()) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const data = `${year}-${month}`;
    let value = data;
    if (value.indexOf("-") > 0) {
      let [year, month] = value.split("-");
      value = `${year.substr(0, 4)}-${month}`;
    }

    this.setState({ [field]: value }, () => {
      this.handleFilterChange();
    });
  };

  handleDefaultDate = (field) => {
    this.setState({ [field]: "" }, () => {
      this.handleFilterChange();
    });
  };

  handleDropdownChange = (field) => {
    return (options) => {
      this.setState({ [field + "Selected"]: options }, () => {
        this.handleFilterChange();
      });
    };
  };

  handleFilterChange = () => {
    const {
      ownersSelected,
      formatsSelected,
      regionsSelected,
      monthFrom,
      monthTo,
    } = this.state;
    let filters = [];

    if (monthFrom) {
      filters.push(`monthFrom=${monthFrom}`);
    }
    if (monthTo) {
      filters.push(`monthTo=${monthTo}`);
    }
    if (regionsSelected.length) {
      let ids = regionsSelected
        .map((option) => `regionIds[]=${option.value}`)
        .join("&");
      filters.push(ids);
    }
    if (ownersSelected.length) {
      let ids = ownersSelected
        .map((option) => `ownerIds[]=${option.value}`)
        .join("&");
      filters.push(ids);
    }
    if (formatsSelected.length) {
      let ids = formatsSelected
        .map((option) => `formatIds[]=${option.value}`)
        .join("&");
      filters.push(ids);
    }

    if (filters.length) {
      filters = filters.join("&") + "&";
      this.setState({ graphsFilter: filters });
    } else {
      this.setState({ graphsFilter: "" });
    }
  };

  render() {
    const { classes, user } = this.props;
    const { regions, owners, formats, graphsFilter } = this.state;

    let kind = this.props.kind;
    if (!kind) {
      kind = "registry";
    }

    const isAdmin = user && user.isAdmin;
    const isGuest = user && user.authorities.indexOf("ROLE_GUEST") >= 0;

    const currentPath = document.location.pathname;

    return (
      <>
        <Grid container spacing={24} className={classes.root}>
          <Grid item lg={4} md={12}>
            <div className={classes.headerLinks}>
              {isGuest ? (
                ""
              ) : (
                <>
                  <Link to={`/graphs`}>
                    <Button
                      variant={
                        !!currentPath.includes("/graphs")
                          ? "contained"
                          : "outlined"
                      }
                      color="primary"
                    >
                      Registry Graphs
                    </Button>
                  </Link>
                  <Link to={`/indicator-graphs`}>
                    <Button
                      variant={
                        !!currentPath.includes("/indicator-graphs")
                          ? "contained"
                          : "outlined"
                      }
                      color="primary"
                    >
                      Indicator Graphs
                    </Button>
                  </Link>
                </>
              )}
            </div>
          </Grid>
          {kind !== "indicators" && (
            <Grid item lg={8} md={12} className={classes.filters}>
              <div>
                <InputLabel>Date range</InputLabel>
                <div
                  className={classNames(classes.calendar, classes.dateBlock)}
                >
                  <div
                    className={classNames(classes.dateWrap, {
                      transparent: this.state.monthFrom === "",
                    })}
                  >
                    <div className={classes.dataIcon}>
                      <img
                        src="/images/icons/calendar.svg"
                        alt="calendar"
                        className="date-icon"
                      />
                    </div>
                    <MuiPickersUtilsProvider utils={DayjsUtils}>
                      <InlineDatePicker
                        views={["year", "month"]}
                        className={classNames(
                          "text-field",
                          classes.textField,
                          classes.dateField
                        )}
                        value={this.state.monthFrom}
                        onChange={(data) => this.handleDate("monthFrom", data)}
                        labelFunc={(date) =>
                          date ? dayjs(date).format("MM.YYYY") : ""
                        }
                      />
                      {this.state.monthFrom !== "" ? (
                        <HighlightOffIcon
                          className="clearDate"
                          onClick={() => this.handleDefaultDate("monthFrom")}
                        />
                      ) : null}
                    </MuiPickersUtilsProvider>
                  </div>
                  <div
                    className={classNames(classes.dateWrap, {
                      transparent: this.state.monthTo === "",
                    })}
                  >
                    <MuiPickersUtilsProvider utils={DayjsUtils}>
                      <div className={classes.dataIcon}>
                        <img
                          src="/images/icons/calendar.svg"
                          alt="calendar"
                          className="date-icon"
                        />
                      </div>
                      <InlineDatePicker
                        views={["year", "month"]}
                        className={classNames(
                          "text-field",
                          classes.textField,
                          classes.dateField
                        )}
                        value={this.state.monthTo}
                        onChange={(data) => this.handleDate("monthTo", data)}
                        labelFunc={(date) =>
                          date ? dayjs(date).format("MM.YYYY") : ""
                        }
                        invalidDateMessage=""
                        inputVariant="filled"
                      />
                      {this.state.monthTo !== "" ? (
                        <HighlightOffIcon
                          className="clearDate"
                          onClick={() => this.handleDefaultDate("monthTo")}
                        />
                      ) : null}
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
              <div>
                <InputLabel>Geography</InputLabel>
                <Select
                  styles={selectStyles}
                  className={classNames(classes.select)}
                  closeMenuOnSelect={false}
                  components={makeAnimated()}
                  options={regions}
                  onChange={this.handleDropdownChange("regions")}
                  isMulti
                />
              </div>
              {isAdmin && (
                <div>
                  <InputLabel>Activity Owner</InputLabel>
                  <Select
                    styles={selectStyles}
                    className={classNames(classes.select)}
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    options={owners}
                    onChange={this.handleDropdownChange("owners")}
                    isMulti
                  />
                </div>
              )}
              <div>
                <InputLabel>Activity Format</InputLabel>
                <Select
                  styles={selectStyles}
                  className={classNames(classes.select)}
                  closeMenuOnSelect={false}
                  components={makeAnimated()}
                  options={formats}
                  onChange={this.handleDropdownChange("formats")}
                  isMulti
                />
              </div>
            </Grid>
          )}
          {kind === "registry" ? (
            <RegistryGraphs graphsFilter={graphsFilter} />
          ) : kind === "indicators" ? (
            <IndicatorGraphs graphsFilter={graphsFilter} />
          ) : (
            <>Custom Graph</>
          )}
        </Grid>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(Graphs));

import React, { useState, useEffect } from 'react';
import { 
  useHistory,
  useParams
} from 'react-router-dom';
import { 
  arrayMoveImmutable, 
  arrayRemoveImmutable 
} from '../support/helpers';
import ActionBar from './ActionBar';
import DndContainer from '../components/dnd/List';
import { ItemTypes } from '../components/dnd/Constants';
import ListItem from './ListItem';
import ListItemText from './ListItemText';
import ListItemActions from './ListItemActions';
import Button from '@material-ui/core/Button';
import { DeleteButtonWithConfirmation } from './DeleteButton';
import EditButton from './EditButton';
import { withNotifications } from 'components/notifications';
import { useApi } from 'support/api';

function List(props) {
  const { notifications } = props;
  const history = useHistory();
  const { surveyId } = useParams();
  const api = useApi();
  const [items, setItems] = useState([]);
  const [reordered, setReordered] = useState(false);

  useEffect(() => setItems(props.items), [props.items]);

  const handleMove = (dragIndex, hoverIndex) => {
    setItems(
      (prevItems) => arrayMoveImmutable(prevItems, dragIndex, hoverIndex)
    );
    setReordered(true);
  };

  const handleSave = () => {
    const questions = items.map(({ id }, weight) => ({ id, weight }));

    setReordered(false);
    notifications.info('Saving', null);

    api.surveys(surveyId).questions.patch({ questions })
      .then(() => notifications.success('Saved'))
      .catch((error) => notifications.error(error.message));
  };

  const handleDiscard = () => {
    setReordered(false);
    setItems(props.items);
  };

  function handleDelete(question, index) {
    const prev = items;

    setItems(
      arrayRemoveImmutable(items, index)
    );

    notifications.info('Deleting', null);

    api.surveys(surveyId).questions(question.id).delete()
      .then(() => { 
        history.replace(`/surveys/${surveyId}/questions/create`);
        notifications.success('Deleted')
      })
      .catch((error) => {
        setItems(prev);
        notifications.error(error.message);
      });
  }

  return (
    <>
      {reordered && (
        <ActionBar>
          <Button 
            variant="outlined"
            onClick={handleDiscard}
          >
            Reset
          </Button>
          <Button 
            color="primary" 
            variant="outlined"
            onClick={handleSave}
          >
            Save
          </Button>
        </ActionBar>
      )}
      <DndContainer 
        cards={items}
        onMove={handleMove}
        itemType={ItemTypes.QUESTION}
      >
        {(question, index) => (
          <ListItem>
            <ListItemText>{question.name}</ListItemText>
            <ListItemActions>
              <EditButton to={`/surveys/${surveyId}/questions/${question.id}/edit`} />
              <DeleteButtonWithConfirmation onClick={() => handleDelete(question, index)} />
            </ListItemActions>
          </ListItem>
        )}
      </DndContainer>
    </>
  );
}

export default withNotifications(List);

import React from "react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table";
import { I18n } from "../../i18n";
import { CategoryResultService } from "../../services/SelectOptionService";
import styles from "../../style/styles";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import { FormTextField } from "../../components/dialog/formField";
import Edit from "../../components/dialog/edit.js";

export class CategoryResults extends React.Component {
  render() {
    const path = "category-results";
    const i18nProps = {
      i18n: I18n[this.props.lang].categoryResults,
      i18nCommon: I18n[this.props.lang].common,
    };

    return (
      <IndexTable
        {...this.props}
        {...i18nProps}
        serviceClass={CategoryResultService}
        path={path}
        columns={[
          { id: "id", title: "ID", center: true },
          { id: "title", title: i18nProps.i18n.title },
        ]}
        onEditItem={(itemId, item, handleItemSave, props) => {
          return (
            <Item
              item={item}
              itemId={itemId}
              indexPath={path}
              handleItemSave={handleItemSave}
              isEditable={true}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
        onViewItem={(itemId, item, props) => {
          return (
            <Item
              item={item}
              itemId={itemId}
              indexPath={path}
              isEditable={false}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
      />
    );
  }
}

const Item = withStyles(styles)((props) => {
  const handleGetData = async (service, editorState) => {
    return {};
  };

  const { classes, i18n, ...rest } = { ...props };

  return (
    <Edit
      i18n={i18n}
      handleGetData={handleGetData}
      serviceClass={CategoryResultService}
      {...rest}
      render={(fieldProps) => (
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={16}>
            <Grid item xs={6}>
              <FormTextField fieldName="title" i18n={i18n} {...fieldProps} />
            </Grid>
          </Grid>
        </Paper>
      )}
    />
  );
});

import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Divider,
  Fab,
  Menu,
  MenuItem
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

function AddOrganizationButton(props) {
  const [ anchorEl, setAnchorEl ] = useState();
  const { id } = useParams();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Fab 
        size="small" 
        color="primary"
        onClick={handleClick}
      >
        <AddIcon />
      </Fab>
      <Menu
        id="adding-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
      >
        <MenuItem
          component={Link} 
          to={`/activity/${id}/organizations/add`}
        >
            Add from registry
        </MenuItem>
        <MenuItem
          component={Link} 
          to={`/activity/${id}/organizations/import`}
        >
          Import from Excel file
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link} 
          to={`/activity/${id}/lists`}
        >
          Manage imported lists
        </MenuItem>
      </Menu>
    </>
  );
}

export default AddOrganizationButton;

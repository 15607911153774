import React from 'react';
import {
  Grid,
  Button,
  Typography,
  Modal,
} from '@material-ui/core';
import { Close, ArrowBack } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../../withRoot';
import fetchData from '../../../utils/fetch';
import downloadFile from '../../../utils/fetchFile'
import Loader from '../../../components/loader';
import { Link, Route, Redirect } from 'react-router-dom';
import Initiative from '../edit-initiative'
import classNames from 'classnames';
import 'url-search-params-polyfill';
import { I18n } from '../../../i18n/';
import {styles} from './styles';
import Slider from './slider';

const reports = {
  meetingProposals: 'meetingProposalsReport',
  createdQuestionnaires: 'questionnariesReport',
  governmentResolutions: 'governmentResolutionsReport',
  planningDocuments: 'planningDocumentsReport'
}


class GoodPracticeView extends React.Component {
    state = {
      loading: true,
      item: [],
      open: true,
    };

    componentDidMount() {
      this.getData();
    }

    async getData() {
      const itemId = window.location.href.match(/good-practices\/view\/(\d+)/)[1];
      const response = await fetchData('get', `/good_practices/${itemId}`);
      const item = response[0];

      if (!item || item.error){
        this.setState({ loading: false });
      }else{
        this.setState({ loading: false, item });
      }
    }

    async deleteItem(id) {
      const {lang} = this.props;
      if (window.confirm(`${I18n[lang].common.deleteConfirm} ${I18n[lang].goodPractice.goodPractice} ${id}?`)) {
        await fetchData('delete', `/good_practices/${id}`);
        this.setState({item: null, open: false});
        this.renderRedirect();
      }
    }

    openInitiative = ({ match: { params: { good_practice_id, id } } }) => {
      const initiative = id === 'new' ? null : this.state.item.initiatives.find(x => x.id === id)
      return <Initiative
        lang={this.props.lang}
        practiceId={good_practice_id}
        item={initiative}
        handleItemSave={ () => { this.getData() } }
      />;
    }

    async deleteInitiative(initiative) {
      if (window.confirm(`${I18n[this.props.lang].common.deleteConfirm} initiative?`)) {
        await fetchData('delete', `/good_practice_initiatives/${initiative.id}`);
        this.getData();
      }
    }

    handleDownload = (file) => {
      return async (e) => {
        e.preventDefault()
        downloadFile(file);
      }
    };

    handleClose = () => {
      this.setState({ open: false });
      this.renderRedirect();
    };

    renderRedirect = () => {
      if (!this.state.open) {
        return <Redirect to='/good-practices' />
      }
    };

    render() {

        const { classes, user } = this.props;
        const { item, loading } = this.state;

        if (loading) return '';

        if (!loading && !item) { return this.renderRedirect() }
        // if (!item) return (<Redirect to='/good-practices' />);

        const asAdmin = user && user.isAdmin
        const asEditor = !item.readOnly

        let keyStakeholdersQuotes = [];

        if (item.keyStackholdersQuote) {
          try {
            keyStakeholdersQuotes = JSON.parse(item.keyStackholdersQuote);
          } catch (e) {
            keyStakeholdersQuotes = [ item.keyStackholdersQuote ];
          }
        }

        return (
          <>
            {this.state.loading && (
              <Loader />
            )}

            {this.renderRedirect()}

            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.open}
              onClose={this.handleClose}
            >
              <div className={classNames('modal', 'root', classes.root, classes.modal )}>
                <a href className="close" onClick={this.handleClose}>
                  <Close/>
                </a>
                <Grid container spacing={24} alignItems='center'>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h2" component="h2" className="title">
                      {item.title}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={24} alignItems='flex-start' className="dates">
                  <Grid item xs={12}>
                    <Grid container spacing={24} alignItems='center'>
                      <Grid item xs={3}>
                        <h3>Development period:</h3>
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: -5 }}>
                        <h4>Start Date</h4>
                        <p>{item.developmentPeriodStartsAt}</p>
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: -5 }}>
                        <h4>End Date</h4>
                        <p>{item.developmentPeriodEndsAt}</p>
                      </Grid>
                      <Grid item xs={5} style={{
                        display: 'flex', justifyContent: 'flex-end', position: 'relative',
                        top: 20
                      }}>
                        <div className="buttons">
                          {(asAdmin || asEditor) && (
                            <Link to={`/good-practices/edit/${item.id}`}>
                              <Button variant="contained" className='button button-edit'>
                                <i></i>
                                Edit
                              </Button>
                            </Link>
                          )}
                          {asAdmin && (
                            <a onClick={() => this.deleteItem(item.id)} href>
                              <Button variant="contained" className='button button-delete'>
                                <Close />
                                Delete
                              </Button>
                            </a>
                          )}
                          <Link to={`/good-practices`}>
                            <Button variant="contained" className='button button-return'>
                              <ArrowBack />
                              Return
                            </Button>
                          </Link>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={24} alignItems='center' style={{ marginTop: 15 }}>
                      <Grid item xs={3}>
                        <h3>Implementation:</h3>
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: -5 }}>
                        <h4>Start Date</h4>
                        <p>{item.implementationStartsAt}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <h3>Municipality Passport</h3>

                <Grid container spacing={24} alignItems='flex-start'>
                  <Grid item xs={6}>

                    <div className={classNames(classes.block)}>
                      <h4>Oblast</h4>
                      <p>{item.region ? item.region.en : ''}</p>
                    </div>
                    <div className={classNames(classes.block)}>
                      <h4>Full name of municipality:</h4>
                      <p>{item.municipalityFullName}</p>
                    </div>
                    <div className={classNames(classes.block)}>
                      <h4>Estimated population:</h4>
                      <p>{item.municipalityPopulation}</p>
                    </div>
                    <div className={classNames(classes.block)}>
                      <h4>Type of Hromada:</h4>
                      <p>{item.hromadaType}</p>
                    </div>
                    <div className={classNames(classes.block)}>
                      <h4>Total number of settlements:</h4>
                      <p>{item.totalNumberOfSettlements}</p>
                    </div>
                    {item.community && (
                    <div className={classNames(classes.block)}>
                      <h4>AH URL:</h4>
                      <p><a href={`/communities/view/${item.community.id}`} target="_blank" rel="noreferrer">{item.community.title}</a></p>
                    </div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <div className="address">
                      <h4>Full postal address of municipality:</h4>
                      <div className={classNames(classes.block)}>
                        <h4>Telephone number:</h4>
                        <p>{item.municipalityPhoneNumber}</p>
                      </div>
                      <div className={classNames(classes.block)}>
                        <h4>Email address:</h4>
                        <p>{item.municipalityEmail}</p>
                      </div>
                      <div className={classNames(classes.block)}>
                        <h4>Official website:</h4>
                        <p>{item.municipalityWebsite}</p>
                      </div>
                      <div className={classNames(classes.block)}>
                        <h4>Head of municipality (P.I.B.):</h4>
                        <p>{item.municipalityHead}</p>
                      </div>
                    </div>

                    <div className={classNames(classes.block)}>
                      <h4>Full name of the department deploying the good practice:</h4>
                      <p>{item.deployingDepartmentName}</p>
                    </div>

                    <div className={classNames(classes.block)}>
                      <h4>Head of the relevant department in the municipality and/or persons resposible for the implementation</h4>
                      <p>{item.deployingDepartmentHead}</p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={24} alignItems='flex-start'>
                  <Grid item xs={6}>

                    <h3>Good Practice Description and Development</h3>

                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Short description of the good practice:</h4>
                      <p>{item.shortDescription}</p>
                    </div>

                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Description of the problem the good practice aimed to address:</h4>
                      <p>{item.aimedProblemDescription}</p>
                    </div>

                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Statistics reflection problem scope and success:</h4>
                      <p>{item.statisticsReflection}</p>
                    </div>

                    <h3>Plan of Activities and Results</h3>

                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Describe the sequence of activities leading to the implementation of the good practice:</h4>
                      <p>{item.activitiesSequence}</p>
                    </div>

                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Intended beneficiaries:</h4>
                      <p>{item.intendedBeneficiaries}</p>
                    </div>

                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Implementation partners:</h4>
                      <p>
                        {item.implementationPartners}
                      </p>
                    </div>

                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Qualitative and quantitative results:</h4>
                      <p>
                        {item.qualitiveAndQuantitiveResults}
                      </p>
                    </div>

                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Did a project have a direct role in supporting the good practice?</h4>
                      <p>{item.directRoleInSupporting}</p>
                    </div>

                    <h3>Good Practice Implementation Budget</h3>
                    <div className={classNames(classes.block)}>
                      <h4>Total spending for the implementation of the good practice:</h4>
                      <p>{item.totalSpending}</p>
                    </div>
                    <div className={classNames(classes.block)}>
                      <h4>Sources of finances:</h4>
                      <p>{item.financeSources}</p>
                    </div>
                    <div className={classNames(classes.block)}>
                      <h4>Non-financial resources required:</h4>
                      <p>{item.nonFinancialResources}</p>
                    </div>
                    <div className={classNames(classes.block)}>
                      <h4>Estimated cost savings due to the implementation of the good practice:  </h4>
                      <p>{item.estimatedCostSavings}</p>
                    </div>

                  </Grid>
                  <Grid item xs={6}>
                    <h3>Lessons Learned</h3>
                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Descriptions of important success factors:</h4>
                      <p>{item.importantSuccessFactors}</p>
                    </div>
                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Risks identified/encountered during development and implementation:</h4>
                      <p>{item.identifiedRisks}</p>
                    </div>
                    <div className={classNames(classes.block, classes.mb32)}>
                      <h4>Methods used to mitigate or resolve the risks:</h4>
                      <p>{item.risksResolvingMethods}</p>
                    </div>

                    <h3>Quotations</h3>
                    <div className={classNames(classes.block, classes.mb32)}>
                      <div>{keyStakeholdersQuotes.map((item, ki) => (<p key={ki}>{item}</p>))}</div>
                    </div>
                    {item.photos.length &&
                      <>
                        <h3> Photographs</h3>
                        {item.photos.length && (
                          <Slider slides={item.photos} />
                        )}
                      </>
                    }


                    <h3>List Key Documents For Reference</h3>

                    <div className="key-list">
                      {Object.keys(reports).map((descField, ri) => {
                        const reportField = reports[descField];
                        const report = item[reportField];
                        return (
                          <p key={ri}>
                            {item[descField]}
                            {report && (
                              <Link to='#' className='button button-pdf' title={report.name} onClick={this.handleDownload(report.url)}>
                                <i></i>
                              </Link>
                            )}
                          </p>
                        )
                      })}
                    </div>

                    <h3>Media Coverage Of The Good Practice</h3>
                    <div className="links">
                      {item.mediaUrls.map((url, ui) => {
                        return (
                          <p key={ui}>
                            <a href={url} target="_blank" rel="noreferrer">{url}</a>
                          </p>
                        )
                      })}
                    </div>

                  </Grid>
                </Grid>

                <div className="table-wrapper">
                  <h3>Initiatives For The Dissemination Of The Good Practice</h3>
                  {(asAdmin || asEditor) && (
                    <Link to={`/good-practices/view/${item.id}/initiatives/new`}>
                      <Button variant="contained" className='button button-edit add-initiative'>
                        <i></i>
                        Add initiative
                </Button>
                    </Link>
                  )}

                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>URL</th>
                        <th>Date(s)</th>
                        <th>Target group(s)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.initiatives.map((initiative, ii) => {
                        return (
                          <tr key={ii}>
                            <td>{ii + 1}</td>
                            <td>{initiative.kindName}</td>
                            <td>{initiative.description}</td>
                            <td>{initiative.url}</td>
                            <td className="light">{initiative.date}</td>
                            <td className="light">{initiative.targetGroups.map((targetGroup, ti) => {
                              return (
                                <span key={ti}>
                                  <span>{targetGroup}</span>
                                  <br />
                                </span>
                              )
                            })}</td>
                            <td className={classNames('actions', classes.actions)}>
                              {(asAdmin || asEditor) && (
                                <>
                                  <Link to={`/good-practices/view/${item.id}/initiatives/${initiative.id}`} className='edit'>
                                    <i></i>
                                  </Link>
                                  <a href onClick={(event) => { event.preventDefault(); this.deleteInitiative(initiative) }}
                                    className='delete'
                                  >
                                    <i></i>
                                  </a>
                                </>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <h3>Further use of the good practice</h3>
                <p>{item.furtherUsage}</p>
              </div>
            </Modal>

            <Route path="/good-practices/view/:good_practice_id/initiatives/:id" render={this.openInitiative} />
          </>
        );
    }
}

export default withRoot(withStyles(styles)(GoodPracticeView));

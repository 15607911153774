import React from "react";
import { observer } from "mobx-react";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import withRoot from "../../withRoot";
import { Form, Field } from "react-final-form";
import { StoreContext } from "../../stores/context";
import { required } from "../../validators/required";
import { forgotPasswordRoute } from "../../utils/routes";
import { GIZPublicHeader } from "../../components/GIZPublicHeader";
import { config } from "../../style/config";

const styles = (theme) => ({
  root: {
    minHeight: "calc(100vh - 100px)",
    "& .remember-link": {
      "& span": {
        color: "#6f6f6f",
      },
    },
    "& .text-field": {
      marginBottom: "0 !important",
    },
  },
  cover: {
    background: config.colors.primaryBackground,
  },
  title: {
    position: "relative",

    "& h1": {
      color: config.colors.primary,
      textTransform: "uppercase",
      fontWeight: "900",
      fontSize: "110px",
      textAlign: "center",
      paddingTop: "40px",
      lineHeight: "1.1",
      margin: "0 0 8px",
      [theme.breakpoints.down(1600)]: {
        fontSize: "80px",
        paddingTop: "0px",
      },
    },
    "& h4": {
      color: config.colors.primary,
      textAlign: "center",
      fontWeight: "100",
      fontSize: "40px",
      padding: "60px 0 15px",
      margin: "0 0 60px",
      letterSpacing: "0",
      lineHeight: 1,
      [theme.breakpoints.down(1600)]: {
        fontSize: "30px",
        padding: "20px 0 10px",
        margin: "0",
      },
    },
    "& p": {
      fontSize: "14px",
      textAlign: "center",
      paddingTop: "10px",
      color: config.colors.primary,
      margin: "0",
      fontWeight: "400",
      position: "relative",
      paddingBottom: "20px",
    },
    "& div": {
      position: "absolute",
      bottom: "20px",
      left: "50%",
      marginLeft: "-5px",
      width: "10px",
      height: "11px",
      background: "url(/images/icons/icon-arrow-login.png) no-repeat 0 0",
      [theme.breakpoints.down(1600)]: {
        bottom: "5px",
      },
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "400px",
    margin: "0 auto",
    justifyContent: "stretch",
  },
  formContainer: {
    padding: "30px 0",
    background: "#fff",
    [theme.breakpoints.down(1600)]: {
      padding: "0 0 15px",
    },
  },
  textField: {
    width: "100%",
    marginTop: "20px",
    background: "#e1edfb",
    textAlign: "left",
    "&.error-field fieldset": {
      border: "1px solid #fe8368",
      borderLeftWidth: "3px",
    },
    "& label": {
      textAlign: "left",
    },
  },
  checkbox: {
    "& .checkbox": {
      width: "20px",
      height: "20px",
      borderRadius: "5px",
      backgroundColor: "#E1EDFB",
      "&--checked": {
        backgroundImage: "url(/images/icons/icon-check-mark.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      },
    },
  },
  forgotLink: {
    color: "#6f6f6f",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& button": {
      marginTop: "30px",
      marginBottom: "20px",
      [theme.breakpoints.down(1600)]: {
        marginTop: "10px",
        marginBottom: "10px",
      },
    },
  },
  authException: {
    width: "100%",
    color: "#a94442",
    border: "1px solid #ebcccc",
    borderRadius: "4px",
    backgroundColor: "#f2dede",
    padding: "10px 20px",
  },
});

class Login extends React.Component {
  static contextType = StoreContext;

  onSubmit = async (values) => {
    const { authStore, routerStore } = this.context;

    try {
      await authStore.signin(
        values.username,
        values.password,
        values.remember_me
      );
      routerStore.goHome();
    } catch (ex) {}
  };

  render() {
    const { classes } = this.props;
    const { authStore, userStore } = this.context;

    if (userStore.id) {
      return <Redirect to="/" />;
    }

    return (
      <div className={classes.root}>
        <div className={classes.cover}>
          <GIZPublicHeader />
          <div className={classes.title}>
            <h1>Welcome</h1>
            <p>please sign in</p>
          </div>
        </div>
        <div className={classes.formContainer}>
          <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className={classes.form}
              >
                {authStore.errorMessage.length > 0 && (
                  <div className={classes.authException}>
                    {authStore.errorMessage}
                  </div>
                )}

                <Field name="username" validate={required}>
                  {({ input, meta }) => (
                    <TextField
                      label="Your username"
                      margin="normal"
                      className={classNames("text-field", {
                        "error-field": meta.error && meta.touched,
                      })}
                      variant="outlined"
                      {...input}
                    />
                  )}
                </Field>

                <Field name="password" validate={required}>
                  {({ input, meta }) => (
                    <TextField
                      label="Your password"
                      type="password"
                      margin="normal"
                      className={classNames("text-field", {
                        "error-field": meta.error && meta.touched,
                      })}
                      variant="outlined"
                      {...input}
                    />
                  )}
                </Field>

                <Field name="remember_me">
                  {({ input }) => (
                    <FormControlLabel
                      className="remember-link"
                      control={
                        <Checkbox
                          className={classes.checkbox}
                          value="checked"
                          icon={<div className="checkbox"></div>}
                          checkedIcon={
                            <div className="checkbox checkbox--checked"></div>
                          }
                          {...input}
                        />
                      }
                      label="Remember me"
                    />
                  )}
                </Field>

                <div className={classes.buttonContainer}>
                  <Button
                    className="btn-login"
                    variant="contained"
                    type="submit"
                    size="large"
                    color="primary"
                  >
                    Sign In
                  </Button>

                  <Link className={classes.forgotLink} to={forgotPasswordRoute}>
                    Forgot your password?
                  </Link>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(observer(Login)));

import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CalendarIcon from '@material-ui/icons/EventNote';
import ListIcon from '@material-ui/icons/List';
import { useSearch } from 'support/hooks';
import dayjs from 'dayjs';

const views = [
  '/activities', 
  '/activities/calendar'
]; 

function ChangeViewBar(props) {
  const { pathname } = useLocation();
  const [ tab, setTab ] = useState(
    views.findIndex((view) => view === pathname)
  );
  const history = useHistory();
  const search = useSearch();

  const handleTabChange = (event, value) => {
    setTab(value);

    if (value === 1 && !(search.get('startDate') && search.get('endDate'))) {
      search.set('startDate', dayjs().startOf('month').format('YYYY-MM-DD'));
      search.set('endDate', dayjs().endOf('month').format('YYYY-MM-DD'));
    }

    history.push({ pathname: views[value], search: search.toString() });
  };

  return (
    <Tabs
      value={tab}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleTabChange}
    >
      <Tab icon={<ListIcon />} />
      <Tab icon={<CalendarIcon />} />
    </Tabs>
  )
}

export default ChangeViewBar;

import { Field } from 'react-final-form';
import {
  FormControl,
  Input,
  InputLabel,
  Select
} from '@material-ui/core';

function QualitativeField(props) {
  const { name, label, scale } = props;

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select 
            native
            input={<Input {...input} />}
          >
            <option></option>
            {scale.map((option, index) => (
              <option 
                key={index}
                value={index}
              >
                {option}
              </option>
            ))}
          </Select>
        </FormControl>
      )}
    </Field>
  );
}

export default QualitativeField;

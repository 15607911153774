import { useState } from 'react';
import { Field } from 'react-final-form';
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import {
  RadioButtonUnchecked as RadioIcon,
  Settings as SettingsIcon
} from '@material-ui/icons';
import DeleteButton from '../DeleteButton';
import ShowQuestionsField from './fields/ShowQuestionsField';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    marginBottom: '5px'
  },
  settings: {
    padding: '10px'
  }
};

const required = (value) => (value ? undefined : 'Required');

function RadioOption(props) {
  const { name, showQuestions, onDelete, classes } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={classes.root}>
      <FormControl fullWidth>
        <Field name={`${name}.value`} validate={required}>
          {({ input, meta }) => (
            <TextField 
              fullWidth
              variant="outlined"
              {...input}
              error={meta.error && meta.touched}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RadioIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setExpanded((expanded) => !expanded)}>
                      <SettingsIcon fontSize="small" />
                    </IconButton>
                    <DeleteButton onClick={onDelete} /> 
                  </InputAdornment>
                )
              }}
            />
          )}
        </Field>
      </FormControl>

      {expanded &&
        <div className={classes.settings}>
          {showQuestions.length > 0 && 
            <ShowQuestionsField 
              name={`${name}.shownQuestionIds`} 
              questions={showQuestions} 
            />
          }
        </div>
      }
    </div>
  );
}

export default withStyles(styles)(RadioOption);

import { withStyles } from '@material-ui/core/styles';
import Image from './Image';

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '160px',
    padding: '20px 0',
    backgroundColor: '#FFF'
  }
};

function Footer(props) {
  const { images, classes } = props;

  return (
    <footer className={classes.root}>
      {!images.length && <img src="/images/logo.jpg" alt="GIZ logo" height="120" />}
      {images.map((url, index) => (
        <Image key={index} src={url} alt={`Partner logo ${index}`} />
      ))}
    </footer>
  );
}

export default withStyles(styles)(Footer);

import React from 'react';
import { I18n } from 'i18n';
import OrganizationsService from 'services/OrganizationsService';
import Edit from 'components/dialog/edit.js';
import {
  FormTextField,
  FormSelectField,
  FormPhoneField,
  FormNumberField
} from 'components/dialog/formField';
import {
  Paper
} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import { withStyles } from '@material-ui/core/styles';
import styles from 'style/styles.js';
import DataSource from 'components/DataSource';
import { Link } from 'react-router-dom';
import ProjectCondition from 'components/conditions/ProjectCondition';
import FormCondition from 'components/conditions/FormCondition';
import Table from 'components/tables/Table';
import { required } from 'validators';
import AddressControl from "../../components/AddressControl.js";
import { Map, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import classNames from "classnames";

class Item extends React.Component {
  constructor(props) {
    super();
  }

  async handleGetData(service, editorState) {
    const regionsList = await service.GetRegions();
    const employerTypesList = await service.GetSelectOptions(
      "employer_types",
      editorState.employerTypeId,
      false,
      true,
      "size=1000&sort[]=id,asc"
    );
    const economicActivityTypesList = await service.GetSelectOptions(
      "economic_activity_types",
      editorState.economicActivityTypeId,
      false,
      true,
      "size=1000&sort[]=id,asc"
    );
    const sizesList = await service.GetSelectOptions(
      "sizes",
      editorState.sizeId,
      false,
      true,
      "size=1000&sort[]=id,asc"
    );

    sizesList.unshift({
      id: '',
      label: ''
    });

    const state = {
      regionsList,
      employerTypesList,
      economicActivityTypesList,
      sizesList
    };

    return state;
  }

  render = () => {
    const i18n = I18n[this.props.lang].organizations;
    const { classes, ...rest } = { i18n, ...this.props };
    const lang = this.props.lang;

    return (
      <Edit
        i18n={i18n}
        handleGetData={this.handleGetData}
        onAfterGetData={this.onAfterGetData}
        serviceClass={OrganizationsService}
        title={
          !this.props.item ? I18n[lang].organizations.create : undefined
        }
        {...rest}
        render={(fieldProps) => (
          <>
            <Grid container spacing={24} alignItems="flex-start">
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={16}>
                    <Grid item xs={4}>
                      <FormTextField 
                        fieldName="nameEn" 
                        label="Name"
                        {...fieldProps} 
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormSelectField
                        fieldName="regionId"
                        label="Country"
                        selectList={fieldProps.formState.regionsList}
                        isDisabled={fieldProps.formState.isRegionDisabled}
                        {...fieldProps}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormTextField 
                        fieldName="address" 
                        label="City/Municipality"
                        {...fieldProps} 
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormSelectField
                        fieldName="employerTypeId"
                        label={I18n[lang].common.employerTypeEn}
                        selectList={fieldProps.formState.employerTypesList}
                        {...fieldProps}
                      />
                    </Grid>

                    <ProjectCondition not abbr="AZP">
                      <Grid item xs={4}>
                        <FormSelectField
                          fieldName="sizeId"
                          label="Size (number of employees)"
                          selectList={fieldProps.formState.sizesList}
                          {...fieldProps}
                        />
                      </Grid>
                    </ProjectCondition>

                    <ProjectCondition abbr="AZP">
                      <Grid item xs={4}>
                        <FormNumberField
                          fieldName="numberOfEmployees"
                          label="Number of Employees"
                          min={1}
                          {...fieldProps}
                        />
                      </Grid>
                    </ProjectCondition>

                    <Grid item xs={4}>
                      <FormSelectField
                        fieldName="economicActivityTypeId"
                        label={I18n[lang].organizations.economicActivityType}
                        selectList={fieldProps.formState.economicActivityTypesList}
                        {...fieldProps}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormTextField 
                        fieldName="email" 
                        label="Email"
                        {...fieldProps} 
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormPhoneField
                        fieldName="phone" 
                        label="Phone"
                        {...fieldProps} 
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormTextField 
                        fieldName="url" 
                        label="Website/Social media"
                        {...fieldProps} 
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormTextField fieldName="address" {...fieldProps} />
                    </Grid>

                    <Grid item xs={4}>
                      <FormTextField
                          fieldName="locationCoordinates"
                          label={I18n[lang].participants.placeOfActivityCoordinates}
                          {...fieldProps}
                          isReadonly
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Map
                          className={classNames(classes.map, {
                            "error-field": fieldProps.isValid(
                                "locationCoordinates"
                            ),
                          })}
                          center={fieldProps.formState.location}
                          zoom={fieldProps.formState.zoom}
                      >
                        {this.props.isEditable && (
                            <AddressControl
                                className={classes.mapSearch}
                                onCoordinatesChange={(
                                    latLng,
                                    addressParts,
                                    address
                                ) =>
                                    fieldProps.action({
                                      fieldName: "locationCoordinates",
                                      value: { latLng, addressParts, address },
                                    })
                                }
                            />
                        )}

                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                        <Marker
                            position={fieldProps.formState.location}
                        ></Marker>
                      </Map>
                    </Grid>

                    <ProjectCondition abbr="AZP">
                      <Grid item xs={4}>
                        <FormSelectField
                          fieldName="relocated"
                          label="Relocated?"
                          selectList={[
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' }
                          ]}
                          {...fieldProps}
                        />
                      </Grid>

                      <FormCondition when="relocated" is={true}>
                        <Grid item xs={4}>
                          <FormSelectField
                            fieldName="relocatedFromRegionId"
                            label="Relocated from"
                            selectList={Array.isArray(fieldProps.formState.regionsList) ? fieldProps.formState.regionsList.filter((region) => region.occupied) : []}
                            validate={required}
                            {...fieldProps}
                          />
                        </Grid>
                      </FormCondition>

                      <Grid item xs={4}>
                        <FormNumberField
                          fieldName="initialNumberOfIdps"
                          label="Initial number of IDPs"
                          min={1}
                          {...fieldProps}
                        />
                      </Grid>
                    </ProjectCondition>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            {this.props.itemId && (
              <DataSource path={`organizations/${this.props.itemId}/participations`}>
                {(participations) => (
                  Array.isArray(participations) && participations.length > 0 && (
                    <Table
                      columns={[
                        {
                          heading: 'Activity',
                          render: (row) => (
                            <Link
                              to={`/legacy/activities/view/${row.activity.id}`}
                              target="_blank"
                            >
                              {row.activity.name}
                            </Link>
                          )
                        },
                        {
                          heading: 'Number of hired IDPs',
                          render: (row) => row.numberOfHiredIdps
                        }
                      ]}
                      rows={participations}
                    />
                ))}
              </DataSource>
            )}
          </>
        )}
      />
    );
  };
}

export default withStyles(styles)(Item);

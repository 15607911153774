import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useApi } from 'support/api';
import Paper from '../components/Paper';
import Button from '@material-ui/core/Button';
import Form from './Form';
import snakecaseKeys from 'snakecase-keys';
import Markdown from './Markdown';
import Footer from './Footer';

const styles = (theme) => ({
  content: {
    minHeight: 'calc(100vh - 160px)',
    paddingTop: '30px'
  },
  paper: {
    margin: '0 auto 10px',
    textAlign: 'left',
    maxWidth: '600px'
  },
  buttons: {
    textAlign: 'center',
    margin: '10px 0'
  }
});

function SurveyView(props) {
  const { survey, foreword, afterword, classes } = props;

  const [status, setStatus] = useState(false);

  const api = useApi(false);

  const initialValues = Object.fromEntries(
    survey.questions.map((question) => [`question${question.id}`, { questionId: question.id }])
  );

  const handleSubmit = (values) => {
    let answers = Object.values(values);

    answers = answers.flatMap(({ questionId, optionId, value }) => {
      if (Array.isArray(optionId)) {
        return optionId.map((optionId) => ({ questionId, optionId, value }));
      }

      return { questionId, optionId, value };
    }).filter(({ questionId, optionId, value }) => optionId !== undefined || value !== undefined);

    answers = snakecaseKeys(answers);

    api.surveys(survey.id).replies.create({ answers })
      .then(() => setStatus('completed'));
  };

  return (
    <>
      <div className={classes.content}>
        <Paper className={classes.paper}>
          {!status && (
            <>
              <Markdown>
                {foreword}
              </Markdown>
              <div className={classes.buttons}>
                <Button 
                  color="primary" 
                  variant="outlined"
                  onClick={() => setStatus('in progress')}
                >
                  Розпочати опитування
                </Button>
              </div>
            </>
          )}

          {status === 'in progress' && (
            <Form survey={survey} onSubmit={handleSubmit} initialValues={initialValues} /> 
          )}

          {status === 'completed' && (
            <Markdown>
              {afterword}
            </Markdown>
          )}
        </Paper>
      </div>

      <Footer images={survey.partnerLogos} />
    </>
  );
}

export default withStyles(styles)(SurveyView);

import React from "react";
import withRoot from "../../../withRoot";
import BaseGraph from "../base-graph";
import fetchData from "../../../utils/fetch";

const datasetOptions = [
  {
    label: "% Activity result",
    data: [51, 65, 40, 49, 60, 37, 40],
    fill: false,
    borderColor: "#fdbb2d",
    backgroundColor: "#fdbb2d",
    pointBorderColor: "#fdbb2d",
    pointBackgroundColor: "#fdbb2d",
    pointHoverBackgroundColor: "#fdbb2d",
    pointHoverBorderColor: "#fdbb2d",
  },
];

const options = {
  responsive: true,
  tooltips: {
    mode: "label",
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
        },
        ticks: {
          callback: function (value, index, values) {
            return value + "%";
          },
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString: "Activities often contribute to more than one result.",
          fontColor: "#aaa",
          fontSize: 12,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

class ActivityResultsChart extends React.Component {
  state = {
    labels: [],
  };

  componentWillMount = () => {
    this.getData();
  };

  getData = async () => {
    const data = await fetchData("get", "/graph/activity_results");
    this.setState({ labels: data[0].secondaryData });
  };

  render() {
    return (
      <>
        <BaseGraph
          type="horizontalBar"
          tableTitle="Activities distributed by result (%)"
          url="/graph/activity_results"
          chartOptions={options}
          datasetOptions={datasetOptions}
          filter={this.props.filter}
          footnotes={this.state.labels}
        />
      </>
    );
  }
}

export default withRoot(ActivityResultsChart);

import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DataSourceWithLoadMore from 'components/DataSourceWithLoadMore';
import {
  InputAdornment,
  TextField
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddParticipantButton from './AddParticipantButton';
import { debounce } from 'lodash';
import AppBar from 'components/AppBarWithBackButton';
import Table from 'components/tables/Table';

function AddParticipantsView(props) {
  const { id } = useParams();
  const [query, setQuery] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(
      (value) => setQuery(value.length > 1 ? `?search=${value}` : '')
      , 500
    )
  , []);

  const columns = [
    {
      heading: 'Name',
      render: (row) => row.nameTranscription
    },
    {
      heading: 'Gender',
      render: (row) => row.gender
    },
    {
      heading: 'Phone',
      render: (row) => row.cellPhone
    },
    {
      heading: 'Region',
      render: (row) => row.region?.name
    },
    {
      heading: 'Organization',
      render: (row) => row.organization?.name
    },
    {
      heading: 'Actions',
      render: (row) => (
        <AddParticipantButton 
          activityId={id}
          participant={row}
        />
      )
    }
  ];

  return (
    <>
      <AppBar>
        <TextField
          placeholder="Search..."
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </AppBar>
      <DataSourceWithLoadMore path={`ul-activities/${id}/participants${query}`}>
        {(participants) => (
          <Table
            columns={columns}
            rows={participants}
          />
        )}
      </DataSourceWithLoadMore>
    </>
  );
}

export default AddParticipantsView;

import React from 'react';
import {
  Grid,
  Card,
  Typography
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';
import {I18n} from '../i18n/';
import classNames from 'classnames';
import Dialogue from '../components/dialogue';
import { config } from "../style/config";
import SurveysIcon from '@material-ui/icons/PollOutlined';
import TemplatesIcon from '@material-ui/icons/Description';
import OrganizationsIcon from '@material-ui/icons/Business';

const styles = theme => ({
  root: {
    padding: '0 2.8% 13px',
    width: '100%',
    margin: '0',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1440)]: {
      padding: '0 5% 13px',
    },
  },
  home: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 220px)',
  },
  h2: {
    fontSize: '4vh !important',
    lineHeight: '1 !important',
    letterSpacing: '2pt',
    '&.extra': {
      margin: '3.2vh 0 3.7vh!important',
      fontSize: '3.6vh !important',
      letterSpacing: '1pt',
      [theme.breakpoints.down(1440)]: {
        margin: '3px 0 26px!important',
        fontSize: '32px !important',
      }
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#eef2f6',
    marginBottom: '3.5vh',
    boxShadow: 'none',
    borderRadius: '10px',
    [theme.breakpoints.down(1440)]: {
      marginBottom: '30px',
      // height: 200,
      height: 117,
    },
    '&:hover': {
      boxShadow: '0 0 0 5px #dce4ec'
    },
    '&.extra': {
      marginBottom: '6px',
      '& h4': {
        fontSize: '2.7vh',
        [theme.breakpoints.down(1440)]: {
          fontSize: '24px'
        },
      },
      '& p': {
        marginTop: '17px'
      },
    }
  },
  cardIcon: {
    color: '#FFF',
    width: '6vw',
    flex: '0 0 6vw',
    height: '14vh',
    background: config.colors.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(1440)]: {
      width: '86px',
      flex: '0 0 86px',
      height: '100%',
    },
    '& i': {
      display: 'block',
      width: '50px',
      height: '50px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: '50px 50px'
    },
    '&.extra': {
      width: '138px',
      flex: '0 0 138px',
      height: '138px',
      [theme.breakpoints.down(1440)]: {
        height: '100%',
      },
      '& i': {
        width: '62px',
        height: '62px',
        backgroundSize: '62px 62px',
        '&.cardIconName': {
          width: '85px',
          height: '85px',
          backgroundSize: '85px 85px',
        }
      }
    }
  },
  cardIconActivities: {
    backgroundImage: 'url(/images/icons/home-events.svg)'
  },
  cardIconParticipants: {
    backgroundImage: 'url(/images/icons/home-participants.svg)',
    backgroundSize: '70px 70px !important',
    width: '70px !important',
    height: '70px !important'
  },
  cardIconTrainers: {
    backgroundImage: 'url(/images/icons/home-trainers.svg)',
    backgroundSize: '60px 60px !important',
    width: '60px !important',
    height: '60px !important'
  },
  cardIconPractice: {
    backgroundImage: 'url(/images/icons/home-practice.svg)'
  },
  cardIconIndicators: {
    backgroundImage: 'url(/images/icons/home-indicators.svg)'
  },
  cardIconMaps: {
    backgroundImage: 'url(/images/icons/home-bar-chart.svg)',
  },
  cardIconGraphs: {
    backgroundImage: 'url(/images/icons/home-graphs.svg)'
  },
  cardIconSearch: {
    backgroundImage: 'url(/images/icons/home-loupe.svg)'
  },
  cardIconFilter: {
    backgroundImage: 'url(/images/icons/home-filter.svg)'
  },
  cardIconFacilitators: {
    backgroundImage: 'url(/images/icons/home-facilitators.svg)'
  },
  cardIconOrganizations: {
    backgroundImage: 'url(/images/icons/home-hromadas.svg)',
    backgroundSize: '70px 70px !important',
    width: '70px !important',
    height: '70px !important'
  },
  cardIconSquare: {
    width: '70px',
    height: '70px'
  },
  cardInfo: {
    padding: '0 22px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h4': {
      color: '#778899',
      fontSize: '2.7vh',
      [theme.breakpoints.down(1440)]: {
        fontSize: '21px'
      },
      fontWeight: '100',
      margin: '0',
      lineHeight: '1.5rem',
      letterSpacing: '0.05em'
    },
    '& p': {
      color: '#778899',
      fontSize: '1.3vh',
      [theme.breakpoints.down(1440)]: {
        fontSize: '10pt'
      },
      fontWeight: '400',
      margin: '5px 0 0',
      lineHeight: '1.2',
      letterSpacing: '1pt'
    }
  },
  subRow: {
    background: '#f7f9fb',
    padding: '0 33px 0',
    margin: '0 30px 0 0',
    width: '100%',
  },
  [theme.breakpoints.down(1440)]: {
    padding: '0 2.8% 0',
  },
  construction: {
    backgroundImage: 'url(/images/icons/under-construction.png)',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    paddingRight: '20px'
  }
});


class Home extends React.Component {
  onWelcomeMsgClose(){
    localStorage.setItem('welcomeMsgShown', true)
  }

  render() {
    const { classes, user } = this.props;
    const lang = this.props.lang;
    const isOes = user && (user.authorities.indexOf('ROLE_OES')>=0)
    const isGuest = user && (user.authorities.indexOf('ROLE_GUEST')>=0)
    const showTrainers = user && !isOes && !isGuest;
    const isMinreg = user && user.authorities.indexOf("ROLE_MINISTRY") >= 0;

    if (!user) {
      return (
        ''
      )
    }

    return (
      <div className={classes.home}>
        {isGuest && !localStorage.getItem('welcomeMsgShown') && (
          <Dialogue
            title={"Dear M&E User,"}
            content={
              "You will be able to preview a number of M&E’s tools: search and analyse activities, amalgamated hromada passport, activities and participants registries, and good practice registry. Please be aware that all data entered during this session will be lost and settings will be reset to defaults on logout.\nYour feedback is highly appreciated. Just click on ‘Feedback’ to share your comments, suggestions or questions."
            }
            onClose={this.onWelcomeMsgClose}
          />
        )}

        <Grid container spacing={24} className={classes.root}>
          <Grid item xs={12} md={4}>
            <Link to="/activities">
              <Card className={classes.card}>
                <div className={classes.cardIcon}>
                  <i className={classes.cardIconActivities}></i>
                </div>
                <div className={classes.cardInfo}>
                  <h4>{I18n[lang].home.activities}</h4>
                  <p>{I18n[lang].home.activitiesText}</p>
                </div>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} md={4}>
            <Link to="/participants">
              <Card className={classes.card}>
                <div className={classes.cardIcon}>
                  <i className={classes.cardIconParticipants}></i>
                </div>
                <div className={classes.cardInfo}>
                  <h4>{I18n[lang].home.participants}</h4>
                  <p>{I18n[lang].home.participantsText}</p>
                </div>
              </Card>
            </Link>
          </Grid>
          {showTrainers && (
            <Grid item xs={12} md={4}>
              <Link to="/trainers">
                <Card className={classes.card}>
                  <div className={classes.cardIcon}>
                    <i className={classes.cardIconTrainers}></i>
                  </div>
                  <div className={classes.cardInfo}>
                    <h4>{I18n[lang].home.trainers}</h4>
                    <p>{I18n[lang].home.trainersText}</p>
                  </div>
                </Card>
              </Link>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Link to="/templates">
              <Card className={classes.card}>
                <div className={classes.cardIcon}>
                  <TemplatesIcon className={classes.cardIconSquare} />
                </div>
                <div className={classes.cardInfo}>
                  <h4>Templates</h4>
                  <p>Browse & add templates</p>
                </div>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link to="/organizations">
              <Card className={classes.card}>
                <div className={classes.cardIcon}>
                  <OrganizationsIcon className={classes.cardIconSquare} />
                </div>
                <div className={classes.cardInfo}>
                  <h4>{I18n[lang].home.organizations}</h4>
                  <p>{I18n[lang].home.organizationsText}</p>
                </div>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <a href="https://survey.dt4sme.info/admin">
              <Card className={classes.card}>
                <div className={classes.cardIcon}>
                  <SurveysIcon className={classes.cardIconSquare} />
                </div>
                <div className={classes.cardInfo}>
                  <h4>Surveys</h4>
                  <p>Browse & add surveys</p>
                </div>
              </Card>
            </a>
          </Grid>
        </Grid>

        <Grid container spacing={24} className={classes.subRow}>
          <Grid item xs={4}>
            <Typography gutterBottom variant="h2" component="h2" className={classNames(classes.h2, 'extra')}>
              {I18n[lang].home.indicators}
            </Typography>

            <Link to="/indicators">
              <Card className={classNames(classes.card, 'extra')}>
                <div className={classNames(classes.cardIcon, 'extra')}>
                  <i className={classes.cardIconIndicators}></i>
                </div>
                <div className={classNames(classes.cardInfo, 'extra')}>
                  <h4>{I18n[lang].home.program}</h4>
                  <p>{I18n[lang].home.programText}</p>
                </div>
              </Card>
            </Link>
          </Grid>

          {!isMinreg && (
          <Grid item xs={4}>
            <Typography gutterBottom variant="h2" component="h2" className={classNames(classes.h2, 'extra')}>
              {I18n[lang].home.map}
            </Typography>
            <Link to="/map">
              <Card className={classNames(classes.card, 'extra')}>
                <div className={classNames(classes.cardIcon, 'extra')}>
                  <i className={classNames(classes.cardIconMaps, 'cardIconName')}></i>
                </div>
                <div className={classNames(classes.cardInfo, 'extra')}>
                  <h4>{I18n[lang].home.maps}</h4>
                  <p>{I18n[lang].home.mapsText}</p>
                </div>
              </Card>
            </Link>
          </Grid>
          )}

          <Grid item xs={4}>
            <Typography gutterBottom variant="h2" component="h2" className={classNames(classes.h2, 'extra')}>
              {I18n[lang].home.search}
            </Typography>
            <a href="/search/oes">
              <Card className={classNames(classes.card, 'extra')}>
                <div className={classNames(classes.cardIcon, 'extra')}>
                  <i className={classes.cardIconSearch}></i>
                </div>
                <div className={classNames(classes.cardInfo, 'extra')}>
                  <h4>{I18n[lang].home.oes}</h4>
                  <p>{I18n[lang].home.oesText}</p>
                </div>
              </Card>
            </a>
          </Grid>
        </Grid>

      </div>
    );
  }
}

export default withRoot(withStyles(styles)(Home));

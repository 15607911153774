import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import isoWeek from 'dayjs/plugin/isoWeek';
import css from './css/Grid.module.css';
import clsx from 'clsx';
import EventsDecorator from './EventsDecorator';
import { useSearch } from 'support/hooks';
import { dayNames, daysFor, extendedMonth } from './helpers';
import IconButton from '@material-ui/core/IconButton';
import NextIcon from '@material-ui/icons/NavigateNext';
import TodayIcon from '@material-ui/icons/Today';
import PrevIcon from '@material-ui/icons/NavigateBefore';

dayjs.extend(isoWeek);

function Grid(props) {
  const { events } = props;
  const search = useSearch();
  const history = useHistory();
  const month = dayjs(search.get('month') || undefined);
  const days = daysFor(month);
  const basis = month.startOf('month').startOf('isoWeek');
  const blocks = EventsDecorator.make(basis, events).blocks;

  const handleMonthChange = (step) => {
    const format = 'YYYY-MM-DD';
    const nextMonth = step ? month.add(step, 'month') : dayjs();
    const [startDate, endDate] = extendedMonth(nextMonth);

    search.set('month', nextMonth.format(format));
    search.set('startDate', startDate.format(format));
    search.set('endDate', endDate.format(format));

    history.push({ search: search.toString() });
  };

  return (
    <>
      <div className={css.nav}>
        <IconButton onClick={() => handleMonthChange(-1)}>
          <PrevIcon />
        </IconButton>
        <IconButton onClick={() => handleMonthChange(0)}>
          <TodayIcon />
        </IconButton>
        <IconButton onClick={() => handleMonthChange(1)}>
          <NextIcon />
        </IconButton>
      </div>

      <div className={css.calendar}>
        {dayNames.map((name, index) => (
          <div
            key={index}
            className={css.dayName}
          >
            {name}
          </div>
        ))}

        {days.map((day, index) => (
          <div 
            key={index} 
            className={clsx(css.day, month.isSame(day, 'month') || css.disabled)}
          >
            {day.date()}
          </div>
        ))}

        {blocks.map((block, key) => (
          <article
            key={key}
            className={clsx(css.event, block.eventStart && css.start, block.eventEnd && css.end)}
            style={{ 
              gridRow: block.week + 2, 
              gridColumn: `${block.day + 1} / span ${block.duration}`, 
              marginTop: `${25 + block.offset * 40}px`
            }}
          >
            {block.event.name}
            {block.events && (<span className={css.badge}>{block.events.length}</span>)}
          </article>
        ))}
      </div>
    </>
  );
}

export default Grid;

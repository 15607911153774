import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import { Typography, Select, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { StoreContext } from "../stores/context";
import { GIZPublicHeader } from "../components/GIZPublicHeader";
import GIZAlert from "../components/GIZAlert";
import GIZNotification from "../components/GIZNotification";
import GIZButton from "../components/GIZButton";
import GIZForm from "../components/GIZForm";
import GIZTextField from "../components/GIZTextField";
import GIZPhoneField from "../components/GIZPhoneField";
import GIZRadioGroup from "../components/GIZRadioGroup";
import GIZSelectField from "../components/GIZSelectField";
import Loader from "../components/loader";
import { required } from "../validators/required";
import { email } from "../validators/email";
import { PreregistrationSteps } from "../utils/PreregistrationSteps";
import { LANGUAGES } from "../utils/lang";

const styles = () => ({
  root: {
    backgroundColor: "#f7f9fb",
    paddingBottom: "30px",
    minHeight: "calc(100vh - 100px)",
  },
  content: {
    position: "relative",
  },
  title: {
    marginTop: "30px",
  },
  formContainer: {
    maxWidth: "500px",
    margin: "0 auto",
  },
  button: {
    margin: "0 10px",
  },
});

class Preregistration extends Component {
  static contextType = StoreContext;

  constructor(props) {
    super(props);
    this.state = {
      notificationError: "",
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { preregistrationStore } = this.context;
    preregistrationStore.loadActivityData(id);
  }

  handleChangeLanguage = (e) => {
    this.context.langStore.changeLang(e.target.value);
  };

  handleRenderForm = (form) => {
    this.context.preregistrationStore.setForm(form);
  };

  handleSubmit = async () => {
    const { preregistrationStore } = this.context;
    try {
      await preregistrationStore.register();
    } catch (ex) {
      this.setState({
        notificationError: "Unknown server error. Please try again.",
      });
    }
  };

  handleExisting = async () => {
    const { preregistrationStore } = this.context;
    await preregistrationStore.checkParticipant();
    if (
      preregistrationStore.step === PreregistrationSteps.ParticipantNotFound
    ) {
      this.setState({
        notificationError: "Participant not found. Please add data manually.",
      });
    }
  };

  handleNew = () => {
    this.context.preregistrationStore.setNewParticipant();
  };

  handleCloseNotification = () => {
    this.setState({
      notificationError: "",
    });
  };

  renderTitle() {
    const { classes } = this.props;
    const { preregistrationStore, langStore } = this.context;

    if (preregistrationStore.step === PreregistrationSteps.ActivityNotFound) {
      return null;
    }

    return (
      <>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          className={classes.title}
        >
          Preregistration
        </Typography>
        <p>Activity name: {preregistrationStore.activityName}</p>
        <div>
          <Select
            className="select"
            value={langStore.lang}
            renderValue={(value) => value}
            onChange={this.handleChangeLanguage}
          >
            <MenuItem value={LANGUAGES.ENGLISH}>English</MenuItem>
            <MenuItem value={LANGUAGES.UKRAINIAN}>Українська</MenuItem>
          </Select>
        </div>
      </>
    );
  }

  renderError() {
    const { preregistrationStore } = this.context;
    return preregistrationStore.step === PreregistrationSteps.ActivityNotFound ? (
      <GIZAlert variant="error">Activity not found.</GIZAlert>
    ) : null;
  }

  renderSuccess() {
    const { preregistrationStore } = this.context;
    return preregistrationStore.step ===
      PreregistrationSteps.RegistrationSuccessful ? (
      <GIZAlert variant="success">Registration successful.</GIZAlert>
    ) : null;
  }

  renderForm() {
    const { classes } = this.props;
    const { preregistrationStore, langStore } = this.context;

    if (
      preregistrationStore.step === PreregistrationSteps.ActivityNotFound ||
      preregistrationStore.step === PreregistrationSteps.RegistrationSuccessful
    ) {
      return null;
    }

    return (
      <div className={classes.formContainer}>
        <GIZForm
          onSubmit={this.handleSubmit}
          onRender={this.handleRenderForm}
        >
          <Typography variant="h3" component="h2">
            {langStore.participants.personalDetails}
          </Typography>
          <GIZTextField
            name="name"
            label={langStore.participants.name}
            validator={required}
          />
          <GIZPhoneField
            name="cellPhone"
            label={langStore.participants.cellPhone}
            validator={required}
          />

          {preregistrationStore.step === PreregistrationSteps.ActivityFound && (
            <>
              <GIZButton
                color="primary"
                onClick={this.handleExisting}
                className={classes.button}
              >
                Existing participant
              </GIZButton>
              <GIZButton
                color="primary"
                onClick={this.handleNew}
                className={classes.button}
              >
                New participant
              </GIZButton>
            </>
          )}

          {preregistrationStore.step ===
            PreregistrationSteps.ParticipantFound && (
            <>
              <GIZAlert variant="info">
                Participant found. Press the button below to register for the
                activity.
              </GIZAlert>
              <GIZButton color="primary" type="submit">
                Register
              </GIZButton>
            </>
          )}

          {preregistrationStore.step ===
            PreregistrationSteps.ParticipantNotFound && (
            <>
              <GIZTextField name="email" label="E-mail" validator={email} />
              <GIZRadioGroup
                name="gender"
                label={langStore.common.gender}
                values={[
                  { label: langStore.common.FEMALE, value: "FEMALE" },
                  { label: langStore.common.MALE, value: "MALE" },
                  { label: "Other", value: "OTHER" },
                ]}
                validator={required}
              />
              <Typography variant="h3" component="h2">
                {langStore.participants.employer}
              </Typography>
              <GIZTextField
                name="position"
                label={langStore.participants.position}
                validator={required}
              />
              <GIZTextField
                name="employerName"
                label={langStore.participants.employerName}
                validator={required}
              />
              <GIZTextField
                name="employerAddress"
                label={langStore.participants.employerAddress}
                validator={required}
              />
              <GIZSelectField
                name="employerTypeId"
                label={langStore.participants.employerType}
                placeholder="Select"
                values={preregistrationStore.employerTypes.map((item) => ({
                  label: item[langStore.langKey],
                  value: item.id,
                }))}
                validator={required}
              />
              <GIZSelectField
                name="employerLevelId"
                label={langStore.participants.employerLevel}
                placeholder="Select"
                values={preregistrationStore.employerLevels.map((item) => ({
                  label: item[langStore.langKey],
                  value: item.id,
                }))}
                validator={required}
              />
              <GIZSelectField
                name="regionId"
                label={langStore.participants.region}
                placeholder="Select"
                values={preregistrationStore.availableRegions.map((item) => ({
                  label: item[langStore.langKey],
                  value: item.id,
                }))}
                disabled={preregistrationStore.regionsDisabled}
                validator={required}
              />
              <GIZButton color="primary" type="submit">
                Register
              </GIZButton>
            </>
          )}
        </GIZForm>
      </div>
    );
  }

  render() {
    const { preregistrationStore, userStore } = this.context;
    const { classes } = this.props;
    const { notificationError } = this.state;

    if (userStore.id) {
      return <Redirect to="/" />;
    }

    return (
      <div className={classes.root}>
        <GIZPublicHeader />
        <div className={classes.content}>
          {this.renderTitle()}
          {this.renderError()}
          {this.renderSuccess()}
          {this.renderForm()}
          {preregistrationStore.pending && <Loader />}
        </div>
        <GIZNotification
          open={notificationError.length > 0}
          onClose={this.handleCloseNotification}
          variant="error"
        >
          {notificationError}
        </GIZNotification>
      </div>
    );
  }
}
export default withStyles(styles)(withRouter(observer(Preregistration)));

import { useContext } from 'react';
import { StoreContext } from 'stores/context';

function ProjectCondition(props) {
  const { not, abbr, children } = props;
  const { userStore } = useContext(StoreContext);
  const predicate = not ? () => !userStore.isCurrentProject(abbr) : () => userStore.isCurrentProject(abbr);

  return predicate() ? children : false;
}

export default ProjectCondition;

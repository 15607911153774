import React from "react";
import { withStyles } from "@material-ui/core/styles";
import markerActivityImg from "./marker-activity.svg";
import markerOrganizationImg from "./marker-organization.svg";

const styles = {
  root: {
    position: "absolute",
    top: "2rem",
    right: "2rem",
    zIndex: 1000,
    pointerEvents: "none",
    padding: "1rem",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    color: "#555",
  },
};

const Legend = ({ classes }) => {
  return (
    <div className={classes.root}>
      <div>
        <img src={markerActivityImg} alt="Activity" /> Activity
      </div>
      <div>
        <img src={markerOrganizationImg} alt="Organization" /> Organization
      </div>
    </div>
  );
};

export default withStyles(styles)(Legend);

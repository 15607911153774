import { Field } from 'react-final-form';
import { TextField } from '@material-ui/core';

function TextualField(props) {
  const { name, label } = props;

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          label={label}
          fullWidth
          multiline
          rows={4}
          {...input}
          error={meta.error && meta.touched}
        />
      )}
    </Field>
  );
}

export default TextualField;

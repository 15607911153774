import { makeAutoObservable, runInAction } from "mobx";
import * as Sentry from "@sentry/react";
import { langToLangKey } from "../utils/lang";
import { REQUEST_STATES } from "../utils/RequestStates";

export class UserStore {
  dataFetcher = null;
  langStore = null;

  constructor(dataFetcher, langStore) {
    this.dataFetcher = dataFetcher;
    this.langStore = langStore;

    this.reset();
    makeAutoObservable(this, {
      dataFetcher: false,
      langStore: false,
    });
  }

  get isMinreg() {
    return this.authorities?.indexOf("ROLE_MINISTRY") >= 0;
  }

  get isOES() {
    return this.authorities?.indexOf("ROLE_OES") >= 0;
  }

  get isGuest() {
    return this.authorities?.indexOf("ROLE_GUEST") >= 0;
  }

  get showAdminMenu() {
    return (
      this.authorities?.filter(
        (item) =>
          [
            "ROLE_ADMIN",
            "ROLE_ANALYST",
            "ROLE_USER",
            "ROLE_PARTNER",
            "ROLE_HUB",
          ].indexOf(item) >= 0
      ).length > 0
    );
  }

  get showTrainers() {
    return !this.isOES && !this.isGuest;
  }

  get showFacilitators() {
    return !this.isOES && !this.isGuest;
  }

  get isPendingState() {
    return (
      this.loadState === REQUEST_STATES.Pending ||
      this.updateState === REQUEST_STATES.Pending
    );
  }

  reset() {
    this.loadState = REQUEST_STATES.Initial;
    this.updateState = REQUEST_STATES.Initial;
    /**
     * @deprecated This field is only used for backward compatibility. User data should be splitted to separated user store fields.
     */
    this.data = null;

    this.id = null;
    this.name = "";
    this.login = "";
    this.email = "";
    this.isAdmin = false;
    this.authorities = [];
    this.currentProjectCode = "";
    this.projects = [];
  }

  async load() {
    this.loadState = REQUEST_STATES.Pending;
    try {
      const res = await this.dataFetcher.getAccount();
      runInAction(() => {
        this.data = res;
        this.id = res.id ?? null;
        this.name = res.name ?? "";
        this.login = res.login ?? "";
        this.email = res.email ?? "";
        this.isAdmin = res.isAdmin ?? false;
        this.authorities = res.authorities ?? [];
        this.projects = res.projects ?? [];
        this.currentProjectCode = res.currentProjectCode ?? "";
        this.langStore.changeLangFromKey(res.langKey);
        this.loadState = REQUEST_STATES.Success;
      });
      Sentry.setUser({ email: this.email, username: this.login, id: this.id });
      return res;
    } catch (ex) {
      runInAction(() => {
        this.loadState = REQUEST_STATES.Error;
      });
      throw ex;
    }
  }

  async updateSettings(name, email, langKey) {
    this.updateState = REQUEST_STATES.Pending;
    try {
      await this.dataFetcher.updateSettings({
        name,
        email,
        langKey,
      });
      runInAction(() => {
        this.name = name;
        this.email = email;
        this.langStore.changeLangFromKey(langKey);
        this.updateState = REQUEST_STATES.Success;
      });
    } catch (ex) {
      runInAction(() => {
        this.updateState = REQUEST_STATES.Error;
      });
    }
  }

  updateLanguage(lang) {
    this.langStore.changeLang(lang);
    this.dataFetcher.updateSettings({
      langKey: langToLangKey(lang),
    });
  }

  async updateActiveProject(code) {
    await this.dataFetcher.updateSettings({
      currentProjectCode: code,
    });
    runInAction(() => {
      this.currentProjectCode = code;
    });
  }

  isCurrentProject(code) {
    return code === this.currentProjectCode;
  }
}

import { Field } from 'react-final-form';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { optional, required } from './validators';

function SingleChoiceAnswerField(props) {
  const { question } = props;

  return (
    <FormControl 
      component="fieldset" 
      fullWidth
    >
      <FormLabel 
        component="legend"
      >
        {question.name}
      </FormLabel>

      <RadioGroup>
        {question.options.map((option) => (
          <FormControlLabel 
            key={option.id}
            label={option.value} 
            value={option.id.toString()}
            control={
              <Field
                name={`question${question.id}.optionId`} 
                type="radio"
                validate={question.optional ? optional : required}
              >
                {({ input }) => (
                  <Radio {...input} />
                )}
              </Field>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default SingleChoiceAnswerField;

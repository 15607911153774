import React from "react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table";
import Item from "./item";
import { I18n } from "../../i18n";
import { CategoryService } from "../../services/SelectOptionService";

export class Categories extends React.Component {
  render() {
    const path = "categories";
    // category uses common texts for translation
    const i18nProps = {
      i18n: I18n[this.props.lang].categories,
      i18nCommon: I18n[this.props.lang].common,
    };

    return (
      <IndexTable
        {...this.props}
        {...i18nProps}
        serviceClass={CategoryService}
        path={path}
        columns={[
          { id: "id", title: "ID", center: true },
          { id: "langKey", title: "Key" },
          { id: "en", title: "Name" },
          {
            id: "active",
            title: "Active",
            displayFunc: (r) => (r.active ? "True" : "False"),
          },
          { id: "descEn", title: "Description" },
          { id: "result", title: "Result" },
          { id: "keywords", title: "Keywords" },
        ]}
        onEditItem={(itemId, item, handleItemSave, props) => {
          return (
            <Item
              item={item}
              itemId={itemId}
              indexPath={path}
              handleItemSave={handleItemSave}
              isEditable={true}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
        onViewItem={(itemId, item, props) => {
          return (
            <Item
              item={item}
              itemId={itemId}
              indexPath={path}
              isEditable={false}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
      />
    );
  }
}

export const Ua = {
  header: {
    home: "Головна",
    registries: "Реєстри",
    indicators: "Індикатори",
    dashboard: "Дешборд",
    searchAnalyse: "Пошук",
    templates: "Шаблони",
    administration: "Адміністрування",
    activities: "Заходи",
    participants: "Учасники",
    trainers: "Тренери",
    facilitators: "Модератори",
    userManagement: "Управління користувачами",
    mailLog: "Mail Log",
    team: "Групи",
    fundingSource: "Джерела фінансування",
    employerLevel: "Рівні організаційя",
    employerType: "Типи організацій",
    oblast: "Області",
    categoryGroup: "Групи категорій",
    category: "Категорії",
    categoryResults: "Результати категорій",
    targetGroup: "Цільові групи",
    targetLevel: "Рівні цільової групи",
    organizer: "Організатори",
    activityFormat: "Формати",
    activityCharacter: "Важливий захід",
    communities: "Муніципалітети",
    organizations: "Організації",
    regionalInitiatives: "Регіональні ініціативи",
    practice: "Належні практики",
    support: "Підтримка",
    supportFeedback: "Feedback",
    contactForm: "Контактна форма",
    forum: "Форум",
    thematicSupportPackages: "Тематичні пакети підтримки",
    programmeIndicators: "Iндикатори програми",
    goodPractice: "Належнi практики",
    csoGrants: "CSO Grants",
    donorProjects: "Donor Funded Projects",
    graphs: "Графи",
    map: "Інтерактивний інструмент для картографування",
  },
  home: {
    registries: "РЕЄСТРИ",
    activities: "Заходи",
    activitiesText: "Переглянути та додати нові заходи",
    participants: "Учасники",
    participantsText: "Переглянути та додати нових учасників заходів",
    trainers: "Тренери та експерти",
    trainersText: "Переглянути та додати тренерів і експертів",
    facilitators: "Модератори",
    facilitatorsText: "Переглянути і додати модераторів",
    hromadas: "Муніципалітети",
    hromadasText: "Переглянути останню інформацію про муніципалітет",
    organizations: "Oрганізації",
    organizationsText: "Переглянути і додати oрганізації",
    practice: "Належні практики",
    practiceText: "Переглянути, додати та відредагувати належні практики",
    indicators: "Індикатори",
    program: "Індикатори програми",
    programText: "Переглянути або виміряти поточний стан індикаторів",
    map: "Мапа",
    maps: "Інтерактивний інструмент для картографування",
    mapsText: "Переглянути візуалізацію даних",
    graphs: "Графи",
    graphsText: "Subtitle",
    search: "Пошук",
    oes: "Пошук заходів та учасників",
    oesText: "Здійснити пошук заходів",
    xs: "Пошук учасників",
    xsText: "Здійснити пошук по учасниках заходів",
  },
  activities: {
    activities: "Заходи",
    header: "Заходи",
    activity: "Захід",
    create: "Створити новий захід",
    search: "Знайти захід",
    id: "ID",
    name: "Назва заходу",
    startDate: "Дата початку",
    endDate: "Дата закінчення",
    oblast: "Область",
    edit: "РЕДАГУВАТИ ЗАХІД ",
    nameEn: "Назва заходу aнгл.",
    activityAddress: "Адреса заходу",
    placeOfActivityCoordinates: "Місце проведення заходу",
    nameUa: "Назва заходу укр.",
    region: "Регіон",
    activityFormat: "Формат",
    organizers: "Організатори",
    mediaReferences: "Посилання на ЗМІ",
    internalFeedback: "Внутрішні коментарі",
    descEn: "Опис англ.",
    targetGroups: "Цільові групи",
    targetLevels: "Рівні цільової групи",
    trainers: "Тренери",
    categories: "Категорії",
    results: "Результати",
    descUa: "Опис укр.",
    fundingSources: "Джерело фінансування",
    activityReport: "Звіт про захід",
    activityFoto: "Фото заходу",
    addParticipant: "Додати учасника",
    downloadParticipantsTemplate: "Завантажити шаблон",
    importParticipants: "Iмпорт учасників",
    printList: "Друкувати список",
    printEmptyList: "Друкувати порожній список",
    printActivityInfo: "Друкувати інформацію про захід",
    characters: "Важливий захід",
    characteristic: "Характеристика",
    kind: 'Онлайн/Офлайн/Гібридний',
    online: 'Онлайн',
    offline: 'Гібридний',
    place: "Place of Activity",
    national: "National",
    international: "International",
    targetedPersons: "Number of Targeted Persons/outreach",
    uniqueUsers: "Unique users",
    implementedVia: "Реалізовано через",
    giz: "GIZ",
    localSubsidy: "Local Subsidy",
    grant: "Grant",
    advisoryType: "Type of Support Services to SMEs",
    grantValue: "Grant Value",
    grantCurrency: "Grant Currency",
    grantCurrencyEur: "EUR",
    grantCurrencyUah: "UAH",
    organization: "Company",
    organizationSectorOfActivities: "Sector of Activities",
    organizationNumberOfEmployees: "Number of Employees",
    organizationAddress: "Address",
    expectedParticipants: "Очікувана кількість учасників",
    actualParticipants: "Фактична кількість учасників",
    createdBy: "Cтворений",
    owner: "Власник",
    getEvaluationForm: "Завантажити анкету",
    viewStatistics: "Переглянути статистику",
    employerTypeDistribution: "Тип організацій",
    location: "Місцезнаходження",
    participants: "Учасники",
    cellPhone: "Мобільний телефон",
    gender: "Стать",
    employerLevel: "Рівень",
    employerType: "Тип",
    regionalInitiatives: "Регіональні ініціативи",
    uniqueHromadas: "КІЛЬКІСТЬ ОТГ, ПРЕДСТАВЛЕНИХ НА ЗАХОДІ",
    employerLevelDistribution: "Рівень організацій",
    genderDistribution: "Стать учасників",
    participantRegistered: "ЗАРЕЄСТРОВАНО УЧАСНИКІВ",
    activityCharacters: "Важливий захід",
    thematicPackagesTitle:
      "Чи впроваджується цей захід в рамках тематичних пакетів підтримки?",
    europeActoinsTitle:
      "Чи впроваджується цей захід в рамках Проектів?",
    trainingPackageTypes: "Навчальні пакети",
    thematicModules: "Тематичні модулі",
    clusterA: "Кластер A: Становлення ефективної та спроможної ОТГ",
    clusterB:
      "Кластер B: Розробка та надання високоякісних муніципальних послуг",
    clusterC: "Кластер C: Сприяння місцевому та регіональному розвитку",
    facilitators: "Модератори",
    actualNumberOfParticipants: "Фактична кількість учасників",
    deleteParticipant: "Видалити з заходу",
    thematicSupportPackage: "Thematic Support Package",
    responsiblePerson: "Відповідальна особа",
    reportMissing: "Звіт відсутній",
    westCooperation: "Чи впроваджується цей захід в рамках співпраці із Західним Регіональним Офісом?",
    consultants: "Консультанти",
    startTime: 'Час початку',
    endTime: 'Час закінчення',
    linkToRegistration: 'Посилання на реєстрацію',
    linkToDescription: 'Посилання на опис',
  },
  participants: {
    header: "Учасника",
    participants: "УЧАСНИКИ",
    create: "Створити учасника",
    search: "Знайти учасника",
    edit: "Редагувати учасника",
    nameTranscription: "Транслітерація імені",
    participant: "Учасник",
    name: "Ім'я",
    gender: "Стать",
    email: "Email",
    cellPhone: "Мобільний телефон",
    personalDetails: "Особиста інформація",
    activities: "Заходи",
    employer: "Організація",
    position: "Посада",
    employerName: "Назва організації",
    employerLocation: "Місцезнаходження організації",
    employerCoordinates: "Координати Організації",
    employerAddress: "Адреса організації",
    employerType: "Тип",
    employerLevel: "Рівень",
    region: "Область",
    placeOfActivityCoordinates: "Координати Організації",
    contactAddress: "Адреса організації",
    matchFoundTitle: "Participant Existed",
    noMatchFoundTitle: "Не знайдено учасника",
    noMatchFoundContent:
      "Не знайдено учасника з такими параметрами в системі",
    checked: "Перевірка iснуючого учасника",
    oblast: "Регіон",
  },
  organizations: {
    header: "Організації",
    participants: "ОРГАНІЗАЦІЇ",
    create: "Створити організацію",
    search: "Знайти організацію",
    edit: "Редагувати організацію",
    nameTranscription: "Транслітерація імені",
    sectorOfActivities: "Сектор діяльності організації",
    economicActivityType: "Вид економічної діяльності",
    participant: "Учасник",
    name: "Назва організації",
    nameEn: "Назва організації aнгл.",
    nameUa: "Назва організації укр.",
    address: "Адреса організації",
    gender: "Стать",
    email: "Email",
    cellPhone: "Мобільний телефон",
    personalDetails: "Особиста інформація",
    activities: "Заходи",
    employer: "Організація",
    position: "Посада",
    employerName: "Назва організації",
    employerLocation: "Місцезнаходження організації",
    employerCoordinates: "Координати Організації",
    employerAddress: "Адреса організації",
    employerTypeId: "Тип",
    employerLevelId: "Рівень",
    communityId: "ID Громади (ввести цифрами)",
    employerLevel: "Рівень",
    region: "Область",
    placeOfActivityCoordinates: "Координати Організації",
    contactAddress: "Адреса організації",
    matchFoundTitle: "Participant Existed",
    noMatchFoundTitle: "Не знайдено учасника",
    noMatchFoundContent:
      "Не знайдено учасника з такими параметрами в системі",
    checked: "Перевірка iснуючого учасника",
    oblast: "Регіон",
    size: "Кількість працівників",
    locationCoordinates: "Координати Організації",
  },
  trainers: {
    trainers: "ТРЕНЕРИ",
    trainer: "ТРЕНЕР",
    create: "Створити нового тренерa",
    search: "Знайти тренера",
    edit: "Редагувати тренера",
    id: "ID",
    name: "Ім'я",
    nameTranscription: "Транслітерація імені",
    gender: "Стать",
    email: "Email",
    cellPhone: "Мобільний телефон",
    info: "Інформація",
    header: "ТРЕНЕР",
  },
  facilitators: {
    facilitators: "МОДЕРАТОРИ",
    facilitator: "МОДЕРАТОР",
    create: "Створити нового модератора",
    search: "Знайти модератора",
    edit: "Редагувати модератора",
    id: "ID",
    name: "Ім'я",
    nameTranscription: "Транслітерація імені",
    gender: "Стать",
    email: "Email",
    cellPhone: "Мобільний телефон",
    info: "Інформація",
    header: "МОДЕРАТОРИ",
  },
  templates: {
    templates: "ШАБЛОНИ",
    createNew: "Створити новий шаблон",
    edit: "Редагувати шаблон",
    create: "Створити шаблон",
    title: "Заголовок",
    subTitle: "Підзаголовок",
    file: "Фаил",
  },
  categoryGroups: {
    header: "Групи",
    create: "Створити нову групу",
    edit: "Редагувати групу",
    search: "Знайти групу",
    langKey: "Мова",
    titleUa: "Укр",
    titleEn: "Англ",
    active: "Активно",
    order: "Order",
  },
  categories: {
    categories: "Категорiï",
    header: "КАТЕГОРІЇ",
    category: "КАТЕГОРІЯ",
    create: "Створити нову категорію",
    search: "Знайти категорію",
    edit: "РЕДАГУВАТИ КАТЕГОРІЮ",
    langKey: "Мова",
    ua: "Укр",
    en: "Англ",
    active: "Активно",
    refYear: "Рік",
    descEn: "Опис Англ",
    descUa: "Опис Укр",
    result: "Результат",
    keywords: "Ключові слова",
    groups: "Групи",
  },
  categoryResults: {
    header: "Результати категорій",
    create: "Створити новий результат категорії",
    edit: "Редагувати результат категорії",
    title: "Назва",
  },
  communities: {
    communities: "Муніципалітети",
    community: "Громада",
    search: "Знайти Громаду",
    generalInformation: "ЗАГАЛЬНА ІНФОРМАЦІЯ",
    ahCentre: "Центр ОТГ",
    id: "ID",
    title: "Назва",
    center: "Центр",
    councilSize: "Pади, що об'єдналися",
    created: "Дата перших виборів",
    establishment: "Дата створення",
    koatuu: "Kоатуу",
    region: "Область",
    initiatives: "Ініціативи",
    support: "ТПП",
    participants: "Participants",
    population: "Населення",
    showOnMap: "Показати на карті",
    square: "Площа",
    updatedAt: "Оновлено на",
    update: "Оновити через API",
    date: "Дата",
    activityTitle: "Назва заходу",
    activityFormat: "Формат",
    regionalInitiative: "Регіональні ініціативи",
    participantName: "Ім'я",
    gender: "Стать",
    cellPhone: "Мобільний телефон",
    position: "Посада",
    employerType: "Тип Роботодавця",
    employerName: "Назва організації",
    supportHromada: "ПІДТРИМКА ГРОМАДИ",
    uleadIndicators: "ІНДИКАТОРИ M&E DT4SME",
    so1dInfo: "Адаптовані внутрішні процедури",
    r2aInfo: "Встановлені місцеві податки/збори",
    uleadStats: "СТАТИСТИКА M&E DT4SME",
    totalParticipantsCount: "Kількість учасників заходів",
    tspCount: "Заходи ТПП",
    regionalCount: "Заходи Регіональних ініціатив",
    totalNumber: "Загальна кількість подій",
    genderBalance: "Гендерний баланс",
    formatCount: "Формат подій",
    employerTypeCount: "Тип роботодавця",
    otherDonorSupport: "Інші донорські проекти",
  },
  employerLevel: {
    header: "РІВНІ ОРГАНІЗАЦІЙ",
    employerLevels: "РІВНІ ОРГАНІЗАЦІЙ",
    employerLevel: "РІВЕНЬ ОРГАНІЗАЦІЙ",
    create: "Створити новий рівень організацій",
    search: "Знайти рівень організацій",
    edit: "РЕДАГУВАТИ РІВЕНЬ ОРГАНІЗАЦІЙ",
  },
  employerType: {
    header: "Типи організацій",
    employerTypes: "Типи організацій",
    employerType: "TИП ОРГАНІЗАЦІЙ",
    create: "Створити новий тип організацій",
    search: "Знайти тип організацій",
    edit: "РЕДАГУВАТИ ТИП ОРГАНІЗАЦІЙ",
  },
  europeActions: {
    europeActions: "Регіональні ініціативи",
    europeAction: "ДІЯ",
    create: "Створити нову дію",
    search: "Знайти дію",
    edit: "РЕДАГУВАТИ ДІЮ",
  },
  activityCharacter: {
    header: "Activity characters",
    activityCharacters: "Activity characters",
    activityCharacter: "Activity character",
    create: "Створити нову Activity Character",
    search: "Знайти Activity Character",
    edit: "РЕДАГУВАТИ Activity Character",
  },
  characteristic: {
    header: "Характеристика",
    create: "Створити нову характеристику",
    edit: "Редагувати характеристику",
    search: "Знайти характеристику",
    langKey: "Мова",
    titleUa: "Укр",
    titleEn: "Англ",
    active: "Активно",
  },
  format: {
    header: "ФОРМАТИ",
    formats: "ФОРМАТИ",
    format: "ФОРМАТ",
    create: "Створити новий формат",
    search: "Знайти формат",
    edit: "РЕДАГУВАТИ ФОРМАТ",
  },
  fundingSources: {
    header: "Джерела фінансування",
    fundingSources: "Джерело фінансування",
    fundingSource: "Джерело фінансування",
    create: "Створити нове джерело фінансування",
    search: "Знайти джерело фінансування",
    edit: "Редагувати Джерело фінансування",
  },
  organizer: {
    organizers: "Організатори",
    organizer: "Організатор",
    create: "Створити нового Організатора",
    search: "Знайти Організатора",
    edit: "РЕДАГУВАТИ ОРГАНІЗАТОРА",
  },
  region: {
    regions: "ОБЛАСТІ",
    region: "ОБЛАСТЬ",
    create: "Створити нову область",
    search: "Знайти область",
    edit: "РЕДАГУВАТИ ОБЛАСТЬ",
  },
  targetGroup: {
    header: "ЦІЛЬОВІ ГРУПИ",
    targetGroups: "ЦІЛЬОВІ ГРУПИ",
    targetGroup: "ЦІЛЬОВІ ГРУПА",
    create: "Створити нову цільову групу",
    search: "Знайти цільову групу",
    edit: "РЕДАГУВАТИ ЦІЛЬОВУ ГРУПУ",
  },
  targetLevel: {
    header: "РІВНІ ЦІЛЬОВОЇ ГРУПИ",
    targetLevels: "РІВНІ ЦІЛЬОВОЇ ГРУПИ",
    targetLevel: "РІВЕНЬ ЦІЛЬОВОЇ ГРУПИ",
    create: "Створити новий рівень цільової групи",
    search: "Search for Target Level",
    edit: "РЕДАГУВАТИ РІВЕНЬ ЦІЛЬОВОЇ ГРУПИ",
  },
  team: {
    teams: "ГРУПИ",
    team: "ГРУПА",
    create: "Створити нову групу",
    search: "Знайти групу",
    edit: "РЕДАГУВАТИ ГРУПУ",
  },
  thematicPackages: {
    thematicPackages: "ТЕМАТИЧНІ ПАКЕТИ ПІДТРИМКИ",
    thematicPackage: "ТЕМАТИЧНІЙ ПАКЕТ ПІДТРИМКИ",
    create: "Створити новий пакет",
    search: "Знайти пакет",
    edit: "РЕДАГУВАТИ ПАКЕТ",
  },
  csoGrants: {
    csoGrants: "Проекти ГО",
    csoGrant: "Проект ГО",
    create: "Створити новий проект ГО",
    search: "Знайти проект ГО",
    edit: "РЕДАГУВАТИ ПРОЕКТ ГО",
  },
  csoGrant: {
    search: "Search",
    id: "ID",
    year: "Рiк",
    projectTitle: "Назва",
    projectTitleEn: "",
    cso: "Organization",
    csoEn: "",
  },
  donorProjects: {
    donorProjects: "Donor Funded Projects",
    donorProject: "Donor Funded Project",
    create: "Створити новий Donor Funded Project",
    search: "Знайти Donor Funded Project",
    edit: "РЕДАГУВАТИ DONOR FUNDED PROJECT",
  },
  donorProject: {
    search: "Search",
    id: "ID",
    title: "Project Title",
    donor: "Donor",
  },
  indicators: {
    title: 'Програмні Індикатори',
    create: 'Створити Індикатор',
    edit: 'Редагувати Індикатор',
    code: 'Код',
    output: 'Результат або конкретна мета',
    indicatorText: 'Текс Індикатора',
    responsiblePerson: 'Відповідальна Особа',
    measurements: 'Виміри',
    frequency: 'Частота',
    updated: 'Оновлено',
    currentValue: 'Поточне Значення',
    targetValue: 'Цільове Значення',
    status: 'Стан',
    manage: 'Керування',
    dataFile: "Data File",
  },
  goodPractice: {
    header: "Належні практики",
    goodPractice: "Належні практики",
    create: "Створити нову належну практику",
    search: "Знайти",
    id: "ID",
    title: "Назва",
    owner: "Власник",
    description: "Опис",
    sumOfInitiatives: "Initiatives",
    region: "Область",
  },
  user: {
    users: "КОРИСТУВАЧІ",
    user: "КОРИСТУВАЧ",
    create: "Створити нового користувача",
    search: "Знайти користувача",
    edit: "РЕДАГУВАТИ КОРИСТУВАЧА",
    id: "ID",
    login: "Логін",
    email: "Електронна пошта",
    activated: "Активований",
    lang_key: "Мова",
    authorities: "Профілі",
    created_date: "Дата створення",
    last_modified_by: "Змінено",
    last_modified_date: "Дата зміни",
    name: "Ім'я",
    password: "Пароль",
    passwordConfirm: "Підтвердити пароль",
    createdBy: "Cтворенний",
    profiles: "Профілі",
    availableProjects: "Доступні проекти",
  },
  search: {
    activities: "Заходи",
    participants: "Учасники",
    search: "Пошук",
    activityFilter: "Фільтр подій",
    participantFilter: "Фільтр учасників",
    dateRange: "Проміжок часу",
    from: "Від",
    to: "До",
    viewAll: "Подивитись все",
    collapse: "Згорнути",
    activitiesFound: "знайдені події",
    participantsFound: "знайдено різних учасників",
    distinctCellPhone: "різних мобільних телефонів і",
    distinctNames: "різні імена",
    participations: "учасники",
    clearAllFilters: "Очистити всі фільтри",
    owners: "Власники",
    regions: "Регіони",
    regionParticipant: "Регіон (учасник)",
    formats: "Формати подій",
    targetGroups: "Цільові групи",
    targetLevels: "Рівні цільової групи",
    categories: "Категорії",
    categoryResults: "Результати",
    organizers: "Організатори",
    fundingSources: "Джерело фінансування",
    activityCharacters: "Важливий захід",
    trainers: "Тренери",
    europeActions: "Регіональні ініціативи",
    thematicModules: "Тематичнi модулі",
    trainingPackageTypes: "Навчальні Пакети",
    employerType: "Тип роботодавця",
    employerLevel: "Рівень роботодавця",
    gender: "Стать",
    relevance: "Релевантність",
    startAsc: "Дата початку: зростання",
    startDesc: "Дата початку: спадання",
    endAsc: "Дата закінчення: зростання",
    endDesc: "Дата закінчення: спадання",
    exportStats: "Експорт статистики",
    selectAll: "Вибрати все",
    projects: "Проекти",
    organizations: "Oрганізації",
    characteristics: "Характеристики",
    kind: "Online/Offline",
    place: "Place of Activity",
    targetedPersons: "Number of Targeted Persons/outreach",
    uniqueUsers: "Unique users",
    advisoryTypes: "Type of Support Services to SMEs",
    grantValue: "Grant Value",
    grantCurrency: "Grant Currencies",
    economicActivityType: "Вид економ. діяльності",
    westCooperation: "Західний Регіон. Офіс",
    size: "Кількість працівників",
    csoGrants: "Реалізовано через"
  },
  common: {
    view: "Переглянути",
    edit: "Змінити",
    delete: "Видалити",
    cancel: "Скасувати",
    print: "Друкувати",
    save: "Зберегти",
    name: "Ім'я",
    gender: "Стать",
    email: "Email",
    cellPhone: "Мобільний телефон",
    position: "Посада",
    employerName: "Назва організації",
    employerLocation: "Місцезнаходження організації",
    contactAddress: "Адреса організації",
    activitiesCount: "Доступно/усього заходів",
    oblastEn: "Регіон",
    employerTypeEn: "Тип",
    organizationEn: "Організація",
    employerLevelEn: "Рівень",
    employerType: "Тип",
    employerLevel: "Рівень",
    yes: "Так",
    no: "Ні",
    description: "Опис",
    memberId: "Члени",
    language: "Мова",
    langKey: "Мова",
    ua: "Укр",
    en: "Англ",
    active: "Активно",
    refYear: "Рік",
    descEn: "Опис Англ",
    descUa: "Опис Укр",
    result: "Результат",
    keywords: "Ключові слова",
    createdAt: "Створено в",
    updatedAt: "Оновлено на",
    nameUa: "Ім'я Укр",
    nameEn: "Ім'я Англ",
    cluster: "Kластер",
    clusterNameEn: "Кластер Ім'я Англ",
    clusterNameUa: "Кластер Ім'я Укр",
    deleteConfirm: "Ви впевнені, що бажаєте видалити ",
    fromActivity: "із Заходу",
    add: "Додати",
    search: "Пошук",
    file: "Фаил",
    close: "Закрити",
    MALE: "Чол.",
    FEMALE: "Жін.",
  },
  dialogs: {
    deleteConfirmation: {
      title: 'Підтвердження Видалення',
      content: 'Ви впевненні, що хочете видалити запис?',
      yes: 'Так',
      no: 'Ні'
    }
  }
};

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Form as FinalForm } from 'react-final-form';
import Filter from './Filter';
import Answer from './AnswerField';
import Question from './Question';

const styles = {
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0'
  }
};

function Form(props) {
  const { survey, classes, onSubmit, initialValues } = props;

  const [question, setQuestion] = useState(0);

  function next() {
    setQuestion((question) => question + 1);
  }

  function previous() {
    setQuestion((question) => question - 1);
  }

  return (
    <FinalForm onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, submitSucceded, pristine, hasValidationErrors, valid, values }) => (
        <form onSubmit={handleSubmit}>

          <Filter questions={survey.questions} answers={values}>
            {(questions) => (
              <Question>
                <Answer question={questions[question]} />

                <div className={classes.buttons}>
                  <div>
                  {question > 0 && (
                    <Button 
                      color="primary" 
                      variant="outlined"
                      onClick={previous}
                    >
                      Повернутися
                    </Button>
                  )}
                  </div>

                  <div>
                    {valid && question === questions.length - 1 && (
                      <Button 
                        color="primary" 
                        type="submit" 
                        disabled={submitting} 
                        variant="outlined"
                      >
                        Завершити опитування
                      </Button>
                    )}

                    {valid && question < questions.length - 1 && (
                      <Button 
                        color="primary" 
                        variant="outlined"
                        onClick={next}
                      >
                        {questions[question].optional && pristine ? 'Пропустити' : 'Відповісти'}
                      </Button>
                    )}
                  </div>
                </div>
              </Question>
            )}
          </Filter>

          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}

        </form>
      )}
    </FinalForm>
  );
}

export default withStyles(styles)(Form);

export const styles = (theme) => ({
  root: {
    textAlign: "left",
    padding: "30px 3%",
    background: "#f7f9fb",
    width: "100%",
    margin: "0",
    position: "relative",
    "&.hidden": {
      overflow: "hidden",
    },
    "& .pl-135": {
      paddingLeft: "135px",
    },
    "& .pl-410": {
      paddingLeft: "410px",
    },
    "& .pr-52": {
      paddingRight: "52px",
    },
    "& .pl-0": {
      paddingLeft: "0",
    },
    "& div": {
      boxShadow: "none",
    },
    "&.p0": {
      padding: "0",
    },
    "& .p0": {
      padding: "0",
    },
    "&.df": {
      display: "flex",
    },
    "& .jce": {
      justifyContent: "flex-end",
    },
    "& h3": {
      fontSize: "3vh !important",
      lineHeight: "1 !important",
      letterSpacing: "2pt",
      color: "#fdbb2d",
    },
    "& h1.sidebar-header": {
      backgroundColor: "#6495ed",
    },
    "& .sidebar-tabs>ul>li.active": {
      backgroundColor: "#6495ed",
    },
    "& .sidebar-tabs>ul>li>a": {
      display: "flex",
      color: "inherit",
      textDecoration: "none",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "40px",
    },
    "& .sidebar.collapsed": {
      width: "44px",
    },
    "& .sidebar-left .sidebar-close": {
      right: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .leaflet-touch .leaflet-bar a": {
      lineHeight: "1.1",
    },
    "& .leaflet-popup-content": {
      margin: "0",
      width: "100% !important",
      fontWeight: "600",
    },
    "& .employer-type": {
      color: "#6495ed",
      letterSpacing: "0.6px",
      textTransform: "uppercase",
      display: "inline",
    },
    "& .leaflet-popup-content-wrapper": {
      background: "white",
      color: "#191970",
      letterSpacing: "1px",
      border: "1px solid grey",
      padding: "13px 19px",
      "& h4": {
        fontSize: "18px",
        fontWeight: "800",
        letterSpacing: "0.9px",
        color: "#fdbb2d",
        margin: "-6px 0 6px",
        fontFamily: "Roboto",
      },
      "& b": {
        color: "#6495ed",
        letterSpacing: "0.6px",
        fontSize: "12px",
      },
      "& div a": {
        color: "#6495ed",
        textDecoration: "underline",
        "&:hover": {
          color: "#191970",
        },
      },
    },
    "& .leaflet-popup-tip-container": {
      position: "relative",
      borderTop: "0px solid white",
      zIndex: "1",
      top: "-1px",
    },
    "& .leaflet-popup-tip": {
      border: "1px solid grey",
    },
    "& .leaflet-container a.leaflet-popup-close-button": {
      top: "0px",
      right: "2px",
    },
    "& .leaflet-right .leaflet-control": {
      color: "white",
      marginRight: "2.1vw",
      borderRadius: "6px",
      background: "#6495ed",
      border: "0",
      "& h4": {
        color: "white",
      },
      "& button": {
        background: "#6495ed",
        border: "0",
        height: "31px",
        width: "71px",
        color: "white",
        fontSize: "12px",
        borderRadius: "6px",
        "&:hover": {
          background: "#5486e0",
        },
      },
    },
    "& .leaflet-control-easyPrint": {
      position: "relative",
      left: "125px",
      top: "-70px",
      border: "0 !important",
      borderRadius: "6px",
    },
    "& .leaflet-control-easyPrint-button-export": {
      backgroundImage: "url(./images/icons/print-icon.png)",
      backgroundColor: "#a0abb7",
      width: "42px !important",
      height: "32px !important",
      borderRadius: "6px !important",
      "&:hover": {
        backgroundColor: "#778899",
      },
    },
  },
  controls: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  map: {
    width: "100%",
    height: "calc(73vh + 9px)",
    minHeight: "600px",
  },
});

const white = '#fff';
const bg = '#F7F9FB';

export const styles = theme => ({
  root: {
    paddingTop: '20px',
    background: white,
    overflow: 'hidden',
    '& .btn-sections':{
      display: 'flex',
      flexDirection: 'column',
    },
    '& .jce':{
      justifyContent: 'flex-end',
    },
    '& .block-info':{
      backgroundColor: bg,
      padding: '25px 3vw 40px',
    },
    '& .block-info__baseline div div .btn-word': {
      backgroundImage: 'url(/images/icons/icon-word.png)',
      [theme.breakpoints.down(1440)]: {
      },
    },
    '& .p0':{
      padding: '0 !important'
    },
    '& .pl0':{
      paddingLeft: '0 !important'
    },
    '& .pr150':{
      paddingRight: 150,
    },
    '& .pl150':{
      paddingLeft: 150,
    },
    '& .df':{
      display: 'flex',
    },
    '& .bt':{
      borderTop: '10px solid #fff',
    },
    '& .lh':{
      '& h5':{
        lineHeight: '1',
      },
    },
    '& .h0':{
      height: '0',
    },
    '& .data-collection':{
      position: 'relative',
      left: '-5px',
    },
    '& .button-edit:hover':{
      backgroundColor: '#5486e0',
    },
    '& .btn-create:hover':{
      backgroundColor: '#5486e0 !important',
    },
    '& .btn-upload:hover':{
      backgroundColor: '#9bcfe0 !important',
    },
    '& .btn-download:hover':{
      backgroundColor: '#9bd9c8 !important',
    },
    '& .actions a.edit': {
      backgroundColor: '#6495ed !important',
      '&:hover': {
        backgroundColor: '#5486e0 !important',
      },
    },
    '& .actions a.view': {
      backgroundColor: '#add8e6',
      '&:hover': {
        backgroundColor: '#9bcfe0',
      },
    },
    '& .actions a.delete': {
      backgroundColor: '#fe8368 !important',
      '&:hover': {
        backgroundColor: '#f67a5f !important',
      },
    },
    '& .button-print':{
      backgroundColor: '#778899',
      borderRadius: '6px',
      height: 47,
      color: white,
      border: '1px solid #778899',
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: '.75rem',
      fontWeight: '700',
      marginRight: '0',
      padding: '.5rem 1rem',
      marginTop: '23px',
      minWidth: '100px',
      '&:hover': {
        backgroundColor: '#778899',
      },
      '& i': {
        display: 'inline-block',
        width: '16px',
        height: '16px',
        marginRight: '10px',
        background: 'url(/images/icons/print-icon.png) no-repeat 0 0 / 16px 16px'
      },
      '& span': {
        fontSize: '12px',
        fontWeight: '500',
        letterSpacing: '1.2px',
      },
    }

  },
  img:{
    marginRight: 25,
  },
  btnTable:{
    width: '3vw !important',
  },
  block: {
    textAlign: 'left',
    '&.block-title': {
      backgroundColor: bg,
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'row',
      marginTop: 18,
      borderBottom: '5px solid #fff',
      '& .column': {
        '&:first-child': {
          flex: '0 0 71%',
          margin: '0 2.3vw'
        },
        '&:nth-child(2)': {
          flexGrow: '1'
        },
        '&:last-child': {
          textAlign: 'right',
          marginRight: '2.3vw'
        }
      },
      '& h3': {
        fontSize: '2rem',
        color: '#6495ED',
        fontWeight: '900',
        lineHeight: '1.2',
        marginTop: 24,
        marginBottom: '40px',

      },
      '& h4': {
        fontSize: '24px',
        color: '#6495ED',
        fontWeight: '700',
        lineHeight: '1.2',
        marginTop: 30,
        letterSpacing: '1px',
      },
      '& p': {
        fontSize: '24px',
        color: '#6495ED',
        fontWeight: '300',
        lineHeight: '1',
      },
      '& .button-graphs': {
        height: 47,
        color: white,
        backgroundColor: '#FCBA2B',
        border: '1px solid #FCBA2B',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '.75rem',
        fontWeight: '700',
        marginRight: '0',
        padding: '.5rem 1rem',
        marginTop: '23px',
        minWidth: '100px',
        borderRadius: '6px',
        '&:hover':{
          backgroundColor: '#f8d95d',
        },
        '& i': {
          display: 'inine-block',
          width: '16px',
          height: '16px',
          marginRight: '5px',
          background: 'url(/images/icons/header-dashboard.svg) no-repeat 0 0 / 16px 16px'
        },
        '& span': {
          fontSize: '12px',
          fontWeight: '500',
          letterSpacing: '1.2px',
        },
      }
    },
    '& .block-info': {
      '&__baseline':{
        backgroundColor: white,
        borderRadius: '10px',
        padding: '10px',
        height: 'calc(100% + 20px)',
        '& ul': {
          margin: '0',
          padding: '0',
          marginLeft: '10px',
          [theme.breakpoints.down(1440)]: {
            marginLeft: '20px',
          },
        },
        '& li': {
          listStyleType: 'none',
          fontSize: '1rem',
          color: '#191970',
          borderBottom: '2px solid #fff',
          letterSpacing: '0.5px',
          '&:last-child': {
            borderBottom: 'none'
          }
        },
        '& pre': {
          color: '#191970',
          padding: '12px 0',
          marginLeft: '10px',
          fontSize: '1rem',
          [theme.breakpoints.down(1440)]: {
            marginLeft: '20px',
          },
        },
      },
      '&__target': {
        backgroundColor: white,
        borderRadius: '10px',
        padding: '10px',
        marginLeft: '32px',
        height: 'calc(100% + 20px)',
        '& pre': {
          color: '#191970',
          padding: '12px 0',
          marginLeft: '10px',
          fontSize: '1rem',
          [theme.breakpoints.down(1440)]: {
            marginLeft: '20px',
          },
        },
        '& ul': {
          margin: '0',
          padding: 0,
          marginLeft: '10px',
          [theme.breakpoints.down(1440)]: {
            marginLeft: '20px',
          },
        },
        '& li': {
          listStyleType: 'none',
          fontSize: '1rem',
          color: '#191970',
          borderBottom: '2px solid #fff',
          letterSpacing: '0.5px',
          '&:last-child': {
            borderBottom: 'none'
          }
        },
      },
      '&__indicators':{
        marginTop: '32px',
        backgroundColor: white,
        borderRadius: '10px',
        padding: '10px',
        '& pre': {
          color: '#191970',
          padding: '12px 0',
          fontSize: '1rem',
        },
      },
      '&__column':{
        padding: '30px 0 10px 70px',
        '& pre': {
          color: '#191970',
          padding: '0',
          fontSize: '1rem',
        },
      },
      '& .column': {
        '&:first-child': {
          display: 'flex',
          flexDirection: 'row',
          flex: '0 0 79.5%',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          margin: '15px 1.2vw 0 2.3vw',
          padding: '30px 0',
          [theme.breakpoints.down(1440)]: {
            flex: '0 0 83%',
            margin: '15px 1.2vw 0 2vw',
          },
          '& > div': {
            backgroundColor: '#EDF6FB',
            borderRadius: '6px',
            padding: '12px 24px'
          }
        },
        '&:last-child': {
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '30vw',
          '& h5': {
            margin: '54px 0 20px',

          },
          '& button': {
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            height: 48,
            width: 120,
            borderRadius: 10,
            marginRight: 30,
          }
        },
        '& .baseline': {
          flex: '0 0 53.8%',
          '& .btn': {
            marginBottom: '10px '
          }
        },
        '& .target': {
          flex: '0 0 40%'
        },
        '& .indicators': {
          width: '100%',
          //flex: '0 0 100%',
          marginTop: '20px'
        }
      },

    },
    '&.block-collection': {
      backgroundColor: bg,
      overflow: 'hidden',
      marginTop: '5px',
      padding: '0 3vw 30px',
      position: 'relative',
      '& .btn-create': {
        padding: '10px 15px',
        borderRadius: '100px',
        backgroundColor: '#6495ed',
      },
      '& .table-wrapper': {
        width: 'calc(100% + 24px)',
        '& button': {
          margin: '10px 0 30px',
          height: 45,
          minWidth: 210,
        },
        '& table': {
          // backgroundColor: '#EDF6FB',
          borderRadius: '6px',
          overflow: 'hidden',
          fontSize: '12px',
          color: '#191970',
          border: 'none',
          borderSpacing: '0',
          borderCollapse: 'collapse',
          width: '100%',
          marginLeft: '-17px',
          '& thead tr': {
            backgroundColor: '#f7f9fb !important',
            '& th': {
              padding: '10px 15px',
              '&:first-child': {
                width: 69,
                paddingLeft: '3vw',
              },
              '&:nth-child(2)': {
                width: '47%',
              },
              '&:nth-child(3)': {
                width: '31%',
              },
            }
          },
          '& th': {
            color: '#6495ED',
            fontSize: '24px',
            fontWeight: '300',
          },
          '& tbody tr': {
            '& td': {
              padding: '38px 15px',
              fontSize: '1rem',
              fontWeight: '400',
              letterSpacing: '1.2px',
              '&:first-child': {
                textAlign: 'center',
                paddingLeft: '3vw',
              },
            }
          },
          '& tr:nth-child(odd)': {
            backgroundColor: white
          },
          '& tr:nth-child(even)': {
            backgroundColor: bg
          },
          '& .date': {
            color: '#778899'
          }
        }
      },
      '& h5': {
        marginTop: '25px !important'
      },
      '& .btn': {
        display: 'inline-block',
        marginRight: '10px'
      },
      '& .button-edit': {
        bottom: '63px',
        borderRadius: '10px',
        top: '-65px',
      }
    },
    '&.block-measurement': {
      backgroundColor: bg,
      overflow: 'hidden',
      marginTop: '5px',
      padding: '0 3vw',
      position: 'relative',
      '& button': {
        margin: '20px 0 10px'
      },
      '& .button-edit': {
        position: 'absolute',
        bottom: '20px',
        right: '40px'
      },
      '& .btn': {
        display: 'inline-block',
        width: '45px',
        height: '45px',
        lineHeight: '45px',
        position: 'relative',
        background: '#9BD9C8 no-repeat center center / 50px 50px',
        borderRadius: '6px',
        '&.btn-upload': {
          cursor: 'pointer',
          width: 'auto',
          marginTop: '20px',
          marginRight: '10px',
          color: white,
          background: ' #add8e6',
          padding: '0 15px 0 40px',
          '& i': {
            transform: 'rotate(180deg)',
            width: '45px',
            height: '45px',
            display: 'inline-block',
            background: 'url(/images/icons/icon-download.svg) no-repeat center center',
            position: 'absolute',
            left: '0',
            top: '0',
          }
        },
        '&.btn-download': {
          marginTop: '20px',
          width: 'auto',
          color: white,
          background: ' #77d2b7',
          padding: '0 15px 0 40px',
          '& i': {
            width: '45px',
            height: '45px',
            display: 'inline-block',
            background: 'url(/images/icons/icon-download.svg) no-repeat center center',
            position: 'absolute',
            left: '0',
            top: '0',
          }
        },
        '&.btn-word': {
          background: '#c3bdd2 no-repeat center center',
          backgroundImage: 'url(/images/icons/icon-word.png)',
          '&:hover': {
            backgroundColor: '#958DAE'
          },
        },
      },
      '& .indicator-actions': {
        display: 'flex',
        '& .btn-create': {
          height: '45px',
          marginRight: '10px',
        }
      },
      '& table': {
        backgroundColor: bg,
        borderRadius: '6px',
        overflow: 'hidden',
        fontSize: '12px',
        color: '#191970',
        border: 'none',
        borderSpacing: '0',
        borderCollapse: 'collapse',
        '& thead tr': {
          backgroundColor: '#F7F9FB !important',
          '& th': {
            color: '#6495ED',
            fontSize: '24px',
            fontWeight: '300',
            padding: '10px 15px',[theme.breakpoints.down(1440)]: {
              textAlign: 'center',
            },
            '&:first-child': {
              paddingLeft: '2.3vw',
            },
            '&:nth-child(3)': {
              width: '29%',
            },
            '&:nth-child(4)': {
              width: '17%',
            },
            '&:nth-child(6)': {
              width: '179px',
            },
          }
        },
        '& tbody tr': {
          verticalAlign: 'top',
          '& td': {
            padding: '10px 15px',
            fontWeight: '400',
            fontSize: '1rem',
            lineHeight: '1.4',
            '&:first-child': {
              paddingLeft: '2.3vw',
            },
            '&:last-child': {
              paddingRight: '2.3vw',
            },
            '&:nth-child(4)': {
              textAlign: 'left',
              color: '#191970',
            },
            '&:nth-child(6)': {
              textAlign: 'center',
            },
          }
        },
        '& tr:nth-child(odd)': {
          backgroundColor: white
        },
        '& .light': {
          color: '#778899'
        },
        '& .light .metric-value': {
          display: 'block'
        },
        '& .status': {
          width: '71px',
          height: '45px',
          borderRadius: '6px',
          '&.green': {
            backgroundColor: '#77D2B7'
          },
          '&.red': {
            backgroundColor: '#FE8368'
          }
        },
        '& .btn-word': {
          display: 'inline-block',
          margin: '5px 5px 0 0'
        },
        '& .actions a': {
          padding: '10px',
          '& svg': {
            width: '25px',
            height: '25px'
          }
        }
      }
    },
    '& pre': {
      padding: '12px 0',
      font: 'inherit',
      fontSize: '1rem',
      margin: '0',
      color: '#191970',
      whiteSpace: 'pre-wrap',
      textAlign: 'justify',
      letterSpacing: '0.5px',
    },
    '& h2': {
      fontSize: '37px !important',
      color: '#88ADF0 !important',
      textTransform: 'uppercase',
      fontWeight: '900  !important',
      textAlign: 'center',
      margin: '30px 0 0 !important'
    },
    '& h5': {
      color: '#6495ED',
      fontSize: '24px',
      fontWeight: '300',
      margin: '0',
      letterSpacing: '1.1px',
      lineHeight: '0.9',
      [theme.breakpoints.down(1440)]: {
        fontSize: '1.5rem',
      },
    },
    '& p': {
      fontSize: '12px',
      color: '#778899'
    },
    '& .btn': {
      display: 'block',
      width: '45px',
      height: '45px',
      borderRadius: '6px',
      background: '#c3bdd2 no-repeat center center','&:hover':{background: '#958DAE no-repeat center center'},
      '&.btn-word': {
        backgroundImage: 'url(/images/icons/icon-word.png)',
        margin: '0 5px 10px',
      }
    },
    '& .button-edit': {
      height: 48,
      width: 120,
      color: white,
      backgroundColor: '#6495ED',
      border: '1px solid #6495ED',
      fontSize: '.75rem',
      fontWeight: '700',
      padding: '.5rem 1rem',
      position: 'relative',
      top: '-32px',
      borderRadius: '10px',
      '& i': {
        display: 'inline-block',
        width: '16px',
        height: '16px',
        marginRight: 20,
        background: 'url(/images/icons/outline_edit_white_36dp.png) no-repeat center center / 16px 16px'
      },
      '& span': {
        fontSize: '12px',
        fontWeight: '500',
        letterSpacing: '1.2px',
      },
    },
  },
  selector: {
    position: 'relative',
    '& .on': {
      backgroundColor: '#77D2B7'
    },
    '& .minor': {
      backgroundColor: '#DDD330'
    },
    '& .major': {
      backgroundColor: '#FCBA2B'
    },
    '& .off': {
      backgroundColor: '#FE8368'
    },
    '& .not': {
      backgroundColor: '#A7A9AC'
    },
    '& .selected-value': {
      textAlign: 'center',
      fontSize: '11px',
      color: white,
      height: '47px',
      width: '70px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '6px',
      cursor: 'pointer',
      padding: 5,
      '&:hover + .list': {
        display: 'block'
      }
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  modal: {
    '& .container': {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(119, 136, 153, 0.6)',
      zIndex: '999',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .modal': {
      position: 'relative',
      width: '760px',
      height: '440px',
      borderRadius: '10px',
      backgroundColor: white,
      display: 'flex',
      flexDirection: 'column'
    },
    '& .close': {
      fontSize: '16px',
      color: '#999',
      display: 'block',
      position: 'absolute',
      top: '15px',
      right: '15px'
    },
    '& h3': {
      fontSize: '37px',
      color: '#FE8368',
      textTransform: 'uppercase',
      textAlign: 'center',
      margin: '20px 0 0'
    },
    '& p': {
      fontSize: '30px',
      fontWeight: '100',
      color: '#FE8368',
      textAlign: 'center',
      marginTop: '75px'
    },
    '& .buttons': {
      flexGrow: '1',
      marginBottom: '35px'
    }
  }

});

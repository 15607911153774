import React from "react";
import { observer } from "mobx-react";
import {
  Grid,
  Button,
  TablePagination,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import downloadFile from "../../utils/fetchFile";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { isEmpty, each } from "lodash";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import "url-search-params-polyfill";
import { WithContext as ReactTags } from "react-tag-input";
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";

import { I18n } from "../../i18n/";
import history from "../../utils/history";
import TablePaginationActions from "../../components/pagination/CustomPagination";
import CustomSearch from "../../components/search";
import categoryCaptions from "../../utils/categoryCaptions";
import { selectStyles } from "../../style/select-styles";
import "react-datepicker/dist/react-datepicker.css";
import style from "./style";
import { activityRoute } from "../../utils/routes";
import { StoreContext } from "../../stores/context";
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';

const styles = (theme) => style(theme);


const filterCategories = {
  regions: ["regions", "regionIds"],
  formats: ["formats", "formatIds"],
  target_groups: ["targetGroups", "targetGroupIds"],
  categories: ["categories", "categoryIds"],
  category_results: ["categoryResults", "categoryResultIds"],
  funding_sources: ["fundingSources", "fundingSourceIds"],
  trainers: ["trainers", "trainerIds"],
  europe_actions: ["europeActions", "europeActionIds"],
  training_package_types: ["trainingPackageTypes", "trainingPackageTypeIds"],
  thematic_modules: ["thematicModules", "thematicModuleIds"],
  projects: ["projects", "projectIds"],
  organizations: ["organizations", "organizationIds"],
  kind: ["kind", "kinds"],
  place: ["place", "places"],
  grant_currency: ["grantCurrency", "grantCurrencies"],
  west_cooperation: ["westCooperation", "westCooperation"],
  cso_grants: ["csoGrants", "csoGrantIds"]
};

class Search extends React.Component {
  static contextType = StoreContext;

  state = {
    loading: true,
    items: [],
    filters: {},
    page: 0,
    total: 0,
    rowsPerPage: 25,
    searchQuery: "",
    order: "desc",
    orderBy: "startDate,desc",
    currentUser: null,
    currentPage: "activities",
    regions: { limit: 5, checkedAll: false, uncheckedIds: [] },
    formats: { limit: 5, checkedAll: false, uncheckedIds: [] },
    target_groups: { limit: 5, checkedAll: false, uncheckedIds: [] },
    categories: { limit: 5, checkedAll: false, uncheckedIds: [] },
    category_results: { limit: 5, checkedAll: false, uncheckedIds: [] },
    funding_sources: { limit: 5, checkedAll: false, uncheckedIds: [] },
    participants: { limit: 5, checkedAll: false, uncheckedIds: [] },
    trainers: { limit: 5, checkedAll: false, uncheckedIds: [] },
    europe_actions: { limit: 5, checkedAll: false, uncheckedIds: [] },
    training_package_types: { limit: 5, checkedAll: false, uncheckedIds: [] },
    thematic_modules: { limit: 5, checkedAll: false, uncheckedIds: [] },
    projects: { limit: 5, checkedAll: false, uncheckedIds: [] },
    organizations: { limit: 5, checkedAll: false, uncheckedIds: [] },
    kind: { limit: 5, checkedAll: false, uncheckedIds: [] },
    place: { limit: 5, checkedAll: false, uncheckedIds: [] },
    cso_grants: { limit: 5, checkedAll: false, uncheckedIds: [] },
    grant_currency: { limit: 5, checkedAll: false, uncheckedIds: [] },
    west_cooperation: { limit: 5, checkedAll: false },
    tags: [],
    participantsCount: 0,
    selectedFilters: "",
    startDate: "",
    endDate: "",
    participantsFrom: "",
    participantsTo: "",
    advisoryType: "",
    grantValueMin: "",
    grantValueMax: "",
    sortingBy: {},
    filteringGetParams: "?",
    params: {
      regionIds: [],
      formatIds: [],
      targetGroupIds: [],
      categoryIds: [],
      categoryResultIds: [],
      activityCharacterIds: [],
      fundingSourceIds: [],
      trainerIds: [],
      europeActionIds: [],
      trainingPackageTypeIds: [],
      thematicModuleIds: [],
      projectIds: [],
      organizationIds: [],
      characteristicIds: [],
      kinds: [],
      places: [],
      grantCurrencies: [],
      westCooperation: [],
      csoGrantIds: [],
    },
  };

  componentDidMount() {
    const query = new URLSearchParams(document.location.search);
    const search = query.get("query");

    if (search) {
      this.setState({ searchQuery: search }, () => {
        this.getFilteredData("", [], true);
      });
    } else {
      const {
        searchQuery,
        selectedTags,
        selectedFilters,
        xsQuery,
      } = this.props;

      if (isEmpty(searchQuery) && isEmpty(selectedTags)) {
        this.getData();
      } else {
        const newState = {
          searchQuery,
          selectedFilters,
          participantsQuery: xsQuery,
          tags: selectedTags,
        };
        const date = (selectedTags || []).find((item) => item.id === "date");
        if (date) {
          newState.startDate = date.startDate;
          newState.endDate = date.endDate;
        }

        this.setState(newState, () => {
          this.getFilteredData(selectedFilters, selectedTags, true);
        });
      }
    }
    this.checkAuth();
  }

  async getFilters(params) {
    const res = await fetchData("get", `/oes/filters${params}`);
    const filters = res[0];
    for (let key in filters) {
      if (!filters.hasOwnProperty(key)) {
        continue;
      }
      const filter = filters[key];
      for (let item of filter) {
        if (!item.id) {
          item.id = item.title_en;
        }
      }
    }

    return filters;
  }

  async getData() {
    const query = this.props.searchQuery || this.state.searchQuery;
    const queryParam = isEmpty(query) ? "" : "?query=" + query;

    const data = await fetchData("get", `/oes${queryParam}`);
    const filters = await this.getFilters(queryParam);

    // const canGetParticipantCount = Array.isArray(data) && data[2] !== undefined;

    this.setState({
      loading: false,
      items: data[0],
      total: data[1],
      participantsCount: data[2].participantsCount, // canGetParticipantCount ? data[2].participantsCount : 0,
      filters,
    });
  }

  formatParams(params) {
    const queryParams = [];
    for (let key in params) {
      const values = params[key];
      for (let val of values) {
        queryParams.push(`${key}[]=${val}`);
      }
    }

    return queryParams.join("&");
  }

  getParams(selectedFilters, selectedTags) {
    const query = this.state.searchQuery || this.props.searchQuery || "";

    let getParams = "?";

    if (query !== "") {
      getParams += `query=${query}&`;
    }
    if (this.state.orderBy !== "") {
      getParams += `sort[]=${this.state.orderBy}&`;
    }
    if (selectedFilters !== "") {
      getParams += selectedFilters;
    }
    const participantQueryTag = (selectedTags || []).find(
      (x) => x.id === "participantsQuery"
    );
    if (participantQueryTag) {
      getParams += `participants_query=${participantQueryTag.value}&`;
    }

    getParams += this.formatParams(this.state.params);

    return getParams;
  }

  async getFilteredData(selectedFilters, selectedTags, withCounters) {
    let getParams = this.getParams(selectedFilters, selectedTags);

    const data = await fetchData("get", `/oes${getParams}`);

    const newState = {
      loading: false,
      items: data[0],
      total: data[1],
      participantsCount: data[2].participantsCount,
    };
    // UPDATE STATS on filters column
    if (withCounters) {
      const filtersFinal = await this.getFilters(getParams);
      const tagsWithoutCounters = [
        "date",
        "participants",
        "activitiesQuery",
        "participantsQuery",
      ];

      selectedTags.forEach((tag) => {
        if (tagsWithoutCounters.indexOf(tag.id) >= 0) {
          return;
        }

        const filterOptions = filtersFinal[tag.category];
        if (tag.filter && filterOptions) {
          tag.filter.count = 0;
          let matchedItem = filterOptions.find((filter) => {
            return filter.id === tag.filter.id;
          });
          if (!matchedItem) {
            filterOptions.unshift(tag.filter);
          }
        } else {
          // case for ALL selected
        }
      });
      newState.filters = filtersFinal;
    }
    this.setState(newState);
    this.scrollToTop();
  }

  getFiltersFromCheckboxes(collection) {
    let tag = [];
    let param = [];
    let [camelCasedName, inputName] = filterCategories[collection];
    const inputNameModified = `${inputName}[]`;
    const collectionState = this.state[collection];
    const { langStore } = this.context;
    const { lang } = langStore;

    if (collectionState.checkedAll) {
      let titles = [];
      const titleColumn = langStore.isEng ? "title_en" : "title_ua";
      let allTitle = `${langStore.isEng ? "All" : "Всi"} ${
        I18n[lang].search[camelCasedName]
      }`;
      param.push(inputNameModified + "=0");
      // ! As far as unchecked inputs could be not rendered, we should to look at uncheckedIds for negatives
      collectionState.uncheckedIds.forEach((id) => {
        const item = this.state.filters[collection].find((obj) => obj.id === id);
        titles.push(item[titleColumn] || item["title_en"] || item["title_ua"]);
        param.push(inputNameModified + "=" + id);
      });
      const title = allTitle + (titles.length ? " - " + titles.join(", ") : "");
      tag.push({ id: title, text: title, category: collection, filter: null });
    } else {
      // ! Assume that all checked inputs have been rendered
      const query = document.querySelectorAll(
        '[name="' + inputNameModified + '"]:checked'
      );

      const params = this.state.params;
      params[inputName] = [];

      query.forEach((item) => {
        let title = item.title;
        const filterSource = this.state.filters[collection].find((obj) => {
          return obj.title_en === title;
        });
        const method = `modify${capitalize(camelCasedName)}Tag`;
        let tagObj = {
            id: title,
            text: title,
            category: collection,
            filter: filterSource,
        };

        if (typeof this[method] === 'function') {
          tagObj = this[method](tagObj);
        } 

        tag.push(tagObj);

        params[inputName].push(item.dataset.id);
      });
      this.setState({ params });
    }
    return tag;
  }

  modifyWestCooperationTag(obj) {
    const { lang } = this.context.langStore;
    obj.text = I18n[lang].search.westCooperation + ': ' + I18n[lang].common[obj.text.toLowerCase()];

    return obj;
  }

  handleFilterChange = (e) => {
    let beforeOpts = { loading: true };
    if (e) {
      // Handle Unchecks fo
      const checkedCollection = e.target.dataset.collection;
      const collectionState = this.state[checkedCollection];
      if (checkedCollection && collectionState && collectionState.checkedAll) {
        const value = parseInt(e.target.value) || e.target.value;
        const valuePos = collectionState.uncheckedIds.indexOf(value);
        if (e.target.checked) {
          collectionState.uncheckedIds.splice(valuePos, 1);
        } else if (valuePos < 0) {
          collectionState.uncheckedIds.push(value);
        }
        beforeOpts[checkedCollection] = collectionState;
      }
    }
    this.setState(beforeOpts);

    const participantParams = [
      "participantRegionIds",
      "employerTypeIds",
      "employerLevelIds",
      "genders",
    ];
    const participantTags = [
      "participant_regions",
      "employer_types",
      "employer_levels",
      "genders",
    ];
    let selectedFilters = this.state.selectedFilters
      .split("&")
      .filter((pair) => {
        return !!participantParams.find((p) => pair.indexOf(p) === 0) || !pair;
      })
      .join("&");
    let selectedTags = this.state.tags.filter(
      (tag) => participantTags.indexOf(tag.category) >= 0
    );

    each(filterCategories, (opts, collection) => {
      let tags = this.getFiltersFromCheckboxes(collection);

      selectedTags = selectedTags.concat(tags);
    });

    let { startDate, endDate } = this.state;

    startDate = startDate && dayjs(startDate).format("YYYY-MM-DD");
    endDate = endDate && dayjs(endDate).format("YYYY-MM-DD");

    if (startDate) {
      selectedFilters += `startDate=${startDate}&`;
    }
    if (endDate) {
      selectedFilters += `endDate=${endDate}&`;
    }

    if (startDate || endDate) {
      selectedTags.push({
        id: "date",
        text: `Date range: ${startDate} - ${endDate}`,
        startDate: startDate,
        endDate: endDate,
      });
    }

    const { participantsFrom, participantsTo } = this.state;
    if (participantsFrom) {
      selectedFilters += `actualNumberOfParticipantsMin=${participantsFrom}&`;
    }
    if (participantsTo) {
      selectedFilters += `actualNumberOfParticipantsMax=${participantsTo}&`;
    }
    if (participantsFrom || participantsTo) {
      selectedTags.push({
        id: "participants",
        text: `Participants range: ${participantsFrom} - ${participantsTo}`,
      });
    }

    const { advisoryType } = this.state;
    if (advisoryType) {
      selectedFilters += `advisoryTypes[]=${advisoryType}&`;
      selectedTags.push({
        id: "advisoryType",
        text: `Advisory Type: ${advisoryType}`,
      });
    }

    const { grantValueMin, grantValueMax } = this.state;
    if (grantValueMin) {
      selectedFilters += `grantValueMin=${grantValueMin}&`;
    }
    if (grantValueMax) {
      selectedFilters += `grantValueMax=${grantValueMax}&`;
    }
    if (grantValueMin || grantValueMax) {
      selectedTags.push({
        id: "grantValue",
        text: `Grant Value range: ${grantValueMin} - ${grantValueMax}`,
      });
    }

    const { searchQuery, participantsQuery } = this.state;
    if (!isEmpty(searchQuery)) {
      selectedTags.push(this.activitiesQueryTag(searchQuery));
    }
    if (!isEmpty(participantsQuery)) {
      selectedTags.push(this.participantsQueryTag(participantsQuery));
    }

    this.props.onSearchChange(selectedTags, selectedFilters, searchQuery);
    this.setState({ tags: selectedTags, selectedFilters: selectedFilters });
    this.getFilteredData(selectedFilters, selectedTags, true);

    return true;
  };

  handleChangePage = (e, page) => {
    this.setState({ loading: true, page: page }, () => {
      let getParams = `page=${page + 1}&`;
      if (this.state.selectedFilters !== "") {
        getParams += this.state.selectedFilters;
      }
      this.getPage(getParams);
    });
  };

  async getPage(getParams) {
    this.getFilteredData(getParams, this.state.tags, false);
    this.scrollToTop();
  }

  handleDownload = (e) => {
    e.preventDefault();
    this.getDownloadableResults(
      `/oes.xlsx${this.getParams(this.state.selectedFilters, this.state.tags)}`
    );
  };

  handleDownloadStats = async (e) => {
    e.preventDefault();
    this.getDownloadableResults(
      `/oes/filters.xlsx${this.getParams(
        this.state.selectedFilters,
        this.state.tags
      )}`
    );
  };

  async getDownloadableResults(path) {
    this.setState({ loading: true });
    await downloadFile(path);
    this.setState({ loading: false });
  }

  updateInputValue = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  participantsQueryTag = (query) => {
    return {
      id: "participantsQuery",
      text: `${this.context.langStore.search.participants}: ${query}`,
      value: query,
    };
  };

  activitiesQueryTag = (query) => {
    return {
      id: "activitiesQuery",
      text: `${this.context.langStore.search.activities}: ${query}`,
      value: query,
    };
  };

  clearSearchField = () => {
    this.setState({ searchQuery: "", participantsQuery: "", loading: false });

    history.push({
      search: ``,
    });

    this.getSearchResults();
  };

  async getSearchResults() {
    const data = await fetchData("get", `/oes`);
    const filters = await fetchData("get", `/xs/filters`);

    this.setState({
      loading: false,
      items: data[0],
      total: data[1],
      participantsCount: data[2].participantsCount,
      filters: filters[0],
    });
  }

  handleSearchQuerySubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const { searchQuery, selectedFilters, participantsQuery } = this.state;

    // TODO: should be uncommented if JIRA for issues with search with empty string is presented
    // and search should be dropped like click on a basket icon insearch input
    // if (!searchQuery) {
    //   return this.clearSearchField();
    // }

    const tags = this.state.tags.filter((t) => t.id !== "activitiesQuery");
    tags.push(this.activitiesQueryTag(searchQuery));
    this.getFilteredData(selectedFilters, tags, true);

    history.push({
      search: `?query=${searchQuery}`,
    });

    this.props.onSearchChange(
      tags,
      selectedFilters,
      searchQuery,
      participantsQuery
    );
  };

  handleSort = (orderBy) => {
    let order = "desc";
    if (this.state.orderBy === orderBy.value && this.state.order === "desc") {
      order = "asc";
    }
    this.setState(
      {
        loading: true,
        orderBy: orderBy.value,
        order: order,
        sortingBy: orderBy,
      },
      () => {
        this.getFilteredData(this.state.selectedFilters, this.state.tags);
      }
    );
  };

  changeLimit = (collection, count) => {
    const collectionState = this.state[collection];
    collectionState.limit = count;
    this.setState({ [collection]: collectionState });
  };

  handleViewAll = (e) => {
    e.preventDefault();
    this.changeLimit(e.target.dataset.collection, 100000);
  };

  handleCollapse = (e) => {
    e.preventDefault();
    this.changeLimit(e.target.dataset.collection, 5);
  };

  handleSelectAll = (e) => {
    e.preventDefault();
    const collection = e.target.dataset.collection;
    const collectionState = this.state[collection];
    collectionState.checkedAll = true;
    collectionState.uncheckedIds = [];
    this.setState({ [collection]: collectionState }, () => {
      this.handleFilterChange();
    });
  };

  handleClearAll = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      participantsFrom: "",
      participantsTo: "",
      advisoryType: "",
      grantValueMin: "",
      grantValueMax: "",
    });

    const collectionStates = {};
    each(filterCategories, (options, collection) => {
      const collectionState = this.state[collection];
      if (!collectionState.checkedAll) {
        return;
      }
      collectionState.checkedAll = false;
      collectionState.uncheckedIds = [];
      collectionStates[collection] = collectionState;
    });
    // only hidden activities query should left
    collectionStates.tags = this.state.tags.filter(
      (t) => t.id === "activitiesQuery"
    );
    collectionStates.participantsQuery = "";
    collectionStates.startDate = "";
    collectionStates.endDate = "";

    collectionStates.params = {};
    each(
      this.state.params,
      (index, param) => (collectionStates.params[param] = [])
    );

    this.setState(collectionStates);

    document
      .querySelectorAll('[type="checkbox"]:checked')
      .forEach((checkbox) => {
        checkbox.removeAttribute("checked");
      });

    const { tags, selectedFilters, searchQuery } = collectionStates;

    this.props.onSearchChange(tags, selectedFilters, searchQuery);

    this.getData();
  };

  async checkAuth() {
    const [result] = await fetchData("get", "/account");
    if (!result || !result.id) {
      window.location.href = "/login";
    } else {
      let language = result.langKey === "en" ? "Eng" : "Ukr";
      this.setState({
        currentUser: result,
        sortingBy: {
          value: "startDate,desc",
          label: I18n[language].search.startDesc,
        },
      });
    }
  }

  handleDeleteTag = (i) => {
    const { tags } = this.state;
    const selectedTag = tags
      .filter((t) => t.id !== "activitiesQuery")
      .filter((tag, index) => index === i)[0];
    const selectedId = selectedTag.id;
    switch (selectedId) {
      case "date":
        this.setState({ startDate: "", endDate: "" }, this.handleFilterChange);
        break;
      case "participants":
        this.setState(
          { participantsFrom: "", participantsTo: "" },
          this.handleFilterChange
        );
        break;
      case "participantsQuery":
        this.setState({ participantsQuery: "" }, this.handleFilterChange);
        break;
      case "advisoryType":
        this.setState(
          { advisoryType: "" },
          this.handleFilterChange
        );
        break;
      case "grantValue":
        this.setState(
          { grantValueMin: "", grantValueMax: "" },
          this.handleFilterChange
        );
        break;
      default:
        if (selectedTag.filter) {
          const checkbox = document.querySelector(
            '[title="' +
              selectedId +
              '"][data-collection="' +
              selectedTag.category +
              '"]'
          );
          if (checkbox) {
            checkbox.removeAttribute("checked");
            checkbox.parentNode.click();
          } else {
            const updatedTags = tags;
            updatedTags.splice(i, 1);
            this.setState({ tags: updatedTags });

            const toCamel = (s) => {
              return s.replace(/([-_][a-z])/gi, ($1) => {
                return $1.toUpperCase().replace("-", "").replace("_", "");
              });
            };

            let selectedFilters = "";
            updatedTags.forEach((item) => {
              let category = toCamel(item.category);
              selectedFilters += `${category}Ids[]=${item.filter.id}&`;
            });
            this.setState({ selectedFilters, loading: true });
            this.getFilteredData(selectedFilters, updatedTags, true);
          }
        } else {
          const collection = selectedTag.category;
          const collectionState = this.state[collection];
          collectionState.checkedAll = false;
          collectionState.uncheckedIds = [];
          this.setState({ [collection]: collectionState }, () => {
            this.handleFilterChange();
          });
        }
    }
  };

  renderFilter = (collection) => {
    const [camelCasedName, inputName] = filterCategories[collection];
    const collectionState = this.state[collection];
    const classes = this.props.classes;
    const { lang } = this.context.langStore;
    const counters = this.state.filters[collection];
    const checkedAll = collectionState.checkedAll;
    const checkedIds = checkedAll
      ? []
      : this.state.tags
          .filter((tag) => tag.category === collection && tag.filter)
          .map((tag) => tag.filter.id);
    const uncheckedIds = checkedAll ? collectionState.uncheckedIds : [];
    const limit = collectionState.limit;
    const isViewAll = limit === 100000;
    let shownCounters = counters;
    if (!isViewAll && counters) {
      shownCounters = counters.slice(0, limit);
      shownCounters = shownCounters.concat(
        counters
          .slice(limit, counters.length)
          .filter((filter) => checkedIds.indexOf(filter.id) >= 0)
      );
    }
    let filterTitle = I18n[lang].search[camelCasedName];
    if (camelCasedName === "categories") {
      filterTitle = categoryCaptions(this.state.currentUser).categories;
    }
    return (
      <div className={classes.filterBlock}>
        <h4>{filterTitle}</h4>
        {counters &&
          shownCounters.map((counter, i) => {
            const checked = checkedIds.indexOf(counter.id) >= 0;
            const unchecked = uncheckedIds.indexOf(counter.id) >= 0;
            return (
              <div className={classes.filter} key={i}>
                <FormControlLabel
                  className={classes.controlLabel}
                  control={
                    <Checkbox
                      name={inputName + "[]"}
                      value={`${counter.id}`}
                      checked={
                        (checkedAll && !unchecked) || (!checkedAll && checked)
                      }
                      inputProps={{
                        title: counter.title_en,
                        "data-collection": collection,
                        "data-id": counter.id,
                      }}
                      onChange={this.handleFilterChange}
                      icon={<div className="checkbox"></div>}
                      checkedIcon={
                        <div className="checkbox checkbox--checked"></div>
                      }
                      className={classes.checkbox}
                    />
                  }
                  label={
                    lang === "Eng"
                      ? counter.title_en
                      : counter.title_ua || counter.title_en
                  }
                />
                {counter.count !== 0 && (
                  <div className="count">{counter.count}</div>
                )}
              </div>
            );
          })}
        {counters && counters.length > 1 && (
          <div className={classes.viewAll}>
            {counters.length > 5 && (
              <a
                href
                data-collection={collection}
                onClick={isViewAll ? this.handleCollapse : this.handleViewAll}
              >
                {isViewAll
                  ? I18n[lang].search.collapse
                  : I18n[lang].search.viewAll}
              </a>
            )}
            <a
              href
              className={classes.selectAllLink}
              data-collection={collection}
              onClick={this.handleSelectAll}
            >
              {I18n[lang].search.selectAll}
            </a>
          </div>
        )}
      </div>
    );
  };

  handleDate = (field) => {
    return (date) => {
      this.setState({ [field]: date }, () => {
        this.handleFilterChange();
      });
    };
  };

  scrollToTop = () => {
    if (window.scrollY !== 0) {
      setTimeout(() => {
        window.scrollTo(0, window.scrollY - 30);
        this.scrollToTop();
      }, 5);
    }
  };

  render() {
    const { classes } = this.props;
    const {
      items,
      page,
      rowsPerPage,
      total,
      currentUser,
      tags,
      participantsCount,
      sortingBy,
      advisoryType,
      grantValueMin,
      grantValueMax,
    } = this.state;
    const { langStore } = this.context;
    const labelFunc = (date) => (date ? dayjs(date).format('DD.MM.YYYY') : "");

    const sortOptions = [
      { value: "startDate,desc", label: langStore.search.startDesc },
      { value: "startDate,asc", label: langStore.search.startAsc },
      { value: "endDate,desc", label: langStore.search.endDesc },
      { value: "endDate,asc", label: langStore.search.endAsc },
    ];

    const asOES =
      currentUser &&
      currentUser.authorities.filter(
        (r) => ["ROLE_OES", "ROLE_MINISTRY", "ROLE_GUEST"].indexOf(r) >= 0
      ).length > 0;

    const calendarStyle = {
      color: "#e6feff",
      width: 32,
    };
    return (
      <div>
        {this.state.loading && <Loader />}
        <div className={classes.appBar}>
          {!asOES && (
            <div className={(classes.filterColumn, classes.filterColumnTop)}>
              <Link to="/search/oes" className={classes.activitiesBtn}>
                <Button
                  variant={"contained"}
                  className={classes.containedCustom}
                >
                  <img
                    src="/images/icons/calendar-white.svg"
                    alt="i"
                    width="42"
                  />
                  {langStore.search.activities}
                </Button>
              </Link>

              <Link to="/search/xs" className={classes.participantsBtn}>
                <Button
                  variant={"outlined"}
                  color="primary"
                  className={classes.outlinedCustomActive}
                >
                  <img
                    src="/images/icons/participants-active.svg"
                    alt="i"
                    width="23"
                  />
                  {langStore.search.participants}
                </Button>
              </Link>
            </div>
          )}
          <div className={classes.wrapRight}>
            <CustomSearch
              placeholder={langStore.search.search}
              props={this.props}
              value={this.state.searchQuery}
              updateInputValue={this.updateInputValue}
              submit={this.handleSearchQuerySubmit}
              state={this.state}
              clearSearchField={this.clearSearchField}
            />
            <div className={classes.wrapBtn}>
              <Link
                to="#"
                className={classNames(classes.stats, "download")}
                onClick={this.handleDownloadStats}
              >
                Export Stats
                <i></i>
              </Link>

              <Link
                to="#"
                className={classNames(classes.calendar, "download")}
                onClick={this.handleDownload}
              >
                Activity calendar
                <i></i>
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.root}>
          <div className={classes.container}>
            <Paper className={classNames(classes.paper, classes.filterColumn)}>
              <div className={classes.filterBlock}>
                <h4>{langStore.search.dateRange}</h4>
                <div className={classes.datesWrapper}>
                  <div
                    className={classNames(
                      classes.calendarPicker,
                      classes.dateBlock
                    )}
                  >
                    <div className={classNames(classes.dataIcon, "iconWrap")}>
                      <img
                        src="/images/icons/calendar.svg"
                        alt="calendar"
                        style={calendarStyle}
                        className={classNames(classes.dataIcon, "icon")}
                      />
                    </div>
                    <div className={classNames(classes.dataPick, "inputWrap")}>
                      <MuiPickersUtilsProvider utils={DayjsUtils}>
                        <InlineDatePicker
                          className={classNames(
                            classes.textField,
                            classes.dateField,
                            "input"
                          )}
                          value={
                            this.state.startDate !== ""
                              ? new Date(this.state.startDate)
                              : null
                          }
                          onChange={this.handleDate("startDate")}
                          labelFunc={labelFunc}
                          format="yyyy/MM/dd"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      classes.calendarPicker,
                      classes.dateBlock
                    )}
                  >
                    <div className={classNames(classes.dataIcon, "iconWrap")}>
                      <img
                        src="/images/icons/calendar.svg"
                        alt="calendar"
                        style={calendarStyle}
                        className={classNames(classes.dataIcon, "icon")}
                      />
                    </div>
                    <div className={classNames(classes.dataPick, "inputWrap")}>
                      <MuiPickersUtilsProvider utils={DayjsUtils}>
                        <InlineDatePicker
                          className={classNames(
                            classes.textField,
                            classes.dateField,
                            "input"
                          )}
                          value={
                            this.state.endDate !== ""
                              ? new Date(this.state.endDate)
                              : null
                          }
                          onChange={this.handleDate("endDate")}
                          labelFunc={labelFunc}
                          format="yyyy/MM/dd"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
              </div>

              {this.renderFilter("regions")}
              {this.renderFilter("formats")}
              {this.renderFilter("target_groups")}
              {this.renderFilter("categories")}
              {this.renderFilter("category_results")}
              {this.renderFilter("funding_sources")}
              {!asOES && this.renderFilter("trainers")}
              {this.renderFilter("projects")}
              {this.renderFilter("organizations")}
              {this.renderFilter("kind")}
              {this.renderFilter("place")}
              {this.renderFilter("cso_grants")}
              <div className={classes.filterBlock}>
                <h4>{langStore.search.advisoryTypes}</h4>
                <TextField
                  name="advisoryType"
                  className={classes.dateField}
                  onChange={(e) => {
                    this.setState(
                      { advisoryType: e.target.value },
                      this.handleFilterChange
                    );
                  }}
                  value={advisoryType}
                />
              </div>
              <div className={classes.filterBlock}>
                <h4>{langStore.search.grantValue}</h4>
                <div className={classes.datesWrapper}>
                  <TextField
                    label={langStore.search.from}
                    type="number"
                    name="grantValueMin"
                    className={classes.dateField}
                    onChange={(e) => {
                      this.setState(
                        { grantValueMin: e.target.value },
                        this.handleFilterChange
                      );
                    }}
                    value={grantValueMin}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label={langStore.search.to}
                    type="number"
                    name="grantValueMax"
                    className={classes.dateField}
                    onChange={(e) => {
                      this.setState(
                        { grantValueMax: e.target.value },
                        this.handleFilterChange
                      );
                    }}
                    value={grantValueMax}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              {this.renderFilter("grant_currency")}
              {this.renderFilter("west_cooperation")}
            </Paper>

            <Paper className={classes.resultsColumn}>
              <Grid
                container
                spacing={24}
                alignItems="center"
                className={classes.topFilters}
              >
                <div className={classes.infoWrap}>
                  <div>
                    {total} {langStore.search.activitiesFound}
                  </div>

                  <div>
                    {participantsCount} {langStore.search.participations}
                  </div>

                  <Select
                    styles={selectStyles}
                    className={classes.select}
                    closeMenuOnSelect={true}
                    components={makeAnimated()}
                    options={sortOptions}
                    onChange={this.handleSort}
                    value={sortingBy}
                  />
                </div>

                <Grid item xs={12} className={classes.tagsWrapper}>
                  <ReactTags
                    tags={tags.filter((t) => t.id !== "activitiesQuery")}
                    handleDelete={this.handleDeleteTag}
                    allowDragDrop={false}
                  />
                  <a
                    href
                    className={classes.clearAll}
                    onClick={this.handleClearAll}
                  >
                    {langStore.search.clearAllFilters}
                  </a>
                </Grid>
              </Grid>

              <div className={classes.results}>
                {items.map((item, i) => {
                  return (
                    <div key={i} className={classes.result}>
                      <div className="photo">
                        {item.activityFotoPreviewUrl ? (
                          <img
                            src={item.activityFotoPreviewUrl}
                            alt="Activity"
                          />
                        ) : (
                          <div className="placeholder"></div>
                        )}
                      </div>
                      <div className="info">
                        <Link
                          to={`${activityRoute}/view/${item.id}`}
                          target="_blank"
                        >
                          {langStore.isEng ? item.nameEn : item.nameUa}
                        </Link>
                        <div>
                          {item.startDate} - {item.endDate} |
                          {langStore.isEng ? item.oblastEn : item.oblastUa} |
                          {langStore.isEng ? item.format.en : item.format.ua} |
                          {item.participantsCount}
                        </div>
                        <p>{langStore.isEng ? item.descEn : item.descUa}</p>
                      </div>
                    </div>
                  );
                })}
                <TablePagination
                  className={classes.pagination}
                  labelDisplayedRows={({ from, to, count }) =>
                    `Showing ${from} - ${to} of ${count} items`
                  }
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  page={page}
                  onChangePage={this.handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(observer(Search)));

import React, { useContext } from 'react';
import 'url-search-params-polyfill';
import IndexTable from 'components/table/index.js';
import Item from './item';
import OrganizationService from 'services/OrganizationsService';
import { I18n } from 'i18n';
import { StoreContext } from 'stores/context';

const Organizations = (props) => {
  const path = 'organizations';
  const i18nProps = {
    i18n: I18n[props.lang].organizations,
    i18nCommon: I18n[props.lang].common,
  };
  const isEng = props.lang === 'Eng';
  const { userStore } = useContext(StoreContext);

  return (
    <IndexTable
      {...props}
      {...i18nProps}
      serviceClass={OrganizationService}
      path={path}
      showCreate={() => true}
      showView={() => true}
      showEdit={() => true}
      showDelete={() => true}
      columns={[
        {
          id: "name",
          displayFunc: (row) => (isEng ? row.nameEn : row.nameUa),
          sort: isEng ? "nameEn" : "nameUa",
        },
        {
          id: 'region',
          title: 'Region',
          displayFunc: (row) => row.region?.name,
        },
        { id: "address" },
        { 
          id: "economicActivityType",
          displayFunc: (row) => (isEng ? row.economicActivityType && row.economicActivityType.en : row.economicActivityType && row.economicActivityType.ua),
          sort: "economicActivityTypeId"
        },
        {
          id: 'size',
          title: 'Size',
          displayFunc: (row) => row.size && row.size.name,
          condition: () => !userStore.isCurrentProject('AZP') 
        },
        {
          id: 'numberOfEmployees',
          title: 'Number of Employees',
          displayFunc: (row) => row.numberOfEmployees,
          condition: () => userStore.isCurrentProject('AZP') 
        },
        {
          id: 'relocated',
          title: 'Relocated?',
          displayFunc: (row) => row.relocated ? 'Yes' : 'No',
          condition: () => userStore.isCurrentProject('AZP') 
        },
        {
          id: 'email',
          title: 'Email'
        },
        {
          id: 'phone',
          title: 'Phone'
        }
      ]}
      onEditItem={(itemId, item, handleItemSave) => {
        return (
          <Item
            item={item}
            itemId={itemId}
            indexPath={path}
            handleItemSave={handleItemSave}
            isEditable={true}
            {...i18nProps}
            {...props}
          />
        );
      }}
      onViewItem={(itemId, item) => {
        return (
          <Item
            item={item}
            itemId={itemId}
            indexPath={path}
            isEditable={false}
            {...i18nProps}
            {...props}
          />
        );
      }}
    />
  );
};

export default Organizations;

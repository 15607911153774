import React from "react";
import withRoot from "../../../withRoot";
import BaseGraph from "../base-graph";

const datasetOptions = [
  {
    label: "Number of Hromadas",
    data: [51, 65, 40, 49, 60, 37, 40],
    fill: false,
    borderColor: "#fdbb2d",
    backgroundColor: "#fdbb2d",
    pointBorderColor: "#fdbb2d",
    pointBackgroundColor: "#fdbb2d",
    pointHoverBackgroundColor: "#fdbb2d",
    pointHoverBorderColor: "#fdbb2d",
  },
];

const options = {
  responsive: true,
  tooltips: {
    mode: "label",
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
        },
        ticks: {
          precision: 0,
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        barThickness: 10,
      },
    ],
  },
};

class CapacityActivityHromadasChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type="horizontalBar"
        tableTitle="Number of AHs in the areas of education and health care, by oblast"
        url="/graph/capacity_activity_hromadas"
        chartOptions={options}
        datasetOptions={datasetOptions}
        filter={this.props.filter}
      />
    );
  }
}

export default withRoot(CapacityActivityHromadasChart);

import { useParams } from 'react-router-dom';
import DataSource from 'components/DataSource';
import AppBar from 'components/AppBarWithBackButton';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = {
  wrapper: {
    margin: 'auto',
    maxWidth: '800px',
    paddingBottom: '20px'
  },
  title: {
    margin: '20px 0 10px 0',
    textTransform: 'uppercase'
  }
};

function VideoView(props) {
  const { classes } = props;
  const { slug } = useParams();

  return (
    <>
      <AppBar>
      </AppBar>
      <DataSource path={`videos/${slug}`}>
        {(video) => (
          <div className={classes.wrapper}>
            <Typography 
              variant="h3"
              color="primary"
              className={classes.title}
            >
              {video.title}
            </Typography>
            <video 
              controls 
              controlslist="nodownload"
              width="100%"
            >
              <source src={video.url} />
            </video>
          </div>
        )}
      </DataSource>
    </>
  );
}

export default withStyles(styles)(VideoView);

import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ChangeViewBar from './ChangeViewBar';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing.unit * 2,
    backgroundColor: '#f5f5f5'
  },
  nav: {
    display: 'flex',
    flexWrap: 'no-wrap'
  },
  fab: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  popper: {
    zIndex: 1
  }
});

function Toolbar(props) {
  const { classes } = props;
  const [ anchorEl, setAnchorEl ] = useState();

  const toggleMore = (event) => {
    setAnchorEl(
      anchorEl ? null : event.currentTarget
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" color="primary">
        Activities
      </Typography>
      <div className={classes.nav}>
        <ChangeViewBar />
        <Fab 
          className={classes.fab}
          to={`/legacy/activities/create`} 
          component={RouterLink} 
          color="primary"
          size="medium"
        >
          <AddIcon />
        </Fab>
        <IconButton
          aria-label="More"
          onClick={toggleMore}
        >
          <MoreVertIcon />
        </IconButton>
        <Popper 
          className={classes.popper}
          anchorEl={anchorEl} 
          open={Boolean(anchorEl)}
          transition 
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={toggleMore}>
                  <MenuList>
                    <MenuItem to="/tags" component={RouterLink}>
                      Tags
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

export default withStyles(styles)(Toolbar);

export function arrayMoveMutable(source, fromIndex, toIndex) {
	const startIndex = fromIndex < 0 ? source.length + fromIndex : fromIndex;

	if (startIndex >= 0 && startIndex < source.length) {
		const endIndex = toIndex < 0 ? source.length + toIndex : toIndex;
		const [item] = source.splice(fromIndex, 1);

		source.splice(endIndex, 0, item);
	}
}

export function arrayMoveImmutable(source, fromIndex, toIndex) {
  const copy = [...source];

  arrayMoveMutable(copy, fromIndex, toIndex);

  return copy;
}

export function arrayRemoveMutable(source, index) {
  source.splice(index, 1);
}

export function arrayRemoveImmutable(source, index) {
  const copy = [...source];

  arrayRemoveMutable(copy, index);

  return copy;
}

import { useState, useCallback } from 'react';
import { useApi } from 'support/api';
import { useNotifications } from 'components/notifications';
import { IconButton } from '@material-ui/core';
import snakecaseKeys from 'snakecase-keys';
import AddIcon from '@material-ui/icons/Add';

function AddParticipantButton(props) {
  const { activityId, participant } = props;
  const [ added, setAdded ] = useState(participant.alreadyParticipating);
  const api = useApi();
  const notifications = useNotifications();

  const handleClick = useCallback(() => {
    const payload = snakecaseKeys({ 
      activityId, 
      partakableId: participant.id,
      partakableType: 'participant'
    }); 

    api
      .activities(activityId)
      .participations
      .create(payload)
      .then(() => { 
        setAdded(true);
        notifications.success('Added');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !added && (
    <IconButton
      color="primary"
      onClick={handleClick}
    >
      <AddIcon fontSize="small" />
    </IconButton>
  );
}

export default AddParticipantButton;

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/icons/FileCopy';
import { useNotifications } from 'components/notifications';
import { useApi } from 'support/api';
import { useHistory } from 'react-router-dom';

function CloneButton(props) {
  const { source } = props;
  const api = useApi();
  const notifications = useNotifications();
  const history = useHistory();

  async function handleClone() {
    let response;
    const prefix = new Date().getTime();
    const attributes = {
      name: `${prefix} ${source.name}`,
      slug: `${prefix}-${source.slug}`,
      foreword: source.foreword,
      afterword: source.afterword
    };

    notifications.info('Cloning', null);

    response = await api.surveys.create(attributes);
    const survey = await response.json();

    response = await api.surveys(source.id).questions.get();
    const questions = await response.json();
  
    questions.forEach(async (attributes) => {
      response = await api.surveys(survey.id).questions.create({
        name: attributes.name,
        type: attributes.type,
        optional: attributes.optional,
        weight: attributes.weight
      });

      let question = await response.json();

      if (question.type !== 'text') {
        await api.surveys(survey.id).questions(question.id).options.put({
          options: attributes.options.map((option) => ({ 
            value: option.value, 
            disableSiblings: option.disableSiblings,
            weight: option.weight 
          }))
        });
      }
    });

    history.push(`/surveys/${survey.id}/edit`);

    notifications.success('Cloned');
  }

  return (
    <IconButton color="primary" {...props} onClick={handleClone}>
      <Icon />
    </IconButton>
  );
}

export default CloneButton;

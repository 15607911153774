import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from 'components/Dialog';

function DeleteButton(props) {
  return (
    <IconButton 
      aria-label="Delete"
      onClick={props.onClick}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
}

function DeleteButtonWithConfirmation(props) {
  const { onClick } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        title="Delete the question?"
        onClose={() => setOpen(false)}
        onConfirm={onClick}
      >
        If the question has options and/or answers, they will be lost. There is no undo. 
      </Dialog>
      <DeleteButton onClick={() => setOpen(true)}/>
    </>
  );
}

export default DeleteButton;

export {
  DeleteButtonWithConfirmation
};


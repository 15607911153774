import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import blue from '@material-ui/core/colors/blue';
import { useParams } from 'react-router-dom';
import DataSource from 'components/DataSource';
import SurveyView from './SurveyView';

const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: blue
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#000',
        fontWeight: 500
      }
    }
  }
});

function Survey(props) {
  const { slug } = useParams();

  return (
    <MuiThemeProvider theme={theme}>
      <DataSource public path={`surveys/${slug}`}>
        {(survey) => (
          survey ? ( 
            <SurveyView 
              survey={survey} 
              foreword={survey.foreword} 
              afterword={survey.afterword} 
            />
          ) : (
            'Not found.'
          )
        )}
      </DataSource>
    </MuiThemeProvider>
  );
}

export default Survey;

import { makeAutoObservable } from "mobx";
import { matchPath } from "react-router";
import { loginRoute, publicRoutes } from "../utils/routes";

export class RouterStore {
  history = null;
  location = null;

  constructor(history) {
    this.history = history;
    this.location = history.location;
    makeAutoObservable(this, {
      history: false,
    });
    history.listen(this.updateLocation);
  }

  updateLocation = (location) => {
    this.location = location;
  };

  push(location) {
    this.history.push(location);
  }

  goToLogin() {
    this.push(loginRoute);
  }

  goHome() {
    this.push("/");
  }

  refresh() {
    this.history.go(0);
  }

  get isPublicRoute() {
    return (
      publicRoutes.filter(
        (item) => matchPath(this.location.pathname, { path: item }) !== null
      ).length > 0
    );
  }
}

export const En = {
  header: {
    home: "Home",
    registries: "Registries",
    indicators: "Indicators",
    dashboard: "Dashboard",
    searchAnalyse: "Search & Analyse",
    templates: "Templates",
    administration: "Administration",
    activities: "Activities",
    participants: "Participants",
    trainers: "Trainers",
    facilitators: "Facilitators",
    userManagement: "User Management",
    mailLog: "Mail Log",
    team: "Team",
    fundingSource: "Funding Sources",
    employerLevel: "Level",
    employerType: "Type",
    oblast: "Oblast",
    categoryGroup: "Category Groups",
    category: "Categories",
    categoryResults: "Category Components",
    targetGroup: "Target Group",
    targetLevel: "Levels of Target Group",
    organizer: "Organizer",
    activityFormat: "Activity Format",
    activityCharacter: "Activity Characters",
    communities: "Municipalities",
    organizations: "Organizations",
    regionalInitiatives: "Regional Initiatives",
    practice: "Good Practices",
    support: "Support",
    supportFeedback: "Feedback",
    contactForm: "Contact Form",
    forum: "Forum",
    thematicSupportPackages: "Thematic Support Packages",
    programmeIndicators: "Project Indicators",
    goodPractice: "Good Practice",
    csoGrants: "Contracts",
    donorProjects: "Donor Funded Projects",
    graphs: "Graphs",
    map: "Interactive Mapping Tool",
    surveys: 'Surveys'
  },
  home: {
    registries: "REGISTRIES",
    activities: "Activities",
    activitiesText: "See and create new activities",
    participants: "Participants",
    participantsText: "See and create participants",
    trainers: "Trainers & Experts",
    trainersText: "Browse & add trainers and experts",
    facilitators: "Facilitators",
    facilitatorsText: "Browse & add facilitators",
    hromadas: "Municipalities",
    hromadasText: "See the latest municipality information",
    organizations: "Organizations",
    organizationsText: "Browse & add organizations",
    practice: "Good Practices",
    practiceText: "Browse, add & edit good practices",
    indicators: "indicators",
    program: "Project Indicators",
    programText: "View or measure the current status of indicators",
    map: "Map",
    maps: "Interactive Mapping Tool",
    mapsText: "View visualisations",
    graphs: "Graphs",
    graphsText: "Subtitle",
    search: "SEARCH & ANALYSE",
    oes: "Activities and participant data",
    oesText: "Search and analyse activities and participant data",
    xs: "Executive Search (XS)",
    xsText: "Analyze participants across activities",
  },
  activities: {
    activities: "Activities",
    header: "Activities",
    activity: "Activity",
    create: "Create an Activity",
    id: "ID",
    name: "Name",
    startDate: "Start Date",
    endDate: "End Date",
    oblast: "Oblast",
    edit: "Edit Activity ",
    nameEn: 'Name',
    activityAddress: "Activity Address",
    placeOfActivityCoordinates: "Place of Activity Coordinates",
    nameUa: "Name Ukrainian",
    region: "Country",
    activityFormat: "Activity Format",
    organizers: "Organizers",
    mediaReferences: "Media References",
    internalFeedback: "Internal Feedback",
    descEn: 'Description',
    targetGroups: "Target Groups",
    targetLevels: "Levels of Target Group",
    trainers: "Trainers",
    categories: "Categories",
    results: "Components",
    descUa: "Description Ukrainian",
    fundingSources: "Funding Sources",
    activityReport: "Activity Report/Agenda",
    activityFoto: "Activity Photo",
    search: "Search",
    addParticipant: "Add Participant",
    downloadParticipantsTemplate: "Download template",
    importParticipants: "Import participants",
    printList: "Print List",
    printEmptyList: "Print Empty List",
    printActivityInfo: "Print Activity Info",
    characters: "Important activities",
    characteristic: "Characteristic",
    kind: 'Online/Offline/Hybrid',
    online: 'Online',
    offline: 'Offline',
    hybrid: 'Hybrid',
    place: "Place of Activity",
    national: "National",
    international: "International",
    targetedPersons: "Number of Targeted Persons/outreach",
    uniqueUsers: "Unique users",
    implementedVia: "Implemented via",
    giz: "GIZ",
    localSubsidy: "Local Subsidy",
    grant: "Grant",
    advisoryType: "Type of Support Services to SMEs",
    grantValue: "Grant Value",
    grantCurrency: "Grant Currency",
    grantCurrencyEur: "EUR",
    grantCurrencyUah: "UAH",
    organization: "Company",
    organizationSectorOfActivities: "Sector of Activities",
    organizationNumberOfEmployees: "Number of Employees",
    organizationAddress: "Address",
    expectedParticipants: "Expected Number of Participants",
    actualParticipants: "Actual Number of Participants",
    createdBy: "Created by",
    owner: "Owner",
    getEvaluationForm: "Get Evaluation Form",
    viewStatistics: "View Statistics",
    employerTypeDistribution: "Type distribution",
    location: "Location",
    participants: "Participants",
    cellPhone: "Cell Phone",
    gender: "Gender",
    employerLevel: "Level",
    employerType: "Type",
    uniqueHromadas: "UNIQUE AMALGAMATED HROMADAS",
    employerLevelDistribution: "Level distribution",
    genderDistribution: "Gender distribution",
    participantRegistered: "PARTICIPANTS REGISTERED",
    thematicPackagesTitle:
      "Is this activity implemented through Thematic Support Packages?",
    europeActoinsTitle:
      "Is this activity implemented through any of Regional initiatives?",

    trainingPackageTypes: "Training Packages",
    thematicModules: "Thematic Modules",

    clusterA: "Cluster A: Becoming an effective and capable AH",
    clusterB:
      "Cluster B: Designing and delivering high quality municipal services",
    clusterC: "Cluster C: Facilitating local and regional development",
    facilitators: "Facilitators",

    actualNumberOfParticipants: "Actual Number of Participants",
    deleteParticipant: "Remove from activity",
    regionalInitiatives: "Regional initiatives",
    thematicSupportPackage: "Thematic Support Package",
    responsiblePerson: "Responsible Person",
    reportMissing: "Report missing",
    westCooperation: "Is this activity implemented through Cooperation with Regional Office West?",
    consultants: "Consultants",
    startTime: 'Start Time',
    endTime: 'End Time',
    linkToRegistration: 'Link to Registration',
    linkToDescription: 'Link to Description',
  },
  participants: {
    header: "Participant",
    participants: "Participants",
    create: "Create a new Participant",
    search: "Search for participant",
    edit: "Edit Participant",
    nameTranscription: "Name Transliteration",
    participant: "Participant",
    name: "Name",
    gender: "Gender",
    email: "Email",
    cellPhone: "Cell Phone",
    personalDetails: "Personal Details",
    activities: "Activities",
    employer: "Employer",
    position: "Position",
    employerName: "Organization",
    employerLocation: "Location of Employer",
    employerCoordinates: "Employer Coordinates",
    employerAddress: "Employer Address",
    employerType: "Type",
    employerLevel: "Level",
    placeOfActivityCoordinates: "Coordinates",
    contactAddress: "Address",
    matchFoundTitle: "Participant Existed",
    noMatchFoundTitle: "No Match Found",
    noMatchFoundContent:
      "There is no existing participant in system with such parameters",
    checked: "Check for existing participant",
    oblast: "Oblast",
    region: 'Region',
    organization: 'Organization'
  },
  organizations: {
    header: "Organization",
    participants: "Participants",
    create: "Create a new organization",
    search: "Search for organization",
    edit: "Edit organization",
    participant: "Organization",
    name: "Name",
    nameEn: "Name Eng",
    nameUa: "Name Ua",
    address: "Address​",
    location: "map",
    sectorOfActivities: "Companies Sector of activities",
    economicActivityType: "Type of Economic Activities",
    employerTypeId: "Type",
    employerLevelId: "Level",
    communityId: "Community id (number)",
    regionId: "Country",
    locationCoordinates: "Coordinates",
    size: "Number of employees",
  },
  trainers: {
    trainers: "Trainers",
    trainer: "Trainer",
    create: "Create a Trainer",
    search: "Search for trainer",
    edit: "Edit Trainer",
    id: "ID",
    name: "Name",
    nameTranscription: "Name Transliteration",
    gender: "Gender",
    email: "Email",
    cellPhone: "Cell Phone",
    info: "Info",
    header: "Trainer",
  },
  facilitators: {
    facilitators: "Facilitators",
    facilitator: "Facilitator",
    create: "Create a Facilitator",
    search: "Search for facilitator",
    edit: "Edit Facilitator",
    id: "ID",
    name: "Name",
    nameTranscription: "Name Transliteration",
    gender: "Gender",
    email: "Email",
    cellPhone: "Cell Phone",
    info: "Info",
    header: "Facilitator",
  },
  templates: {
    templates: "Templates and Documents",
    createNew: "Create a new template",
    edit: "Редагувати шаблон",
    create: "Create template",
    title: "Title",
    subTitle: "Sub Title",
    file: "File",
  },
  categoryGroups: {
    header: "Category Groups",
    create: "Create a new Group",
    edit: "Edit Group",
    search: "Search a Group",
    langKey: "Key",
    titleUa: "Title UA",
    titleEn: "Title",
    active: "Active",
    order: "Order",
  },
  categories: {
    categories: "Categories",
    header: "Categories",
    category: "Category",
    create: "Create New Category",
    search: "Search for category",
    result: "Component",
    edit: "EDIT CATEGORY",
    langKey: "Key",
    ua: "UA",
    en: "Name",
    active: "Active",
    descEn: "Description",
    descUa: "Desc Ua",
    keywords: "Keywords",
    groups: "Groups",
  },
  categoryResults: {
    header: "Category Components",
    create: "Create New Category Component",
    edit: "Edit Category Component",
    title: "Title",
  },
  communities: {
    communities: "Municipalities",
    community: "Hromada",
    search: "Search for Hromada",
    generalInformation: "General Information",
    ahCentre: "AH centre",
    id: "ID",
    title: "Name",
    center: "Center",
    councilSize: "# of Settlements",
    created: "Date of 1st election",
    establishment: "Date of establishment",
    koatuu: "Koatuu",
    region: "Oblast",
    showOnMap: "Show AH on map",
    initiatives: "Initiatives",
    support: "TSP Support",
    participants: "Participants",
    population: "Population",
    square: "Size (km2)",
    updatedAt: "Updated At",
    update: "Update via API",
    date: "Date",
    activityTitle: "Activity Title",
    activityFormat: "Activity Format",
    regionalInitiative: "Regional Initiatives",
    participantName: "Name",
    gender: "Gender",
    cellPhone: "Cell Phone",
    position: "Position",
    employerType: "Type",
    supportHromada: "Support To Hromada",
    uleadIndicators: "M&E DT4SME INDICATORS",
    so1dInfo: "Number of internal procedures adjusted",
    r2aInfo: "Number of fees/taxes introduced",
    uleadStats: "M&E DT4SME STATISTICS",
    totalParticipantsCount: "Total Number of Participants",
    tspCount: "TSP Activities",
    regionalCount: "Regional Initiative Activities",
    totalNumber: "Total Number of Activities",
    genderBalance: "Gender Balance",
    formatCount: "Format of Activities",
    employerTypeCount: "Type",
    employerName: "Employer Name",
    otherDonorSupport: "Other Donor Support",
  },
  employerLevel: {
    header: "LEVELS",
    employerLevels: "LEVELS",
    employerLevel: "LEVEL",
    create: "Create a new Level",
    search: "Search for Level",
    edit: "EDIT LEVEL",
  },
  employerType: {
    header: "TYPES",
    employerTypes: "TYPES",
    employerType: "TYPE",
    create: "Create a new Type",
    search: "Search for Type",
    edit: "EDIT TYPE",
  },
  europeActions: {
    europeActions: "Regional initiatives",
    europeAction: "Action",
    create: "Create a new Action",
    search: "Search for Action",
    edit: "EDIT ACTION",
  },
  activityCharacter: {
    header: "Activity characters",
    activityCharacters: "Activity character",
    activityCharacter: "Activity character",
    create: "Create a new Activity Character",
    search: "Search for Activity Character",
    edit: "Edit Activity Character",
  },
  characteristic: {
    header: "Characteristics",
    create: "Create a new Characteristic",
    edit: "Edit Characteristic",
    search: "Search a Characteristic",
    langKey: "Key",
    titleUa: "Title UA",
    titleEn: "Title",
    active: "Active",
  },
  format: {
    header: "FORMATS",
    formats: "FORMATS",
    format: "FORMAT",
    create: "Create a new Format",
    search: "Search for Format",
    edit: "EDIT FORMAT",
  },
  fundingSources: {
    header: "Funding Sources",
    fundingSources: "Funding Sources",
    fundingSource: "Funding Source",
    create: "Create a new Funding Source",
    search: "Search for Funding Sources",
    edit: "Edit Funding Source",
  },
  organizer: {
    organizers: "ORGANIZERS",
    organizer: "ORGANIZER",
    create: "Create a new Organizer",
    search: "Search for Organizer",
    edit: "EDIT ORGANIZER",
  },
  region: {
    regions: "Regions",
    region: "Region",
    create: "Create a new Region",
    search: "Search for Region",
    edit: "EDIT Region",
  },
  targetGroup: {
    header: "TARGET GROUPS",
    targetGroups: "TARGET GROUPS",
    targetGroup: "TARGET GROUP",
    create: "Create a new Target Group",
    search: "Search for Target Group",
    edit: "EDIT TARGET GROUP",
  },
  targetLevel: {
    header: "TARGET LEVELS",
    targetLevels: "TARGET LEVELS",
    targetLevel: "TARGET LEVEL",
    create: "Create a new Target Level",
    search: "Search for Target Level",
    edit: "EDIT TARGET LEVEL",
  },
  team: {
    teams: "TEAMS",
    team: "TEAM",
    create: "Create a new Team",
    search: "Search for Team",
    edit: "EDIT TEAM",
  },
  thematicPackages: {
    thematicPackages: "THEMATIC SUPPORT PACKAGES",
    thematicPackage: "THEMATIC SUPPORT PACKAGE",
    create: "Create a new package",
    search: "Search for package",
    edit: "EDIT PACKAGE",
  },
  csoGrants: {
    csoGrants: "Contracts",
    csoGrant: "Contract",
    create: "Create a new Contract",
    search: "Search for Contracts",
    edit: "EDIT CONTRACT",
  },
  csoGrant: {
    search: "Search",
    id: "ID",
    year: "Year",
    projectTitle: "Project Title",
    projectTitleEn: "",
    cso: "Organization",
    csoEn: "",
  },
  donorProjects: {
    donorProjects: "Donor Funded Projects",
    donorProject: "Donor Funded Project",
    create: "Create a new Donor Funded Project",
    search: "Search for Donor Funded Project",
    edit: "EDIT DONOR FUNDED PROJECT",
  },
  donorProject: {
    search: "Search",
    id: "ID",
    title: "Project Title",
    donor: "Donor",
  },
  user: {
    users: "USERS",
    user: "USER",
    create: "Create a new user",
    search: "Search for user",
    edit: "EDIT USER",
    id: "ID",
    login: "Login",
    email: "Email",
    activated: "Activated",
    lang_key: "Language",
    authorities: "Profiles",
    created_date: "Created Date",
    last_modified_by: "Modified by",
    last_modified_date: "Modified Date",
    name: "Name",
    password: "Password",
    passwordConfirm: "Confirm Password",
    createdBy: "Created by",
    profiles: "Profiles",
    availableProjects: "Available projects",
  },
  indicators: {
    title: 'Project Indicators',
    create: 'Create an Indicator',
    edit: 'Edit an Indicator',
    code: 'Code',
    output: 'Output or Specific Objective',
    indicatorText: 'Indicator Text',
    responsiblePerson: 'Responsible Person',
    measurements: 'Measurements',
    frequency: "Frequency",
    updated: "Updated",
    currentValue: "Current Value",
    targetValue: "Target Value",
    status: "Status",
    manage: "Manage",
    dataFile: "Data File",
  },
  goodPractice: {
    header: "Good Practice",
    goodPractice: "Good Practice",
    create: "Create a good practice",
    search: "Search",
    id: "ID",
    title: "Title",
    owner: "Owner",
    description: "Description",
    sumOfInitiatives: "Initiatives",
    region: "Oblast",
  },
  search: {
    activities: "Activities",
    participants: "Participants",
    search: "Search",
    activityFilter: "Activity Filter",
    participantFilter: "PARTICIPANTS FILTER",
    dateRange: "Date Range",
    from: "From",
    to: "To",
    viewAll: "View All",
    collapse: "View Less",
    activitiesFound: "Activities found",
    participantsFound: "unique participants found",
    distinctCellPhone: "distinct cell phones and",
    distinctNames: "distinct names without a phone number",
    participations: "participants",
    clearAllFilters: "Clear all filters",
    owners: "Owners",
    regions: "Regions",
    regionParticipant: "Region (participant)",
    formats: "Activity Formats",
    targetGroups: "Target Groups",
    targetLevels: "Target Levels",
    categories: "Categories",
    categoryResults: "Components",
    organizers: "Organizers",
    fundingSources: "Funding Sources",
    activityCharacters: "Important Activities",
    trainers: "Trainers",
    europeActions: "Regional initiatives",
    thematicModules: "Thematic Modules",
    trainingPackageTypes: "Training Packages",
    employerType: "Type",
    employerLevel: "Level",
    gender: "Gender",
    relevance: "Relevance",
    startAsc: "Start date ascending",
    startDesc: "Start date descending",
    endAsc: "End date ascending",
    endDesc: "End date descending",
    exportStats: "Export stats",
    selectAll: "Select all",
    projects: "Projects",
    organizations: "Organizations",
    characteristics: "Characteristics",
    kind: "Online/Offline",
    place: "Place of Activity",
    targetedPersons: "Number of Targeted Persons/outreach",
    uniqueUsers: "Unique users",
    advisoryTypes: "Type of Support Services to SMEs",
    grantValue: "Grant Value",
    grantCurrency: "Grant Currencies",
    economicActivityType: "Economic Activity Type",
    westCooperation: "Regional Office West",
    size: "Number of Employees",
    csoGrants: "Contracts"
  },
  common: {
    view: "View",
    edit: "Edit",
    delete: "Delete",
    cancel: "Cancel",
    print: "Print",
    save: "Save",
    name: "Name",
    gender: "Gender",
    email: "Email",
    cellPhone: "Cell Phone",
    position: "Position",
    employerName: "Name",
    employerLocation: "Employer Location",
    contactAddress: "Contact Adress",
    activitiesCount: "Total Activities",
    oblastEn: "Oblast",
    employerTypeEn: "Type",
    organizationEn: "Organization",
    employerType: "Type",
    employerLevelEn: "Level",
    employerLevel: "Level",
    yes: "Yes",
    no: "No",
    description: "Description",
    memberId: "Members",
    language: "Language",
    langKey: "Key",
    ua: "UA",
    en: "Name",
    active: "Active",
    refYear: "Ref Year",
    descEn: "Description",
    descUa: "Desc Ua",
    result: "Component",
    keywords: "Keywords",
    createdAt: "Created At",
    updatedAt: "Updated At",
    nameUa: "Name Ua",
    nameEn: "Name",
    cluster: "Cluster",
    clusterNameEn: "Cluster Name En",
    clusterNameUa: "Cluster Name Ua",
    deleteConfirm: "Are you sure you want to delete ",
    fromActivity: "from Activity",
    add: "Add",
    search: "Search",
    file: "File",
    close: "Close",
    MALE: "Male",
    FEMALE: "Female",
  },
  dialogs: {
    deleteConfirmation: {
      title: 'Delete Confirmation',
      content: 'Are you sure you want delete an item?',
      yes: 'Ok',
      no: 'Cancel'
    }
  }
};

import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { FetchError } from "../utils/FetchError";

const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const ACTIVITY_ENDPOINT = "/ul-activities";
export const COMMUNITY_ENDPOINT = "/communities";
export const FORMAT_ENDPOINT = "/formats";
export const CATEGORY_GROUP_ENDPOINT = "/category_groups";
export const CATEGORY_ENDPOINT = "/categories";
export const CHARACTER_ENDPOINT = "/activity_characters";
export const CHARACTERISTIC_ENDPOINT = "/characteristics";
export const ORGANIZATION_ENDPOINT = "/organizations";

export class DataFetcher {
  API_HOST = "";
  API_ROOT = "";
  PUBLIC_API_ROOT = "";
  token = localStorage.getItem("TOKEN") ?? "";

  constructor() {
    this.API_HOST = process.env.REACT_APP_API_HOST ?? "";
    this.API_ROOT = `${this.API_HOST}/api`;
    this.PUBLIC_API_ROOT = `${this.API_HOST}/public_api`;
  }

  post(endpoint, params=null) {
    return this.request(endpoint, METHODS.POST, params);
  }

  put(endpoint, params=null) {
    return this.request(endpoint, METHODS.PUT, params);
  }

  request(endpoint, method = METHODS.GET, params = null, isPrivate = true) {
    const root = isPrivate ? this.API_ROOT : this.PUBLIC_API_ROOT;
    const headers = {};
    if (isPrivate) {
      headers.Authorization = this.token;
    }

    let body;
    if (params instanceof FormData) {
      body = params;
    } else if (params instanceof Object) {
      body = JSON.stringify(params);
      headers["Content-Type"] = "application/json";
    }

    return fetch(`${root}${endpoint}`, {
      method,
      body,
      headers,
    }).then(async (res) => {
      let data = {};
      const contentDisposition = res.headers.get("content-disposition") ?? "";
      const contentType = res.headers.get("content-type");
      if (contentDisposition.indexOf("attachment") === 0) {
        data = await res.blob();
      } else if (contentType === "application/json") {
        data = await res.json();
      }
      if (res.ok) {
        return {
          data,
          res,
        };
      } else {
        return Promise.reject({
          status: res.status,
          data,
          res,
        });
      }
    });
  }

  async signin(username, password, rememberMe) {
    try {
      const { res } = await this.request("/authenticate", METHODS.POST, {
        username,
        password,
        remember_me: rememberMe,
      });
      this.token = res.headers.get("Authorization");
      localStorage.setItem("TOKEN", this.token);
      return { token: this.token };
    } catch ({ data }) {
      throw new FetchError({
        errorMessage: data.authenticationException,
      });
    }
  }

  async signout() {
    await this.request("/logout", METHODS.DELETE);
    this.token = "";
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("welcomeMsgShown");
  }

  async refreshToken() {
    try {
      const { res } = await this.request("/authenticate", METHODS.POST, {
        username: "",
        password: "",
      });
      this.token = res.headers.get("Authorization");
      localStorage.setItem("TOKEN", this.token);
      return { token: this.token };
    } catch ({ data }) {
      throw new FetchError({
        errorMessage: data.authenticationException,
      });
    }
  }

  updateSettings(params) {
    return this.request("/settings", METHODS.PUT, params);
  }

  async getAccount() {
    try {
      const { data } = await this.request("/account");
      if (!data?.id) {
        throw new FetchError();
      }
      return data;
    } catch (ex) {
      throw new FetchError();
    }
  }

  async getPreregistrationActivityData(id) {
    try {
      const { data } = await this.request(
        `/enrollments/${id}`,
        METHODS.GET,
        null,
        false
      );
      return camelcaseKeys(data, { deep: true });
    } catch (ex) {
      throw new FetchError();
    }
  }

  async checkPreregistrationParticipant(name, phone) {
    try {
      const { data } = await this.request(
        "/enrollments/past_records_exists",
        METHODS.PUT,
        {
          name,
          cell_phone: phone,
        },
        false
      );
      return data?.records_found ?? false;
    } catch (ex) {
      throw new FetchError();
    }
  }

  async registerParticipantToActivity(id, params) {
    try {
      const { data } = await this.request(
        `/enrollments/${id}`,
        METHODS.POST,
        snakecaseKeys(params),
        false
      );
      if (data?.success) {
        return;
      }
      throw new FetchError();
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async downloadParticipantsTemplate() {
    try {
      const { res, data } = await this.request(
        `${ACTIVITY_ENDPOINT}/participants_template`
      );
      let match = res.headers
        .get("content-disposition")
        .match(/filename="(.*)";/);
      return { name: match[1] ?? "participants-template", data };
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async uploadParticipants(activityId, file) {
    const formData = new FormData();
    formData.set("participants_file", file);

    try {
      const { data } = await this.request(
        `${ACTIVITY_ENDPOINT}/${activityId}/participants_import`,
        METHODS.POST,
        formData
      );
      return camelcaseKeys(data, { deep: true });
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async getNMActivities(from, to) {
    const params = {};
    if (from) {
      params.dateFrom = from;
    }
    if (to) {
      params.dateTo = to;
    }

    try {
      const { data } = await this.request(
        `/network_map/activities?${new URLSearchParams(params)}`,
        METHODS.GET
      );
      return data;
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async getNMActivity(id) {
    try {
      const { data } = await this.request(
        `/network_map/activities/${id}`,
        METHODS.GET
      );
      return data;
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async getNMOrganization(id) {
    try {
      const { data } = await this.request(
        `/network_map/organizations/${id}`,
        METHODS.GET
      );
      return data;
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async getNMActivityToActivityEdge(fromId, toId) {
    try {
      const { data } = await this.request(
        `/network_map/activities/${fromId}/edge_to_activity/${toId}`,
        METHODS.GET
      );
      return data;
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async getNMActivityToOrganizationEdge(activityId, organizationId) {
    try {
      const { data } = await this.request(
        `/network_map/activities/${activityId}/edge_to_organization/${organizationId}`,
        METHODS.GET
      );
      return data;
    } catch (ex) {
      throw new FetchError(ex);
    }
  }

  async getNMOrganizationToOrganizationEdge(fromId, toId) {
    try {
      const { data } = await this.request(
        `/network_map/organizations/${fromId}/edge_to/${toId}`,
        METHODS.GET
      );
      return data;
    } catch (ex) {
      throw new FetchError(ex);
    }
  }
}

import { config } from "../../../style/config";

export const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '100%',
    padding: '30px 3%',
    backgroundColor: '#f7f9fb',
    '&.modal':{
      position: 'initial',
      transform: 'initial',
      margin: '2% auto',
      fontFamily: 'Roboto, sans-serif',
    },
    '& h2': {
      fontSize: '30px !important',
      fontWeight: '900 !important',
      '&.title': {
        textAlign: 'center'
      }
    },
    '& h3': {
      color: config.colors.secondary,
      fontSize: '30px !important',
      fontWeight: '300 !important',
      margin: '30px 0 20px !important',
      letterSpacing: 1.6,
    },
    '& h4': {
      color: config.colors.secondary,
      fontSize: '20px !important',
      fontWeight: '300 !important',
      margin: '7px 10px 0 0 !important',
      letterSpacing: 1,
      lineHeight: '26px',
    },
    '& p': {
      color: '#2A276B',
      fontSize: '12px',
      fontWeight: '400',
      margin: '15px 0 0 !important',
      lineHeight: '14px',
      '& a': {
        color: '#2A276B',
        fontSize: '12px',
        fontWeight: '500',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
    },
    '& .dates': {
      marginBottom: 25,
      '& h3': {
        fontSize: '19px !important',
        margin: '0 10px 0 0 !important'
      },
      '& h4': {
        display: 'inline-block',
        margin: '0 10px 0 0 !important'
      },
      '& p': {
        display: 'inline-block',
      },
    },
    '& .key-list': {
      '& p': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '4px 40px !important'
      }
    },
    '& .table-wrapper': {
      '& button': {
        margin: '20px 0 30px'
      },
      '& .add-initiative': {
        width: '9.38vw',
        [theme.breakpoints.down(1400)]: {
          width: '14vw',
        },
      },
      '& table': {
        backgroundColor: '#f7f9fb',
        borderRadius: '6px',
        overflow: 'hidden',
        fontSize: '12px',
        color: '#191970',
        border: 'none',
        borderSpacing: '0',
        borderCollapse: 'collapse',
        width: '100%',
        '& thead tr': {
          backgroundColor: '#f7f9fb !important',
          '& th': {
            padding: '10px 15px',
            letterSpacing: '1px',
            textAlign: 'center',
            '&:nth-child(3)': {
              width: '45%',
              [theme.breakpoints.down(1400)]: {
                width: '35%',
              },
            },
          },
        },
        '& th': {
          fontSize: '24px',
          fontWeight: '100',
        },
        '& tbody tr': {
          '& td': {
            padding: '20px 15px',
            verticalAlign: 'top',
            '&:nth-child(4)': {
              textAlign: 'center',
            },
            '&:nth-child(5)': {
              textAlign: 'center',
            },
            '&:nth-child(6)': {
              textAlign: 'center',
            },
          }
        },
        '& tr:nth-child(odd)': {
          backgroundColor: '#fff'
        },
        '& .light': {
          color: '#778899'
        },
        '& .actions': {
          '& a': {
            '& i': {
              marginRight: 0
            }
          }
        }
      }
    },
    '& .buttons': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '19.7vw'

    },
    '& .button': {
      color: "#fff",
      backgroundColor: "#c80f0f",
      border: "1px solid #c80f0f",
      boxShadow: 'none',
      textTransform: 'none',
      width: '6.15vw',
      height: '47px',
      fontSize: '.75rem',
      fontWeight: '700',
      borderRadius: '10px',
      padding: '.5rem 1rem',
      '&:hover': {
        backgroundColor: "#c80f0f",
      },
      '& svg': {
        marginRight: '4px'
      }
    },

    '& .button-edit': {
      '& i': {
        display: 'inline-block',
        width: '16px',
        height: '16px',
        marginRight: '7px',
        background: 'url(/images/icons/icon-action-edit.svg) no-repeat 0 0 / 16px 16px'
      },
    },
    '& .button-delete': {
    },
    '& .button-return': {
    },
    '& .button-pdf': {
      backgroundColor: '#958DAE',
      borderColor: '#958DAE',
      borderRadius: '6px',
      display: 'inline-block',
      padding: '3px 8px 0',
      height: '40px',
      width: '40px',
      '& i': {
        display: 'inline-block',
        width: '23px',
        height: '30px',
        marginRight: '0',
        background: 'url(/images/icons/icon-pdf.svg) no-repeat 0 0 / 23px 30px'
      },
      '&:hover': {
        backgroundColor: '#958DAE !important'
      },
    },
  },
  'mt-10':{
    marginTop: -10,
  },
  paper: {
    overflowX: 'auto',
  },
  table: {
    maxWidth: '100%',
    minWidth: '100%',
    textAlign: 'center',
    '& tbody tr': {
      verticalAlign: 'top'
    },
    '& tbody td': {
      paddingTop: '10px'
    }
  },
  block:{
    '& p':{
      letterSpacing: '1px',
    }
  },
  mb32:{
    marginBottom: 32,
  },
  alignRight: {
    textAlign: 'right'
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  },
  actions: {
    '& a': {
      padding: '12px 15px !important',
      width: '3.18vw !important',
      [theme.breakpoints.down(1400)]: {
        padding: '6px 12px !important',
      },
    }
  }
});

// export default styles;

import React from 'react';
import {
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  InputAdornment,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import classNames from 'classnames';
import { selectStyles } from '../../style/select-styles';
import { 
  MuiPickersUtilsProvider, 
  DatePicker,
  TimePicker
} from 'material-ui-pickers';
import DayjsUtils from '@date-io/dayjs';
import NumberFormat from 'react-number-format';

const isNumber = (val) => {
  return val === +val;
};

const GetValueAndLabel = (item, lang) => {
  let label = "";
  if (lang === "Eng") {
    if (item.en) {
      label = item.en;
    } else if (item.titleEn) {
      label = item.titleEn;
    } else if (item.nameEn) {
      label = item.nameEn;
    } else {
      label = item.title;
    }
  } else {
    if (item.ua) {
      label = item.ua;
    } else if (item.titleUa) {
      label = item.titleUa;
    } else if (item.nameUa) {
      label = item.nameUa;
    } else {
      label = item.title;
    }
  }

  label = label || item.name // Temporary workaround for organization sizes.

  return {
    value: item.id,
    label,
    ...item,
  };
};

export const FormFileInput = (props) => {
  const {
    fieldName,
    i18n,
    label,
    isEditable,
    message,
    classes,
    formState,
    action,
  } = props;
  const labelFinal = label ? label : i18n[fieldName];

  // const disabledClassNames = classNames("text-field", props.classes.disabled);

  let file = formState[fieldName];
  let fileName = file?.name;
  let fileSize = undefined;
  if (file) {
    fileSize = `(${(Number(file.size) / 1024 / 1024).toFixed(2)} MB)`;
  }

  return isEditable ? (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div className={classes.inputFile}>
          <InputLabel>{labelFinal}</InputLabel>
          <input
            type="file"
            id={`${fieldName}Input`}
            name={fieldName}
            onChange={(e) => action({ fieldName, value: e.target.files[0] })}
          />
          <label htmlFor={`${fieldName}Input`}>{message}</label>
          <span>{fileName}</span>
          <span>{fileSize && fileSize}</span>
          <p>max. 10 MB</p>
          {meta.error && (
            <Typography variant="caption" className={classes.error}>
              {meta.error}
            </Typography>
          )}
        </div>
      )}
    </Field>
  ) : (
    <Typography>
      <h4>{labelFinal}</h4>
      <p>{formState[fieldName]}</p>
    </Typography>
  );
};

export const FormDateField = (props) => {
  const {
    fieldName,
    i18n,
    label,
    isEditable,
    value,
    action,
    isDisabled,
    classes,
    formState,
    tabIndex,
  } = props;
  const labelFinal = label ? label : i18n[fieldName];

  // const disabledClassNames = classNames("text-field", props.classes.disabled);
  const currentValue = value ? value : formState ? formState[fieldName] : {};

  return isEditable ? (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <>
          <InputLabel>{labelFinal}</InputLabel>
          <div className={classNames(classes.calendar, classes.dateBlock)}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <DatePicker
                className={classNames(classes.dateField, classes.textField, {
                  [classes.errorField]: meta.invalid,
                })}
                format="DD.MM.YYYY"
                {...input}
                onChange={(e) => {
                  action({ fieldName, value: e });
                }}
                value={currentValue}
                disabled={isDisabled}
                InputProps={{
                  tabIndex,
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.dataIcon}>
                        <img
                          src="/images/icons/calendar.svg"
                          alt="calendar"
                          className="date-icon"
                        />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </>
      )}
    </Field>
  ) : (
    <div>
      <InputLabel>{labelFinal}</InputLabel>
      <Typography varian="body2">{currentValue}</Typography>
    </div>
  );
};

export const FormTimeField = (props) => {
  const {
    fieldName,
    i18n,
    label,
    isEditable,
    value,
    action,
    isDisabled,
    classes,
    formState
  } = props;

  const labelFinal = label || i18n[fieldName];
  const currentValue = value || formState[fieldName] || null;

  return isEditable ? (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <>
          <InputLabel>{labelFinal}</InputLabel>
          <div className={classNames(classes.calendar, classes.dateBlock)}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <TimePicker
                className={classNames(classes.dateField, classes.textField, {
                  [classes.errorField]: meta.invalid,
                })}
                ampm={false}
                clearable
                minutesStep={5}
                {...input}
                onChange={(e) => {
                  action({ fieldName, value: new Date(e.$y, e.$M, e.$D, e.$H, e.$m, 0, 0)});
                }}
                value={currentValue}
                disabled={isDisabled}
              />
            </MuiPickersUtilsProvider>
          </div>
        </>
      )}
    </Field>
  ) : (
    <div>
      <InputLabel>{labelFinal}</InputLabel>
      <Typography varian="body2">{currentValue}</Typography>
    </div>
  );
}

export const FormNumberField = (props) => {
  const {
    fieldName,
    i18n,
    label,
    isEditable,
    value,
    action,
    isDisabled,
    formState,
    classes,
    hideLabel,
    placeholder,
    onChange,
    tabIndex,
    min
  } = props;
  const labelFinal = label ? label : i18n[fieldName];
  // const disabledClassNames = classNames("text-field", props.classes.disabled);

  // value or formstate[fieldName] - but never undefined!
  const currentValue = value ? value : formState ? formState[fieldName] ?? '' : '';

  let currentOnChange =
    onChange ??
    ((e) => {
      action({ fieldName, value: e.currentTarget.value }, true);
    });

  return isEditable ? (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          {!hideLabel && <InputLabel>{labelFinal}</InputLabel>}
          <TextField
            type="number"
            className={classNames(
              classes.textField,
              { [classes.errorField]: meta.invalid },
              { disabled: isDisabled }
            )}
            variant="outlined"
            // helperText={meta.touched && meta.error ? meta.error : ' '}
            disabled={isDisabled}
            value={currentValue}
            onChange={currentOnChange}
            placeholder={placeholder}
            inputProps={{ tabIndex, min }}
          />
        </div>
      )}
    </Field>
  ) : (
    <ReadOnlyField label={labelFinal} value={currentValue} />
  );
};

export const FormTextField = (props) => {
  const {
    fieldName,
    i18n,
    label,
    isEditable,
    value,
    action,
    isDisabled,
    formState,
    classes,
    hideLabel,
    placeholder,
    onChange,
    tabIndex,
  } = props;
  const labelFinal = label ? label : i18n[fieldName];
  // const disabledClassNames = classNames("text-field", props.classes.disabled);

  // value or formstate[fieldName] - but never undefined!
  const currentValue = value ? value : formState ? formState[fieldName] ?? '' : '';

  let currentOnChange =
    onChange ??
    ((e) => {
      action({ fieldName, value: e.currentTarget.value }, true);
    });

  return isEditable ? (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          {!hideLabel && <InputLabel>{labelFinal}</InputLabel>}
          <TextField
            className={classNames(
              classes.textField,
              { [classes.errorField]: meta.invalid },
              { disabled: isDisabled }
            )}
            variant="outlined"
            // helperText={meta.touched && meta.error ? meta.error : ' '}
            disabled={isDisabled}
            value={currentValue}
            onChange={currentOnChange}
            placeholder={placeholder}
            inputProps={{ tabIndex }}
          />
        </div>
      )}
    </Field>
  ) : (
    <ReadOnlyField label={labelFinal} value={currentValue} />
  );
};

export const FormLabel = (props) => {
  const { fieldName, i18n, label } = props;
  const labelFinal = label ? label : i18n[fieldName];
  return (
    <InputLabel className={props.className}>
      {labelFinal}
      {props.withColon ? ':' : ''}
    </InputLabel>
  );
};

export const ReadOnlyField = (props) => {
  return (
    <div>
      <InputLabel>{props.label}</InputLabel>
      {!props.value || props.value === '' ? (
        <Typography varian="body2">
          <br />
        </Typography>
      ) : (
        <Typography varian="body2">{props.value}</Typography>
      )}
    </div>
  );
};

export const FormPhoneField = (props) => FormTextField(props);

export const FormTextAreaField = (props) => {
  const {
    fieldName,
    i18n,
    label,
    isEditable,
    value,
    action,
    formState,
    classes,
    hideLabel,
    tabIndex,
  } = props;
  const labelFinal = label ? label : i18n[fieldName];
  const currentValue = (value ? value : formState ? formState[fieldName] : '') ?? '';

  return isEditable ? (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          {!hideLabel && <InputLabel>{labelFinal}</InputLabel>}
          <TextField
            // label={labelFinal}
            className={classNames(classes.textField, {
              [classes.errorField]: meta.invalid,
            })}
            variant="outlined"
            multiline
            rows="1"
            rowsMax="20"
            inputProps={{ maxLength: '4000', tabIndex }}
            {...input}
            value={currentValue}
            onChange={(e) => {
              action({ fieldName, value: e.currentTarget.value }, true);
            }}
          />
        </div>
      )}
    </Field>
  ) : (
    <div>
      <InputLabel>{labelFinal}</InputLabel>
      <Typography varian="body2">{currentValue}</Typography>
    </div>
  );
};

export const FormCheckboxField = (props) => {
  const {
    classes,
    fieldName,
    i18n,
    label,
    isEditable,
    value,
    formState,
    action,
    isDisabled,
    tabIndex,
  } = props;

  const labelFinal = label ? label : i18n[fieldName];
  const currentValue = value ? value : formState ? formState[fieldName] ?? false : false;

  return (
    <Field name={fieldName} type="checkbox">
      {({ input, meta }) => (
        <FormControlLabel
          label={labelFinal}
          className={props.labelClassName ?? classes.checkboxLabel}
          control={
            <Checkbox
              {...input}
              inputProps={{ tabIndex }}
              name={fieldName}
              disabled={isDisabled || !isEditable}
              checked={currentValue}
              onChange={(e) => {
                action({ fieldName, value: e.target.checked });
              }}
            />
          }
        />
      )}
    </Field>
  );
};

export const FormSelectField = (props) => {
  const {
    fieldName,
    classes,
    i18n,
    label,
    isEditable,
    action,
    isDisabled,
    selectList,
    value,
    formState,
    lang,
    tabIndex,
    formatGroupLabel,
    grouping,
    getValueAndLabel,
    isClearable,
    validate
  } = props;
  const labelFinal = label ? label : i18n[fieldName];

  let list = selectList ? selectList : formState ? formState[`${fieldName}List`] : [];

  if (list) {
    if (typeof getValueAndLabel === 'function') {
      list = list.map((option) => getValueAndLabel(option));
    } else {
      list = list.map((option) => GetValueAndLabel(option, lang));
    }
  } else {
    list = [];
  }

  const selectedValue = value ? value : formState ? formState[fieldName] : {};

  let selection;
  if (grouping) {
    for (let group of list) {
      for (let option of group.options) {
        if (Number(option.value) === Number(selectedValue)) {
          selection = option;
          break;
        }
      }

      if (selection) {
        break;
      }
    }
  } else {
    selection = list.find((x) => {
      const value = selectedValue?.id ? selectedValue.id : selectedValue;

      if (x.id === value) {
        return true;
      }
      if (isNumber(x.value) === value) {
        return true;
      }
      if (x?.value === value) {
        return true;
      }

      return false;
    });
  }

  return isEditable ? (
    <Field 
      name={fieldName}
      validate={validate}
    >
      {({ input, meta }) => (
        <>
          <InputLabel className={classNames(classes.label)}>
            {label ? label : i18n[fieldName]}
          </InputLabel>
          <Select
            inputProps={{ tabIndex }}
            className={classNames({
              errorSelect: meta.invalid,
              [classes.groupSelect]: grouping,
            })}
            styles={selectStyles}
            closeMenuOnSelect={true}
            components={makeAnimated()}
            {...input}
            formatGroupLabel={formatGroupLabel}
            options={list}
            helperText={meta.touched && meta.error ? meta.error : ""}
            value={selection}
            isDisabled={isDisabled}
            onChange={(e) => {
              action({ fieldName, value: e?.value });
            }}
            isClearable={isClearable}
          />
        </>
      )}
    </Field>
  ) : (
    <div>
      <InputLabel>{labelFinal}</InputLabel>
      <Typography varian="body2">{selection?.label}</Typography>
    </div>
  );
};

export const FormMultiSelectField = (props) => {
  const {
    lang,
    fieldName,
    classes,
    i18n,
    label,
    isEditable,
    action,
    selectList,
    value,
    formState,
    hideLabel,
    tabIndex,
    grouping = false,
    validate
  } = props;
  const labelFinal = label ? label : i18n[fieldName];

  let list = selectList ? selectList : formState ? formState[`${fieldName}List`] : [];

  if (list) {
    list = list.map((x) => GetValueAndLabel(x, lang));
  } else {
    list = [];
  }

  const curentValues = value ? value : formState ? formState[fieldName] : [];
  let selection;
  if (curentValues) {
    //selectedValue = selectedValue.map((x) => GetValueAndLabel(x, lang));
    selection = list
      .filter((x) => curentValues.find((y) => y.id === x.id))
      .map((x) => GetValueAndLabel(x, lang));
  } else {
    selection = [];
  }

  if (grouping) {
    selection = curentValues;
  }

  return isEditable ? (
    <Field name={fieldName} validate={validate}>
      {({ input, meta }) => (
        <>
          {!hideLabel && (
            <InputLabel className={classNames(classes.label)}>
              {label ? label : i18n[fieldName]}
            </InputLabel>
          )}
          <Select
            inputProps={{ tabIndex }}
            isClearable={false}
            className={classNames({
              errorSelect: meta.invalid,
            })}
            styles={selectStyles}
            closeMenuOnSelect={false}
            components={makeAnimated()}
            {...input}
            onChange={(e) => {
              action({ fieldName, value: e });
            }}
            isMulti
            options={list}
            value={selection}
          />
        </>
      )}
    </Field>
  ) : (
    <div>
      <InputLabel>{labelFinal}</InputLabel>
      <Typography varian="body2">
        {selection?.map((x) => x.label).join(", ")}
      </Typography>
    </div>
  );
};

export const FormRadioField = (props) => {
  const { fieldName, classes, i18n, label, isEditable, action, value, formState, tabIndex } = props;
  const labelFinal = label ? label : i18n[fieldName];

  const selectedValue = value ? value : formState ? formState[fieldName] ?? '' : '';

  return isEditable ? (
    <Field name={fieldName}>
      {({ input, meta }) => {
        return (
          <RadioGroup
            inputprops={{ tabIndex }}
            row
            aria-label={labelFinal}
            name={fieldName}
            className={classNames(classes.group, {
              [classes.groupError]: meta.invalid,
            })}
            value={selectedValue}
            onChange={(e) => {
              action({ fieldName, value: e.target.value });
            }}
          >
            <FormControlLabel
              value="MALE"
              control={<Radio color="primary" />}
              label="Male"
              labelPlacement="end"
            />
            <FormControlLabel
              value="FEMALE"
              control={<Radio color="primary" />}
              label="Female"
              labelPlacement="end"
            />
            <FormControlLabel
              value="n/a"
              control={<Radio color="primary" />}
              label="N/A"
              labelPlacement="end"
            />
          </RadioGroup>
        );
      }}
    </Field>
  ) : (
    <div>
      <InputLabel>{labelFinal}</InputLabel>
      <Typography varian="body2">{selectedValue}</Typography>
    </div>
  );
};

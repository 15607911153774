import React from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  InputLabel,
  IconButton
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import { Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { I18n } from "../../i18n/";
import classNames from "classnames";
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { DataFetcher } from '../../utils/DataFetcher';
import camelcaseKeys from 'camelcase-keys';

const styles = (theme) => ({
  modal: {
    width: 'auto !important'
  },
  alignRight: {
    textAlign: 'right'
  },
  measurementsTitle: {
    color: '#6f6f6f'
  }
});

const moveMeasurements = (item) => {
  if (!item) {
    return item;
  }

  const measurements = [];

  for (let i = 1; i < 5; i++) {
    let precision = item[`measurementPrecision${i}`];
    let description = item[`measurementDescription${i}`];

    if (precision && description) {
      measurements.push({
        [`measurementPrecision${i}`]: precision,
        [`measurementDescription${i}`]: description,
      });
    }
  }

  return {
    ...item,
    measurements
  }
}

class ItemEdit extends React.Component {
  state = {
    open: true
  };

  constructor(props) {
    super(props);

    this.state.item = moveMeasurements(this.props.item);

    this.fetcher = new DataFetcher();
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to='/indicators' />;
    }
  };

  send = (values) => {
    const { item } = this.state;

    if (Array.isArray(values.measurements)) {
      for (let i = 1; i < 5; i++) {
        values[`measurementPrecision${i}`] = null;
        values[`measurementDescription${i}`] = null;
      }

      values.measurements.forEach(m => {
        values = {...values, ...m};
      });
    }

    if (item) {
      return this.fetcher.put(`/indicators/${item.id}`, values)
        .catch((res) => res);
    }

    return this.fetcher.post('/indicators', values)
      .catch((res) => res);
  }

  handleSubmit = async (values) => {
    const response = await this.send(values);

    if (response.status === 400) {
      return camelcaseKeys(
        Object.fromEntries(
          response.data.error.params.map((param) => [param, true])
        )
      );
    }

    this.handleClose();

    this.props.handleItemSave();
  };

  render() {
    const { classes, lang } = this.props;
    const { item } = this.state;
    const t = I18n[lang].indicators;

    return (
      <>
        {this.renderRedirect()}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classNames('modal', classes.modal)}>
            <a href className="close" onClick={this.handleClose}>
              <Close />
            </a>
            <Typography
              gutterBottom
              variant="h2"
              component="h2"
            >
              {item ? t.edit : t.create}
            </Typography>
              <Form
                onSubmit={this.handleSubmit}
                mutators={{...arrayMutators}}
                initialValues={item ? item : {}}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name='code'>
                      {({ input, meta }) => (
                        <div>
                          <InputLabel>{t.code}</InputLabel>
                          <TextField
                            className={classNames(
                              'text-field',
                              { 'error-field': meta.invalid }
                            )}
                            variant='outlined' 
                            {...input} 
                          />
                        </div>
                      )}
                    </Field>

                    <Field name='output'>
                      {({ input, meta }) => (
                        <div>
                          <InputLabel>{t.output}</InputLabel>
                          <TextField 
                            className={classNames(
                              'text-field',
                              { 'error-field': meta.invalid }
                            )}
                            variant='outlined' 
                            {...input} 
                          />
                        </div>
                      )}
                    </Field>

                    <Field name='jhiIndicator'>
                      {({ input, meta }) => (
                        <div>
                          <InputLabel>{t.indicatorText}</InputLabel>
                          <TextField
                            variant='outlined'
                            multiline
                            rows='4'
                            rowsMax='20'
                            {...input}
                            className={classNames(
                              'text-area',
                              { 'error-field': meta.invalid }
                            )}
                          />
                        </div>
                      )}
                    </Field>

                    <Field name='responsiblePerson'>
                      {({ input, meta }) => (
                        <div>
                          <InputLabel>{t.responsiblePerson}</InputLabel>
                          <TextField 
                            className={classNames('text-field')}
                            variant='outlined' 
                            {...input} 
                          />
                        </div>
                      )}
                    </Field>
                    
                    <Typography 
                      className={classes.measurementsTitle} 
                      gutterBottom 
                      variant='h4' 
                      component='h4'
                    >{t.measurements}</Typography>

                    <FieldArray name='measurements'>
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <Grid container spacing={16} key={name}>
                              <Grid item xs={12} sm={3}>
                                <Field name={`${name}.measurementPrecision${index + 1}`}>
                                  {({ input, meta }) => (
                                    <TextField 
                                      className={classNames('text-field')}
                                      variant='outlined' 
                                      {...input} 
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} sm={9}>
                                <Field name={`${name}.measurementDescription${index + 1}`}>
                                  {({ input, meta }) => (
                                    <TextField 
                                      className={classNames('text-field')}
                                      variant='outlined' 
                                      {...input} 
                                    />
                                  )}
                                </Field>
                              </Grid>
                            </Grid>
                          ))}
                           
                          { fields.length < 4 && 
                             <IconButton 
                               onClick={() => fields.push({})}
                               aria-label='add a measurement'
                               size='small'
                             >
                               <AddIcon />
                             </IconButton>
                          }
                        </>
                      )}
                    </FieldArray>

                    <Grid
                      container
                      spacing={24}
                      alignItems="center"
                      className={classes.buttons}
                    >
                      <Grid item xs={12} className={classes.alignRight}>
                        <Button
                          variant="contained"
                          className="button-cancel"
                          onClick={this.handleClose}
                        >
                          <i></i>
                          {I18n[lang].common.cancel}
                        </Button>
                        <Button
                          variant="contained"
                          className="button-submit"
                          type="submit"
                          color="primary"
                        >
                          <i></i>
                          {I18n[lang].common.save}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
          </div>
        </Modal>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(ItemEdit));

import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog as BaseDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import DataSource from 'components/DataSource';
import { useApi } from 'support/api';
import snakecaseKeys from 'snakecase-keys';
import { Form } from 'react-final-form';
import Field from './Field';

function Dialog(props) {
  const { open, onClose, fields, participation } = props;
  const { id: activityId } = useParams();
  const api = useApi();
  let submit;

  async function handleSubmit(values) {
    const { id, ...rest } = values;
    const payload = snakecaseKeys(rest);

    await api.activities(activityId).participations(id).supportResults.put(payload);

    onClose();
  }

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Result of support</DialogTitle>
      <DialogContent>
        <DataSource path={`activities/${activityId}/participations/${participation.id}/support_results`}>
          {(supportResults) => (
            <Form
              onSubmit={handleSubmit}
              initialValues={{ id: participation.id, ...supportResults }}
            >
              {({
                handleSubmit, 
                pristine,
                submitting 
              }) => { 
                submit = handleSubmit;
                return (
                  <form onSubmit={handleSubmit}>
                    {fields.map((field, index) => (
                      <Field
                        key={index}
                        { ...field }
                      />
                    ))}
                  </form>
                );
              }}
            </Form>
          )}
        </DataSource>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button 
          variant="outlined"
          color="primary"
          type="submit" 
          onClick={(event) => submit(event)}
        >
          Save
        </Button>
      </DialogActions>
    </BaseDialog>
  );
}

export default Dialog;

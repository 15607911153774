import AppBar from 'components/AppBar';
import BackButton from 'components/buttons/BackButton';

function AppBarWithBackButton(props) {
  const { children } = props;

  return (
    <AppBar>
      <BackButton />
      {children}
    </AppBar>
  );
}

export default AppBarWithBackButton;

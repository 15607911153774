import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  root: {
    display: 'flex',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    width: '100%'
  },
  aside: {
    display: 'flex',
    flexDirection: 'column'
  },
  image: {
  },
  placeholder: {
    marginTop: '-20px',
    width: '200px'
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  content: {
    paddingLeft: theme.spacing.unit * 2,
  },
  details: {
    color: '#778899'
  }
});

function Activity(props) {
  const { classes, attributes } = props;
  const { 
    id, 
    name, 
    desc, 
    startDate, 
    endDate, 
    formatName, 
    regionName, 
    participantsCount, 
    regions,
    activityFotoPreviewUrl
  } = attributes; 

  const location = Array.isArray(regions) && regions.length 
    ? regions.map((r) => r.name).join(', ') 
    : regionName;

  return (
    <div className={classes.root}>
      <div className={classes.aside}>
        <Link 
          to={`/legacy/activities/view/${id}`}
          component={RouterLink}
        >
          <img
            className={classes.placeholder}
            src={activityFotoPreviewUrl ?? "/images/search-placeholder.png"}
            alt={name}
          />
        </Link>
        <div className={classes.actions}>
          <IconButton 
            to={`legacy/activities/edit/${id}`}
            component={RouterLink} 
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton 
            to={`activities/:id/delete`}
            component={Link} 
            color="primary"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div className={classes.content}>
        <Typography variant="h4" gutterBottom>
          <Link 
            to={`legacy/activities/view/${id}`} 
            component={RouterLink}
          >
            {name}
          </Link>
        </Typography>
        <Typography variant="body1" className={classes.details}>
          {startDate} - {endDate} | {location} | {formatName} | {participantsCount}
        </Typography>
        <Typography variant="body1">
          {desc}
        </Typography>
      </div>
    </div>
  );
}

export default withStyles(styles)(Activity);

import { Field } from 'react-final-form';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';

function ShowQuestionsField(props) {
  const { name, questions } = props;

  return (
    <FormControl fullWidth>
      <InputLabel>Show questions when chosen</InputLabel>
      <Field 
        name={name}
        multiple
      >
        {({ input }) => 
          <Select
            {...input}
            value={Array.isArray(input.value) ? input.value : []}
          >
            {questions.map((question) => 
              <MenuItem 
                key={question.id} 
                value={question.id}
              >
                {question.name}
              </MenuItem>
            )}
          </Select>
        }
      </Field>
    </FormControl>
  );
}

export default ShowQuestionsField;

export const config = {
  fontFamily: "Roboto, san-serif",
  yellow: "#FDBB2D",
  red: "#FE7153",
  input: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#000000",
    background: "#ececed",
    borderRadius: "5px",
    letterSpacing: "0.5px",
    padding: "5.5px 7px",
  },
  readOnly: {
    fontSize: "16px",
    color: "#191970",
  },
  label: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#6f6f6f",
    background: "#e1edfb",
    letterSpacing: "0.7px",
  },
  error: {
    color: "#fe8368 !important",
  },

  breakpoints: {
    small: 1440,
  },

  small: {
    label: {
      fontSize: "13px",
      fontWeight: 300,
    },
    readOnly: {
      fontSize: "12px",
    },
  },

  colors: {
    primary: "#c80f0f",
    primaryBackground: "#ffffff",
    secondary: "#6f6f6f",
    secondaryBackground: "#000000",
  },
};

export const EdgeTypes = {
  ActivityToActivity: "activity-to-activity",
  ActivityToOrganization: "activity-to-organization",
  OgranizationToOrganization: "ogranization-to-ogranization",
};

export class Edge {
  constructor({ fromId, toId, type, positions }) {
    this.fromId = fromId;
    this.toId = toId;
    this.type = type;
    this.positions = positions;
  }

  get mapId() {
    return `${this.type}-${this.fromId}-${this.toId}`;
  }

  get isActivityToActivity() {
    return this.type === EdgeTypes.ActivityToActivity;
  }

  get isActivityToOrganization() {
    return this.type === EdgeTypes.ActivityToOrganization;
  }

  get isOrganizationToOrganization() {
    return this.type === EdgeTypes.OgranizationToOrganization;
  }
}

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from 'support/api';
import { useNotifications } from 'components/notifications';
import Image from './components/Image';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircle';

const styles = {
  images: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  upload: {
    width: '120px',
    height: '120px',
    cursor: 'pointer',
    position: 'relative'
  },
  icon: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  fileInput: {
    opacity: 0
  }
};

function PartnerLogos(props) {
  const { classes } = props;
  const [images, setImages] = useState(props.images);
  const { id } = useParams();
  const api = useApi();
  const notifications = useNotifications();

  async function upload(event) {
    const files = event.target.files;

    if (!files.length) {
      return;
    }

    const payload = new FormData();

    for (const file of event.target.files) {
      payload.append('images[]', file);
    }

    const response = await api.surveys(id).partner_logos.create(payload);
    const survey = await response.json();

    setImages(survey.partnerLogos);

    notifications.success('Uploaded');
  }

  async function remove(index) {
    const response = await api.surveys(id).partner_logos(index).delete();
    const survey = await response.json();

    setImages(survey.partnerLogos);

    notifications.success('Deleted');
  }

  return (
    <div className={classes.images}>
      {images.map((src, index) => ( 
        <Image 
          key={index} 
          src={src} 
          alt={`Partner logo ${index}`} 
          onDelete={() => remove(index)} 
        />
      ))}
      <label className={classes.upload}>
        <AddIcon className={classes.icon} color="primary" fontSize="large" />
        <input 
          className={classes.fileInput}
          type="file"
          multiple
          accept="image/png, image/jpeg"
          onChange={upload}
        />
      </label>
    </div>
  );
}

export default withStyles(styles)(PartnerLogos);

import dayjs from 'dayjs';

const dayNames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

function daysFor(month) {
  const start = month.startOf('month').startOf('isoWeek');
  const end = month.endOf('month').endOf('isoWeek');
  const days = [];
  let current = start.clone();

  while (!current.isAfter(end)) {
    days.push(current);
    current = current.add(1, 'day');
  }

  return days;
}

function monthFromRange(startDate, endDate) {
  startDate = dayjs(startDate || undefined);
  endDate = dayjs(endDate || undefined);

  return startDate.add(endDate.diff(startDate, 'days') / 2, 'days');
}

function extendedMonth(month) {
  const start = month.startOf('month').startOf('isoWeek');
  const end = month.endOf('month').endOf('isoWeek');

  return [start, end];
}

export { 
  dayNames, 
  daysFor,
  monthFromRange,
  extendedMonth
};

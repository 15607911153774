import React from "react";
import BaseGraph from "../base-graph";
import withRoot from "../../../withRoot";

const options = {
  responsive: true,
  tooltips: {
    mode: "label",
    callbacks: {
      label: function (tooltipItem, data) {
        const i = tooltipItem.index;
        let label = data.labels[i];
        let value = data.datasets[0].data[i];
        return `${label}: ${value}%`;
      },
    },
  },
  legend: {
    display: true,
  },
};

class ActivityTargetGroupsChart extends React.Component {
  datasetOptions = [
    {
      data: [51, 65, 40, 49, 60, 37, 40],
      hoverBackgroundColor: [],
      backgroundColor: [],
    },
  ];

  dynamicColor = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return [r, g, b];
  };

  componentWillMount() {
    this.datasetOptions[0].hoverBackgroundColor = [];
    this.datasetOptions[0].backgroundColor = [];

    for (let i = 0; i < 50; i++) {
      let color = this.dynamicColor();
      color = `${color[0]},${color[1]},${color[2]}`;
      this.datasetOptions[0].hoverBackgroundColor.push(`rgba(${color},0.6)`);
      this.datasetOptions[0].backgroundColor.push(`rgba(${color},1)`);
    }
  }

  render() {
    return (
      <BaseGraph
        type="pie"
        tableTitle="Activities by target groups"
        url="/graph/activity_target_groups"
        chartOptions={options}
        datasetOptions={this.datasetOptions}
        filter={this.props.filter}
      />
    );
  }
}

export default withRoot(ActivityTargetGroupsChart);

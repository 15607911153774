import ItemService from "./ItemService";
import {ORGANIZATION_ENDPOINT} from "../utils/DataFetcher";
import {organizationsRoute} from "../utils/routes";

class OrganizationService extends ItemService {

    constructor(onUpdateState, lang) {
        super(ORGANIZATION_ENDPOINT, onUpdateState, lang);
    }

    NavigateAfterSave(state, response) {
        state = {...state};

        if (!state.messages) {
            state.messages = [];
        }

        state.id
            ? state.messages.push(
            this.GetRedirect(`${organizationsRoute}/view/${state.id}`)
            )
            : state.messages.push(this.GetRedirect(organizationsRoute));

        return state;
    }

    GetInitialState(userRoles) {
        let state = super.GetInitialState(userRoles);

        state = {
            initialLocation: [50.4501, 30.5234],
            zoom: 12,
            ...state,
        };

        return state;
    }

    MapStateToModel(state) {
        let data = super.MapStateToModelMultiPart(state);

        const params = this.GetApiPutParams();

        for (let p of params) {
            if (p.name === "locationCoordinates" && state.location) {
                // api saves lat lng twisted
                data.append(
                    "locationCoordinates",
                    `POINT(${state.location[1]} ${state.location[0]})`
                );
            } else {
                this.MapProperty(data, state, p);
            }
        }

        return data;
    }

    ValidateItem(state) {
        let result = super.ValidateItem(state);

        this.validationRequired(result, state, "nameEn");

        this.validationRequired(result, state, "address");

        this.validationSelectionRequired(result, state, "regionId");
        this.validationSelectionRequired(result, state, "employerTypeId");
        this.validationSelectionRequired(result, state, "economicActivityTypeId");
        // this.validationSelectionRequired(result, state, "sizeId");

        return result;
    }

    GetApiPutParams() {
        return [
            {
                in: "formData",
                name: "address",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "employerTypeId",
                type: "integer",
                format: "int32",
                required: false,
            },
            {
                in: "formData",
                name: "nameEn",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "regionId",
                type: "integer",
                format: "int32",
                required: false,
            },
            {
                in: "formData",
                name: "economicActivityTypeId",
                type: "int32",
                required: false,
            },
            {
                in: "formData",
                name: "sizeId",
                type: "integer",
                format: "int32",
                required: false,
            },
            {
                in: "formData",
                name: "email",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "phone",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "url",
                type: "string",
                required: false,
            },
            {
              in: "formData",
              name: "relocated",
              type: "boolean",
              required: false,
            },
            {
              in: 'formData',
              name: 'relocatedFromRegionId',
              type: 'int32',
              required: false
            },
            {
              in: 'formData',
              name: 'initialNumberOfIdps',
              type: 'int32',
              required: 'false'
            },
            {
              in: 'formData',
              name: 'numberOfEmployees',
              type: 'int32',
              required: 'false'
            },
            {
                in: "formData",
                name: "locationCoordinates",
                type: "string",
                required: false,
            },
        ];
    }

    UpdateStateFromItem(state, item) {
        state = super.UpdateStateFromItem(state, item);

        // api returns and keeps lat lng twisted
        state = this.SetLocation(state, {
            latLng: item.locationCoordinates
                ? [
                    item.locationCoordinates.coordinates[1],
                    item.locationCoordinates.coordinates[0],
                ]
                : undefined,
        });

        state = {
            ...state,
        };

        return state;
    }

    async Action(e, state) {
        // base class is not called and contains only default implementation
        // could be improved ...

        switch (e.fieldName) {
            // case "nameEn":
            //   state = this.SetName(state, e.value);
            //   break;
            // case "nameUa":
            //   state = this.SetName(state, e.value);
            //   break;
            case "locationCoordinates": // employerLocationCoordinates
                state = this.SetLocation(state, e.value);
                break;
            case "employerLevelId":
                state = this.SetEmployerLevel(state, e.value);
                break;
            // case "disconnectItemFromActivity":
            //   state = this.SetDisconnectItemFromActivity(state, e.value);
            //   break;
            // case "rejectDisconnect":
            //   state = this.SetRejectDisconnect(state);
            //   break;
            // case "confirmDisconnect":
            //   state = await this.SetConfirmDisconnect(state, e.value);
            //   break;
            // case "checkParticipant":
            //   state = await this.CheckParticipant(state);
            //   break;
            // case "acceptMatchDialog":
            //   state = await this.AcceptMatch(state);
            //   break;
            // case "cancelMatchDialog":
            //   state = this.CancelMatch(state);
            //   break;
            case 'relocated':
                state = this.SetRelocated(state, e.value);
                break;
            default:
                // never modify state, always clone
                state = {...state};
                state[e.fieldName] = e.value;
                break;
        }

        state = this.SetStateChanged(state);

        return state;
    }

    // SetName(state, value) {
    //   let translit = cyrillicToTranslit({ preset: "uk" }).transform(value);
    //   translit = map(translit.split(" "), capitalize).join(" ");

    //   let result = { ...state, name: value, nameTranscription: translit };

    //   return result;
    // }
    // ────────────────────────────────────────────────────────────────────────────────
    // Actions
    SetEmployerLevel(state, value) {
        const isRegionDisabled = value === 2 || value === 1;
        let result = {...state};
        const naRegion = {id: 30, value: 30, label: "N/A"};

        if (isRegionDisabled === true && state.isRegionDisabled === false) {
            if (state.regionsList) {
                const regionsList = [...state.regionsList];
                regionsList.unshift(naRegion);

                result = {...result, regionsList, regionId: naRegion.id};
            }
        } else if (isRegionDisabled === false && state.isRegionDisabled === true) {
            let regionsList = [...state.regionsList];
            regionsList = regionsList.filter((x) => x.id !== naRegion.id);

            result = {...result, regionsList, regionId: undefined};
        }

        result = {...result, employerLevelId: value, isRegionDisabled};
        return result;
    }

    SetLocation(state, value) {
        let location, locationCoordinates;

        if (value?.latLng?.length === 2) {
            location = value.latLng;
            locationCoordinates = `${value.latLng[0]} ${value.latLng[1]}`;
        } else {
            location = state.initialLocation;
            locationCoordinates = "";
        }

        let result = {...state, location, locationCoordinates};

        if (value.addressParts) {
            var cityParts = [];
            var cityTypes = [
                "locality",
                "administrative_area_level_2",
                "administrative_area_level_1",
            ];
            value.addressParts.forEach((part) => {
                if (cityTypes.indexOf(part.types[0]) < 0) {
                    return;
                }
                cityParts.push(part.long_name);
            });

            result = {
                ...result,
                address: result.address || value.address,
                // location: cityParts.join(", "),
            };
        }

        return result;
    }

    SetRelocated(state, value) {
      return {
        ...state,
        relocated: value,
        relocatedFromRegionId: null
      };
    }
}

export default OrganizationService;

import React from "react";
import { observer } from "mobx-react";
import {
  Grid,
  Button,
  TablePagination,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  Phone,
  Mail,
  Build,
  LocationCity,
  Domain,
  BarChart,
  LocationOn,
  Label,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import downloadFile from "../../utils/fetchFile";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { isEmpty, each } from "lodash";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import "url-search-params-polyfill";
import history from "../../utils/history";
import { WithContext as ReactTags } from "react-tag-input";
import { I18n } from "../../i18n/";
import "react-datepicker/dist/react-datepicker.css";
import CustomSearch from "../../components/search";
import style from "./style_xs";
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";
import { selectStyles } from "../../style/select-styles";
import { StoreContext } from "../../stores/context";
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';

const styles = (theme) => style(theme);

const filterCategories = {
  regions: ["regions", "regionIds"],
  formats: ["formats", "formatIds"],
  target_groups: ["targetGroups", "targetGroupIds"],
  categories: ["categories", "categoryIds"],
  category_results: ["categoryResults", "categoryResultIds"],
  organizers: ["organizers", "organizerIds"],
  trainers: ["trainers", "trainerIds"],
  projects: ["projects", "projectIds"],
  participant_regions: ["regionParticipant", "participantRegionIds"],
  employer_types: ["employerType", "employerTypeIds"],
  employer_levels: ["employerLevel", "employerLevelIds"],
  genders: ["gender", "genders"],
  economic_activity_types: ["economicActivityType", "economicActivityTypeIds"],
  west_cooperation: ["westCooperation", "westCooperation"],
  sizes: ["size", "sizeIds"],
  cso_grants: ["csoGrants", "csoGrantIds"]
};

class SearchXS extends React.Component {
  static contextType = StoreContext;

  state = {
    currentUser: null,
    loading: true,
    items: [],
    filters: {},
    page: 0,
    total: 0,
    rowsPerPage: 25,
    searchQuery: "",
    activitiesQuery: "",
    order: "desc",
    orderBy: "activitiesStartDate,desc",
    currentPage: "activities",
    regions: { limit: 5, checkedAll: false, uncheckedIds: [] },
    formats: { limit: 5, checkedAll: false, uncheckedIds: [] },
    target_groups: { limit: 5, checkedAll: false, uncheckedIds: [] },
    categories: { limit: 5, checkedAll: false, uncheckedIds: [] },
    category_results: { limit: 5, checkedAll: false, uncheckedIds: [] },
    organizers: { limit: 5, checkedAll: false, uncheckedIds: [] },
    participants: { limit: 5, checkedAll: false, uncheckedIds: [] },
    trainers: { limit: 5, checkedAll: false, uncheckedIds: [] },
    projects: { limit: 5, checkedAll: false, uncheckedIds: [] },
    participant_regions: { limit: 5, checkedAll: false, uncheckedIds: [] },
    employer_types: { limit: 5, checkedAll: false, uncheckedIds: [] },
    employer_levels: { limit: 5, checkedAll: false, uncheckedIds: [] },
    economic_activity_types: { limit: 5, checkedAll: false, uncheckedIds: [] },
    genders: { limit: 5, checkedAll: false, uncheckedIds: [] },
    west_cooperation: { limit: 5, checkedAll: false },
    sizes: { limit: 5, checkedAll: false, uncheckedIds: [] },
    cso_grants: { limit: 5, checkedAll: false, uncheckedIds: [] },
    phonesCount: 0,
    namesCount: 0,
    tags: [],
    selectedFilters: "",
    startDate: "",
    endDate: "",
    sortingBy: {},
    filteringGetParams: "?",
    params: {
      regionIds: [],
      formatIds: [],
      targetGroupIds: [],
      categoryIds: [],
      categoryResultIds: [],
      organizerIds: [],
      activityCharacterIds: [],
      fundingSourceIds: [],
      trainerIds: [],
      projectIds: [],
      participantRegionIds: [],
      employerTypeIds: [],
      employerLevelIds: [],
      genders: [],
      economicActivityTypeIds: [],
      westCooperation: [],
      csoGrantIds: []
    },
  };

  componentDidMount() {
    this.checkAuth();
    const query = new URLSearchParams(document.location.search);
    const search = query.get("query");

    if (search) {
      this.setState({ searchQuery: search }, () => {
        this.getFilteredData("", [], true);
      });
    } else {
      const {
        searchQuery,
        selectedTags,
        selectedFilters,
        oesQuery,
      } = this.props;
      if (isEmpty(searchQuery) && isEmpty(selectedTags)) {
        this.getData();
      } else {
        const newState = {
          searchQuery,
          selectedFilters,
          activitiesQuery: oesQuery,
          tags: selectedTags,
        };
        const date = (selectedTags || []).find((item) => item.id === "date");
        if (date) {
          newState.startDate = date.startDate;
          newState.endDate = date.endDate;
        }
        this.setState(newState, () => {
          this.getFilteredData(selectedFilters, selectedTags, true);
        });
      }
    }
  }

  async getData() {
    const query = this.props.searchQuery || this.state.searchQuery;
    const queryParam = isEmpty(query) ? "" : "?query=" + query;
    const data = await fetchData("get", `/xs${queryParam}`);
    const filters = await fetchData("get", `/xs/filters${queryParam}`);

    this.setState({
      loading: false,
      items: data[0] ?? [],
      total: data[1] ?? [],
      phonesCount: data[2].phonesCount,
      namesCount: data[2].namesCount,
      filters: filters[0],
    });
  }

  getParams(selectedFilters, selectedTags) {
    const query = this.state.searchQuery || this.props.searchQuery || "";

    let getParams = "?";

    if (query !== "") {
      getParams += `query=${query}&`;
    }
    if (this.state.orderBy !== "") {
      getParams += `sort[]=${this.state.orderBy}&`;
    }
    if (selectedFilters !== "") {
      getParams += selectedFilters;
    }
    const activitiesQueryTag = (selectedTags || []).find(
      (x) => x.id === "activitiesQuery"
    );
    if (activitiesQueryTag) {
      getParams += `activities_query=${activitiesQueryTag.value}&`;
    }

    getParams += this.formatParams(this.state.params);

    return getParams;
  }

  async getFilteredData(selectedFilters, selectedTags, withCounters) {
    let getParams = this.getParams(selectedFilters, selectedTags);

    const data = await fetchData("get", `/xs${getParams}`);

    const newState = {
      loading: false,
      items: data[0] ?? [],
      total: data[1] ?? 0,
      phonesCount: data[2].phonesCount,
      namesCount: data[2].namesCount,
    };

    // UPDATE STATS on filters column
    if (withCounters) {
      const filters = await fetchData("get", `/xs/filters${getParams}`);
      let filtersFinal = filters[0];
      const tagsWithoutCounters = [
        "date",
        "participants",
        "activitiesQuery",
        "participantsQuery",
      ];

      selectedTags.forEach((tag) => {
        if (tagsWithoutCounters.indexOf(tag.id) >= 0) {
          return;
        }

        const filterOptions = filtersFinal[tag.category];
        if (tag.filter && filterOptions) {
          tag.filter.count = 0;
          let matchedItem = filterOptions.find((filter) => {
            return filter.id === tag.filter.id;
          });
          if (!matchedItem) {
            filterOptions.unshift(tag.filter);
          }
        } else {
          // case for ALL selected
        }
      });
      newState.filters = filtersFinal;
    }

    this.setState(newState);
    this.scrollToTop();
  }

  getFiltersFromCheckboxes(collection) {
    let tag = [];
    // let param = [];
    let [camelCasedName, inputName] = filterCategories[collection];
    const inputNameModified = `${inputName}[]`;
    const collectionState = this.state[collection];
    const { langStore } = this.context;
    const { lang } = langStore;
    const params = this.state.params;

    params[inputName] = [];

    if (collectionState.checkedAll) {
      let titles = [];
      const titleColumn = langStore.isEng ? "title_en" : "title_ua";
      let allTitle = `${langStore.isEng ? "All" : "Всi"} ${
        I18n[lang].search[camelCasedName]
      }`;
      params[inputName].push("0");

      // ! As far as unchecked inputs could be not rendered, we should to look at uncheckedIds for negatives
      collectionState.uncheckedIds.forEach((id) => {
        const item = this.state.filters[collection].find((obj) => obj.id === id);
        titles.push(item[titleColumn] || item["title_en"] || item["title_ua"]);

        params[inputName].push(id);
      });
      const title = allTitle + (titles.length ? " - " + titles.join(", ") : "");

      tag.push({ id: title, text: title, category: collection, filter: null });
    } else {
      // ! Assume that all checked inputs have been rendered
      const query = document.querySelectorAll(
        '[name="' + inputNameModified + '"]:checked'
      );

      query.forEach((item) => {
        const title = item.title;
        const filterSource = this.state.filters[collection].find((obj) => {
          return obj.title_en === title;
        });
        const method = `modify${capitalize(camelCasedName)}Tag`;
        let tagObj = {
            id: title,
            text: title,
            category: collection,
            filter: filterSource,
        };

        if (typeof this[method] === 'function') {
          tagObj = this[method](tagObj);
        } 

        tag.push(tagObj);

        params[inputName].push(item.dataset.id);
      });

      this.setState({ params });
    }

    return tag;
  }

  modifyWestCooperationTag(obj) {
    const { lang } = this.context.langStore;
    obj.text = I18n[lang].search.westCooperation + ': ' + I18n[lang].common[obj.text.toLowerCase()];

    return obj;
  }

  formatParams = (params) => {
    const queryParams = [];
    for (let key in params) {
      const values = params[key];
      for (let val of values) {
        queryParams.push(`${key}[]=${val}`);
      }
    }

    return queryParams.join("&");
  };

  handleFilterChange = (e) => {
    let beforeOpts = { loading: true };
    if (e) {
      // Handle Unchecks fo
      const checkedCollection = e.target.dataset.collection;
      const collectionState = this.state[checkedCollection];
      if (checkedCollection && collectionState && collectionState.checkedAll) {
        const value = parseInt(e.target.value) || e.target.value;
        const valuePos = collectionState.uncheckedIds.indexOf(value);
        if (e.target.checked) {
          collectionState.uncheckedIds.splice(valuePos, 1);
        } else if (valuePos < 0) {
          collectionState.uncheckedIds.push(value);
        }
        beforeOpts[checkedCollection] = collectionState;
      }
    }
    this.setState(beforeOpts);
    let selectedFilters = "";
    let selectedTags = [];

    each(filterCategories, (opts, collection) => {
      // let [tempA, tempB] = this.getFiltersFromCheckboxes(collection);
      // selectedFilters += tempA;
      const tag = this.getFiltersFromCheckboxes(collection);

      selectedTags = selectedTags.concat(tag);
    });

    let { startDate, endDate } = this.state;

    startDate = startDate && dayjs(startDate).format("YYYY-MM-DD");
    endDate = endDate && dayjs(endDate).format("YYYY-MM-DD");

    if (startDate) {
      selectedFilters += `startDate=${startDate}&`;
    }
    if (endDate) {
      selectedFilters += `endDate=${endDate}&`;
    }

    if (startDate || endDate) {
      selectedTags.push({
        id: "date",
        text: `Date range: ${startDate} - ${endDate}`,
        startDate: startDate,
        endDate: endDate,
      });
    }

    const { searchQuery, activitiesQuery } = this.state;
    if (!isEmpty(activitiesQuery)) {
      selectedTags.push(this.activitiesQueryTag(activitiesQuery));
    }
    if (!isEmpty(searchQuery)) {
      selectedTags.push(this.participantsQueryTag(searchQuery));
    }

    this.props.onSearchChange(
      selectedTags,
      selectedFilters,
      activitiesQuery,
      searchQuery
    );
    this.setState({ tags: selectedTags, selectedFilters: selectedFilters });
    this.getFilteredData(selectedFilters, selectedTags, true);
  };

  handleChangePage = (e, page) => {
    let getParams = `page=${page + 1}&`;
    if (this.state.selectedFilters !== "") {
      getParams += this.state.selectedFilters;
    }
    this.getPage(getParams);
    this.setState({ loading: true, page: page });
  };

  async getPage(getParams) {
    this.getFilteredData(getParams, this.state.selectedTags, false);
    this.scrollToTop();
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value });
  };

  handleDownload = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.getDownloadableResults();
  };

  getDownloadableResults = async () => {
    await downloadFile(
      `/xs.xlsx${this.getParams(this.state.selectedFilters, this.state.tags)}`
    );
    this.setState({ loading: false });
  };

  updateInputValue = (e) =>
    this.setState({
      searchQuery: e.target.value,
      participantsQuery: e.target.value,
    });

  participantsQueryTag = (query) => {
    return {
      id: "participantsQuery",
      text: `${this.context.langStore.search.participants}: ${query}`,
      value: query,
    };
  };

  activitiesQueryTag = (query) => {
    return {
      id: "activitiesQuery",
      text: `${this.context.langStore.search.activities}: ${query}`,
      value: query,
    };
  };

  handleSearchQuerySubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    const { searchQuery, selectedFilters, activitiesQuery } = this.state;
    history.push({
      search: `?query=${searchQuery}`,
    });
    // timeout to wait for new searchQuery from updateInputValue
    const tags = this.state.tags.filter((t) => t.id !== "participantsQuery");
    tags.push(this.participantsQueryTag(searchQuery));
    this.getFilteredData(selectedFilters, tags, true);
    this.props.onSearchChange(
      tags,
      selectedFilters,
      activitiesQuery,
      searchQuery
    );
  };

  handleSort = (orderBy) => {
    let order = "desc";
    if (this.state.orderBy === orderBy.value && this.state.order === "desc") {
      order = "asc";
    }
    this.setState(
      {
        loading: true,
        orderBy: orderBy.value,
        order: order,
        sortingBy: orderBy,
      },
      () => {
        this.getFilteredData(this.state.selectedFilters, this.state.tags);
      }
    );
  };

  handlePageChange = (currentPage) => (e) => {
    this.setState({ currentPage: currentPage });
  };

  changeLimit = (collection, count) => {
    const collectionState = this.state[collection];
    collectionState.limit = count;
    this.setState({ [collection]: collectionState });
  };

  handleViewAll = (e) => {
    e.preventDefault();
    this.changeLimit(e.target.dataset.collection, 100000);
  };

  handleCollapse = (e) => {
    e.preventDefault();
    this.changeLimit(e.target.dataset.collection, 5);
  };

  handleSelectAllOptions = (e) => {
    e.preventDefault();
    const collection = e.target.dataset.collection;
    const collectionState = this.state[collection];
    collectionState.checkedAll = true;
    collectionState.uncheckedIds = [];
    this.setState({ [collection]: collectionState }, () => {
      this.handleFilterChange();
    });
  };

  handleClearAllTags = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const collectionStates = {};
    each(filterCategories, (options, collection) => {
      const collectionState = this.state[collection];
      if (!collectionState.checkedAll) {
        return;
      }
      collectionState.checkedAll = false;
      collectionState.uncheckedIds = [];
      collectionStates[collection] = collectionState;
    });
    // only hidden participants query should left
    collectionStates.tags = this.state.tags.filter(
      (t) => t.id === "participantsQuery"
    );
    collectionStates.activitiesQuery = "";
    collectionStates.startDate = "";
    collectionStates.endDate = "";

    collectionStates.params = {};
    each(
      this.state.params,
      (index, param) => (collectionStates.params[param] = [])
    );

    this.setState(collectionStates);

    document
      .querySelectorAll('[type="checkbox"]:checked')
      .forEach((checkbox) => {
        checkbox.removeAttribute("checked");
      });

    const {
      tags,
      selectedFilters,
      activitiesQuery,
      searchQuery,
    } = collectionStates;

    this.props.onSearchChange(
      tags,
      selectedFilters,
      activitiesQuery,
      searchQuery
    );

    this.getData();
  };

  onSignOutClick = () => {
    return async (e) => {
      e.preventDefault();
      await fetchData("delete", "/logout");
      delete localStorage.TOKEN;
      window.location.href = "/login";
    };
  };

  async checkAuth() {
    const [result] = await fetchData("get", "/account");
    if (!result || !result.id) {
      window.location.href = "/login";
    } else {
      let language = result.langKey === "en" ? "Eng" : "Ukr";
      this.setState({
        currentUser: result,
        sortingBy: {
          value: "startDate,desc",
          label: I18n[language].search.startDesc,
        },
      });
    }
  }

  handleDeleteTag = (i) => {
    const { tags } = this.state;
    const selectedTag = tags
      .filter((t) => t.id !== "participantsQuery")
      .filter((tag, index) => index === i)[0];
    const selectedId = selectedTag.id;
    switch (selectedId) {
      case "date":
        this.setState({ startDate: "", endDate: "" }, this.handleFilterChange);
        break;
      case "activitiesQuery":
        this.setState({ activitiesQuery: "" }, this.handleFilterChange);
        break;
      default:
        if (selectedTag.filter) {
          const checkbox = document.querySelector(
            '[title="' +
              selectedId +
              '"][data-collection="' +
              selectedTag.category +
              '"]'
          );
          if (checkbox) {
            checkbox.removeAttribute("checked");
            checkbox.parentNode.click();
          } else {
            const updatedTags = tags;
            updatedTags.splice(i, 1);
            this.setState({ tags: updatedTags });

            const toCamel = (s) => {
              return s.replace(/([-_][a-z])/gi, ($1) => {
                return $1.toUpperCase().replace("-", "").replace("_", "");
              });
            };

            let selectedFilters = "";
            updatedTags.forEach((item) => {
              let category = toCamel(item.category);
              selectedFilters += `${category}Ids[]=${item.filter.id}&`;
            });
            this.setState({ selectedFilters, loading: true });
            this.getFilteredData(selectedFilters, updatedTags, true);
          }
        } else {
          const collection = selectedTag.category;
          const collectionState = this.state[collection];
          collectionState.checkedAll = false;
          collectionState.uncheckedIds = [];
          this.setState({ [collection]: collectionState }, () => {
            this.handleFilterChange();
          });
        }
    }
  };

  clearSearchField = () => {
    this.setState({ searchQuery: "", participantsQuery: "", loading: false });
    history.push({
      search: ``,
    });
    this.getSearchResults();
  };

  async getSearchResults() {
    const data = await fetchData("get", `/xs`);
    const filters = await fetchData("get", `/xs/filters`);

    this.setState({
      loading: false,
      items: data[0] ?? [],
      total: data[1] ?? 0,
      phonesCount: data[2].phonesCount,
      namesCount: data[2].namesCount,
      filters: filters[0],
    });
  }

  renderFilter = (collection) => {
    const [camelCasedName, inputName] = filterCategories[collection];
    const collectionState = this.state[collection];
    const classes = this.props.classes;
    const { lang } = this.context.langStore;
    const counters = this.state.filters[collection];
    const checkedAll = collectionState.checkedAll;
    const checkedIds = checkedAll
      ? []
      : this.state.tags
          .filter((tag) => tag.category === collection && tag.filter)
          .map((tag) => tag.filter.id);
    const uncheckedIds = checkedAll ? collectionState.uncheckedIds : [];
    const limit = collectionState.limit;
    const isViewAll = limit === 100000;
    let shownCounters = counters;
    if (!isViewAll && counters) {
      shownCounters = counters.slice(0, limit);
      shownCounters = shownCounters.concat(
        counters
          .slice(limit, counters.length)
          .filter((filter) => checkedIds.indexOf(filter.id) >= 0)
      );
    }
    return (
      <div className={classes.filterBlock}>
        <h4>{I18n[lang].search[camelCasedName]}</h4>
        {counters &&
          shownCounters.map((counter) => {
            const checked = checkedIds.indexOf(counter.id) >= 0;
            const unchecked = uncheckedIds.indexOf(counter.id) >= 0;
            return (
              <div className={classes.filter} key={counter.id}>
                <FormControlLabel
                  className={classes.controlLabel}
                  control={
                    <Checkbox
                      name={inputName + "[]"}
                      value={counter.id}
                      checked={
                        (checkedAll && !unchecked) || (!checkedAll && checked)
                      }
                      inputProps={{
                        title: counter.title_en,
                        "data-collection": collection,
                        "data-id": counter.id,
                      }}
                      onChange={this.handleFilterChange}
                      icon={<div className="checkbox"></div>}
                      checkedIcon={
                        <div className="checkbox checkbox--checked"></div>
                      }
                      className={classes.checkbox}
                    />
                  }
                  label={
                    lang === "Eng"
                      ? counter.title_en
                      : counter.title_ua || counter.title_en
                  }
                />
                {counter.count !== 0 && (
                  <div className="count">{counter.count}</div>
                )}
              </div>
            );
          })}
        {counters && counters.length > 1 && (
          <div className={classes.viewAll}>
            {counters.length > 5 && (
              <a
                href
                data-collection={collection}
                className={classes.viewAllLink}
                onClick={isViewAll ? this.handleCollapse : this.handleViewAll}
              >
                {isViewAll
                  ? I18n[lang].search.collapse
                  : I18n[lang].search.viewAll}
              </a>
            )}
            <a
              href
              className={classes.selectAllLink}
              data-collection={collection}
              onClick={this.handleSelectAllOptions}
            >
              {I18n[lang].search.selectAll}
            </a>
          </div>
        )}
      </div>
    );
  };

  handleDate = (field) => {
    return (date) => {
      this.setState({ [field]: date }, () => {
        this.handleFilterChange();
      });
    };
  };

  scrollToTop = () => {
    if (window.scrollY !== 0) {
      setTimeout(() => {
        window.scrollTo(0, window.scrollY - 30);
        this.scrollToTop();
      }, 5);
    }
  };

  render() {
    const labelFunc = (date) => (date ? dayjs(date).format('DD.MM.YYYY') : '');

    const calendarStyle = {
      color: "#e6feff",
      width: 32,
    };
    const { classes } = this.props;
    const asOES = true;
    const {
      items,
      page,
      rowsPerPage,
      total,
      phonesCount,
      namesCount,
      tags,
      sortingBy,
    } = this.state;
    const { langStore } = this.context;

    const sortOptions = [
      {  value: "activitiesStartDate,desc", label: langStore.search.startDesc },
      { value: "activitiesStartDate,asc", label: langStore.search.startAsc },
      { value: "activitiesEndDate,desc", label: langStore.search.endDesc },
      { value: "activitiesEndDate,asc", label: langStore.search.endAsc },
    ];

    return (
      <div>
        {this.state.loading && <Loader />}
        <div className={classes.appBar}>
          {asOES && (
            <div className={(classes.filterColumn, classes.filterColumnTop)}>
              <Link to="/search/oes" className={classes.activitiesBtn}>
                <Button
                  variant={asOES ? "contained" : "outlined"}
                  className={classes.containedCustomActive}
                >
                  <img
                    src="/images/icons/calendar-active.svg"
                    alt="i"
                    width="40"
                  />
                  {langStore.search.activities}
                </Button>
              </Link>

              <Link to="/search/xs" className={classes.participantsBtn}>
                <Button
                  variant={asOES ? "contained" : "outlined"}
                  color="primary"
                  className={classes.outlinedCustom}
                >
                  <img
                    src="/images/icons/participants-white.svg"
                    alt="i"
                    width="23"
                  />
                  {langStore.search.participants}
                </Button>
              </Link>
            </div>
          )}
          <div className={classes.wrapRight}>
            <CustomSearch
              placeholder={langStore.search.search}
              props={this.props}
              value={this.state.searchQuery}
              updateInputValue={this.updateInputValue}
              submit={this.handleSearchQuerySubmit}
              state={this.state}
              clearSearchField={this.clearSearchField}
              // clearIcon={false}
            />
            <div className={classes.wrapBtn}>
              <Link
                to="#"
                className={classNames(classes.stats, "download")}
                onClick={this.getDownloadableResults}
              >
                Export Stats
                <i></i>
              </Link>

              <Link
                to="#"
                className={classNames(classes.calendar, "download")}
                onClick={this.handleDownload}
              >
                Activity calendar
                <i></i>
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.root}>
          <div className={classes.container}>
            <Paper className={classNames(classes.paper, classes.filterColumn)}>
              <div className={classes.filterBlock}>
                <h3>{langStore.search.activityFilter}</h3>

                <h4>{langStore.search.dateRange}</h4>
                <div className={classes.datesWrapper}>
                  <div
                    className={classNames(
                      classes.calendarPicker,
                      classes.dateBlock
                    )}
                  >
                    <div className={classNames(classes.dataIcon, "iconWrap")}>
                      <img
                        src="/images/icons/calendar.svg"
                        alt="calendar"
                        style={calendarStyle}
                        className={classNames(classes.dataIcon, "icon")}
                      />
                    </div>
                    <div className={classNames(classes.dataPick, "inputWrap")}>
                      <MuiPickersUtilsProvider utils={DayjsUtils}>
                        <InlineDatePicker
                          defaultValue={null}
                          className={classNames(
                            classes.textField,
                            classes.dateField,
                            "input"
                          )}
                          value={
                            this.state.startDate !== ""
                              ? new Date(this.state.startDate)
                              : null
                          }
                          onChange={this.handleDate("startDate")}
                          labelFunc={labelFunc}
                          format="yyyy/MM/dd"
                          hintText=""
                          errorText=""
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      classes.calendarPicker,
                      classes.dateBlock
                    )}
                  >
                    <div className={classNames(classes.dataIcon, "iconWrap")}>
                      <img
                        src="/images/icons/calendar.svg"
                        alt="calendar"
                        style={calendarStyle}
                        className={classNames(classes.dataIcon, "icon")}
                      />
                    </div>
                    <div className={classNames(classes.dataPick, "inputWrap")}>
                      <MuiPickersUtilsProvider utils={DayjsUtils}>
                        <InlineDatePicker
                          defaultValue={null}
                          className={classNames(
                            classes.textField,
                            classes.dateField,
                            "input"
                          )}
                          value={
                            this.state.endDate !== ""
                              ? new Date(this.state.endDate)
                              : null
                          }
                          onChange={this.handleDate("endDate")}
                          labelFunc={labelFunc}
                          format="yyyy/MM/dd"
                          hintText=""
                          errorText=""
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
              </div>
              {this.renderFilter("regions")}
              {this.renderFilter("formats")}
              {this.renderFilter("target_groups")}
              {this.renderFilter("categories")}
              {this.renderFilter("category_results")}
              {this.renderFilter("organizers")}
              {this.renderFilter("trainers")}
              {this.renderFilter("projects")}
              {this.renderFilter("west_cooperation")}
            </Paper>
            <Paper className={classNames(classes.paper, classes.filterColumn)}>
              <div className={classes.filterBlock}>
                <h3>{langStore.search.participantFilter}</h3>
              </div>

              {this.renderFilter(
                "participant_regions",
                "regionParticipant",
                "participantRegionIds"
              )}
              {this.renderFilter(
                "employer_types",
                "employerType",
                "employerTypeIds"
              )}
              {this.renderFilter(
                "employer_levels",
                "employerLevel",
                "employerLevelIds"
              )}
              {this.renderFilter("genders", "gender", "genders")}
              {this.renderFilter(
                "economic_activity_types",
                "economicActivityType",
                "economicActivityTypeIds"
              )}
              {this.renderFilter("sizes", "size", "sizeIds")}
              {this.renderFilter("cso_grants")}
            </Paper>

            <Paper className={classes.resultsColumn}>
              <Grid
                container
                spacing={24}
                alignItems="center"
                className={classes.topFilters}
              >
                <div className={classes.infoWrap}>
                  <div>
                    {total} {langStore.search.participantsFound}
                  </div>

                  <div>
                    {phonesCount} {langStore.search.distinctCellPhone}{" "}
                    {namesCount} {langStore.search.distinctNames}
                  </div>

                  <Select
                    styles={selectStyles}
                    className={classes.select}
                    closeMenuOnSelect={true}
                    components={makeAnimated()}
                    options={sortOptions}
                    onChange={this.handleSort}
                    value={sortingBy}
                  />
                </div>
                <Grid item xs={12} className={classes.tagsWrapper}>
                  <ReactTags
                    tags={tags.filter(
                      (t) =>
                        t.id !== "participantsQuery" &&
                        t.category !== "funding_sources"
                    )}
                    handleDelete={this.handleDeleteTag}
                    allowDragDrop={false}
                  />
                  <a
                    href
                    className={classes.clearAll}
                    onClick={this.handleClearAllTags}
                  >
                    {langStore.search.clearAllFilters}
                  </a>
                </Grid>
              </Grid>

              <div className={classes.results}>
                {items.map((item) => {
                  return (
                    <div className={classes.result}>
                      <Link
                        to={`/participants/view/${item.id}`}
                        target="_blank"
                      >
                        <h2 className={`${item.gender.toLowerCase()}`}>
                          {langStore.isEng && item.nameTranscription
                            ? item.nameTranscription
                            : item.name}
                        </h2>
                      </Link>
                      <p className={classes.linkItem}>
                        <a href={`tel:${item.cellPhone}`}>
                          <Phone />
                          {item.cellPhone}
                        </a>
                        &nbsp; &nbsp;
                        <a href={`mailto:${item.email}`}>
                          <Mail />
                          {item.email}
                        </a>
                      </p>
                      <p className={classes.listItem}>
                        <Build />
                        {item.position}
                      </p>
                      <p className={classes.listItem}>
                        <LocationCity />
                        {item.employerName}
                      </p>
                      <p className={classes.listItem}>
                        <Domain />
                        {langStore.isEng
                          ? item.employerTypeEn
                          : item.employerTypeUa}
                      </p>
                      <p className={classes.listItem}>
                        <BarChart />
                        {langStore.isEng
                          ? item.employerLevelEn
                          : item.employerLevelUa}
                      </p>
                      <p className={classes.listItem}>
                        <LocationOn />
                        {langStore.isEng ? item.oblastEn : item.oblastUa}
                        &nbsp; &nbsp;
                        <LocationOn />
                        {item.employerLocation}
                      </p>
                      <p className={classes.listItem}>
                        <LocationOn />
                        {item.oblastEn}
                        &nbsp; &nbsp;
                        <Label />
                        {langStore.isEng
                          ? item.employerTypeEn
                          : item.employerTypeUa}
                      </p>
                    </div>
                  );
                })}
                <TablePagination
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={this.handleChangePage}
                />
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(observer(SearchXS)));

import React from "react";
import withRoot from "../../../withRoot";
import BaseGraph from "../base-graph";

const datasetOptions = [
  {
    label: "Number of activities",
    data: [51, 65, 40, 49, 60, 37, 40],
    fill: false,
    borderColor: "#6495ed",
    backgroundColor: "#6495ed",
    pointBorderColor: "#6495ed",
    pointBackgroundColor: "#6495ed",
    pointHoverBackgroundColor: "#6495ed",
    pointHoverBorderColor: "#6495ed",
  },
];

const options = {
  responsive: true,
  tooltips: {
    mode: "label",
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
        },
        ticks: {
          precision: 0,
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        barThickness: 10,
      },
    ],
  },
};

class CapacityActivitiesChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type="horizontalBar"
        tableTitle="Number of activities (education and health) by oblast"
        url="/graph/capacity_activities"
        chartOptions={options}
        datasetOptions={datasetOptions}
        filter={this.props.filter}
      />
    );
  }
}

export default withRoot(CapacityActivitiesChart);

import React, { useContext } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import "url-search-params-polyfill";
import IndexTable from "../../components/table";
import { StoreContext } from "../../stores/context";
import {
  FormatService,
  CharacterService,
} from "../../services/SelectOptionService";
import SelectOption from "./item";
import { I18n } from "../../i18n";
import { formatRoute, characterRoute } from "../../utils/routes";

const SelectOptions = (props) => {
  const { path } = { ...props };

  // option uses common texts for translation
  const i18nProps = {
    i18n: props.i18n,
    i18nCommon: I18n[props.lang].common,
  };

  return (
    <IndexTable
      {...props}
      {...i18nProps}
      path={path}
      columns={[
        { id: "id", title: "ID" },
        { id: "langKey", title: "Key" },
        { id: "en", title: "Name" },
        {
          id: "active",
          title: "Active",
          displayFunc: (r) => (r.active ? "True" : "False"),
        },
      ]}
      onEditItem={(itemId, item, handleItemSave) => {
        return (
          <SelectOption
            serviceClass={props.serviceClass}
            {...i18nProps}
            item={item}
            itemId={itemId}
            indexPath={path}
            handleItemSave={handleItemSave}
            isEditable={true}
            {...props}
          />
        );
      }}
      onViewItem={(itemId, item) => {
        return (
          <SelectOption
            serviceClass={props.serviceClass}
            {...i18nProps}
            item={item}
            itemId={itemId}
            indexPath={path}
            isEditable={false}
            {...props}
          />
        );
      }}
    />
  );
};

SelectOptions.propTypes = {
  i18n: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  serviceClass: PropTypes.any.isRequired,
};

export default SelectOptions;

export const Formats = observer(() => {
  const { langStore } = useContext(StoreContext);
  return (
    <SelectOptions
      path={formatRoute.slice(1)}
      serviceClass={FormatService}
      lang={langStore.lang}
      i18n={langStore.format}
    />
  );
});

export const Characters = observer(() => {
  const { langStore } = useContext(StoreContext);
  return (
    <SelectOptions
      path={characterRoute.slice(1)}
      serviceClass={CharacterService}
      lang={langStore.lang}
      i18n={langStore.activityCharacter}
    />
  );
});

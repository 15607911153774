import { config } from "../../style/config";

const style = (theme) => {
  return {
    root: {
      textAlign: "left",
      width: "100%",
      padding: "30px 2.3vw",
      backgroundColor: "#f7f9fb",
      "& .react-datepicker-wrapper": {
        "& input": {
          background: "rgb(225, 237, 251)",
          borderRadius: "4px",
          fontSize: "12px",
          border: "none",
          width: "92%",
          padding: "17px 5px 18px",
        },
      },
    },
    containedCustom: {
      backgroundColor: config.colors.primary,
      color: "white",
      width: "130px",
      height: "44px",
      borderRadius: "6px",
      "&>span": {
        justifyContent: "start",
        fontSize: "14px",
      },
      "& img": {
        margin: "0 10px",
      },
      "&:hover": {
        backgroundColor: config.colors.primary,
      },
    },
    containedCustomActive: {
      borderRadius: "23px",
      border: "1px solid #6495ed",
      backgroundColor: "#f7f9fb",
      color: "#6495ed",
      width: "130px",
      height: "44px",
      "&>span": {
        justifyContent: "start",
        fontSize: "14px",
      },
      "& img": {
        marginRight: "10px",
      },
      "&:hover": {
        boxShadow: "0px 1px 5px #6495ed !important",
        border: "1px solid #6495ed !important",
        backgroundColor: "#f7f9fb !important",
      },
    },
    outlinedCustom: {
      backgroundColor: "#fdbb2d",
      border: "1px solid #fdbb2d",
      color: "white",
      width: "130px",
      height: "44px",
      borderRadius: "6px",

      "&>span": {
        fontSize: "14px",
      },
      "&:hover": {
        border: "1px solid #fdbb2d",
        backgroundColor: "#fdbb2d",
      },
      "& img": {
        marginRight: "6px",
      },
    },
    outlinedCustomActive: {
      border: `1px solid ${config.colors.primary}`,
      backgroundColor: "#fff",
      color: config.colors.primary,
      width: "130px",
      height: "44px",
      borderRadius: "6px",
      "&>span": {
        fontSize: "14px",
      },
      "& img": {
        marginRight: "6px",
      },
    },
    pagination: {
      marginTop: 20,
      color: "#BDBEC0",
      "& div:first-child": {
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
      },
    },
    searchActivity: {
      borderRadius: "0.5rem",
      fontWeight: "100",
      width: "100%",
      maxWidth: "640px",
      background: "#eef2f6",
      marginTop: "0",
      marginBottom: "0",

      "& >div": {
        padding: "0",
        "& div": {
          height: "100%",
          maxHeight: "100%",
          position: "absolute",
          right: "0",
          width: "170px",
        },
      },
      "& label": {
        fontSize: "2rem",
        top: "-12px",
        [theme.breakpoints.down(1700)]: {
          fontSize: "12pt",
          top: "-12px",
        },
      },
      "& fieldset": {
        border: "none",
      },
      "& input": {
        fontSize: "20pt",
        padding: "15px 14px",
        [theme.breakpoints.down(1700)]: {
          padding: "10px 14px",
          fontSize: "12pt",
        },
      },
    },
    deleteSearch: {
      "& a": {
        color: "#9daab7",
        "& svg": {
          width: "1.5em",
          height: "1.5em",
          marginRight: "5px",
          background: "url(/images/trash.svg) no-repeat center center",
          backgroundSize: "80% 80%",
          "& path": {
            fill: "transparent",
          },
          [theme.breakpoints.down(1700)]: {
            width: "1em",
            height: "1em",
          },
        },
      },
    },
    wrapBtn: {
      display: "flex",
    },
    wrapRight: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "flex-end",
      "& form": {
        width: "60%",
        textAlign: "left",
      },
    },
    stats: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      alignItems: "center",
      height: "44px",
      borderRadius: "6px",
      padding: "0 13px 0 5px",
    },
    calendar: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      padding: "0 13px 0 5px",
      alignItems: "center",
      height: "44px",
      borderRadius: "6px",
      marginRight: "0 !important",
    },
    btnSearch: {
      borderRadius: "0 0.5rem 0.5rem 0",
      background: config.colors.primary,
      padding: "5px 2.2vw",
      fontSize: "13px",
      color: "#ffffff",
      height: "100%",

      "& path": {
        fill: "transparent",
      },
      [theme.breakpoints.down(1700)]: {
        padding: "5px 10px",
      },
      "&:hover": {
        background: config.colors.primary,
      },
      "& svg": {
        width: "2em",
        height: "2em",
        background: "url(/images/loupe.svg) no-repeat center center",
        backgroundSize: "250% 250%",
        [theme.breakpoints.down(1700)]: {
          width: "1em",
          height: "1em",
        },
      },
    },
    alignRight: {
      textAlign: "right",
    },
    textField: {
      "&.search": {
        "&>label": {
          transform: "translate(14px, 14px) scale(1)",
        },
      },
    },
    "& .react-datepicker-wrapper": {
      "& input": {
        background: "rgb(225, 237, 251)",
        borderRadius: "4px",
        fontSize: "12px",
      },
    },
    dateField: {
      width: "47%",
      "& label": {
        fontSize: "12px",
        color: "rgb(119, 136, 153)",
        fontWeight: "500",
        lineHeight: "2.25",
        transform: "translate(0, -17px) scale(1)",
      },
      "& > div:before": {
        display: "none",
      },
      "& input": {
        background: "rgb(225, 237, 251)",
        borderRadius: "4px",
        fontSize: "12px",
      },
    },
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    topFilters: {
      margin: "0 0 20px",
      fontSize: "12px",
      fontWeight: "500",
      letterSpacing: "0.1em",
      color: "#000",
    },
    paper: {
      overflowX: "auto",
      padding: "10px 20px",
      boxShadow: "none",
    },
    buttons: {
      marginBottom: "30px",
      "& button": {
        marginRight: "20px",
      },
    },
    filterColumn: {
      flex: "0 0 300px",
      marginRight: "30px",
    },
    filterColumnTop: {
      flex: "0 0 300px",
      marginRight: "30px",
      justifyContent: "space-between",
      display: "flex",
      [theme.breakpoints.down(1700)]: {
        paddingLeft: "0",
      },
    },
    dFlex: {
      display: "flex",
    },
    dateBlock: {
      paddingTop: "5px",
      "& .text-field": {
        marginBottom: "0",
        position: "relative",
        top: "-45px",
        left: "44px",
        width: "90px",
      },
      "& div div": {
        "&::before": {
          display: "none",
        },
        "& input": {
          background: "#e1edfb",
          height: "34px",
          paddingLeft: "15px",
          border: "0 !important",
        },
      },
    },
    dataIcon: {
      background: config.colors.primary,
      width: 51,
      height: 45,
      borderRadius: 7,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "2",
      position: "relative",
    },
    dataPick: {
      width: "calc(100% - 230px)",
      "& input": {
        top: "-45px",
        left: "45px",
        width: "65px",
        height: "24px",
        position: "absolute",
      },
    },
    calendarPicker: {
      maxWidth: "0",
      maxHeight: "55px",
      "& .input": {
        zIndex: "1",
        position: "relative",
        left: "-5px",
        "& input": {
          color: "#191970",
          borderRadius: "6px",
          paddingRight: "5px",
        },
      },
    },
    resultsColumn: {
      flexGrow: "1",
      padding: "10px 20px",
      width: "calc(100vw - 300px)",
      boxShadow: "none",
      "& .ReactTags__tagInput": {
        display: "none",
      },
      "& .ReactTags__tag": {
        padding: "4px 5px",
        borderRadius: "4px",
        backgroundColor: "#778899",
        fontFamily: "Roboto",
        fontSize: "1rem",
        color: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginRight: "4px",
        minHeight: "31px",
        marginBottom: "5px",
      },
      "& .ReactTags__selected": {
        display: "flex",
        flexWrap: "wrap",
      },
      "& .ReactTags__remove": {
        cursor: "pointer",
        marginLeft: "10px",
        marginRight: "5px",
        minWidth: "1rem",
        height: "1rem",
        textAlign: "center",
        paddingLeft: "2px",
        color: "transparent",
        backgroundImage: 'url("/images/icons/cross.png")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
    },
    tagsWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "12px 12px 12px 0 !important",
    },
    filterBlock: {
      "& h3": {
        fontSize: "24px",
        fontWeight: "900",
        padding: "10px 0 15px",
        margin: "0",
        color: "rgb(173, 216, 230) !important",
        textTransform: "uppercase",
        lineHeight: "1",
        "& a": {
          fontSize: "12px",
        },
      },
      "& h4": {
        fontSize: "24px",
        fontWeight: "100",
        margin: "10px 0 0",
        color: config.colors.secondary,
      },
    },
    controlLabel: {
      alignItems: "flex-start",
      "& span": {
        paddingTop: "0",
        paddingBottom: "0",
        color: "#000",
        fontSize: "12px",
        letterSpacing: "0.1em",
      },
      "& svg path": {
        fill: "#ADD8EF",
        fillOpacity: 1,
      },
      "& + .count": {
        color: "#778899",
        fontSize: "12px",
        letterSpacing: "0.1em",
        fontWeight: "900",
      },
    },
    result: {
      display: "flex",
      marginBottom: "20px",
      "& .photo": {
        flex: "0 0 198px",
        marginRight: "30px",
      },
      "& .photo img": {
        maxWidth: "100%",
        borderRadius: "5px",
      },
      "& .photo .placeholder": {
        height: "100px",
        backgroundImage: 'url("/images/search-placeholder.png")',
        backgroundSize: "80%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      },
      "& .info": {
        flexGrow: "1",
      },
      "& .info a": {
        color: config.colors.secondary,
        fontSize: "30px",
        lineHeight: "1.2",
      },
      "& .info a:hover": {
        textDecoration: "underline",
      },
      "& .info > div": {
        color: "#778899",
        fontSize: "12px",
      },
      "& .info > p": {
        color: "#000",
        fontSize: "14px",
        marginTop: "5px",
      },
    },
    header: {
      display: "flex",
      flexDirection: "row",
    },
    participantsBtn: {
      "& button": {
        height: "42px",
      },
    },
    activitiesBtn: {
      "& button": {
        height: "42px",
      },
    },
    infoWrap: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      padding: "1px 14px 1px 0",
      alignItems: "center",
    },
    appBar: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      backgroundColor: "#f7f9fb",
      padding: "5px 2.3vw",
      height: `100px`,
      "& button": {
        marginRight: "0",
        padding: "0",
      },
      "& form": {},
      "& form button": {
        marginRight: "0px",
        padding: "0 5px",
        width: "100%",
      },
      "& form fieldset": {
        backgroundColor: "#add8e6",
      },
      "& form input": {
        zIndex: 0,
        background: "#eef2f6",
        borderRadius: "4px",
        [theme.breakpoints.down(1700)]: {
          height: "24px",
        },
      },
      "& .download": {
        margin: "0 8px",
        color: "#ffffff !important",
        backgroundColor: config.colors.primary,
        "& i": {
          display: "block",
          width: "45px",
          height: "45px",
          background: "url(/images/icons/icon-download.svg) no-repeat 0 0",
        },
        [theme.breakpoints.down(1440)]: {
          fontSize: "14px",
        },
      },
      "& .icon": {
        display: "block",
        height: "25px",
        width: "25px",
      },
      "& .icon-user": {
        background: "url(/images/icons/header-user.svg) no-repeat 0 0",
        width: "17px",
        marginLeft: "14px",
        [theme.breakpoints.down(1440)]: {
          marginLeft: "4px",
        },
      },
      "& .icon-register": {
        background: "url(/images/icons/header-register.svg) no-repeat 0 0",
      },
    },
    appMenu: {
      "&.menu-popup": {
        position: "absolute",
        width: "225px",
        backgroundColor: "#6495ed",
        top: "25px",
        paddingTop: "20px",
        left: "-20px",
        borderRadius: "5px",
        paddingBottom: "20px",
        display: "none",
      },
      "& .icon": {
        display: "block",
        height: "14px",
        width: "26px",
        color: "#fff",
        fontSize: "16px",
        margin: " 5px 0 0 7px",
        position: "absolute",
        left: "5px",
        top: "0",
      },
      "& p": {
        outline: "none",
      },
      "& > div:last-child": {
        backgroundColor: "#6495ed",
        border: "none",
        boxShadow: "none",
        marginTop: "80px",
      },
      "& a": {
        color: "#add8e6",
        fontWeight: "700",
        fontSize: "12px",
        padding: "5px 10px 0",
        paddingLeft: "40px",
        position: "relative",
        letterSpacing: "0.1em",
      },
      "&.user-menu": {
        paddingBottom: "5px",
        width: "130px",
        top: "40px",
        background:
          "linear-gradient(to top, #6495ed, #6495ed 130px, transparent 130px)",
        left: "-40px",
        [theme.breakpoints.down(1440)]: {
          left: "-70px",
          top: "50px",
        },
        "& a": {
          padding: "10px 10px 0 40px",
          "&:last-child": {
            marginBottom: "15px",
          },
        },
        "& .icon": {
          height: "100%",
          position: "absolute",
          left: "5px",
        },
      },
      "&.register-menu": {
        width: "153px",
        "& a": {
          padding: "5px 10px 0 40px",
          "&.link-hromadas": {
            alignItems: "flex-start",
            textAlign: "left",
            "& i": {
              width: "30px",
              marginRight: "7px",
            },
          },
        },
        "& .icon": {
          height: "100%",
          marginLeft: "4px",
        },
      },
    },

    appBarLink: {
      color: "#add8e6",
      textDecoration: "none",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontWeight: "700",
      fontSize: "0.9vw",
      [theme.breakpoints.down(1440)]: {
        fontSize: "10pt",
      },
      cursor: "pointer",
      fontFamily: "Roboto, sans-serif",
      position: "relative",
      "nav &": {
        [theme.breakpoints.down(1440)]: {
          "&:last-child": {
            marginRight: "0",
          },
        },
      },

      "&:hover, &.active": {
        color: "#ffffff",
        "& .menu-popup": {
          display: "block",
        },
        "& .icon-home": {
          background: "url(/images/icons/header-home.svg) no-repeat 0 0",
        },
        "& .icon-register": {
          background: "url(/images/icons/header-register.svg) no-repeat 0 0",
        },
        "& .icon-indicators": {
          background: "url(/images/icons/header-indicators.svg) no-repeat 0 0",
        },
        "& .icon-dashboard": {
          background: "url(/images/icons/header-dashboard.svg) no-repeat 0 0",
        },
        "& .icon-search": {
          background: "url(/images/icons/header-search.svg) no-repeat 0 0",
        },
        "& .icon-templates": {
          background: "url(/images/icons/header-templates.svg) no-repeat 0 0",
        },
        "& .icon-administration": {
          background:
            "url(/images/icons/header-administration.svg) no-repeat 0 0",
        },
        "& .icon-support": {
          background: "url(/images/icons/header-support.svg) no-repeat 0 0",
        },
      },
      "& svg": {
        height: "100%",
        width: "100%",
        maxHeight: "35px",
        maxWidth: "35px",
      },
      "& i": {
        marginRight: "10px",
        [theme.breakpoints.down(1440)]: {
          marginRight: "0",
        },
      },
      "&.active": {
        color: "#ffffff",
      },
      "&.user-link": {
        color: "#191970",
        padding: "10px 0 10px 0",

        [theme.breakpoints.down(1440)]: {
          flexDirection: "column-reverse",
        },
        "& .icon": {
          marginRight: "0",
          marginLeft: "4px",
        },
      },
      [theme.breakpoints.down(1440)]: {
        flexDirection: "column",
      },
    },
    appBarMenuLink: {
      color: "#add8e6",
      textDecoration: "none",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      transition: "opacity 0.2s",
      opacity: "0.6",
      fontSize: "14px",
      padding: "5px 10px",
      outline: "none",
      backgroundColor: "transparent !important",
      "&:hover": {
        opacity: "1",
        color: "#fff",
      },
      "& svg": {
        height: "100%",
        width: "100%",
        maxHeight: "20px",
        maxWidth: "20px",
        marginRight: "10px",
      },
      "& .icon-settings": {
        background:
          "url(/images/icons/header-settings.svg) no-repeat center center/ 17px 17px",
      },
      "& .icon-password": {
        background:
          "url(/images/icons/header-password.svg) no-repeat center center / 15px 15px",
      },
      "& .icon-signout": {
        background:
          "url(/images/icons/header-signout.svg) no-repeat center center / 15px 15px",
      },
    },
    logo: {
      marginRight: "20px",
      marginTop: "5px",
      "& img": {
        width: "126px",
      },
    },
    checkbox: {
      "& .checkbox": {
        width: "20px",
        height: "20px",
        borderRadius: "5px",
        backgroundColor: "#E1EDFB",
        "&--checked": {
          backgroundImage: "url(/images/icons/icon-check-mark.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        },
      },
    },
    filter: {
      display: "flex",
      marginTop: "5px",
      "& label": {
        flexGrow: 1,
        color: "rgb(25, 25, 112)",
        fontSize: "12px",
      },
    },
    datesWrapper: {
      display: "flex",
      width: "140px",
      flexDirection: "row",
      justifyContent: "space-between",
      "& > input": {
        fontSize: "10px",
      },
      "& > div": {
        marginBottom: "5px",
      },
    },
    clearAll: {
      color: config.colors.primary,
      fontSize: "14px",
      padding: "4px 5px",
      border: `1px solid ${config.colors.primary}`,
      borderRadius: "5px",
      minWidth: "130px",
      marginBottom: "5px",
      textAlign: "center",
    },
    exportStatsLink: {
      float: "right",
      fontWeight: "400",
      fontSize: "0.875em",
      marginTop: "3px",
    },
    selectAllLink: {
      float: "right",
    },
    viewAll: {
      marginTop: "10px",
      minHeight: "10px",
      cursor: "pointer",
      "& a": {
        fontSize: "12px",
        color: "#778899",
        letterSpacing: "0.1em",
      },
    },
    langSelector: {
      margin: "0",
      marginRight: "10px",
      "& .select": {
        float: "right",
      },
      "& .select:before": {
        display: "none",
      },
      "& .select:after": {
        display: "none",
      },
      "& .select > div": {
        padding: "6px 6px 7px",
      },
      "& .select > div > div": {
        height: "2.2vw",
        width: "2.2vw",
        padding: "0",
        textAlign: "center",
        backgroundColor: "#191970",
        borderRadius: "8px",
        textTransform: "uppercase",
        fontWeight: "400",
        color: "#5c89df",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down(1440)]: {
          height: "30px",
          width: "30px",
          margin: "4px 0 0 14px",
          borderRadius: "4px",
        },
      },
      "& .select > div > div:hover": {
        backgroundColor: "#add8e6",
      },
      "& .select > div > svg": {
        display: "none",
      },
    },
    select: {
      marginBottom: "0",
      width: "400px",
      "&> div:first-child": {
        background: "#e1edfb",
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderRadius: "0.25rem",
        padding: "0",
        minHeight: "40px",
      },
      "& .css-xp4uvy": {
        color: "#191970",
      },
      "& .css-12jo7m5": {
        color: "#191970",
      },
      "&.error-field > div": {
        borderLeft: "1px solid #fe8368",
        borderLeftWidth: "6px",
        borderRadius: "0.5rem 0.25rem 0.25rem 0.5rem",
      },
      "& .groupStyles": {
        color: "#fff",
        padding: "3px 10px",
        fontSize: "14px",
      },
      "&.startup-field .css-15k3avv": {
        backgroundColor: "rgb(253, 187, 45)",
      },
      "& .css-vj8t7z": {
        border: "0",
        backgroundColor: "#E1EDFB",
        borderRadius: "6px",
      },
      "& .css-d8oujb": {
        display: "none",
      },
      "& .css-15k3avv": {
        zIndex: "9999",
      },
      "& .css-1ep9fjw": {
        backgroundColor: "#B4C3D1",
        padding: "6px 8px",
        borderRadius: "0 6px 6px 0",
        display: "none",
        "&:last-child": {
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& svg": {
          background:
            "url(/images/icons/icon-arrow-bottom.png) no-repeat center center / 15px 15px",
          "& path": {
            display: "none",
          },
        },
      },
      "& .css-1ep9fjw:nth-child(4)": {
        display: "none",
      },
    },
  };
};

export default style;

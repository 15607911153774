import ItemService from "./ItemService";
import { Endpoints } from "../config";
import {
  CATEGORY_GROUP_ENDPOINT,
  FORMAT_ENDPOINT,
  CHARACTER_ENDPOINT,
  CHARACTERISTIC_ENDPOINT,
} from "../utils/DataFetcher";

export class SelectOptionService extends ItemService {
  ValidateItem(state) {
    let result = super.ValidateItem(state);

    this.validationRequired(result, state, "langKey");
    this.validationRequired(result, state, "en");

    return result;
  }

  MapStateToModel(state) {
    let data = {};
    this.GetFields().forEach((x) => {
      data[x] = state[x];
    });

    return data;
  }

  GetFields() {
    return ["id", "langKey", "en", "descEn", "active"];
  }
}

export class CategoryGroupService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(CATEGORY_GROUP_ENDPOINT, onUpdateState, lang);
  }

  ValidateItem(state) {
    const result = {};
    this.validationRequired(result, state, "langKey");
    this.validationRequired(result, state, "titleEn");
    this.validationRequired(result, state, "order");

    return result;
  }

  GetFields() {
    return ["id", "langKey", "titleEn", "order"];
  }
}

export class CategoryService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.Category.path, onUpdateState, lang);
  }

  ValidateItem(state) {
    let result = super.ValidateItem(state);

    this.validationRequired(result, state, "langKey");
     this.validationRequired(result, state, "en");
    this.validationRequired(result, state, "categoryGroupId");
    this.validationRequired(result, state, "keywords");

    return result;
  }

  GetFields() {
    return [
      "id",
      "en",
      "categoryGroupId",
      "descEn",
      "langKey",
      "active",
      "keywords",
      "categoryResultId",
      'partakableType'
    ];
  }

  getAdditionalParams() {
    return "all=true";
  }

  getGroups() {
    return this.GetAllSelectOptions(CATEGORY_GROUP_ENDPOINT);
  }
}

export class FundingSourceService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.FundingSource.path, onUpdateState, lang);
  }
}

export class EmployerLevelService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.EmployerLevel.path, onUpdateState, lang);
  }
}

export class EmployertypeService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.EmployerType.path, onUpdateState, lang);
  }
}

export class TargetGroupService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.TargetGroup.path, onUpdateState, lang);
  }
}

export class TargetLevelService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.TargetLevel.path, onUpdateState, lang);
  }
}

export class OrganizerService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.Organizer.path, onUpdateState, lang);
  }
}

export class FormatService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(FORMAT_ENDPOINT, onUpdateState, lang);
  }
}

export class CharacterService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(CHARACTER_ENDPOINT, onUpdateState, lang);
  }
}

export class CharacteristicService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(CHARACTERISTIC_ENDPOINT, onUpdateState, lang);
  }

  ValidateItem(state) {
    const result = {};
    this.validationRequired(result, state, "langKey");
    this.validationRequired(result, state, "titleEn");

    return result;
  }

  GetFields() {
    return ["id", "langKey", "titleEn"];
  }
}

export class CategoryResultService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.CategoryResults.path, onUpdateState, lang);
  }

  ValidateItem(state) {
    let result = {};

    this.validationRequired(result, state, 'title');

    return result;
  }

  GetFields() {
    return [
      'id',
      'title',
    ];
  }
}

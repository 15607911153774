import React from "react";
import {
    Grid,
    Button,
    TextField,
    Typography,
    Modal,
    InputLabel,
    InputAdornment,
} from "@material-ui/core";
import {Close, Add} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import fetchMultiPartData from "../../utils/fetchMultiPart";
import {Redirect, withRouter} from "react-router-dom";
import {Form, Field} from "react-final-form";
import {each, isEmpty} from "lodash";
import {I18n} from "../../i18n/";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import Loader from "../../components/loader";
import classNames from "classnames";
import {selectStyles} from "../../style/select-styles";
import {config} from "../../style/config";
import {MuiPickersUtilsProvider, InlineDatePicker} from "material-ui-pickers";
import DayjsUtils from '@date-io/dayjs';

const styles = (theme) => ({
    root: {
        textAlign: "left",
        padding: "30px 60px 0",
        backgroundColor: "#f7f9fb",
        "& label": {
            fontSize: "24px !important",
            fontFamily: "Roboto !important",
            fontWeight: 100,
        },
        "& h2": {
            fontSize: "36px !important",
            fontFamily: "Roboto",
            fontWeight: 900,
            letterSpacing: "0.15rem",
            "&.title": {
                textAlign: "center",
            },
        },
        "& h3": {
            color: config.colors.secondaryBackground,
            fontSize: "30px !important",
            fontFamily: "Roboto !important",
            fontWeight: 100,
            "&.m100": {
                marginTop: "100px",
            },
            "&.m70": {
                marginTop: "70px",
            },
            "&.m40": {
                marginTop: "40px",
            },
            "&.m50": {
                marginTop: "50px",
            },
            "&.mt77": {
                marginTop: 77,
            },
            "&.mb20": {
                marginBottom: 20,
            },
        },
        "& .btn-create": {
            width: "160px",
            fontSize: "12px",
            height: "45px",
            padding: 0,
            marginLeft: "12px",
        },
        "& .delete-report": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            border: "2px solid transparent",
            paddingLeft: "5px",
            borderRadius: "5px",
            position: "relative",

            "& p": {
                display: "none",
                margin: "0",
            },

            "& div": {
                color: "rgb(119, 136, 153)",
                fontSize: "1rem",
                fontWeight: "500",
            },

            "&:hover": {
                boxShadow: "0 0 0px 5px #fe8368 !important",

                "& p": {
                    position: "absolute",
                    background: "#fe8368",
                    top: "-13px",
                    right: "-12px",
                    display: "flex",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    color: "white",
                    justifyContent: "center",
                    alignItems: "center",
                },
            },
        },
        "&.modal": {
            top: "5%",
            left: "auto",
            width: "94%",
            height: "90vh",
            outline: "none",
            padding: "32px",
            margin: "0 auto",
            position: "relative",
            transform: "none",
            boxShadow: "none",
            overflowY: "auto",
            borderRadius: "15px",
            backgroundColor: "#f7f9fb",
        },
    },

    imgEdit: {
        maxWidth: "100%",
        maxHeight: "650px",
        borderRadius: 5,
    },

    hoverDelete: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
        cursor: "pointer",
        "& img": {
            boxShadow: "0 0 0px 5px transparent",
        },
        "&:hover": {
            "& > img": {
                boxShadow: "0 0 0px 5px #fe8368 !important",
            },
            "& i": {
                display: "block",
                background: "#fe8368",
            },
        },
    },

    imgEditClose: {
        position: "absolute",
        display: "none",
        borderRadius: "10px",
        height: "20px",
        width: "20px",
        textAlign: "center",
        color: "white",
        // marginLeft: '-10px',
        marginTop: "-10px",
    },
    alignRight: {
        textAlign: "right",
    },
    data: {
        "& h4": {
            marginBottom: "0",
        },
    },
    "mt-27": {
        marginTop: -27,
    },
    textField: {
        width: "100%",
        height: "auto",
        "&.error-field fieldset": {
            borderLeft: "1px solid #fe8368",
            borderLeftWidth: "6px",
        },
        "& input": {
            height: "24px",
            width: "100%",
            borderRadius: "8px !important",
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "1.5",
            [theme.breakpoints.down(1590)]: {
                width: "79%",
            },
        },
        "&.source input": {
            width: "100%",
            maxWidth: 500,
        },
        "&.full input": {
            width: "100%",
        },
        "&.full div": {
            width: "100%",
            maxWidth: "100% !important",
        },
        "& label": {
            marginBottom: "10px",
        },
        "&.h3 label": {
            fontSize: "30px",
            marginTop: "0 !important",
        },
        "&.title-field": {
            marginBottom: "80px",
        },
        "&.inline-field": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "32px",
            "&_mb0": {
                marginBottom: 0,
            },
            "& label": {
                margin: "0 10px 0 0",
                padding: "0 0 8px",
            },
            "& > div": {
                marginTop: 0,
                width: "100%",
                maxWidth: 500,
                "&:before": {
                    display: "none",
                },
            },
        },
    },
    dateField: {
        height: 67,
        "& > div": {
            width: "160px",
            fontSize: "12px",
            "& > input": {
                padding: "10px 0 10px 14px !important",
            },
        },
        "&.error-field": {
            borderLeft: "1px solid #fe8368",
            borderLeftWidth: "6px",
            borderRadius: "0.5rem 0.25rem 0.25rem 0.5rem",
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
            color: "rgba(0, 0, 0, 0)",
            opacity: "1",
            display: "block",
            background:
                "#add8e6 url(/images/icons/calendar.svg) no-repeat center center",
            backgroundSize: "24px 24px",
            width: "40px",
            height: "40px",
            borderWidth: "thin",
        },
    },
    textarea: {
        "& h67": {
            height: 67,
        },
        "&.text-area": {
            margin: "0 0 30px",
            "&.h160 div": {
                minHeight: "160px",
            },
            "&.h150 div": {
                minHeight: "150px",
            },
            "&.h140 div": {
                minHeight: "140px",
            },
            "&.h135 div": {
                minHeight: "135px",
                minWidth: "53%",
            },
            "&.h120 div": {
                minHeight: "120px",
            },
            "&.h70 div": {
                minHeight: "70px",
            },
            "& textarea": {
                fontSize: "12px",
                fontWeight: "600",
                lineHeight: "1.5",
                color: config.colors.secondaryBackground,
            },
            "& fieldset": {
                borderRadius: "10px",
            },
            "&.big-label label": {
                fontSize: "30px !important",
            },
        },
        "&.error-field fieldset": {
            borderLeft: "1px solid #fe8368",
            borderLeftWidth: "6px",
        },
    },
    buttonCancel: {
        marginRight: "20px",
    },
    select: {
        "& .css-vj8t7z": {
            border: "0",
            backgroundColor: "#E1EDFB",
            borderRadius: "6px",
            height: "45px",
            width: "100%",
            maxWidth: 500,
        },
        "& .css-2o5izw": {
            width: "20.78vw",
        },
        "&.error-field > div": {
            borderLeft: "1px solid #fe8368",
            borderLeftWidth: "6px",
            borderRadius: "0.5rem 0.25rem 0.25rem 0.5rem",
        },
        "& .css-d8oujb": {
            display: "none",
        },
        "& .css-15k3avv": {
            zIndex: "9999",
        },
        "& .css-1ep9fjw": {
            backgroundColor: "#B4C3D1",
            padding: "11px",
            borderRadius: "0 6px 6px 0",
            height: "45px",
            width: "52px",
            justifyContent: "center",
            alignItems: "center",
            "& svg": {
                background:
                    "url(/images/icons/icon-arrow-bottom.png) no-repeat center center / 15px 15px",
                "& path": {
                    display: "none",
                },
            },
        },
        "&.select-target": {
            width: "170px",
            flex: "0 0 170px !important",
        },
        "&.full": {
            width: "80%",
        },
        "&.full > div": {
            width: "100%",
        },
    },
    buttons: {
        marginTop: "30px",
        "& .button-submit": {
            fontSize: 14,
            marginRight: "0",
            height: "47px",
        },
        "& .button-cancel": {
            fontSize: 14,
            marginRight: "15px",
        },
    },
    inputFile: {
        "& label": {
            display: "block",
            marginBottom: "10px",
        },
        "& input": {
            display: "block",
            width: "0.1px",
            height: "0.1px",
            opacity: "0",
            overflow: "hidden",
            position: "absolute",
            zIndex: "-1",
        },
        "& input + label": {
            color: "#fff",
            backgroundColor: config.colors.primary,
            display: "inline-block",
            width: "160px",
            height: "45px",
            borderRadius: "50px",
            fontSize: "12px !important",
            fontWeight: "700",
            textAlign: "center",
            paddingTop: "13px",
            letterSpacing: "0.12rem",
            cursor: "pointer",
        },
        "& p": {
            marginTop: "4px",
            fontSize: "12px",
            color: "rgb(119, 136, 153)",
            fontWeight: "700",
            textAlign: "center",
            display: "block",
            width: "160px",
        },
    },
    error: {
        width: "100%",
        color: "#a94442",
        border: "1px solid #ebcccc",
        borderRadius: "4px",
        backgroundColor: "#f2dede",
        padding: "10px 20px",
    },
    dataIcon: {
        background: config.colors.primary,
        width: 51,
        height: 45,
        borderRadius: 7,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "2",
        position: "relative",
        minWidth: "45px",
        left: "-15px",

        "& img.date-icon": {
            fill: "#e6feff",
            width: "32px",
        },
    },
    dataPick: {
        width: "calc(100% - 230px)",
        "& input": {
            top: "-45px",
            left: "51px",
            width: "84px",
            height: "24px",
            position: "absolute",
        },
    },
    calendar: {
        minWidth: "145px",
        maxHeight: "55px",

        "& .transparent input": {
            color: "transparent !important",
        },
    },
    dateWrap: {
        maxHeight: "45px",
        marginBottom: "10px",
        display: "flex",
        flexDirection: "row-reverse",
        cursor: "pointer",

        "& input": {
            border: "0 !important",

            "&:focus": {
                backgroundColor: "transparent !important",
            },
        },
    },
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const required = (value) => (value ? undefined : "Required");

const hromadaTypes = [
    {label: "A - AGRICULTURE, FORESTRY AND FISHING", value: "A"},
    {label: "B - MINING AND QUARRYING", value: "B"},
    {label: "C - MANUFACTURING", value: "C"},
    {label: "D - ELECTRICITY, GAS, STEAM AND AIR CONDITIONING SUPPLY", value: "D"},
    {label: "E - WATER SUPPLY; SEWERAGE, WASTE MANAGEMENT AND REMEDIATION ACTIVITIES", value: "E"},
    {label: "F - CONSTRUCTION", value: "F"},
    {label: "G - WHOLESALE AND RETAIL TRADE; REPAIR OF MOTOR VEHICLES AND MOTORCYCLES", value: "G"},
    {label: "H - TRANSPORTATION AND STORAGE", value: "H"},
    {label: "I - ACCOMMODATION AND FOOD SERVICE ACTIVITIES", value: "I"},
    {label: "J - INFORMATION AND COMMUNICATION", value: "J"},
    {label: "K - FINANCIAL AND INSURANCE ACTIVITIES", value: "K"},
    {label: "L - REAL ESTATE ACTIVITIES", value: "L"},
    {label: "M - PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES", value: "M"},
    {label: "N - ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES", value: "N"},
    {label: "O - PUBLIC ADMINISTRATION AND DEFENCE; COMPULSORY SOCIAL SECURITY", value: "O"},
    {label: "P - EDUCATION", value: "P"},
    {label: "Q - HUMAN HEALTH AND SOCIAL WORK ACTIVITIES", value: "Q"},
    {label: "R - ARTS, ENTERTAINMENT AND RECREATION", value: "R"},
    {label: "S - OTHER SERVICE ACTIVITIES", value: "S"},
    {
        label: "T - ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS; UNDIFFERENTIATED GOODS- AND SERVICES-PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE",
        value: "T"
    },
    {label: "U - ACTIVITIES OF EXTRATERRITORIAL ORGANISATIONS AND BODIES", value: "U"}

];

class ItemEdit extends React.Component {
    state = {
        open: true,
        item: null,
        regions: [],
        users: [],
        showLeadUnit: false,
        errorLead: false,
        selectedLeadUnit: null,
        files: {}, // fileInputId => (input, names, error)
        mediaUrls: ["", "", "", ""],
        slides: [],
        keyStakeholdersQuotes: [""],
        loading: true,
        isEdit: false,
        isCreate: null,
    };

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const response = await fetchData("get", "/regions?size=1000");
        const regions = response[0];

        this.setState({isEdit: !!regions});
        let regionsList = [];
        each(regions, (item) => {
            regionsList.push({
                value: item.id,
                label: this.props.lang === "Eng" ? item.en : item.ua,
            });
        });
        regionsList = regionsList.sort(function (a, b) {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        });

        const users = [];

        const editItemMatch = window.location.href.match(
            /good-practices\/edit\/(\d+)/
        );
        const itemId = editItemMatch && editItemMatch[1];

        const item = itemId
            ? (await fetchData("get", `/good_practices/${itemId}`))[0]
            : {};

        const showLeadUnit = this.props.user && this.props.user.isAdmin;
        if (showLeadUnit) {
            const usersData = await fetchData(
                "get",
                `/users/activity_owners${item && item.fake ? "?all=1" : ""}`
            );
            each(usersData[0], (item) => {
                users.push({value: item.id.toString(), label: item.name});
            });
        }

        const newState = {
            isCreate: !!editItemMatch,
            loading: false,
            regions: regionsList,
            showLeadUnit: showLeadUnit,
            users: users,
        };

        if (item) {
            if (item.photos && item.photos.length) {
                newState.slides = item.photos.map((slide, idx) => {
                    return {
                        id: slide.id,
                        url: slide.photoUrl,
                        quote: slide.caption,
                    };
                });
            }
            if (item.mediaUrls) {
                newState.mediaUrls = item.mediaUrls;
                const emptyCount = 4 - item.mediaUrls.length;
                for (let j = 0; j < emptyCount; j++) {
                    newState.mediaUrls.push("");
                }
            }
            if (item.keyStackholdersQuote) {
                try {
                    newState.keyStakeholdersQuotes = JSON.parse(
                        item.keyStackholdersQuote
                    );
                } catch (e) {
                    newState.keyStakeholdersQuotes = [item.keyStackholdersQuote];
                }
            }

            newState.selectedLeadUnit =
                item.leadUnit && item.leadUnitType === "User"
                    ? this.filterSelected(users, item.leadUnit.id)
                    : null;
            newState.item = item;
        }

        this.setState(newState);
    }

    saveItem = async (values) => {
        const {selectedLeadUnit, showLeadUnit, files, slides} = this.state;
        let data = new FormData();

        const customSendFields = [
            "regionId",
            "region",
            "leadUnit",
            "leadUnitType",
            "leadUnitId",
            "photos",
            "beneficiariesPhotos",
            "citizensPhotos",
            "meetingProposalsReport",
            "questionnariesReport",
            "governmentResolutionsReport",
            "planningDocumentsReport",
            "createdAt",
            "updatedAt",
            "readOnly",
            "mediaUrls",
            "hromadaType",
        ];

        for (let name in values) {
            if (customSendFields.indexOf(name) >= 0) continue;
            if (name.match(/^url/)) continue;

            let value = values[name];

            data.set(name, value === undefined || value === "N/A" ? null : value);
        }

        if (values.hromadaType && values.hromadaType.value) {
            data.set("hromadaType", values.hromadaType.value);
        }

        this.state.mediaUrls.forEach((url) => {
            if (url) {
                data.append("mediaUrls[]", url);
            }
        });

        Object.keys(files).forEach((fileInputName) => {
            const fileInfo = files[fileInputName];
            const attrName = fileInputName + (fileInfo.input.multiple ? "[]" : "");
            [...fileInfo.input.files].forEach((file) => {
                data.append(attrName, file);
            });
        });

        slides.forEach((slide, idx) => {
            data.append(`photos_attributes[${idx}][caption]`, slide.quote);
            if (this.props.item && slide.id) {
                data.append(`photos_attributes[${idx}][id]`, slide.id);
            }
        });

        let errorOpts = {};

        if (showLeadUnit) {
            if (selectedLeadUnit) {
                data.set("leadUnitId", selectedLeadUnit.value);
                data.set("leadUnitType", "User");
            } else {
                //errorOpts.errorLead = true;
            }
        }

        if (values.regionId && values.regionId.value) {
            data.set("regionId", values.regionId.value);
        } else if (typeof values.regionId != "number") {
            //errorOpts.errorRegion = true
        }

        // typo in field name, need to be updated on API side
        data.set(
            "keyStackholdersQuote",
            JSON.stringify(this.state.keyStakeholdersQuotes)
        );

        if (!isEmpty(errorOpts)) {
            this.setState(errorOpts);
            return;
        }

        //const filesCount = this.fileInput.files.length;
        //for(let i = 0; i < filesCount; i++){
        //  data.append('files[]', this.fileInput.files[i])
        //}

        if (values.id) {
            await fetchMultiPartData("put", `/good_practices/${values.id}`, data);
        } else {
            await fetchMultiPartData("post", "/good_practices", data);
        }
        this.setState({open: false});
        this.setState({loading: true});
        this.getData();
        this.props.history.goBack();
    };

    onSubmit = async (values) => {
        await sleep(300);
        this.saveItem(values);
    };

    filterSelected = (collection, value) => {
        return collection.find((option) => option.value === value);
    };

    handleDropdownChange = (option) => {
        this.setState({[option]: false});
    };

    handleLeadUnitChange = (selectedOption) => {
        this.setState({
            selectedLeadUnit: selectedOption.value ? selectedOption : null,
        });
    };

    handleFiles = (name, multi, index) => {
        var fileInput = document.querySelector(`[name="${name}"]`);
        if (typeof index === "number") {
            if (fileInput && fileInput.files && fileInput.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    document.querySelector(`#slideImage${index}`).src = e.target.result;
                };
                reader.readAsDataURL(fileInput.files[0]);
            }
        }
        return () => {
            if (fileInput && fileInput.files[0]) {
                const files = this.state.files;
                if (!files[name]) {
                    files[name] = {input: fileInput};
                }
                let anyLargeExists = false;
                [...fileInput.files].forEach((file) => {
                    anyLargeExists = anyLargeExists || !!(file.size > 10485760);
                });
                files[name].error = anyLargeExists;
                files[name].names = [...fileInput.files].map((file) => file.name);
                this.setState({files: files});
            }
        };
    };

    renderFileField = ({
                           name,
                           label,
                           accept,
                           multifile = false,
                           id,
                           index = false,
                       }) => {
        const {classes} = this.props;
        const {files, item} = this.state;

        const isKeyStakeholders = name.includes("photos_attributes");
        const fileInfo = files[name];
        const ident = id || name;
        if (accept === "images") {
            accept = ".jpg, .jpeg, .png, .gif";
        }
        return (
            <Field name={name}>
                {() => (
                    <div className={classes.inputFile}>
                        {label && <InputLabel>{label}</InputLabel>}
                        <input
                            type="file"
                            id={ident}
                            name={name}
                            onChange={this.handleFiles(name, multifile, index)}
                            multiple={multifile ? "multiple" : false}
                            accept={accept ? accept : "*"}
                        />
                        <label htmlFor={name}>choose file{multifile ? "(s)" : ""}</label>
                        {fileInfo && fileInfo.names && (
                            <>
                                <br/>
                                <p>{fileInfo.names.join(", ")}</p>
                            </>
                        )}
                        <p>max. 10 MB</p>
                        {fileInfo && fileInfo.error && (
                            <div className={classes.error}>File size should be 10MB max</div>
                        )}
                        {item &&
                            item[name] &&
                            (multifile ? (
                                item[name].map((file, i) => {
                                    return (
                                        <a
                                            key={i}
                                            href
                                            title={`Delete '${file.name}'`}
                                            className={
                                                isKeyStakeholders ? "btn btn-image" : "btn btn-word"
                                            }
                                            onClick={this.handleRemoveFile(file)}
                                        >
                                            <i>&times;</i>
                                            {isKeyStakeholders && (
                                                <img
                                                    src={`${file.url}`}
                                                    alt=""
                                                    style={{maxWidth: "467px", maxHeight: "334px"}}
                                                />
                                            )}
                                        </a>
                                    );
                                })
                            ) : (
                                <a
                                    href
                                    title={`Delete '${item[name].name}'`}
                                    className={classNames("delete-report", [
                                        isKeyStakeholders ? "btn btn-image" : "btn btn-word",
                                    ])}
                                    onClick={this.handleRemoveFile(item[name])}
                                >
                                    <p>&times;</p>
                                    <div> {` Delete '${item[name].name}'`} </div>
                                    {isKeyStakeholders && (
                                        <img
                                            src={`${item[name].url}`}
                                            alt=""
                                            style={{maxWidth: "467px", maxHeight: "334px"}}
                                        />
                                    )}
                                </a>
                            ))}
                    </div>
                )}
            </Field>
        );
    };

    handleRemoveFile = (file) => {
        const lang = this.props.lang;
        return async (event) => {
            if (
                window.confirm(
                    `${I18n[lang].common.deleteConfirm}${I18n[
                        lang
                        ].common.file.toLowerCase()} ${file.name}?`
                )
            ) {
                event.currentTarget.remove();
                await fetchData("delete", `/good_practices/attachments/${file.id}`);
            }
        };
    };

    handleRemoveQuotation = (idx) => {
        if (window.confirm(`Are you sure you want to delete quotation?`)) {
            this.setState({
                keyStakeholdersQuotes: this.state.keyStakeholdersQuotes.filter(
                    (s, sidx) => idx !== sidx
                ),
            });
        }
    };

    handleRemoveSlide = (idx) => {
        const copyFiles = {...this.state.files};
        let data = new FormData();
        data.append(`photos_attributes[${idx}][id]`, this.state.slides[idx].id);
        data.append(`photos_attributes[${idx}][_destroy]`, 1);

        return async (event) => {
            if (window.confirm(`Are you sure you want to delete slide?`)) {
                if (this.state.slides[idx].id) {
                    await fetchMultiPartData(
                        "put",
                        `/good_practices/${this.state.item.id}`,
                        data
                    );
                    // return false;
                }
                delete copyFiles[`photos_attributes[${idx}][photo]`];

                this.setState({
                    files: copyFiles,
                    slides: this.state.slides.filter((s, sidx) => idx !== sidx),
                });
                return false;
            }
        };
    };

    handleUrlChange = (idx) => {
        return (event) => {
            const urls = this.state.mediaUrls;
            urls[idx] = event.target.value;
            this.setState({mediaUrls: urls});
        };
    };

    handleNewUrl = () => {
        const urls = this.state.mediaUrls;
        urls.push("");
        this.setState({mediaUrls: urls});
    };

    renderUrlField = (url, idx) => {
        return (
            <Field name={`url${idx + 1}`} key={idx}>
                {({input}) => (
                    <TextField
                        margin="normal"
                        placeholder="Max 6000"
                        className={classNames(
                            "text-field",
                            "full",
                            this.props.classes.textField
                        )}
                        variant="outlined"
                        value={url}
                        onChange={this.handleUrlChange(idx)}
                    />
                )}
            </Field>
        );
    };

    handleAddSlide = () => {
        this.setState({
            slides: this.state.slides.concat([{url: "", quote: ""}]),
        });
    };

    photosQuoteChange = (idx) => (evt) => {
        let tempSlides = this.state.slides;
        tempSlides[idx].quote = evt.target.value;
        this.setState({slides: tempSlides});
    };

    handleAddKeyStakeholdersQuote = () => {
        this.setState({
            keyStakeholdersQuotes: this.state.keyStakeholdersQuotes.concat([""]),
        });
    };

    handleKeyStakeholdersQuoteChange = (idx) => (evt) => {
        const newKeyStakeholdersQuotes = this.state.keyStakeholdersQuotes.map(
            (keyStakeholdersQuotes, sidx) => {
                if (idx !== sidx) return keyStakeholdersQuotes;
                return evt.target.value;
            }
        );

        this.setState({keyStakeholdersQuotes: newKeyStakeholdersQuotes});
    };

    handleClose = () => {
        this.setState({open: false});
        this.renderRedirect();
    };

    renderRedirect = () => {
        if (!this.state.open) {
            return <Redirect to="/good-practices"/>;
        }
    };

    render() {
        const {classes, lang} = this.props;
        const {
            regions,
            users,
            item,
            showLeadUnit,
            errorLead,
            selectedLeadUnit,
            mediaUrls,
            loading,
        } = this.state;

        // if (loading) return (<div> loading... </div>);
        if (!loading && !item) {
            return this.renderRedirect();
        }

        return (
            <>
                {this.state.loading && (
                    <div>
                        <Loader/>
                    </div>
                )}

                {this.renderRedirect()}

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <div
                        className={classNames("modal", "root", classes.modal, classes.root)}
                    >
                        <a className="close" onClick={this.handleClose}>
                            <Close/>
                        </a>
                        <Typography
                            gutterBottom
                            variant="h2"
                            component="h2"
                            className="title"
                        >
                            {!this.state.isCreate ? "Add" : "Edit"} Good Practice
                        </Typography>

                        <Typography className={classes.data} component="div">
                            <Form
                                onSubmit={this.onSubmit}
                                initialValues={item}
                                render={({
                                             handleSubmit,
                                             form,
                                             submitting,
                                             pristine,
                                             values,
                                         }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field name="title" validate={required}>
                                            {({input, meta}) => (
                                                <TextField
                                                    label="Title"
                                                    margin="normal"
                                                    style={{maxWidth: "100%"}}
                                                    placeholder="Please specify the title name"
                                                    className={classNames(
                                                        "text-field",
                                                        classes.textField,
                                                        "inline-field",
                                                        "h3",
                                                        "full",
                                                        "title-field",
                                                        {"error-field": meta.error && meta.touched}
                                                    )}
                                                    variant="outlined"
                                                    {...input}
                                                />
                                            )}
                                        </Field>

                                        {showLeadUnit && (
                                            <Grid container spacing={24} alignItems="center">
                                                <Grid item xs={6}>
                                                    <Field name="leadUnitId">
                                                        {({input, meta}) => (
                                                            <div
                                                                className={classNames(
                                                                    "text-field",
                                                                    classes.textField,
                                                                    "inline-field"
                                                                )}
                                                            >
                                                                <InputLabel className={classes.label}>
                                                                    Lead Unit
                                                                </InputLabel>
                                                                <Select
                                                                    styles={selectStyles}
                                                                    className={classNames("full", {
                                                                        "error-field": errorLead,
                                                                    })}
                                                                    closeMenuOnSelect={true}
                                                                    components={makeAnimated()}
                                                                    onMenuOpen={() =>
                                                                        this.handleDropdownChange("errorLead")
                                                                    }
                                                                    {...input}
                                                                    options={users}
                                                                    value={selectedLeadUnit}
                                                                    onChange={this.handleLeadUnitChange}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Grid container spacing={24} alignItems="flex-start">
                                            <Grid item md={12} lg={6}>
                                                <Grid container spacing={24} alignItems="flex-start">
                                                    <Grid item xs={2} lg={4}>
                                                        <InputLabel>Development period:</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={3} lg={4}>
                                                        <Field name="developmentPeriodStartsAt">
                                                            {({input, meta}) => (
                                                                <div
                                                                    className={classNames(
                                                                        classes.calendar,
                                                                        classes.dateBlock
                                                                    )}
                                                                >
                                                                    <div
                                                                        className={classNames(classes.dateWrap, {
                                                                            transparent: input.value === "",
                                                                        })}
                                                                    >
                                                                        <MuiPickersUtilsProvider
                                                                            utils={DayjsUtils}
                                                                        >
                                                                            <InlineDatePicker
                                                                                label="Start Date"
                                                                                className={classNames(
                                                                                    "text-field",
                                                                                    classes.textField,
                                                                                    classes.dateField,
                                                                                    "inline-field",
                                                                                    "inline-field_mb0",
                                                                                    {
                                                                                        "error-field":
                                                                                            meta.error && meta.touched,
                                                                                    }
                                                                                )}
                                                                                format="DD.MM.YYYY"
                                                                                {...input}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            <div
                                                                                                className={classes.dataIcon}>
                                                                                                <img
                                                                                                    src="/images/icons/calendar.svg"
                                                                                                    alt="calendar"
                                                                                                    className="date-icon"
                                                                                                />
                                                                                            </div>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={3} lg={4}>
                                                        <Field name="developmentPeriodEndsAt">
                                                            {({input, meta}) => (
                                                                <div
                                                                    className={classNames(
                                                                        classes.calendar,
                                                                        classes.dateBlock
                                                                    )}
                                                                >
                                                                    <div
                                                                        className={classNames(classes.dateWrap, {
                                                                            transparent: input.value === "",
                                                                        })}
                                                                    >
                                                                        <MuiPickersUtilsProvider
                                                                            utils={DayjsUtils}
                                                                        >
                                                                            <InlineDatePicker
                                                                                label="End Date"
                                                                                className={classNames(
                                                                                    "text-field",
                                                                                    classes.textField,
                                                                                    classes.dateField,
                                                                                    "inline-field",
                                                                                    "inline-field_mb0",
                                                                                    {
                                                                                        "error-field":
                                                                                            meta.error && meta.touched,
                                                                                    }
                                                                                )}
                                                                                format="DD.MM.YYYY"
                                                                                {...input}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            <div
                                                                                                className={classes.dataIcon}>
                                                                                                <img
                                                                                                    src="/images/icons/calendar.svg"
                                                                                                    alt="calendar"
                                                                                                    className="date-icon"
                                                                                                />
                                                                                            </div>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    container
                                                    spacing={24}
                                                    alignItems="flex-start"
                                                    className={classNames(classes["mt-27"])}
                                                >
                                                    <Grid item xs={2} lg={4}>
                                                        <InputLabel>Implementation:</InputLabel>
                                                    </Grid>
                                                    <Grid item xs={3} lg={4}>
                                                        <Field name="implementationStartsAt">
                                                            {({input, meta}) => (
                                                                <div
                                                                    className={classNames(
                                                                        classes.calendar,
                                                                        classes.dateBlock
                                                                    )}
                                                                >
                                                                    <div
                                                                        className={classNames(classes.dateWrap, {
                                                                            transparent: input.value === "",
                                                                        })}
                                                                    >
                                                                        <MuiPickersUtilsProvider
                                                                            utils={DayjsUtils}
                                                                        >
                                                                            <InlineDatePicker
                                                                                className={classNames(
                                                                                    "text-field",
                                                                                    classes.textField,
                                                                                    classes.dateField,
                                                                                    "inline-field",
                                                                                    "inline-field_mb0",
                                                                                    {
                                                                                        "error-field":
                                                                                            meta.error && meta.touched,
                                                                                    }
                                                                                )}
                                                                                format="DD.MM.YYYY"
                                                                                {...input}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            <div
                                                                                                className={classes.dataIcon}>
                                                                                                <img
                                                                                                    src="/images/icons/calendar.svg"
                                                                                                    alt="calendar"
                                                                                                    className="date-icon"
                                                                                                />
                                                                                            </div>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={24} alignItems="flex-start">
                                            <Grid item xs={6} style={{paddingRight: "25px"}}>
                                                <h3 className="mt77 mb20">Enterprise</h3>
                                                <Field name="regionId">
                                                    {({input, meta}) => (
                                                        <div
                                                            className={classNames(
                                                                "text-field",
                                                                classes.textField,
                                                                "inline-field"
                                                            )}
                                                        >
                                                            <InputLabel className={classes.label}>
                                                                Country:
                                                            </InputLabel>
                                                            <Select
                                                                styles={selectStyles}
                                                                closeMenuOnSelect={true}
                                                                components={makeAnimated()}
                                                                options={regions}
                                                                //onMenuOpen={() => this.handleDropdownChange('errorResult')}
                                                                {...input}
                                                                value={this.filterSelected(
                                                                    regions,
                                                                    input.value
                                                                )}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="municipalityFullName">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Name of enterprise:"
                                                            margin="normal"
                                                            placeholder="Type here"
                                                            className={classNames(
                                                                "text-field",
                                                                classes.textField,
                                                                "inline-field"
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="municipalityPopulation">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Number of employees:"
                                                            margin="normal"
                                                            className={classNames(
                                                                "text-field",
                                                                classes.textField,
                                                                "inline-field"
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="hromadaType">
                                                    {({input, meta}) => (
                                                        <div
                                                            className={classNames(
                                                                "text-field",
                                                                classes.textField,
                                                                "inline-field"
                                                            )}
                                                        >
                                                            <InputLabel className={classes.label}>
                                                                to Sector of organsation’s activity:
                                                            </InputLabel>
                                                            <Select
                                                                styles={selectStyles}
                                                                closeMenuOnSelect={true}
                                                                components={makeAnimated()}
                                                                options={hromadaTypes}
                                                                {...input}
                                                                value={hromadaTypes.find(
                                                                    (option) => option.value === input.value
                                                                )}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="enterpriseAddress">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Full postal address of an enterprise:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h120",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="3"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>

                                                <Field name="municipalityPhoneNumber">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Telephone number:"
                                                            margin="normal"
                                                            className={classNames(
                                                                "text-field",
                                                                classes.textField,
                                                                "inline-field"
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="municipalityEmail">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Email address:"
                                                            margin="normal"
                                                            placeholder="Type here"
                                                            className={classNames(
                                                                "text-field",
                                                                classes.textField,
                                                                "inline-field"
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="municipalityWebsite">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Official website:"
                                                            margin="normal"
                                                            placeholder="Type here"
                                                            className={classNames(
                                                                "text-field",
                                                                classes.textField,
                                                                "inline-field"
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="municipalityHead">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Manager:"
                                                            margin="normal"
                                                            placeholder="Type here"
                                                            className={classNames(
                                                                "text-field",
                                                                classes.textField,
                                                                "inline-field"
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="deployingDepartmentName">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Full name of the enterprise department deploying the good practice:"
                                                            margin="normal"
                                                            placeholder="Type here"
                                                            className={classNames(
                                                                "text-field",
                                                                "full",
                                                                classes.textField
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="deployingDepartmentHead">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Head of the relevant department in the enterprise and/or persons responsible for the implementation"
                                                            margin="normal"
                                                            placeholder="Type here"
                                                            className={classNames(
                                                                "text-field",
                                                                "full",
                                                                classes.textField
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>

                                                <h3 className="m100">
                                                    Good Practice Description and Development
                                                </h3>
                                                <Field name="shortDescription">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Short description of the good practice:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Please provide a description capturing the essence of the approach and desired results in 1-2 paragraphs maximum"
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h150",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="aimedProblemDescription">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Description of the problem the good practice aimed to address:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Describe the historical context of the problem in 1-2 paragraphs maximum"
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h150",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="statisticsReflection">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Statistics reflection problem scope and success:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Max 2000"
                                                            inputProps={{maxLength: "2000"}}
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h150",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>

                                                <h3 className="m70">Plan of Activities and Results</h3>
                                                <Field name="activitiesSequence">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Describe the sequence of activities leading to the implementation of the good practice:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Please describe in one paragraph."
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h70",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="2"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="intendedBeneficiaries">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Intended beneficiaries:"
                                                            margin="normal"
                                                            className={classNames(
                                                                "text-field",
                                                                "source",
                                                                classes.textField,
                                                                "inline-field"
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="implementationPartners">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Implementation partners:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Please insert the names of organisations that collaborated with the enterprise to operationalise the good practice."
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h70",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="2"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="qualitiveAndQuantitiveResults">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Qualitative and quantitative results:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Describe “process” results, such as regional cooperation project, transformation strategies (action plans, budget proposals, training plans, innovation cooperation, etc including ERP software and / or MES software"
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h120",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="directRoleInSupporting">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Did a project have a direct role in supporting the good practice?"
                                                            margin="normal"
                                                            placeholder="Text"
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h70",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="2"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>

                                                <h3 className="m50">
                                                    Good Practice Implementation Budget
                                                </h3>
                                                <Field name="totalSpending">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Total spending for the implementation of the good practice:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Max 2000"
                                                            inputProps={{maxLength: "2000"}}
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h120",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="financeSources">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Sources of finances:"
                                                            margin="normal"
                                                            className={classNames(
                                                                "text-field",
                                                                classes.textField,
                                                                "source",
                                                                "inline-field"
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="nonFinancialResources">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Non-financial resources required:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="E.g. number of volunteer, etc."
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h120",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="estimatedCostSavings">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Estimated cost savings due to the implementation of the good practice:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Describe “process” results, such as “2 new facilities were opened,” as well as outcomes, such “municipal service delivery time to citizens reduced by one third” or “citizen usage of municipal service increased 2.5 times” … etc; add quotes of citizens and other stakeholders as well as photos that reflect changes"
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={6} style={{paddingLeft: "25px"}}>
                                                <h3 className="mt77 mb20">Lessons Learned</h3>
                                                <Field name="importantSuccessFactors">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Descriptions of important success factors:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Max 2000"
                                                            inputProps={{maxLength: "2000"}}
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h160",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="identifiedRisks">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Risks identified/encountered during development and implementation:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="E.g.numbers of volunteer, etc."
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h160",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="risksResolvingMethods">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            label="Methods used to mitigate or resolve the risks:"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="Max 2000"
                                                            inputProps={{maxLength: "2000"}}
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h160",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>

                                                <h3 className="m40">Quotation(s)</h3>

                                                <Grid container spacing={24} alignItems="flex-start">
                                                    <Grid item xs={12}>
                                                        {this.state.keyStakeholdersQuotes.map(
                                                            (keyStakeholdersQuote, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    <button
                                                                        className="remove-button"
                                                                        onClick={() =>
                                                                            this.handleRemoveQuotation(idx)
                                                                        }
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <Field name="keyStakeholdersQuote">
                                                                        {({input, meta}) => (
                                                                            <TextField
                                                                                margin="normal"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                placeholder="Max 2000"
                                                                                inputProps={{maxLength: "2000"}}
                                                                                className={classNames(
                                                                                    classes.textarea,
                                                                                    "text-area",
                                                                                    "h140",
                                                                                    {
                                                                                        "error-field":
                                                                                            meta.error && meta.touched,
                                                                                    }
                                                                                )}
                                                                                variant="outlined"
                                                                                multiline
                                                                                rows="4"
                                                                                rowsMax="20"
                                                                                value={keyStakeholdersQuote}
                                                                                onChange={this.handleKeyStakeholdersQuoteChange(
                                                                                    idx
                                                                                )}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Button
                                                        variant="contained"
                                                        className="btn-create"
                                                        color="primary"
                                                        onClick={this.handleAddKeyStakeholdersQuote}
                                                    >
                                                        <Add/>
                                                        Add Quotation
                                                    </Button>
                                                </Grid>

                                                <h3>Photograph(s)</h3>

                                                <Grid container spacing={24} alignItems="flex-start">
                                                    {this.state.slides.map((slide, idx, i) =>
                                                        slide.url !== null ? (
                                                            <React.Fragment key={idx}>
                                                                <Grid item xs={12} className="slideImage">
                                                                    <div
                                                                        href="#"
                                                                        title="Delete"
                                                                        className={classNames("btn", "btn-image", {
                                                                            [classes.hoverDelete]:
                                                                            typeof slide.url === "string",
                                                                        })}
                                                                        onClick={this.handleRemoveSlide(idx)}
                                                                    >
                                                                        <img
                                                                            id={`slideImage${idx}`}
                                                                            src={slide.url}
                                                                            className={classes.imgEdit}
                                                                            alt=""
                                                                        />
                                                                        <i className={classes.imgEditClose}>Х</i>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} className="slideInputFile">
                                                                    <input
                                                                        type="hidden"
                                                                        name={`photos_attributes[${idx}][id]`}
                                                                        value={idx}
                                                                    />
                                                                    {this.renderFileField({
                                                                        name: `photos_attributes[${idx}][photo]`,
                                                                        accept: "images",
                                                                        multifile: false,
                                                                        id: `photos_attributes[${idx}][photo]`,
                                                                        index: idx,
                                                                    })}
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    {/* <button className="remove-button" onClick={this.handleRemoveSlide(idx)}>-</button> */}
                                                                    <Field
                                                                        name={`photos_attributes[${idx}][caption]`}
                                                                    >
                                                                        {({input, meta}) => (
                                                                            <TextField
                                                                                margin="normal"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                placeholder="Photo caption"
                                                                                inputProps={{maxLength: "2000"}}
                                                                                className={classNames(
                                                                                    classes.textarea,
                                                                                    "text-area",
                                                                                    {
                                                                                        "error-field":
                                                                                            meta.error && meta.touched,
                                                                                    }
                                                                                )}
                                                                                variant="outlined"
                                                                                multiline
                                                                                rows="3"
                                                                                rowsMax="20"
                                                                                value={slide.quote}
                                                                                onChange={this.photosQuoteChange(idx)}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Grid>
                                                            </React.Fragment>
                                                        ) : (
                                                            ""
                                                        )
                                                    )}
                                                    <Button
                                                        variant="contained"
                                                        className="btn-create"
                                                        color="primary"
                                                        onClick={this.handleAddSlide}
                                                    >
                                                        <Add/>
                                                        Add Photograph
                                                    </Button>
                                                </Grid>

                                                <h3 className="m100">
                                                    List Key Documents For Reference
                                                </h3>
                                                <p>
                                                    Please write a list of relevant documentation and
                                                    provide them in annex or upload supplementary
                                                    documentation to DMS (if applicable).
                                                </p>
                                                <Field name="planningDocuments">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Planning documents:"
                                                            margin="normal"
                                                            placeholder="Text"
                                                            className={classNames(
                                                                "text-field",
                                                                "full",
                                                                classes.textField
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                {this.renderFileField({
                                                    name: "planningDocumentsReport",
                                                    label: "Report",
                                                })}
                                                <Field name="governmentResolutions">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Decision made by enterprise:"
                                                            margin="normal"
                                                            placeholder="Text"
                                                            className={classNames(
                                                                "text-field",
                                                                "full",
                                                                classes.textField
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                {this.renderFileField({
                                                    name: "governmentResolutionsReport",
                                                    label: "Report",
                                                })}
                                                <Field name="meetingProposals">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Meeting proposals:"
                                                            margin="normal"
                                                            placeholder="Text"
                                                            className={classNames(
                                                                "text-field",
                                                                "full",
                                                                classes.textField
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                {this.renderFileField({
                                                    name: "meetingProposalsReport",
                                                    label: "Report",
                                                })}
                                                <Field name="createdQuestionnaires">
                                                    {({input}) => (
                                                        <TextField
                                                            label="Questionnaires created and/or used for implementation of the good practice:"
                                                            margin="normal"
                                                            placeholder="Text"
                                                            className={classNames(
                                                                "text-field",
                                                                "full",
                                                                classes.textField
                                                            )}
                                                            variant="outlined"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                                {this.renderFileField({
                                                    name: "questionnariesReport",
                                                    label: "Report",
                                                })}

                                                <h3>Media Coverage Of The Good Practice</h3>
                                                <p>
                                                    Provide a list of URLs directing to articles, videos
                                                    or scans of printed documents appearing in local,
                                                    regional or national mass media displaying the good
                                                    practice.
                                                </p>
                                                <InputLabel>URLs:</InputLabel>
                                                {mediaUrls.map(this.renderUrlField)}
                                                <Button
                                                    variant="contained"
                                                    className="btn-create"
                                                    color="primary"
                                                    onClick={this.handleNewUrl}
                                                >
                                                    <Add/>
                                                    Add URL
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={24} alignItems="center">
                                            <Grid item xs={8}>
                                                <h3 className="m40" style={{marginBottom: 0}}>
                                                    Further use of the good practice
                                                </h3>
                                                <Field name="furtherUsage">
                                                    {({input, meta}) => (
                                                        <TextField
                                                            margin="normal"
                                                            className={classNames(
                                                                classes.textarea,
                                                                "text-area",
                                                                "h135",
                                                                "big-label",
                                                                {"error-field": meta.error && meta.touched}
                                                            )}
                                                            variant="outlined"
                                                            multiline
                                                            rows="4"
                                                            rowsMax="20"
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={24}
                                            alignItems="center"
                                            className={classes.buttons}
                                        >
                                            <Grid item xs={12} className={classes.alignRight}>
                                                <Button
                                                    variant="contained"
                                                    className="button-cancel"
                                                    onClick={this.handleClose}
                                                >
                                                    <i></i>
                                                    {I18n[lang].common.cancel}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className="button-submit"
                                                    type="submit"
                                                    color="primary"
                                                    id="submitForm"
                                                >
                                                    <i></i>
                                                    {I18n[lang].common.save}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            />
                        </Typography>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(withRoot(withStyles(styles)(ItemEdit)));

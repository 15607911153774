import React, { useState } from "react";
import { Popup as LeafletPopup } from "react-leaflet";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { useLangStore } from "../../stores/useLangStore";
import { useNetworkMapStore } from "../../stores/network-map";
import { activityRoute, organizationsRoute } from "../../utils/routes";

const styles = {
  info: {
    fontWeight: 400,
    whiteSpace: "nowrap",
  },
  title: {
    fontWeight: 700,
  },
};

const ActivityInfo = withStyles(styles)(({ classes, data }) => {
  const langStore = useLangStore();
  const name = langStore.isEng ? data.nameEn : data.nameUa;
  const format = langStore.isEng ? data.formatEn : data.formatUa;
  const categories = langStore.isEng ? data.categoriesEn : data.categoriesUa;

  return (
    <div className={classes.info}>
      <div className={classes.title}>Activity</div>
      <div>
        Name:{" "}
        <a
          href={`${activityRoute}/view/${data.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
      </div>
      <div>Format: {format}</div>
      <div>Categories: {categories}</div>
      <div>Participants: {data.participantCount}</div>
    </div>
  );
});

const OrganizationInfo = withStyles(styles)(({ classes, data }) => {
  const langStore = useLangStore();
  const name = langStore.isEng ? data.nameEn : data.nameUa;

  return (
    <div className={classes.info}>
      <div className={classes.title}>Ogranization</div>
      <div>
        Name:{" "}
        <a
          href={`${organizationsRoute}/view/${data.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
      </div>
      <div>Participants: {data.participantCount}</div>
    </div>
  );
});

export const VertexPopup = ({ vertex }) => {
  const store = useNetworkMapStore();
  const [data, setData] = useState(null);

  const handleOpen = async () => {
    if (data === null) {
      const res = await store.getVertexData(vertex);
      setData(res);
    }
  };

  return (
    <LeafletPopup onOpen={handleOpen}>
      {!data && <CircularProgress />}
      {data && vertex.isActivity && <ActivityInfo data={data} />}
      {data && vertex.isOrganization && <OrganizationInfo data={data} />}
    </LeafletPopup>
  );
};

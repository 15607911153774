import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing.unit * 2,
    backgroundColor: '#f5f5f5'
  }
});

function Toolbar(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Typography variant="h4" color="primary">
        Tags
      </Typography>
      <div>
        <Fab 
          to="/tags"
          component={RouterLink} 
          color="primary"
          size="medium"
        >
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
}

export default withStyles(styles)(Toolbar);

import React from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { config } from "../style/config";

const styles = {
  root: {
    color: "#fff",
    padding: "8px 22px",
    borderRadius: "10px",
  },
  primary: {
    backgroundColor: config.colors.primary,
    "&:hover": {
      backgroundColor: config.colors.primary,
      opacity: "0.9",
    },
  },
  secondary: {
    backgroundColor: config.colors.primary,
    "&:hover": {
      backgroundColor: config.colors.primary,
      opacity: "0.9",
    },
  },
  tertiary: {
    backgroundColor: config.colors.primary,
    "&:hover": {
      backgroundColor: config.colors.primary,
      opacity: "0.9",
    },
  },
  quaternary: {
    backgroundColor: config.colors.primary,
    "&:hover": {
      backgroundColor: config.colors.primary,
      opacity: "0.9",
    },
  },
  icon: {
    fontSize: "16px",
    marginRight: "8px",
  },
};

const GIZButton = (props) => {
  const { children, classes, icon = null, color, className, ...rest } = props;
  const { primary, secondary, tertiary, quaternary, icon: iconClass, ...restClasses } = classes;

  let cssClass = "";
  switch (color) {
    case "primary":
      cssClass = primary;
      break;
    case "secondary":
      cssClass = secondary;
      break;
    case "tertiary":
      cssClass = tertiary;
      break;
    case "quaternary":
      cssClass = quaternary;
      break;
    default:
      cssClass = "none";
  }

  let IconComponent;
  if (icon) {
    IconComponent = require(`@material-ui/icons`)[icon];
  }


  return (
    <Button
      variant="contained"
      classes={restClasses}
      className={classNames(cssClass, className)}
      {...rest}
    >
      {IconComponent && <IconComponent className={iconClass} />}
      {children}
    </Button>
  );
};

export default withStyles(styles)(GIZButton);

import React from "react";
import { observer } from "mobx-react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../withRoot";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { StoreContext } from "../stores/context";
import { GIZHeader } from "../components/GIZHeader";
import { GIZFooter } from "../components/GIZFooter";
import Home from "../pages/home";
import Login from "../pages/login/";
import ForgotPassword from "../pages/forgot-password";
import ResetPassword from "../pages/reset-password";
import Search from "../pages/search/";
import SearchXS from "../pages/search/xs";
import Activities from "../pages/activities";
import Participants from "../pages/participants/";
import About from "../pages/about";
import { CategoryGroups } from "../pages/category/group";
import { CategoryResults } from "../pages/category/result";
import { Categories } from "../pages/category";
import SelectOptions, { Formats, Characters } from "../pages/selectOptions";
import { Characteristics } from "../pages/characteristics";
import Region from "../pages/region/";
import Team from "../pages/team/";
import User from "../pages/user/";
import Communities from "../pages/communities/";
import Organizations from "../pages/organizations/";
import { Trainers } from "../pages/persons";
import Templates from "../pages/templates/";
import Support from "../pages/support/";
import Settings from "../pages/settings/";
import Password from "../pages/password/";
import EuropeActions from "../pages/europe-actions";
import CsoGrants from "../pages/cso-grants";
import DonorProjects from "../pages/donor-projects";
import ThematicPackages from "../pages/thematic-packages";
import Indicator from "../pages/indicator";
import IndicatorDetails from "../pages/indicator/details/";
import GoodPractice from "../pages/good-practice/";
import Dashboard from "../pages/dashboard";
import Graphs from "../pages/graphs";
import InteractiveMap from "../pages/map";
import NetworkMap from "../pages/network-map";
import MailLogs from "../pages/mail-logs";
import Notallowed from "../pages/notallowed";
import Notfound from "../pages/notfound";
import UIDemo from "./ui-demo";
import Preregistration from "./preregistration";
import Notifier from "../components/notifier";
import V2Routes from 'routes';

import {
  loginRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
  preregistrationRoute,
  activityRoute,
  formatRoute,
  characterRoute,
  characteristicRoute,
  categoryGroupRoute,
  categoryRoute,
  networkMapRoute,
  categoryResultRoute,
} from "../utils/routes";

import {
  FundingSourceService,
  EmployerLevelService,
  EmployertypeService,
  TargetGroupService,
  TargetLevelService,
  OrganizerService,
} from "../services/SelectOptionService";

import { I18n } from "../i18n";
import { config } from "../style/config";

const styles = (theme) => ({
  root: {
    letterSpacing: "normal",
    background: "#fff",
    "& a": {
      cursor: "pointer",
      textDecoration: "none",
    },
    "& h2": {
      textTransform: "uppercase !important",
      fontWeight: "700",
      color: config.colors.primary,
      fontSize: "60px",
      margin: "2.6vh 0 1.3vh 4vw",
      [theme.breakpoints.down("1700px")]: {
        fontSize: "40px",
      },
    },
    "& .modal-actions": {
      textAlign: "right",
      "& .button-cancel": {
        marginRight: "20px",
      },
    },
    "& .actions": {
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        "& span": {
          display: "none",
        },
      },
      "& button": {
        marginTop: "-8px"
      },
      "& a, button": {
        display: "inline-flex",
        color: config.colors.primaryBackground,
        fontSize: "12px",
        minHeight: "25px",
        lineHeight: "25px",
        height: "47px",
        width: "6vw",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: config.colors.primary,
        padding: "6px 12px",
        "& i": {
          display: "block",
          width: "16px",
          height: "16px",
          marginRight: "9px",
        },
        "&.view": {
          "& i": {
            width: "19px",
            background:
              "url(/images/icons/icon-action-view.svg) no-repeat 0 center / 19px 20px",
          },
        },
        "&.edit": {
          "& i": {
            top: "-2px",
            background:
              "url(/images/icons/icon-action-edit.svg) no-repeat 0 0 / 20px 20px",
          },
        },
        "&.delete": {
          "& i": {
            background:
              "url(/images/icons/icon-action-delete.svg) no-repeat 0 0 / 14px 14px",
          },
        },
        "&:first-child": {
          borderRadius: "6px 0 0 6px",
        },
        "&:last-child": {
          borderRadius: "0 6px 6px 0",
        },
      },
      "& svg": {
        width: "15px",
        height: "15px",
        marginRight: "2px",
      },
    },
  },
  content: {
    minHeight: "calc(100vh - 100px)",
    [theme.breakpoints.down(1440)]: {
      minHeight: "calc(100vh - 90px)",
      justifyContent: "space-between"
    },
  },

  searchActivity: {
    marginLeft: "4vw",
    borderRadius: "0.5rem",
    fontWeight: "100",
    width: "34%",
    background: "#eef2f6",
    marginBottom: "20px",
    marginTop: "0",
    [theme.breakpoints.down(1700)]: {
      width: "28%",
    },
    "& >div": {
      padding: "0",
      "& div": {
        height: "100%",
        maxHeight: "100%",
        position: "absolute",
        right: "0",
      },
    },
    "& label": {
      fontSize: "20pt",
      top: "-12px",
      color: "#778899",
      [theme.breakpoints.down(1700)]: {
        fontSize: "12pt",
        top: "-12px",
      },
    },
    "& fieldset": {
      border: "none",
    },
    "& input": {
      fontSize: "20pt",
      padding: "15px 14px",
      [theme.breakpoints.down(1700)]: {
        padding: "10px 14px",
        fontSize: "12pt",
      },
    },
  },
});

class Index extends React.Component {
  static contextType = StoreContext;

  state = {
    tags: [],
    filters: "",
  };

  async componentDidMount() {
    const { history } = this.props;
    this.handleLocationChange(history.location);
    history.listen(this.handleLocationChange);
  }

  handleLocationChange = (location) => {
    const { appStore, routerStore } = this.context;
    if (routerStore.isPublicRoute) {
      appStore.hideHeader();
    } else {
      appStore.showHeader();
    }
  };

  handleTags = (tags, filters) => {
    this.setState({ tags, filters });
  };

  handleSearch = (tags, filters, oesQuery, xsQuery) => {
    this.setState({ tags, filters, oesQuery, xsQuery });
  };

  restrictedAccess = (permission, componentFunc) => {
    // On the page load currentUser could be null, so we should pass component render
    // Don't worry. User will be redirected after currentUser assigning
    if (permission || !this.context.userStore.data) {
      return componentFunc;
    } else {
      return () => <Redirect to="/" />;
    }
  };

  oesRoute = () => {
    const { langStore } = this.context;
    const { tags, filters, oesQuery, xsQuery } = this.state;

    return (
      <Search
        lang={langStore.lang}
        onSearchChange={this.handleSearch}
        selectedTags={tags}
        selectedFilters={filters}
        searchQuery={oesQuery}
        xsQuery={xsQuery}
      />
    );
  };

  render() {
    const { history, classes } = this.props;
    const { isHeaderShowing } = this.context.appStore;
    const { isMinreg, isOES, data: currentUser } = this.context.userStore;
    const { lang } = this.context.langStore;
    const { tags, filters } = this.state;

    const indicatorsAllowed = currentUser && currentUser.indicatorsAllowed;
    const isAdmin = currentUser && currentUser.isAdmin;
    const dashboardAllowed = !isMinreg;

    return (
      <div className={classes.root}>
        <Router history={history}>
          <Typography component="div">
            <div className={classes.content}>
              {isHeaderShowing && <GIZHeader />}
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    if (isMinreg) {
                      return this.oesRoute();
                    } else {
                      return <Home user={currentUser} lang={lang} />;
                    }
                  }}
                />
                <Route path={loginRoute} component={Login} />
                <Route path={forgotPasswordRoute} component={ForgotPassword} />
                <Route path={resetPasswordRoute} component={ResetPassword} />
                <Route
                  path={preregistrationRoute}
                  component={Preregistration}
                />

                <Route
                  path="/support"
                  render={() => <Support user={currentUser} lang={lang} />}
                />
                <Route path="/settings" component={Settings} />
                <Route path="/password" component={Password} />

                <Route path="/notallowed" component={Notallowed} />

                <Route
                  path={activityRoute}
                  render={() => <Activities user={currentUser} lang={lang} />}
                />
                <Route path="/search/oes" render={this.oesRoute} />
                {isAdmin && <Route path="/ui-demo" component={UIDemo} />}
                {!isMinreg && (
                  <Switch>
                    {!isOES && (
                      <Route
                        path="/search/xs"
                        render={() => (
                          <SearchXS
                            lang={lang}
                            onSearchChange={this.handleSearch}
                            selectedTags={tags}
                            selectedFilters={filters}
                            searchQuery={this.state.xsQuery}
                            oesQuery={this.state.oesQuery}
                          />
                        )}
                      />
                    )}

                    <Route
                      path="/participants"
                      render={() => <Participants lang={lang} />}
                    />
                    <Route path="/trainers" component={Trainers} />
                    <Route path="/about" component={About} />
                    <Route
                      path="/communities"
                      render={() => (
                        <Communities user={currentUser} lang={lang} />
                      )}
                    />
                    <Route
                      path="/organizations"
                      render={() => <Organizations lang={lang} />}
                    />
                    <Route
                      path="/indicators"
                      render={() => (
                        <Indicator user={currentUser} lang={lang} />
                      )}
                    />
                    <Route
                      path="/indicator/:id"
                      render={this.restrictedAccess(indicatorsAllowed, () => (
                        <IndicatorDetails user={currentUser} lang={lang} />
                      ))}
                    />
                    <Route
                      path="/good-practices"
                      render={() => (
                        <GoodPractice user={currentUser} lang={lang} />
                      )}
                    />
                    {/* should be declared in the index view */}
                    {/* <Route
                      path="/good-practices/view/:id"
                      render={() => (
                        <GoodPracticeView user={currentUser} lang={lang} />
                      )}
                    />
                    <Route
                      path="/good-practices/edit/:id"
                      render={() => (
                        <GoodPracticeEdit user={currentUser} lang={lang} />
                      )}
                    />
                    <Route
                      path="/good-practices/create"
                      render={() => (
                        <GoodPracticeEdit user={currentUser} lang={lang} />
                      )}
                    /> */}
                    <Route
                      path="/dashboard"
                      render={this.restrictedAccess(dashboardAllowed, () => (
                        <Dashboard user={currentUser} lang={lang} />
                      ))}
                    />
                    <Route
                      path="/graphs"
                      render={this.restrictedAccess(dashboardAllowed, () => (
                        <Graphs user={currentUser} lang={lang} />
                      ))}
                    />
                    <Route
                      path="/map"
                      render={this.restrictedAccess(dashboardAllowed, () => (
                        <InteractiveMap user={currentUser} lang={lang} />
                      ))}
                    />
                    <Route
                      path={networkMapRoute}
                      render={this.restrictedAccess(dashboardAllowed, () => (
                        <NetworkMap />
                      ))}
                    />

                    <Route
                      path="/indicator-graphs/:id?"
                      render={this.restrictedAccess(dashboardAllowed, () => (
                        <Graphs
                          user={currentUser}
                          lang={lang}
                          kind={"indicators"}
                        />
                      ))}
                    />

                    <Route
                      path="/templates"
                      render={() => (
                        <Templates user={currentUser} lang={lang} />
                      )}
                    />
                    <Route
                      path="/team"
                      render={this.restrictedAccess(isAdmin, () => (
                        <Team lang={lang} />
                      ))}
                    />
                    <Route
                      path="/user"
                      render={this.restrictedAccess(isAdmin, () => (
                        <User lang={lang} />
                      ))}
                    />
                    <Route
                      path="/maillog"
                      component={this.restrictedAccess(isAdmin, MailLogs)}
                    />
                    <Route
                      path="/europe-actions"
                      render={this.restrictedAccess(isAdmin, () => (
                        <EuropeActions lang={lang} />
                      ))}
                    />
                    <Route
                      path="/thematic-packages"
                      render={this.restrictedAccess(isAdmin, () => (
                        <ThematicPackages lang={lang} />
                      ))}
                    />
                    <Route
                      path={categoryGroupRoute}
                      render={this.restrictedAccess(isAdmin, () => (
                        <CategoryGroups />
                      ))}
                    />
                    <Route
                      path={categoryRoute}
                      render={this.restrictedAccess(isAdmin, () => (
                        <Categories lang={lang} user={currentUser} />
                      ))}
                    />
                    <Route
                      path={categoryResultRoute}
                      render={this.restrictedAccess(isAdmin, () => (
                        <CategoryResults lang={lang} user={currentUser} />
                      ))}
                    />
                    <Route
                      path="/employer-level"
                      render={this.restrictedAccess(isAdmin, () => (
                        <SelectOptions
                          path="employer-level"
                          serviceClass={EmployerLevelService}
                          lang={lang}
                          i18n={I18n[lang].employerLevel}
                          title={I18n[lang].common.employerLevel}
                        />
                      ))}
                    />
                    <Route
                      path="/employer-type"
                      render={this.restrictedAccess(isAdmin, () => (
                        <SelectOptions
                          path="employer-type"
                          serviceClass={EmployertypeService}
                          lang={lang}
                          i18n={I18n[lang].employerType}
                        />
                      ))}
                    />
                    <Route
                      path={formatRoute}
                      render={this.restrictedAccess(isAdmin, () => (
                        <Formats />
                      ))}
                    />
                    <Route
                      path={characterRoute}
                      render={this.restrictedAccess(isAdmin, () => (
                        <Characters />
                      ))}
                    />
                    <Route
                      path={characteristicRoute}
                      render={this.restrictedAccess(isAdmin, () => (
                        <Characteristics />
                      ))}
                    />
                    <Route
                      path="/funding-source"
                      render={this.restrictedAccess(isAdmin, () => (
                        <SelectOptions
                          path="funding-source"
                          serviceClass={FundingSourceService}
                          lang={lang}
                          i18n={I18n[lang].fundingSources}
                        />
                      ))}
                    />
                    <Route
                      path="/organizer"
                      render={this.restrictedAccess(isAdmin, () => (
                        <SelectOptions
                          path="organizer"
                          serviceClass={OrganizerService}
                          lang={lang}
                          i18n={I18n[lang].organizer}
                        />
                      ))}
                    />
                    <Route
                      path="/region"
                      render={this.restrictedAccess(isAdmin, () => (
                        <Region lang={lang} />
                      ))}
                    />
                    <Route
                      path="/target-group"
                      render={this.restrictedAccess(isAdmin, () => (
                        <SelectOptions
                          path="target-group"
                          serviceClass={TargetGroupService}
                          lang={lang}
                          i18n={I18n[lang].targetGroup}
                        />
                      ))}
                    />
                    <Route
                      path="/target-level"
                      render={this.restrictedAccess(isAdmin, () => (
                        <SelectOptions
                          path="target-group"
                          serviceClass={TargetLevelService}
                          lang={lang}
                          i18n={I18n[lang].targetLevel}
                        />
                      ))}
                    />
                    <Route
                      path="/cso-grants"
                      render={this.restrictedAccess(isAdmin, () => (
                        <CsoGrants lang={lang} />
                      ))}
                    />
                    <Route
                      path="/donor-projects"
                      render={this.restrictedAccess(isAdmin, () => (
                        <DonorProjects lang={lang} />
                      ))}
                    />

                    <V2Routes />

                  </Switch>
                )}
                <Route component={Notfound} />
              </Switch>
            </div>
            <GIZFooter />
            <Notifier />
          </Typography>
        </Router>
      </div>
    );
    //}
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Sentry.withProfiler(withRoot(withStyles(styles)(observer(Index))));

import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { Mail } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import fetchData from '../../utils/fetch';
import fetchMultiPartData from '../../utils/fetchMultiPart';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import Loader from '../../components/loader';


const styles = theme => ({
  root: {
    minHeight: 'calc(100vh - 210px)'
  },
  textField: {
    width: '100%',
    marginBottom: '20px',
    background: '#e1edfb',
    '&.error-field fieldset': {
      border: '1px solid #fe8368',
      borderLeftWidth: '3px'
    }
  },
  formControl: {
    width: '100%',
    marginBottom: '20px',
    background: '#e1edfb',
  },
  textArea: {
    width: '100%',
    marginBottom: '20px',
    background: '#e1edfb',
    '& textarea': {
      resize: 'both',
      overflow: 'scroll'
    },
    '&.error-field fieldset': {
      border: '1px solid #fe8368',
      borderLeftWidth: '3px'
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '400px',
    margin: '0 auto',
    justifyContent: 'stretch'
  },
  formContainer: {
    padding: '30px 0',
    marginBottom: '50px'
  },
  alignRight: {
    textAlign: 'right'
  },
  success: {
    color: '#a94442',
    border: '1px solid #8bc34a',
    borderRadius: '4px',
    backgroundColor: '#a2cf6e',
    padding: '10px 20px',
    width: '400px',
    margin: '0 auto 50px'
  }
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const required = value => (value ? undefined : "Required");

class Password extends React.Component {
    state = {
      loading: true,
      successMessage: false,
      items: {}
    };

    componentDidMount() {
      this.getData();
    }

    async getData() {
      const data = await fetchData('get', '/account');
      this.setState({ loading: false, items: await data[0] });
    }

    saveItem = async (values) => {
      var data = new FormData();

      for(let name in values) {
        data.append(name, values[name]);
      }

      await fetchMultiPartData('put', '/settings', data);

      this.setState({ loading: false, successMessage: true });
    }

    onSubmit = async values => {
      this.setState({ loading: true });
      await sleep(300)
      this.saveItem(values);
    }

    render() {
      const { classes } = this.props;
      const { successMessage, items } = this.state;
        return (
          <div className={classes.root}>

            {this.state.loading && (
              <Loader />
            )}

            <Typography gutterBottom variant="h2" component="h2">
              Password for [{items.login}]
            </Typography>

              {successMessage && (
                <div className={classes.success}>
                  Your password successfully saved.
                </div>
              )}

              {!successMessage && (
                <Typography className={classes.data}>
                  <div className={classes.formContainer}>
                    <Form
                    onSubmit={this.onSubmit}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                      <form onSubmit={handleSubmit} className={classes.form}>

                        <Field name="password" validate={required}>
                          {({ input, meta }) =>
                            <TextField
                              label="Password"
                              margin="normal"
                              type="password"
                              className={classNames(classes.textField, {'error-field': meta.error && meta.touched} ) }
                              variant="outlined"
                              {...input}
                            />
                          }
                        </Field>

                        <Field name="passwordConfirmation" validate={required}>
                          {({ input, meta }) =>
                            <TextField
                              label="Confirm Password"
                              margin="normal"
                              type="password"
                              className={classNames(classes.textField, {'error-field': meta.error && meta.touched} ) }
                              variant="outlined"
                              {...input}
                            />
                          }
                        </Field>

                        <Grid container spacing={24} alignItems='center'>
                          <Grid item xs={12} className={classes.alignRight}>
                            <Button variant="contained" type="submit" color="primary">
                              <Mail />&nbsp;
                              Send
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                  </div>
                </Typography>
              )}
          </div>
        );
    }
}

export default withRoot(withStyles(styles)(Password));

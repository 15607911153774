import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSearch } from 'support/hooks';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SortIcon from '@material-ui/icons/Sort';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `0 ${theme.spacing.unit * 2}px`,
  }
});

const options = [
  { 
    value: 'start_date desc', 
    label: 'Start date descending' 
  },
  { 
    value: 'start_date asc', 
    label: 'Start date ascending' 
  },
  { 
    value: 'end_date desc', 
    label: 'End date descending' 
  },
  { 
    value: 'end_date asc', 
    label: 'End date ascending' 
  }
];

function SortingBar(props) {
  const { classes } = props;
  const [ anchorEl, setAnchorEl ] = useState();
  const history = useHistory();
  const search = useSearch();

  const toggleMore = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);

  const handleOrderChange = (value) => {
    const [field, direction] = value.split(' ');

    search.set('order[][field]', field);
    search.set('order[][direction]', direction);

    history.push({ search: search.toString() });
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="More"
        onClick={toggleMore}
      >
        <SortIcon />
      </IconButton>
      <Popper 
        anchorEl={anchorEl} 
        open={Boolean(anchorEl)}
        transition 
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={toggleMore}>
                <MenuList>
                  {options.map(({ value, label }) => (
                    <MenuItem 
                      key={value}
                      onClick={() => handleOrderChange(value)}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default withStyles(styles)(SortingBar);

import { useHistory, useParams } from 'react-router-dom';
import { withNotifications } from 'components/notifications';
import { useApi } from 'support/api';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

function Delete(props) {
  const { notifications } = props;
  const history = useHistory();
  const { id } = useParams();
  const api = useApi();

  const handleClose = () => {
    history.goBack();
  };

  const handleDelete = async () => {
    notifications.info('Deleting', null);

    await api.v2.tags(id).delete();

    notifications.success('Deleted');

    history.replace(
      `/tags`, 
      { 
        action: {
          name: 'delete',
          params: { id: parseInt(id) }
        }
      }
    );
  };

  return (
    <Dialog open>
      <DialogTitle>Delete tag?</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          No
        </Button>
        <Button onClick={handleDelete} color="primary" variant="outlined" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withNotifications(Delete);

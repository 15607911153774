import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { config } from "../style/config";

const styles = {
  root: {
    padding: "1rem 2.5rem",
    fontSize: "1.6rem",
    color: config.colors.primary,
    backgroundColor: config.colors.primaryBackground,
  },
};

export const GIZPublicHeader = withStyles(styles)(({ classes }) => {
  return (
    <div className={classes.root}>Digital Transformation of small and medium-sized enterprises in Eastern Partnership countries</div>
  );
});

import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../stores/context";
import { characteristicRoute } from "../../utils/routes";
import { CharacteristicService } from "../../services/SelectOptionService";
import IndexTable from "../../components/table";
import Edit from "../../components/dialog/edit";
import { FormTextField } from "../../components/dialog/formField";
import styles from "../../style/styles";

const Item = withStyles(styles)(
  observer((props) => {
    const { langStore } = useContext(StoreContext);
    const { classes, ...rest } = { ...props };

    const handleGetData = async (service, editorState) => {
      if (editorState.id === undefined) {
        return editorState;
      }
      const item = await service.GetItem(editorState.id);
      const state = { ...editorState, ...item };
      return state;
    };

    return (
      <Edit
        lang={langStore.lang}
        i18nCommon={langStore.common}
        i18n={langStore.characteristic}
        handleGetData={handleGetData}
        {...rest}
        render={(fieldProps) => (
          <Paper className={classes.paper}>
            <Grid container direction="column" spacing={16}>
              <Grid item xs={6}>
                <FormTextField
                  fieldName="langKey"
                  i18n={langStore.characteristic}
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  fieldName="titleEn"
                  i18n={langStore.characteristic}
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  fieldName="titleUa"
                  i18n={langStore.characteristic}
                  {...fieldProps}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
      />
    );
  })
);

export const Characteristics = observer(() => {
  const { langStore } = useContext(StoreContext);
  const path = characteristicRoute.slice(1);
  const serviceClass = CharacteristicService;

  return (
    <IndexTable
      i18nCommon={langStore.common}
      i18n={langStore.characteristic}
      path={path}
      lang={langStore.lang}
      serviceClass={serviceClass}
      columns={[
        { id: "id", title: "ID" },
        { id: "langKey", title: "Key" },
        { id: "titleEn", title: "UA" },
        { id: "titleUa", title: "EN" },
      ]}
      onEditItem={(itemId, item, handleItemSave) => {
        return (
          <Item
            serviceClass={serviceClass}
            item={item}
            itemId={itemId}
            indexPath={path}
            handleItemSave={handleItemSave}
            isEditable={true}
          />
        );
      }}
      onViewItem={(itemId, item) => {
        return (
          <Item
            serviceClass={serviceClass}
            item={item}
            itemId={itemId}
            indexPath={path}
            isEditable={false}
          />
        );
      }}
    />
  );
});

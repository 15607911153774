import Card from '../../components/dnd/Card';

function List(props) {
  const { cards, onMove, children, itemType } = props;

  const renderCard = (card, name, index) => {
    return (
      <Card
        key={card?.id || card.key}
        index={index}
        id={card?.id}
        onMove={onMove}
        type={itemType}
      >
        {children(name, index)}
      </Card>
    );
  };

  return (
    <>
      {cards.map((card, i) => renderCard(cards.value[i], card, i))}
    </>
  );
}

export default List;


import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    margin: '20px auto',
    padding: '0 10px',
    maxWidth: '800px'
  }
};

function Wrapper(props) {
  const { classes, children } = props;

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
}

export default withStyles(styles)(Wrapper);

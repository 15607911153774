import React from 'react';
import {
  Grid
} from '@material-ui/core';
import AmalgamationProgressChart from '../../components/charts/registry/amalgamation-progress';
import GenderBalanceChart from '../../components/charts/registry/gender-balance';
import ParticipantEmployerLevelsChart from '../../components/charts/registry/participant-employer-levels';
import ParticipantEmployerTypesChart from '../../components/charts/registry/participant-employer-types';
import ActivityTargetGroupsChart from "../../components/charts/registry/ActivityTargetGroupsChart";
import ActivityFormatsChart from "../../components/charts/registry/ActivityFormatsChart";
import ActivityResultsChart from "../../components/charts/registry/ActivityResultsChart";
import CapacityActivitiesChart from "../../components/charts/registry/CapacityActivitiesChart";
import CapacityActivityHromadasChart from "../../components/charts/registry/CapacityActivityHromadasChart";
import ActivitySatisfactionChart from "../../components/charts/registry/ActivitySatisfactionChart";

class RegistryGraphs extends React.Component {
  render() {
    const graphsFilter = this.props.graphsFilter;

    return (
      <>
        <Grid item xs={12}>
          <GenderBalanceChart filter={graphsFilter} />
        </Grid>
        <Grid item xs={6}>
          <ParticipantEmployerLevelsChart filter={graphsFilter} />
        </Grid>
        <Grid item xs={6}>
          <ParticipantEmployerTypesChart filter={graphsFilter} />
        </Grid>
        <Grid item xs={6}>
          <AmalgamationProgressChart filter={graphsFilter} />
        </Grid>
        <Grid item xs={6}>
          <ActivityTargetGroupsChart filter={graphsFilter} />
        </Grid>
        <Grid item xs={6}>
          <ActivitySatisfactionChart filter={graphsFilter} />
        </Grid>
        <Grid item xs={6}>
          <ActivityFormatsChart filter={graphsFilter} />
        </Grid>
        <Grid item xs={6}>
          <ActivityResultsChart filter={graphsFilter} />
        </Grid>

      </>
    );
  }
}

export default RegistryGraphs;

import { useHistory, useParams } from 'react-router-dom';
import DataSource from 'components/DataSource';
import { withNotifications } from 'components/notifications';
import { useApi } from 'support/api';
import Form from './Form';

function Edit(props) {
  const { notifications } = props;
  const { id } = useParams();
  const api = useApi();
  const history = useHistory();

  const handleUpdate = async (values) => {
    notifications.info('Saving', null);

    const { name } = values;

    await api.v2.tags(id).put({ name });

    notifications.success('Saved');

    history.replace(
      `/tags/${id}/edit`, 
      {
        action: {
          name: 'update',
          params: { id: parseInt(id), name }
        }
      }
    );
  };

  return (
    <DataSource path={`v2/tags/${id}`}>
      {(tag) => (
        <Form onSubmit={handleUpdate} values={tag} />
      )}
    </DataSource>
  );
}

export default withNotifications(Edit);

import React, { useState } from "react";
import { Popup as LeafletPopup } from "react-leaflet";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { useLangStore } from "../../stores/useLangStore";
import { useNetworkMapStore } from "../../stores/network-map";
import {
  activityRoute,
  participantRoute,
  organizationsRoute,
} from "../../utils/routes";

const styles = {
  info: {
    fontWeight: 400,
  },
  title: {
    fontWeight: 700,
    fontSize: "14px",
    minWidth: "150px",
  },
  subtitle: {
    fontWeight: 700,
    fontSize: "13px",
    marginTop: "10px",
    marginBottom: "2px",
  },
  table: {
    borderCollapse: "collapse",
    whiteSpace: "nowrap",
  },
  cell: {
    borderBottom: "1px solid #999",
    padding: "2px 6px",
  },
};

const ActivityToActivityInfo = withStyles(styles)(({ classes, edge, data }) => {
  const langStore = useLangStore();
  const { isEng } = langStore;

  return (
    <div className={classes.info}>
      {data.organizations?.length > 0 && (
        <>
          <div className={classes.subtitle}>Organizations</div>
          <table className={classes.table}>
            <tbody>
              {data.organizations.map((item) => (
                <tr key={item.id}>
                  <td className={classes.cell}>
                    <a
                      href={`${organizationsRoute}/view/${item.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {isEng ? item.nameEn : item.nameUa}
                    </a>
                  </td>
                  <td className={classes.cell}>
                    {isEng ? item.typeEn : item.typeUa}
                  </td>
                  <td className={classes.cell}>
                    {isEng ? item.relationEn : item.relationUa}
                  </td>
                  <td className={classes.cell}>
                    {item.participantCount > 0 &&
                      `${item.participantCount} participant(s)`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
});

const ActivityToOrganizationInfo = withStyles(styles)(
  ({ classes, edge, data }) => {
    const langStore = useLangStore();
    const activityName = langStore.isEng
      ? data.activityNameEn
      : data.activityNameUa;
    const organizationName = langStore.isEng
      ? data.organizationNameEn
      : data.organizationNameUa;

    return (
      <div className={classes.info}>
        {data.isOrganizer && (
          <div className={classes.title}>
            <a
              href={`${organizationsRoute}/view/${edge.toId}`}
              target="_blank"
              rel="noreferrer"
            >
              {organizationName}
            </a>{" "}
            is Company in{" "}
            <a
              href={`${activityRoute}/view/${edge.fromId}`}
              target="_blank"
              rel="noreferrer"
            >
              {activityName}
            </a>
          </div>
        )}
        {!data.isOrganizer && (
          <div className={classes.title}>
            <a
              href={`${organizationsRoute}/view/${edge.toId}`}
              target="_blank"
              rel="noreferrer"
            >
              {organizationName}
            </a>{" "}
            has employees in{" "}
            <a
              href={`${activityRoute}/view/${edge.fromId}`}
              target="_blank"
              rel="noreferrer"
            >
              {activityName}
            </a>
          </div>
        )}
        {data.participants?.length > 0 && (
          <>
            <div className={classes.subtitle}>Participants</div>
            <table className={classes.table}>
              <tbody>
                {data.participants.map((item) => (
                  <tr key={item.id}>
                    <td className={classes.cell}>
                      <a
                        href={`${participantRoute}/view/${item.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {langStore.isEng ? item.nameEn : item.nameUa}
                      </a>
                    </td>
                    <td className={classes.cell}>{item.position}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  }
);

const OrganizationToOrganizationInfo = withStyles(styles)(
  ({ classes, data }) => {
    const langStore = useLangStore();

    return (
      <div className={classes.info}>
        {data.activities?.length > 0 && (
          <>
            <div className={classes.subtitle}>Activities</div>
            <table className={classes.table}>
              <tbody>
                {data.activities.map((item) => (
                  <tr key={item.id}>
                    <td className={classes.cell}>
                      <a
                        href={`${activityRoute}/view/${item.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {langStore.isEng ? item.nameEn : item.nameUa}
                      </a>
                    </td>
                    <td className={classes.cell}>
                      {langStore.isEng ? item.formatEn : item.formatUa}
                    </td>
                    <td className={classes.cell}>
                      {langStore.isEng ? item.categoriesEn : item.categoriesUa}
                    </td>
                    <td className={classes.cell}>{item.startDate}</td>
                    <td className={classes.cell}>{item.endDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  }
);

export const EdgePopup = ({ edge }) => {
  const store = useNetworkMapStore();
  const [data, setData] = useState(null);

  const handleOpen = async () => {
    if (data === null) {
      const res = await store.getEdgeData(edge);
      setData(res);
    }
  };

  return (
    <LeafletPopup onOpen={handleOpen}>
      {!data && <CircularProgress />}
      {data && edge.isActivityToActivity && (
        <ActivityToActivityInfo edge={edge} data={data} />
      )}
      {data && edge.isActivityToOrganization && (
        <ActivityToOrganizationInfo edge={edge} data={data} />
      )}
      {data && edge.isOrganizationToOrganization && (
        <OrganizationToOrganizationInfo data={data} />
      )}
    </LeafletPopup>
  );
};

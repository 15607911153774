import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  gutters: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  }
});

function StyledPaper(props) {
  const { className, classes, children } = props;

  return (
      <Paper className={`${className} ${classes.gutters}`}>
        {children}
      </Paper>
  );
}

export default withStyles(styles)(StyledPaper);

import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import CssBaseline from "@material-ui/core/CssBaseline";
import { config } from "./style/config";

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: blue[300],
      main: "#c80f0f",
      dark: "#c80f0f",
    },
    secondary: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    background: {
      paper: "#ffffff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1400,
      xl: 1600,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Roboto, sans-serif",
    h2: {
      color: "#c80f0f",
      fontSize: "40px",
      fontWeight: "900!important",
      letterSpacing: "1px",
      textTransform: "uppercase!important",
      fontFamily: "Roboto, sans-serif",
      lineHeight: 1,
    },
    h3: {
      textTransform: "uppercase",
      fontSize: "24px !important",
      fontWeight: "500 !important",
      letterSpacing: "0.8px !important",
      marginLeft: 15,
    },
    h4: {
      color: "#88adf0",
      fontSize: "16px",
      fontFamily: "Roboto, sans-serif",
      paddingTop: "10px",
      fontWeight: 400,
      letterSpacing: "0.7px",
    },
    body2: {
      fontSize: config.readOnly.fontSize,
      color: config.readOnly.color,
      [breakpoints.down(1440)]: {
        fontSize: config.small.readOnly.fontSize,
      },
    },
  },
  overrides: {
    root: {
      "&$disabled": { backgroundColor: "#eceeef!important" },
    },
    MuiOutlinedInput: {
      input: {
        padding: config.input.padding,
        filter: "blur(0)",
      },
      multiline: {
        padding: config.input.padding,
        boxSizing: "border-box",
        backgroundColor: config.input.background,
        borderRadius: config.input.borderRadius,
      },
      adornedEnd: {
        paddingRight: 0,
      },
      inputAdornedEnd: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    MuiGrid: {
      item: {
        "& label": {
          padding: "0!important",
        },
        "& textField": {
          marginBottom: "0!important",
        },
      },
    },

    MuiFormLabel: {
      root: {
        fontSize: config.label.fontSize,
        fontWeight: config.label.fontWeight,
        letterSpacing: config.label.letterSpacing,
        padding: "10px 0px 2px",
        marginBottom: "2px",

        color: config.label.color,

        position: "relative",
        transform: "translate(0, 0px) scale(1)",
        display: "block",

        [breakpoints.down(1440)]: {
          fontSize: config.small.label.fontSize,
          fontWeight: config.small.label.fontWeight,
        },

        // "@media only screen and (max-width: 1700px)": {
        //   fontSize: "16px",
        // },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",

        "& div fieldset:hover": {
          borderColor: "rgba(0, 0, 0, 0) !important",
        },
        "& div fieldset": {
          borderColor: "rgba(0, 0, 0, 0) !important",
        },

        // Obsolete but applied to older pages
        "&.text-field": {
          width: "100%",
          display: "block",
          "& div": {
            display: "flex",
          },
          "& label": {
            position: "relative",
            color: "#6f6f6f",
            transform: "none",
            padding: "1vh 0",
            textAlign: "left",
          },
          "& fieldset": {
            border: "none",
            borderRadius: "0.25rem",
          },
          "& fieldset legend": {
            display: "none",
          },
          "& input": {
            border: "1px solid #ececed",
            backgroundColor: "#ececed",
            borderRadius: "0.25rem",
            padding: "10px 14px",
            borderBottom: "transparent",
            color: "#000",
          },
          "& input:focus": {
            color: "#000",
            backgroundColor: "#ececed",
            outline: "0",
          },
          "&.error-field input": {
            borderLeft: "1px solid #fe8368",
            borderLeftWidth: "6px",
            borderRadius: "0.5rem 0.25rem 0.25rem 0.5rem",
          },
        },
        "&.text-area": {
          width: "100%",
          marginBottom: "20px",
          "-ms-overflow-style": "none !important",
          "& textarea": {
            lineHeight: "1.1",
            padding: "4px 0 !important",
            color: "#191970",
            "-ms-overflow-style": "none !important",
          },
          "& label": {
            paddingBottom: "3px",
            color: "#6f6f6f",
            fontSize: "30pt",
            fontWeight: "100",
            position: "relative",
            transform: "translate(0, -10px) scale(1)",
            "@media only screen and (max-width: 1700px)": {
              fontSize: "16pt",
            },
          },
          "& fieldset": {
            borderRadius: "0.25rem",
            border: "none !important",
            padding: "10px 14px",
          },
          "& fieldset:hover": {
            outline: "0",
          },
          "& > div:hover": {
            outline: "0",
          },
          "& > div": {
            padding: "7px 14px 0",
          },
          "&.error-field fieldset": {
            borderLeft: "1px solid #fe8368 !important",
            borderLeftWidth: "6px !important",
            borderRadius: "0.5rem 0.25rem 0.25rem 0.5rem",
          },
        },
      },
    },
    MuiTableSortLabel: {
      active: {
        color: "#3366cc!important",
      },
    },
    MuiInputBase: {
      input: {
        color: config.input.color,
        fontSize: config.input.fontSize,
        fontWeight: config.input.fontWeight,
        letterSpacing: config.input.letterSpacing,
        fontFamily: config.fontFamily,
        background: config.input.background,
        borderRadius: config.input.borderRadius,
        padding: config.input.padding,
      },
    },

    MuiInput: {
      root: {
        color: config.input.color,
        fontSize: config.input.fontSize,
        fontWeight: config.input.fontWeight,
        letterSpacing: config.input.letterSpacing,
        fontFamily: config.fontFamily,
        borderRadius: config.input.borderRadius,
      },
      input: {
        color: config.input.color,
        fontSize: config.input.fontSize,
        fontWeight: config.input.fontWeight,
        letterSpacing: config.input.letterSpacing,
        fontFamily: config.fontFamily,
        background: config.input.background,
        borderRadius: config.input.borderRadius,
        padding: config.input.padding,
      },
      error: {
        "&:after": {
          border: 0,
        },
      },
    },
    MuiFormHelperText: {
      error: {
        display: "none",
      },
    },
    MuiButton: {
      root: {
        boxShadow: "none !important",
        textTransform: "none",
        "&.btn-create": {
          borderRadius: "100px",
          backgroundColor: config.colors.primary,
          marginRight: "2.1vw",
          boxShadow: "none",
          padding: "6px 25px 6px 20px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: config.colors.primary,
            opacity: 0.9,
          },
          "& svg": {
            width: "1.2em",
            height: "1.2em",
            marginRight: "5px",
          },
        },
        "& span": {
          minWidth: "60px",
          justifyContent: "space-around",
        },
        "&.btn-login": {
          borderRadius: "100px",
        },
        "&.btn-delete": {
          backgroundColor: "#fe8368",
        },
        "&.btn-delete:hover": {
          backgroundColor: "#f67a5f",
        },
        "&.btn-add": {
          backgroundColor: "#add8e6",
        },
        "&.btn-add:hover": {
          backgroundColor: "#9bcfe0",
        },
        "&.button-cancel": {
          color: "#fff",
          backgroundColor: "#c80f0f",
          border: "1px solid #c80f0f",
          borderRadius: "10px",
          boxShadow: "none",
          textTransform: "none",
          fontSize: "11px",
          fontWeight: "400",
          marginRight: "20px",
          padding: "14px 22px 15px",
          lineHeight: "1",
          minWidth: "118px",
          textAlign: "center",
          "& i": {
            display: "inline-block",
            minWidth: "16px",
            minHeight: "16px",
            marginRight: "10px",
            background:
              "url(/images/icons/button-close.svg) no-repeat 0 0 / 16px 16px",
          },
          "&:hover": {
            backgroundColor: "#6f6f6f",
          },
        },
        "&.button-submit": {
          color: "#fff",
          backgroundColor: "#c80f0f",
          border: "1px solid #c80f0f",
          borderRadius: "10px",
          boxShadow: "none",
          textTransform: "none",
          fontSize: "11px",
          fontWeight: "400",
          marginRight: "20px",
          padding: "14px 22px 15px",
          lineHeight: "1",
          minWidth: "118px",
          textAlign: "center",
          maxHeight: "47px",
          "& i": {
            display: "inine-block",
            width: "19px",
            height: "19px",
            marginRight: "10px",
            background:
              "url(/images/icons/button-save.svg) no-repeat 0 0 / 19px 19px",
          },
          "&:hover": {
            backgroundColor: "#6f6f6f",
            borderColor: "#6f6f6f",
          },
        },
      },
      sizeSmall: {
        padding: "8px",
      },
    },

    MuiBackdrop: {
      invisible: {
        opacity: "0 !important",
      },
    },
    MuiListItem: {
      root: {
        paddingTop: "3px",
        paddingBottom: "3px",
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: "3001",
      },
    },
    MuiModal: {
      root: {
        boxShadow: "none",
        zIndex: "2001",
        "& .modal": {
          position: "absolute",
          height: "94vh !important",
          width: "94%",
          backgroundColor: "#f7f9fb",
          boxShadow: "none",
          outline: "none",
          borderRadius: "15px",
          padding: "25px",
          left: "50%",
          overflowY: "auto",
          top: "50%",
          transform: `translate(-50%, -50%)`,
          "& a": {
            textDecoration: "none",
          },
        },
        "& .close": {
          position: "absolute",
          right: "10px",
          top: "10px",
          color: "#666",
          cursor: "pointer",
        },
      },
    },
    MuiDrawer: {
      root: {},
      paper: {
        background: config.colors.primary,
      },
    },
    MuiSwitch: {
      checked: {
        // backgroundColor: '#8ccfe5'
      },
      bar: {
        backgroundColor: "#d6d6d6",
        opacity: "1",
        top: "51%",
        left: "55%",
        width: "31px",
        height: "15px",
      },
      icon: {
        width: "15px",
        height: "15px",
        boxShadow: "0 0 5px 4px rgba(73, 66, 66, 0.26)",
        backgroundColor: "#a6a6a6",
        opacity: "1",
      },
      iconChecked: {
        width: "15px",
        height: "15px",
        backgroundColor: "#add8e6",
        boxShadow: "0 0 5px 4px rgba(73, 66, 66, 0.26)",
      },
    },
    MuiTooltip: {
      popper: {
        opacity: "1",
      },
      tooltipPlacementRight: {
        position: "relative",
        left: "-45px",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#c80f0f",
      },
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: "#c80f0f",
        "&:hover": {
          backgroundColor: "#c80f0f",
        },
      },
    },
  },
  title: {
    "& div h2": {
      fontSize: "60px",
      margin: "2.6vh 0 1.3vh 3.1vw",
      "@media only screen and (max-width: 1700px)": {
        fontSize: "40px",
      },
    },
    "div &": {
      width: "100%",
      margin: "0",
    },
  },
});

theme.overrides.MuiTablePagination = {
  root: {
    "& span": {
      fontWeight: 900,
      [theme.breakpoints.down(1440)]: {
        fontWeight: 100,
      },
    },
  },
};

theme.overrides.MuiTable = {
  root: {
    "& tr th": {
      backgroundColor: "#f7f9fb",
      borderBottom: "none",
      whiteSpace: "nowrap",
      fontSize: "20pt",
      color: config.colors.secondary,
      fontWeight: "100",
    },

    "& tr td": {
      borderBottom: "none",
    },
    "& tr td, & tr th": {
      padding: "15px",
    },
    "& tr": {
      backgroundColor: "#fff",
    },
    "& tr:nth-child(even)": {
      backgroundColor: "#f7f9fb",
    },
  },
};

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;

import React from 'react';
import {
  Grid,
  Button,
  Typography,
  Modal
} from '@material-ui/core';
import { Edit, Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import { I18n } from '../../i18n/';

import { Link, Redirect } from 'react-router-dom';

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '94%',
    margin: '30px 3%',
  },
  modal: {
    position: 'absolute',
    width: '94%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    left: '50%',
    height: '90vh',
    overflowY: 'auto',
    top: '50%',
    transform: `translate(-50%, -50%)`,
    '& a': {
      textDecoration: 'none'
    }
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  },
  alignRight: {
    textAlign: 'right'
  },
  data: {
    '& h4': {
      marginBottom: '0'
    }
  }
});


class ItemView extends React.Component {
  state = {
    open: true,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderRedirect = () => {
      if (!this.state.open) {
        return <Redirect to='/user' />
      }
  }

  render() {
      const { classes, item, lang } = this.props;
      return (
        <>
          {this.renderRedirect()}
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div className="modal">
              <Grid container spacing={24} alignItems='center'>
                <Grid item xs={12}>
                  <Close className="close" onClick={this.handleClose} />
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom variant="h2" component="h2">
                    {I18n[lang].user.user} {item.id}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.alignRight}>
                  <Link to={`/user/edit/${item.id}`}>
                    <Button variant="contained" color="primary">
                      <Edit/>
                      {I18n[lang].common.edit}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={12}>
                <Grid item xs={6}>
                  <Typography className={classes.data}>
                    <h4>{I18n[lang].user.login}</h4>
                    <p>{item.login}</p>
                    <h4>{I18n[lang].user.name}</h4>
                    <p>{item.name}</p>
                    <h4>{I18n[lang].user.lastName}</h4>
                    <p>{item.lastName}</p>
                    <h4>{I18n[lang].user.email}</h4>
                    <p>{item.email}</p>
                    {false && (
                      <>
                        <h4>{I18n[lang].user.activated}</h4>
                        <p>{(item.activated) ? 'True' : 'False'}</p>
                      </>
                    )}
                    <h4>{I18n[lang].user.createdBy}</h4>
                    <p>{item.createdBy}</p>
                    <h4>{I18n[lang].user.created_date}</h4>
                    <p>{item.createdDate}</p>
                    <h4>{I18n[lang].user.last_modified_by}</h4>
                    <p>{item.lastModifiedBy}</p>
                    <h4>{I18n[lang].user.last_modified_date}</h4>
                    <p>{item.lastModifiedDate}</p>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {item.locations && (
                    <Typography className={classes.data}>
                      <h4>Location tracking</h4>
                      {item.locations.map(location => {
                        return <p>{location.createdAt}: {location.country}</p>
                      })}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </div>
          </Modal>
        </>
      );
  }
}

export default withRoot(withStyles(styles)(ItemView));

import React, { createRef } from "react";
import { Grid, Button, Checkbox, FormControlLabel } from "@material-ui/core";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import Loader from "../../components/loader";
import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Link, withRouter } from "react-router-dom";
import {
  max,
  min,
  uniq,
  clone,
  each,
  isEmpty,
  isNumber,
  isPlainObject,
} from "lodash";
import classNames from "classnames";
import { styles } from "./styles";
import { default as OblastLayer } from "./oblast-layer";
import { default as HromadaLayer } from "./hromada-layer";
import Color from "color";
import CustomMarkers from "./custom-markers";
import { default as layersMeta } from "./layers.json";
import { section2color, getNumber } from "./marker-utils";
import MiniDrawer from "./sidebar/index";
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";
import { availableColors, coloredLayers } from "./constant";
import * as markerUtils from "./marker-utils";
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import { GisData } from "../../config";

require("leaflet-easybutton");
require("leaflet-easyprint");

const initialZoom = GisData.MappingTool.initialZoom;
let zoom = initialZoom;
class InteractiveMap extends React.Component {
  state = {
    kind: "",
    lat: GisData.MappingTool.center[0],
    lng: GisData.MappingTool.center[1],
    showMap: true,
    legendData: [],
    legendTitle: "",
    collapsed: true,
    //selectedSidebarTab: 'home',
    buttonsLabelsColors: {},
    areasData: {},
    selectedLayers: [], // layerIds
    layersData: {}, // layerId => distribution
    layersFilterObjects: {}, // layerId => Array of filter objects
    selectedSubLayers: {},
    baseLayer: null,
    layersVersion: 0,
    asColorGradient: false,
    withGenderProportion: false,
    colorGradient: [],
    isOpenDrawer: false,
    yearFilter: { 2015: true, 2016: true, 2017: true, 2018: true, 2019: true },
    dataPickerStart: null,
    dataPickerEnd: null,
    selectedProjects: new Set(),
    loading: true,
    lastSelectYears: 0,
    position: GisData.MappingTool.center,
    showOblastBorders: true,
  };

  legendTpl = createRef();

  originalBaseColors = {};

  constructor(props) {
    super(props);
    this.state.kind = props.kind || "oblast";
  }

  changeTab = (kind) => {
    return () => {
      if (kind !== this.state.kind) {
        if (this.legendPopup) {
          this.map.removeControl(this.legendPopup);
        }

        const layersData = {};
        const layersVersion = this.state.layersVersion + 1;
        this.setState({ kind, layersData, layersVersion, loading: true });
      }
    };
  };

  handleDefault = () => {
    zoom = initialZoom;
    this.setState({
      selectedLayers: [],
      layersData: {}, // layerId => distribution
      layersFilterObjects: {}, // layerId => Array of filter objects
      selectedSubLayers: {},
      colorGradient: [],
      showMap: false,
      loading: true,
      isOpenDrawer: false,
      legendData: [],
      legendTitle: "",
      yearFilter: {
        2015: true,
        2016: true,
        2017: true,
        2018: true,
        2019: true,
      },
      dataPickerStart: null,
      dataPickerEnd: null,
      selectedProjects: new Set(),
      position: GisData.MappingTool.center,
      showOblastBorders: true,
    });

    setTimeout(() => {
      this.setState({
        showMap: true,
        loading: false,
      });
    }, 300);

    setTimeout(() => {
      this.legendBtn = L.easyButton(
        "<span>Legend</span>",
        (btn, map) => {
          if (!this.legendPopup) return;
          this.legendTpl.current.style.display = "block";
          this.legendBtn.button.style.display = "none";
          this.delayedImagePrepare();
        },
        { position: "topright" }
      ).addTo(this.map);
      //this.imageLink.children[0].disabled = true
      if (this.legendTpl && this.legendTpl.current) {
        this.setLegendTootlipListener();
      }

      this.setInitialColors();
    }, 400);
  };

  componentDidMount() {
    this.legendBtn = L.easyButton(
      "<span>Legend</span>",
      (btn, map) => {
        if (!this.legendPopup) return;
        this.legendTpl.current.style.display = "block";
        this.legendBtn.button.style.display = "none";
        this.delayedImagePrepare();
      },
      { position: "topright" }
    ).addTo(this.map);
    //this.imageLink.children[0].disabled = true
    if (this.legendTpl && this.legendTpl.current) {
      this.setLegendTootlipListener();
    }
    this.setInitialColors();
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);

    if (this.props.location.search) {
      const JSONcurrentPos = this.props.location.search.split("=");
      const currentPos = JSON.parse(JSONcurrentPos[1]);

      zoom = 11;
      this.setState({ position: currentPos });
    }
  }

  setInitialColors() {
    const initialButtonsLabelsColors = coloredLayers.reduce(
      (result, currentValue, currentIndex) => {
        return { ...result, [currentValue]: availableColors[currentIndex] };
      },
      {}
    );
    const initialGradientColors = this.getGradientColors(
      initialButtonsLabelsColors
    );
    this.setState({
      buttonsLabelsColors: initialButtonsLabelsColors,
      colorGradient: initialGradientColors,
    });
  }

  getGradientColors(colors) {
    const result = Object.values(colors).map((item) =>
      this.handleGradientColorChange(item)
    );
    return result;
  }

  componentWillUnmount() {
    this.legendTpl &&
      this.legendTpl.current &&
      this.legendTpl.current.removeEventListener("mousewheel");
  }

  setLegendTootlipListener = () => {
    this.legendTpl.current.addEventListener("mousewheel", (event) => {
      event.stopPropagation();
    });
  };

  handleCloseLegend = () => {
    const legendDiv = this.legendPopup._container;
    legendDiv.style.display = "none";
    this.legendBtn.button.style.display = "block";
  };

  onOverLayerLoad = ({ legendTitle, legendData, areasData, baseLayer }) => {
    let originalColors = this.originalBaseColors[this.state.kind];

    if (!originalColors) {
      originalColors = {};
      baseLayer.eachLayer((layer) => {
        originalColors[layer.feature.properties.id] = layer.options.fillColor;
      });
      this.originalBaseColors[this.state.kind] = originalColors;
    }

    this.setState(
      { legendData, legendTitle, areasData, baseLayer, loading: false },
      () => {
        if (this.legendPopup) {
          this.map.removeControl(this.legendPopup);
        }
        this.legendPopup = L.control({ position: "topright" });
        this.legendPopup.onAdd = (map) => {
          return L.DomUtil.get(this.legendTpl.current);
        };
        this.legendPopup.addTo(this.map);

        if (this.state.selectedLayers.length) {
          this.loadFilterLayerData(this.state.selectedLayers);
        } else {
          setTimeout(this.delayedImagePrepare, 100);
        }
      }
    );
  };

  prepareDownloadableImage = () => {
    if (!this.map) {
      return;
    }
    if (this.printPlugin) {
      this.map.removeControl(this.printPlugin);
    }
    this.printPlugin = L.easyPrint({
      hideControlContainer: false,
      hideClasses: ["leaflet-control-zoom", "leaflet-control-easyPrint"],
      exportOnly: true,
      position: "topleft",
      sizeModes: ["Current", "A4Landscape"],
    }).addTo(this.map);
  };

  delayedImagePrepare = () => {
    if (this.imagePrepareTimeout) {
      clearTimeout(this.imagePrepareTimeout);
    }
    this.imagePrepareTimeout = setTimeout(() => {
      this.prepareDownloadableImage();
    }, 100);
  };

  currentBaseLayer = () => {
    return this.state.kind === "detailed" ? "communities" : "regions";
  };

  deselectLayer = (layerId) => {
    const { selectedLayers, layersData } = this.state;
    const i = selectedLayers.indexOf(layerId);
    if (i < 0) {
      return;
    }
    selectedLayers.splice(i, 1);
    delete layersData[layerId];

    let layersVersion = this.state.layersVersion + 1;
    this.setState({ selectedLayers, layersData, layersVersion }, () => {
      if (this.state.asColorGradient) {
        this.changeBaseLayerColor(
          this.state.baseLayer,
          this.state.asColorGradient
        );
      }
      this.delayedImagePrepare();
    });
  };

  onFilterLayerClick = (layerId) => {
    return () => {
      // Action: de-select filter
      if (this.state.selectedLayers.indexOf(layerId) >= 0) {
        this.deselectLayer(layerId);
        return;
      }
      // Action: select filter
      this.loadFilterLayerData([layerId]);

      if (
        !(
          layerId === "total_participations" || layerId === "total_participants"
        )
      ) {
        this.setState({
          withGenderProportion: false,
        });
      }
    };
  };

  onFilterSubLayerClick = (layerId, item) => {
    return () => {
      const selectedSubLayers = this.state.selectedSubLayers;
      let subLayers = selectedSubLayers[layerId] || [];
      if (item) {
        const curIndex = subLayers.indexOf(item);
        if (curIndex < 0) {
          subLayers.push(item);
        } else {
          subLayers.splice(curIndex, 1);
        }
      } else {
        subLayers = [];
      }
      selectedSubLayers[layerId] = subLayers;
      const layersVersion = this.state.layersVersion + 1;
      this.setState({ selectedSubLayers, layersVersion }, () => {
        this.changeBaseLayerColor(
          this.state.baseLayer,
          this.state.asColorGradient
        );
        this.delayedImagePrepare();
      });
    };
  };

  asyncForEach = async function (array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  loadFilterLayerData = async (layerIds) => {
    const {
      layersData,
      layersFilterObjects,
      selectedLayers,
      dataPickerStart,
      dataPickerEnd,
      selectedProjects,
    } = this.state;

    // ! Requirement: 3 layers as a maximum
    const overLimit = uniq(selectedLayers.concat(layerIds)).length - 3;
    for (let i = 0; i < overLimit; i++) {
      let oldLayerId = selectedLayers.shift();
      delete layersData[oldLayerId];
    }
    const baseLayer = this.currentBaseLayer();
    const params = new URLSearchParams();
    if (dataPickerStart) {
      params.append("dateFrom", dataPickerStart.toISOString().slice(0, 7));
    }
    if (dataPickerEnd) {
      params.append("dateTo", dataPickerStart.toISOString().slice(0, 7));
    }
    for (const pr of selectedProjects) {
      params.append("project_ids[]", pr);
    }

    await this.asyncForEach(layerIds, async (layerId) => {
      let data = await fetchData(
        "get",
        `/map/layers/${baseLayer}/${layerId}?${params}`
      );

      layersData[layerId] = data[0];
      const entitiesPath = layersMeta[layerId].entitiesPath;
      if (entitiesPath && !layersFilterObjects[layerId]) {
        // get additional objects for the layer
        let entitiesData = await fetchData("get", entitiesPath);
        if (entitiesPath === "/categories/results") {
          layersFilterObjects[layerId] = entitiesData[0].map((x) => {
            x.nameEn = x.nameEn.split(":")[0];
            return x;
          });
        } else {
          layersFilterObjects[layerId] = entitiesData[0];
        }
      }

      if (selectedLayers.indexOf(layerId) < 0) {
        selectedLayers.push(layerId);
      }
    });

    let layersVersion = this.state.layersVersion + 1;

    this.setState(
      {
        layersData,
        layersFilterObjects,
        selectedLayers,
        layersVersion,
      },
      () => {
        if (this.state.asColorGradient) {
          this.changeBaseLayerColor(
            this.state.baseLayer,
            this.state.asColorGradient
          );
        }
        this.delayedImagePrepare();
      }
    );
  };

  onSidebarToggle = ({ isOpen }) => {
    this.setState({ collapsed: !isOpen });
  };

  zoomToDetailedView = (position) => {
    const [lng, lat] = position;
    zoom = 9;
    this.setState({ lat, lng, kind: "detailed", position: [lat, lng] });
  };

  toggleColorGradient = () => {
    const asColorGradient = !this.state.asColorGradient;
    this.changeBaseLayerColor(this.state.baseLayer, asColorGradient);
    let layersVersion = this.state.layersVersion + 1;
    this.setState({ asColorGradient, layersVersion });
  };

  toggleGenderProportion = () => {
    let layersVersion = this.state.layersVersion + 1;
    this.setState({
      withGenderProportion: !this.state.withGenderProportion,
      layersVersion,
    });
  };

  toggleShowOblastBorders = () => {
    const layersVersion = this.state.layersVersion + 1;
    const showOblastBorders = !this.state.showOblastBorders;
    this.setState({ showOblastBorders, layersVersion });
  };

  isSingleLayerMode = () => {
    const { selectedLayers, selectedSubLayers } = this.state;
    const firstLayerId = selectedLayers[0];
    return (
      selectedLayers.length === 1 &&
      (layersMeta[firstLayerId].type !== "distribution" ||
        (selectedSubLayers[firstLayerId] &&
          selectedSubLayers[firstLayerId].length === 1))
    );
  };

  isSpecialTSPcase = () => {
    const {
      selectedLayers,
      selectedSubLayers,
      layersFilterObjects,
    } = this.state;
    const firstLayerId = selectedLayers[0];
    const layersWithTotalGradient = [
      "leader_steps",
      "special_steps",
      "sector_decentr_ah_outreach",
    ];
    return (
      selectedLayers.length === 1 &&
      layersWithTotalGradient.indexOf(firstLayerId) >= 0 &&
      (isEmpty(selectedSubLayers[firstLayerId]) ||
        selectedSubLayers[firstLayerId].length ===
          layersFilterObjects[firstLayerId].length)
    );
  };

  changeBaseLayerColor = (baseLayer, asColorGradient) => {
    const originalColors = this.originalBaseColors[this.state.kind];

    const firstLayerId = this.state.selectedLayers[0];
    const subLayers = this.state.selectedSubLayers[firstLayerId] || [];

    const specialCase = this.isSpecialTSPcase();
    const showGradient =
      asColorGradient && (this.isSingleLayerMode() || specialCase);
    const filterColors = showGradient
      ? this.state.layersData[firstLayerId]
      : [];
    const colorLevels = showGradient ? this.getColorLevels(firstLayerId) : [];
    if (showGradient && !filterColors) {
      return;
    }
    baseLayer.eachLayer((layer) => {
      const featureId = layer.feature.properties.id;
      let color = "";
      if (showGradient) {
        let value = filterColors[featureId];
        if (value) {
          value = getNumber(
            !specialCase && subLayers.length
              ? value[subLayers[0].id]
              : specialCase || isNumber(value) || isPlainObject(value)
              ? value
              : 0
          );
        } else {
          value = 0;
        }
        color = this.getColor(value, colorLevels);
      } else {
        color = originalColors[featureId];
      }
      layer.setStyle({ fillColor: color });
    });
  };

  getColorLevels = (firstLayerId) => {
    let filterColors = this.state.layersData[firstLayerId];
    if (!filterColors) {
      return;
    }

    let colorLevels = [];
    if (this.state.kind === "detailed") {
      switch (firstLayerId) {
        case "total_activities":
          colorLevels = [200, 100, 50, 25];
          break;

        case "total_participants":
          colorLevels = [150, 100, 50, 25];
          break;

        case "total_participations":
          colorLevels = [500, 200, 50, 25];
          break;
        default:
      }
    }

    if (!colorLevels.length) {
      const specialCase = this.isSpecialTSPcase();
      const subLayers = this.state.selectedSubLayers[firstLayerId] || [];
      const values = Object.values(filterColors).map((v) =>
        getNumber(
          !specialCase && subLayers.length
            ? v[subLayers[0].id]
            : specialCase || isNumber(v) || isPlainObject(v)
            ? v
            : 0
        )
      );

      const maxValue = Math.round(max(values));
      const minValue = Math.round(min(values));
      const step = Math.ceil(Math.max((maxValue - minValue) / 5, 1));

      for (
        let i = Math.max(minValue - 1, 1);
        i <= Math.max(maxValue - 2, 2);
        i += step
      ) {
        colorLevels.push(i);
      }

      colorLevels.reverse();
      colorLevels = uniq(colorLevels);
    }

    return colorLevels;
  };

  getCurrentColorGradient = () => {
    const { selectedLayers, colorGradient } = this.state;
    const firstLayerId = selectedLayers[0];
    let gradientIndex = coloredLayers.indexOf(firstLayerId);
    if (gradientIndex < 0) {
      gradientIndex = 0;
    }
    return colorGradient[gradientIndex];
  };

  getColor = (v, levels) => {
    if (v === 0) {
      return "#FFFFFF";
    }

    let found = false;
    let cur = 0;

    const gradient = this.getCurrentColorGradient();
    for (let i = levels.length - 1; i >= 0 && !found; i--) {
      if (v <= levels[i]) {
        found = true;
        cur = i + 1;
      }
    }
    if (cur === gradient.length && v > 0) {
      cur -= 1;
    }

    return gradient[cur];
  };

  handleButtonsLabelsColorsChange = (buttonsLabelsColors) => {
    const colorGradient = this.getGradientColors(buttonsLabelsColors);

    this.setState(
      {
        buttonsLabelsColors,
        colorGradient,
      },
      () =>
        this.changeBaseLayerColor(
          this.state.baseLayer,
          this.state.asColorGradient
        )
    );
  };

  handleYearsFilterChange = (item) => {
    const yearFilter = this.state.yearFilter;
    const length = Object.keys(yearFilter).length;
    const year = [2015, 2016, 2017, 2018, 2019];
    let checkFalse = true;

    // if (this.state.lastSelectYears === item) {
    //   for (let i = 0; i < length; i++) {
    //     yearFilter[year[i]] = true;
    //   }
    //   this.setState({ lastSelectYears: 0 });
    // } else {
    //   for (let i = 0; i < length; i++) {
    //     yearFilter[year[i]] = false;
    //   }
    //   yearFilter[item] = true;
    //   this.setState({ lastSelectYears: last });
    // }
    for (const key in yearFilter) {
      if (yearFilter[key] === false) {
        checkFalse = false;
      }
    }

    if (!checkFalse && yearFilter[item]) {
      for (let i = 0; i < length; i++) {
        yearFilter[year[i]] = true;
      }
      return this.setState({ yearFilter: yearFilter });
    }

    if (yearFilter[item]) {
      for (let i = 0; i < length; i++) {
        yearFilter[year[i]] = false;
      }
    }
    yearFilter[item] = !yearFilter[item];
    this.setState({ yearFilter: yearFilter });
    this.setInitialColors();
  };

  handledataPickerStar = (date, flag) => {
    const opts = {};
    opts[flag === "start" ? "dataPickerStart" : "dataPickerEnd"] = date;
    this.setState(opts, () => {
      if (this.state.selectedLayers.length) {
        this.loadFilterLayerData(this.state.selectedLayers);
      }
    });
  };

  handleProject = (projectId) => {
    const selectedProjects = new Set(this.state.selectedProjects);
    if (selectedProjects.has(projectId)) {
      selectedProjects.delete(projectId);
    } else {
      selectedProjects.add(projectId);
    }
    this.setState({ selectedProjects }, () => {
      if (this.state.selectedLayers.length) {
        this.loadFilterLayerData(this.state.selectedLayers);
      }
    });
  };

  isOpenDrawer = (status) => {
    this.setState({
      isOpenDrawer: status,
    });
  };

  handleGradientColorChange = (color, index) => {
    const colors = this.state.colorGradient;
    switch (color) {
      case "#2d77fc": {
        return (colors[index] = [
          "#2d77fc",
          "#5f98fd",
          "#98bdfe",
          "#caddfe",
          "#dfebfe",
        ]);
      }
      case "#bf17ff": {
        return (colors[index] = [
          "#bf17ff",
          "#d25dff",
          "#de89ff",
          "#ebb7ff",
          "#f7e3ff",
        ]);
      }
      case "#000000": {
        return (colors[index] = [
          "#000000",
          "#4a4a4a",
          "#7d7d7d",
          "#adadad",
          "#dbdbdb",
        ]);
      }
      case "#FCBB2D": {
        return (colors[index] = [
          "#FCBB2D",
          "#fdcb60",
          "#fdd98b",
          "#fee7b5",
          "#feeeca",
        ]);
      }
      case "#B07D10": {
        return (colors[index] = [
          "#B07D10",
          "#bc9034",
          "#ccaa63",
          "#dbc493",
          "#ebdec3",
        ]);
      }
      case "#33b0b1":
        {
          return (colors[index] = [
            "#33b0b1",
            "#5cc0c1",
            "#85d0d0",
            "#addfe0",
            "#ccebeb",
          ]);
        }
      case "#003EB0": {
        return (colors[index] = [
          "#003EB0",
          "#3365c0",
          "#668bd0",
          "#99b2df",
          "#ccd8ef",
        ]);
      }
      case "#2D77FC": {
        return (colors[index] = [
          "#2D77FC",
          "#5792fd",
          "#81adfd",
          "#abc9fe",
          "#d5e4fe",
        ]);
      }
      case "#9EDA13": {
        return (colors[index] = [
          "#9EDA13",
          "#b1e142",
          "#c5e971",
          "#d8f0a1",
          "#ecf8d0",
        ]);
      }
      case "#BF17FF": {
        return (colors[index] = [
          "#BF17FF",
          "#cc45ff",
          "#d974ff",
          "#e5a2ff",
          "#f2d1ff",
        ]);
      }
      case "#75edfd": {
        return (colors[index] = [
          "#75edfd",
          "#91f1ff",
          "#acf5ff",
          "#c8f8ff",
          "#e3fcff",
        ]);
      }
      case "#ed1c24": {
        return (colors[index] = [
          "#ed1c24",
          "#f2575d",
          "#f6898d",
          "#f9adb0",
          "#fcd4d5",
        ]);
      }
      default:
        break;
    }
  };

  handleChangeYear = (item) => {
    this.handleYearsFilterChange(item);
    this.setState({ showMap: false });

    setTimeout(() => {
      this.setState({
        showMap: true,
        loading: false,
      });
    }, 300);

    setTimeout(() => {
      this.legendBtn = L.easyButton(
        "<span>Legend</span>",
        (btn, map) => {
          if (!this.legendPopup) return;
          this.legendTpl.current.style.display = "block";
          this.legendBtn.button.style.display = "none";
          this.delayedImagePrepare();
        },
        { position: "topright" }
      ).addTo(this.map);
      //this.imageLink.children[0].disabled = true
      if (this.legendTpl && this.legendTpl.current) {
        this.setLegendTootlipListener();
      }

      this.setInitialColors();
    }, 400);
  };

  preparePopupData = (areaId) => {
    const {
      layersData,
      layersFilterObjects,
      selectedLayers,
      selectedSubLayers,
    } = this.state;
    return selectedLayers
      .map((layerId) => {
        const meta = layersMeta[layerId];
        let value = layersData[layerId][areaId];
        let row = `<div  class="employer-type">${meta.label}</div>`;

        const subLayers = selectedSubLayers[layerId] || [];
        const subLayerIds = subLayers.map((x) => x.id);
        const isSingleSubLayer = subLayers.length === 1;

        if (isSingleSubLayer) {
          value = (value && value[subLayers[0].id]) || 0;
          row = `<strong>${subLayers[0].en || subLayers[0].nameEn}</strong>`;
        }

        if (meta.type === "distribution" && !isSingleSubLayer) {
          const data = [];
          const filterObjects = layersFilterObjects[layerId];
          each(filterObjects, (filterObject) => {
            if (
              subLayerIds.length &&
              subLayerIds.indexOf(filterObject.id) < 0
            ) {
              return;
            }
            const v = value && value[filterObject.id];
            if (v) {
              data.push(
                `<strong>${
                  filterObject.en || filterObject.nameEn
                }</strong>: ${markerUtils.getNumber(v)}`
              );
            }
          });
          row += "<br/>" + data.join("<br/>");
        } else if (meta.type === "entity" && !isNumber(value) && value) {
          let s = `: <a target="_blank" href="${meta.entityPath}/${value.id}">${value.title}</a>`;
          if (layerId === "r3b") {
            s += ` (${value.count} initiatives)`;
          }
          row += s;
        } else {
          row += ": " + markerUtils.getNumber(value);
        }

        return row;
      })
      .join("<br/><br/>");
  };

  handleDefaultDate = (field) => {
    this.setState({ [field]: null }, () => {
      if (this.state.selectedLayers.length) {
        this.loadFilterLayerData(this.state.selectedLayers);
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { kind, collapsed, position } = this.state;

    // perhaps it will be extracted to the separate component
    let { legendData, legendTitle } = this.state;

    // Layer-specific params
    const {
      selectedSubLayers,
      layersData,
      areasData,
      baseLayer,
      layersFilterObjects,
      layersVersion,
      asColorGradient,
      withGenderProportion,
      buttonsLabelsColors,
      showMap,
      selectedProjects,
    } = this.state;

    const selectedLayers = clone(this.state.selectedLayers);

    // const southWest = [43.50, 20.00]
    // const northEast = [55.00, 42.00]
    // const bounds = [southWest, northEast] // automatic card alignment (:797)

    const asHromada = kind === "detailed";
    if (asHromada) {
      let wrong_layer_id = selectedLayers.indexOf("employer_levels");
      if (wrong_layer_id >= 0) {
        selectedLayers.splice(wrong_layer_id, 1);
      }
    }
    const firstLayerId = selectedLayers[0];
    const singleLayerMode = this.isSingleLayerMode();
    const specialTspMode = this.isSpecialTSPcase();
    const isParticipantLayer =
      singleLayerMode && layersMeta[firstLayerId].participants;

    if ((singleLayerMode || specialTspMode) && asColorGradient) {
      let colorLevels = this.getColorLevels(firstLayerId);

      if (colorLevels) {
        const gradient = this.getCurrentColorGradient();
        let ind = 0;
        let lastLevel = 0;
        legendData = colorLevels.map((level, i) => {
          let label;
          lastLevel = level;
          if (i === 0) {
            label = `${level + 1}+`;
          } else {
            let nextLevel = colorLevels[i - 1];
            label = `${
              level + (nextLevel === level + 1 || i === 4 ? 0 : 1)
            }–${nextLevel}`;
          }
          ind += 1;
          return [gradient[i], label];
        });
        if (lastLevel > 1 && ind <= 4) {
          legendData.push([gradient[ind], `1-${lastLevel}`]);
        }
        const subLayers = selectedSubLayers[firstLayerId];
        legendTitle =
          subLayers && subLayers.length
            ? subLayers[0].en || subLayers[0].nameEn
            : layersMeta[firstLayerId].label;
      }
    }

    return (
      <>
        {this.state.loading && (
          <div style={{ height: "100vh" }}>
            <Loader />
          </div>
        )}

        <Grid
          container
          spacing={24}
          className={classNames("p0", "df", "hidden", classes.root)}
        >
          <Grid item xs={8} lg={6}>
            <div
              className={classNames(
                classes.headerLinks,
                [this.state.isOpenDrawer ? "pl-410" : "pl-135"],
                "pr-52"
              )}
            >
              {/*<Link to="#" onClick={this.changeTab("detailed")}>*/}
              {/*  <Button*/}
              {/*    variant={asHromada ? "contained" : "outlined"}*/}
              {/*    color="primary"*/}
              {/*    className={*/}
              {/*      asHromada*/}
              {/*        ? classes.containedCustom*/}
              {/*        : classes.containedCustomActive*/}
              {/*    }*/}
              {/*  >*/}
              {/*    {asHromada ? (*/}
              {/*      <img src="images/icons/detailed.png" alt="i" />*/}
              {/*    ) : (*/}
              {/*      <img src="images/icons/detailed-noActive.png" alt="i" />*/}
              {/*    )}*/}
              {/*    Detailed Overview*/}
              {/*  </Button>*/}
              {/*</Link>*/}
              {/*<Link to="#" onClick={this.changeTab("oblast")}>*/}
              {/*  <Button*/}
              {/*    variant={kind === "oblast" ? "contained" : "outlined"}*/}
              {/*    color="primary"*/}
              {/*    className={*/}
              {/*      !asHromada*/}
              {/*        ? classes.outlinedCustom*/}
              {/*        : classes.outlinedCustomActive*/}
              {/*    }*/}
              {/*  >*/}
              {/*    {!asHromada ? (*/}
              {/*      <img src="images/icons/view.png" alt="i" />*/}
              {/*    ) : (*/}
              {/*      <img src="images/icons/view-noActive.png" alt="i" />*/}
              {/*    )}*/}
              {/*    Oblast Overview*/}
              {/*  </Button>*/}
              {/*</Link>*/}
              {asHromada ? (
                <FormControlLabel
                  className={classes.headerCheckbox}
                  label="Oblast Borders"
                  control={
                    <Checkbox
                      onClick={this.toggleShowOblastBorders}
                      checked={this.state.showOblastBorders}
                      disableRipple
                      icon={<div className="checkbox"></div>}
                      checkedIcon={
                        <div className="checkbox checkbox--checked"></div>
                      }
                      className={classNames("checked", classes.checkbox)}
                    />
                  }
                />
              ) : (
                <div />
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            lg={3}
            className={classNames(classes.dateBlock, classes.dFlex, "jce")}
          >
            <div className={classes.date}> Date Range </div>
            <div className={classNames(classes.calendar, classes.dateBlock)}>
              <div
                className={classes.dataIcon}
                onClick={this.handleToggleCalendar}
              >
                <img
                  src="/images/icons/calendar.svg"
                  alt="calendar"
                  style={{ width: 28 }}
                />
              </div>
              <div
                className={classes.dataPick}
                style={{
                  display: this.state.isVisibleDataPicker ? "block" : "none",
                }}
              />
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <InlineDatePicker
                  views={["year", "month"]}
                  className={classNames(
                    "text-field",
                    classes.textField,
                    classes.dateField
                  )}
                  value={this.state.dataPickerStart}
                  name="start"
                  onChange={(date) => this.handledataPickerStar(date, "start")}
                  labelFunc={(date) =>
                    date ? dayjs(date).format("MM.YYYY") : ""
                  }
                />
                {this.state.dataPickerStart !== null ? (
                  <HighlightOffIcon
                    className="clearDate"
                    onClick={() => this.handleDefaultDate("dataPickerStart")}
                  />
                ) : null}
              </MuiPickersUtilsProvider>
            </div>

            <div className={classNames(classes.calendar, classes.dateBlock)}>
              <div
                className={classes.dataIcon}
                onClick={this.handleToggleCalendar}
              >
                <img
                  src="/images/icons/calendar.svg"
                  alt="calendar"
                  style={{ width: 28 }}
                />
              </div>
              <div
                className={classes.dataPick}
                style={{
                  display: this.state.isVisibleDataPicker ? "block" : "none",
                }}
              />
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <InlineDatePicker
                  views={["year", "month"]}
                  className={classNames(
                    "text-field",
                    classes.textField,
                    classes.dateField
                  )}
                  value={this.state.dataPickerEnd}
                  onChange={(date) => this.handledataPickerStar(date, "end")}
                  ref={this.dataPickerEnd}
                  name="end"
                  labelFunc={(date) =>
                    date ? dayjs(date).format("MM.YYYY") : ""
                  }
                />
                {this.state.dataPickerEnd !== null ? (
                  <HighlightOffIcon
                    className="clearDate"
                    onClick={() => this.handleDefaultDate("dataPickerEnd")}
                  />
                ) : null}
              </MuiPickersUtilsProvider>
            </div>
          </Grid>

          <Grid item xs={12} className="p0">
            {false && (
              <p style={{ textAlign: "right" }}>
                <a
                  ref={(link) => {
                    this.imageLink = link;
                  }}
                  className="imageUrl"
                  href
                >
                  <Button variant="contained" color="primary">
                    Download .png
                  </Button>
                </a>
              </p>
            )}

            {showMap && (
              <Map
                ref={(reference) =>
                  (this.map = reference && reference.leafletElement)
                }
                className={classNames(
                  classes.map,
                  this.state.isOpenDrawer ? "" : "sidebar-collapsed"
                )}
                center={position}
                zoom={zoom}
                minZoom={5}
                maxZoom={13}
                zoomSnap={0.5}
                zoomDelta={0.5}
                smoothZoom={true}
                smoothZoomDelay={1000}
                // maxBounds={bounds} //automatic card alignment
                onMoveEnd={this.delayedImagePrepare}
                onZoomEnd={() => {
                  if (this.map) {
                    zoom = this.map.getZoom();
                  }
                  this.delayedImagePrepare();
                }}
              >
                <TileLayer
                  url="https://api.mapbox.com/styles/v1/ocode/cjxp8ay36363q1cnyq3eams8z/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib2NvZGUiLCJhIjoiY2p4cDZ4ZGIxMGRsbDNvcm5zdXlzOGlxNSJ9.zV4zT_qbBRzAoXzvzbvp3A"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {kind === "oblast" ? (
                  <OblastLayer
                    onLoad={this.onOverLayerLoad}
                    zoomToDetailedView={this.zoomToDetailedView}
                    detectRetina={true}
                    asColorGradient={asColorGradient}
                    preparePopupData={this.preparePopupData}
                  />
                ) : (
                  asHromada && (
                    <HromadaLayer
                      onLoad={this.onOverLayerLoad}
                      detectRetina={true}
                      asColorGradient={asColorGradient}
                      yearFilter={this.state.yearFilter}
                      preparePopupData={this.preparePopupData}
                      showOblastBorders={this.state.showOblastBorders}
                      style={{
                        boxShadow: "0px 20px 10px 3px #0000004d",
                      }}
                    />
                  )
                )}

                {(!asColorGradient ||
                  (!singleLayerMode && !specialTspMode) ||
                  withGenderProportion) && (
                  <CustomMarkers
                    layersVersion={layersVersion}
                    baseLayer={baseLayer}
                    layersData={layersData}
                    areasData={areasData}
                    yearFilter={asHromada ? this.state.yearFilter : null}
                    disableClusteringAtZoom={kind === "oblast" ? 6 : 9}
                    selectedLayers={selectedLayers}
                    selectedSubLayers={selectedSubLayers}
                    withGenderProportion={
                      isParticipantLayer && withGenderProportion
                    }
                    asColorGradient={asColorGradient}
                    singleLayerMode={singleLayerMode}
                    layersFilterObjects={layersFilterObjects}
                    buttonsLabelsColors={buttonsLabelsColors}
                    asHromada={asHromada}
                  />
                )}
              </Map>
            )}

            {
              <MiniDrawer
                onFilterLayerClick={this.onFilterLayerClick}
                user={this.props.user}
                selectedLayers={selectedLayers}
                layersFilterObjects={layersFilterObjects}
                selectedSubLayers={selectedSubLayers}
                onFilterSubLayerClick={this.onFilterSubLayerClick}
                collapsed={collapsed}
                onSidebarToggle={this.onSidebarToggle}
                singleLayerMode={singleLayerMode}
                specialTspMode={specialTspMode}
                isParticipantLayer={isParticipantLayer}
                asColorGradient={asColorGradient}
                toggleColorGradient={this.toggleColorGradient}
                withGenderProportion={withGenderProportion}
                toggleGenderProportion={this.toggleGenderProportion}
                buttonsLabelsColors={buttonsLabelsColors}
                isHromadaView={asHromada}
                onButtonsLabelsColorsChange={
                  this.handleButtonsLabelsColorsChange
                }
                onHandleGradientColorChange={this.handleGradientColorChange}
                isOpenDrawer={this.isOpenDrawer}
                handleDefault={this.handleDefault}
                selectedProjects={selectedProjects}
                handleProject={this.handleProject}
              />
            }

            <div
              ref={this.legendTpl}
              className={classNames(classes.legend, classes.info)}
            >
              <h4>{legendTitle}</h4>
              <a href className="close" onClick={this.handleCloseLegend}>
                <Close />
              </a>
              {legendData.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div style={{ display: "flex" }}>
                      <i
                        style={{
                          background: Color(item[0]).alpha(0.5).string(),
                        }}
                      ></i>
                      {item[1]}
                    </div>
                  </React.Fragment>
                );
              })}

              {selectedLayers.map((layerId, index) => {
                const meta = layersMeta[layerId];
                const subLayers = selectedSubLayers[layerId];
                if (
                  meta.type !== "distribution" ||
                  (subLayers && subLayers.length === 1) ||
                  (asColorGradient && specialTspMode)
                ) {
                  return "";
                }

                return (
                  <React.Fragment key={index}>
                    <br />
                    <h4>{meta.legendLabel || meta.label}</h4>
                    {layersFilterObjects[layerId].map((obj, i) => {
                      const label = obj.en || obj.nameEn;
                      if (
                        !subLayers ||
                        !subLayers.length ||
                        subLayers.find((x) => x.id === obj.id)
                      ) {
                        return (
                          <React.Fragment key={i}>
                            <div
                              style={{ display: "flex", marginBottom: "10px" }}
                            >
                              <i
                                style={{
                                  background: section2color(obj.id, layerId),
                                  flex: "0 0 11%",
                                }}
                              />
                              <div>{label}</div>
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return null
                      }
                    })}
                  </React.Fragment>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(withRoot(withStyles(styles)(InteractiveMap)));

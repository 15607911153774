import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import LoadMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Loader from 'components/v2/Loader';
import ResourceDispatcher from 'support/ResourceDispatcher';
import { useNotifications } from 'components/notifications';
import { arrayRemoveImmutable } from 'helpers';

const uri = `${process.env.REACT_APP_API_HOST}/api`;

const styles = {
  bottomBar: {
    display: 'flex',
    justifyContent: 'center'
  }
};

function DataSourceWithLoadMore(props) {
  const { path, children, classes } = props;
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(true);
  let [total, setTotal] = useState(0);
  let [page, setPage] = useState(1);
  const notifications = useNotifications();
  const location = useLocation();
  const action = location.state?.action;

  useEffect(() => {
    if (!action) return;

    const { name, params } = action;
    const actions = {
      create: (items) => [{ ...params }, ...items],
      update: (items) => items.map((item) => item.id === params.id ? { ...item, ...params } : item),
      delete: (items) => arrayRemoveImmutable(items, items.findIndex((item) => item.id === params.id))
    };

    setData((prev) => actions[name](prev.concat()));
  }, [action]);

  const load = async (page = 1) => {
    const api = new ResourceDispatcher(`${uri}/${path}${path.includes('?') ? '&' : '?'}page=${page}`);

    setLoading(true);

    try {
      const response = await api.get();
      const data = await response.json();

      setData((prev) => prev.concat(data));
      setTotal(parseInt(response.headers.get('x-total')));
    } catch(error) {
      notifications.error(error.message);
    }

    setLoading(false);
  }

  const loadMore = () => {
    load(page + 1);
    setPage(page + 1)
  }

  useEffect(() => {
    setPage(1);
    load();

    return () => setData([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <>
      {data.length > 0 && children(data)}

      {loading && <Loader compact={page > 1} />}

      {data.length < total && !loading && 
        <div className={classes.bottomBar}>
          <IconButton onClick={loadMore}>
            <LoadMoreIcon />
          </IconButton>
        </div>
      }
    </>
  );
}

export default withStyles(styles)(DataSourceWithLoadMore);

import { Fragment, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSearch } from 'support/hooks';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DateField from 'components/form/DateField';
import dayjs from 'dayjs';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import ActivitiesIcon from '@material-ui/icons/Event';
import ParticipantsIcon from '@material-ui/icons/People';
import Divider from '@material-ui/core/Divider';
import { truncate } from 'lodash';
import classnames from 'classnames';
import { monthFromRange } from './calendar/helpers';

const styles = (theme) => ({
  totalBar: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '8px 16px'
  },
  nested: {
    display: 'flex',
    padding: '0 16px'
  },
  dateFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 16px 0'
  },
  dateField: {
    width: 'calc(50% - 10px)'
  },
  monthFilter: {
    padding: '16px 16px 0',
    '& > div': {
      width: '100%'
    }
  },
  expander: {
    display: 'flex',
    justifyContent: 'center',
    '& > svg': {
      cursor: 'pointer'
    }
  },
  enabled: {
    order: -1
  }
});

function Filter(props) {
  const { classes, total, filters } = props;

  const initiallyExpanded = Object.fromEntries(
    filters.map(({ name, expanded }) => [name, expanded])
  );
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const search = useSearch();

  const initiallyEnabled = Object.fromEntries(
    filters
      .map(({ name }) => [name, search.getAll(name + '[]')])
      .concat([['startDate', search.get('startDate')], ['endDate', search.get('endDate')]])
  );
  const [enabled, setEnabled] = useState(initiallyEnabled);

  const history = useHistory();

  useEffect(() => {
    for (const name in enabled) {
      if (Array.isArray(enabled[name])) {
        search.delete(name + '[]');
        if (enabled[name].length > 0) {
          enabled[name]
            .sort((a, b) => a - b)
            .forEach((value) => search.append(name + '[]', value));
        }
      } else {
        if (enabled[name]) {
          search.set(name, enabled[name]);
        }
      }
    }

    history.push({ search: search.toString() });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, history]);

  const handleExpand = (name) => {
    setExpanded((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleChange = (name, value) => {
    setEnabled((prev) => { 
      const next = { ...prev };

      if (next[name] && next[name].includes(value)) {
        next[name] = next[name].filter((v) => v !== value);
      } else {
        next[name] = next[name] ? next[name].concat([value]) : next[name] = [value]; 
      }

      return next;
    });
  };

  const handleDateChange = (name, value) => {
    setEnabled((prev) => ({ ...prev, [name]: value ? dayjs(value).format('YYYY-MM-DD') : '' }));
    search.delete(name);
  };

  const handleMonthChange = (value) => {
    const format = 'YYYY-MM-DD';
    const month = value.format(format);
    const startDate = value.startOf('month').format(format);
    const endDate = value.endOf('month').format(format);

    setEnabled((prev) => ({ ...prev, month, startDate, endDate }));
  }

  return (
    <>
      <div className={classes.totalBar}>
        <Tooltip
          title="Total activities"
          placement="top"
        >
          <Badge
            badgeContent={total.activities}
            color="primary"
            max={Infinity}
            showZero
          >
            <ActivitiesIcon />
          </Badge>
        </Tooltip>
        <Tooltip
          title="Total participants"
          placement="top"
        >
          <Badge
            badgeContent={total.participants}
            color="primary"
            max={Infinity}
            showZero
          >
            <ParticipantsIcon />
          </Badge>
        </Tooltip>
      </div>

      <Divider variant="middle" />

      <Route exact path="/activities">
        <div className={classes.dateFilter}>
          <DateField
            className={classes.dateField}
            label="Start Date"
            onChange={(value) => handleDateChange('startDate', value)}
            value={enabled['startDate']}
          />
          <DateField
            className={classes.dateField}
            label="End Date"
            onChange={(value) => handleDateChange('endDate', value)}
            value={enabled['endDate']}
          />
        </div>
      </Route>

      <Route exact path="/activities/calendar">
        <div className={classes.monthFilter}>
          <DateField
            views={['year', 'month']}
            label="Month"
            onChange={(value) => handleMonthChange(value)}
            value={monthFromRange(enabled['startDate'], enabled['endDate'])}
            format="MMMM YYYY"
          />
        </div>
      </Route>

      <List>
        {filters.map(({ name, label, terms }) => terms.length > 0 && (
          <Fragment key={name}>
            <ListItem>
              <ListItemText primary={label} primaryTypographyProps={{variant: 'h5', color: 'primary'}} />
            </ListItem>

            <Collapse 
              in={expanded[name] || terms.length <= 5} 
              timeout="auto" 
              collapsedHeight={terms.length > 5 ? '240px' : '0'}
            >
              <FormGroup className={classes.nested}>
                {terms.map(({ label, value, count }, index) => (
                  <FormControlLabel
                    className={classnames({ [classes.enabled]: enabled[name] ? enabled[name].includes(value.toString()) : false})}
                    key={index}
                    control={
                      <Checkbox
                        checked={enabled[name] ? enabled[name].includes(value.toString()) : false}
                        onChange={() => handleChange(name, value.toString())} 
                        value={value.toString()}
                        color="primary"
                      />
                    }
                    label={`${truncate(label)} (${count})`}
                  />
                ))}
              </FormGroup>
            </Collapse>

            {terms.length > 5 && (
              <div className={classes.expander}>
                {expanded[name] ? 
                  <ExpandLess color="primary" onClick={() => handleExpand(name)} fontSize="small" /> : 
                  <ExpandMore color="primary" onClick={() => handleExpand(name)} fontSize="small" />
                }
              </div>)
            }
          </Fragment>
        ))}
      </List>
    </>
  );
}

export default withStyles(styles)(Filter);

import React from "react";
import withRoot from "../../../withRoot";
import BaseGraph from "../base-graph";

const datasetOptions = [
  {
    label: "% Type",
    fill: false,
    borderColor: "#fdbb2d",
    backgroundColor: "#fdbb2d",
    pointBorderColor: "#fdbb2d",
    pointBackgroundColor: "#fdbb2d",
    pointHoverBackgroundColor: "#fdbb2d",
    pointHoverBorderColor: "#fdbb2d",
  },
];

const options = {
  responsive: true,
  tooltips: {
    mode: "label",
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
        },
        ticks: {
          callback: function (value, index, values) {
            return value + "%";
          },
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

class ParticipantEmployerTypesChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type="horizontalBar"
        tableTitle="Types of activity participants"
        url="/graph/participant_employer_types"
        chartOptions={options}
        datasetOptions={datasetOptions}
        filter={this.props.filter}
      />
    );
  }
}

export default withRoot(ParticipantEmployerTypesChart);

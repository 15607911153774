import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useApi } from '../support/api';
import { saveAs } from 'file-saver';
import CopyUrlButton from './CopyUrlButton';
import CloneButton from './buttons/CloneButton';

function DataTable(props) {
  const { rows } = props;
  const api = useApi(); 

  async function handleDownload(survey) {
    const response = await api.surveys(survey.id).replies.get('xlsx');
    const blob = await response.blob();

    saveAs(blob, `${survey.slug}-replies.xlsx`);
  }
  
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Slug</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.slug}</TableCell>
            <TableCell align="right">
              <Tooltip title="Download">
                <IconButton 
                  color="primary"
                  onClick={() => handleDownload(row)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton 
                  component={Link} 
                  to={`/surveys/${row.id}/edit`}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy URL">
                <CopyUrlButton path={`/public/surveys/${row.slug}`} />
              </Tooltip>
              <Tooltip title="Clone">
                <CloneButton source={row} />
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default DataTable;

import { makeAutoObservable, runInAction } from "mobx";
import FileSaver from "file-saver";
import { REQUEST_STATES } from "../utils/RequestStates";

export class ActivityItemStore {
  dataFetcher = null;
  templateDownloadingStatus = REQUEST_STATES.Initial;
  importUploadingStatus = REQUEST_STATES.Initial;
  importedRows = 0;
  importedErrors = 0;

  constructor(dataFetcher) {
    this.dataFetcher = dataFetcher;
    makeAutoObservable(this, {
      dataFetcher: false,
    });
  }

  get downloadTemplateInProgress() {
    return this.templateDownloadingStatus === REQUEST_STATES.Pending;
  }

  get uploadParticipantsInProgress() {
    return this.importUploadingStatus === REQUEST_STATES.Pending;
  }

  async downloadParticipantsTemplate() {
    this.templateDownloadingStatus = REQUEST_STATES.Pending;
    try {
      const {
        name,
        data,
      } = await this.dataFetcher.downloadParticipantsTemplate();
      FileSaver.saveAs(data, name);
      runInAction(() => {
        this.templateDownloadingStatus = REQUEST_STATES.Success;
      });
    } catch (ex) {
      runInAction(() => {
        this.templateDownloadingStatus = REQUEST_STATES.Error;
      });
    }
  }

  async uploadParticipants(activityId, file) {
    this.importUploadingStatus = REQUEST_STATES.Pending;
    try {
      const data = await this.dataFetcher.uploadParticipants(activityId, file);
      runInAction(() => {
        this.importedRows = data.importedIds?.length ?? 0;
        this.importedErrors = data.errors?.length ?? 0;
        this.importUploadingStatus = REQUEST_STATES.Success;
      });
    } catch (ex) {
      runInAction(() => {
        this.importUploadingStatus = REQUEST_STATES.Error;
      });
      throw ex;
    }
  }
}

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DataSource from 'components/DataSource';
import Form from './Form';
import { useApi } from 'support/api';
import { withNotifications } from 'components/notifications';

function Edit(props) {
  const { notifications, onEdit } = props;
  const [ reload, setReload ] = useState('');
  const { surveyId, id } = useParams();
  const api = useApi();

  const handleEdit = async (values, form) => {
    notifications.info('Saving', null);

    const { name, type, optional } = values;

    const response = await api.surveys(surveyId).questions(id).put({ name, type, optional });
    const question = await response.json();

    onEdit(question);

    if (Array.isArray(values.options)) {
      const options = values.options.map(
        (option, weight) => ({ ...option, weight })
      );

      await api.surveys(surveyId).questions(question.id).options.put({ options });

      if (form.getFieldState('options')) {
        const ids = options.map((o) => o.id);
        const deletedOptions = form.getFieldState('options').initial.filter((option) => !ids.includes(option.id));

        await Promise.all(
          deletedOptions.map(
            (option) => api.surveys(surveyId).questions(question.id).options(option.id).delete()
          )
        );
      }
    }

    notifications.success('Saved');
    setReload('#reload' + new Date().getTime());
  }

  return (
    <DataSource path={`surveys/${surveyId}/questions/${id}${reload}`}>
      {(question) => (
        <Form onSubmit={handleEdit} values={question} />
      )}
    </DataSource>
  );
}

export default withNotifications(Edit);

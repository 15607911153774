import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../../withRoot';
import downloadFile from '../../../utils/fetchFile';
import { PlayArrow, FastForward, FastRewind } from '@material-ui/icons';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '30px',
    '& .container': {
      width: '467px',
      height: '334px',
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto'
      },
      '&:hover .btn': {
        opacity: '1'
      }
    },
    '& .btn': {
      display: 'inline-block',
      width: '45px',
      height: '45px',
      lineHeight: '45px',
      background: '#9BD9C8 no-repeat center center / 50px 50px',
      borderRadius: '6px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: '0',
      transition: 'opacity 0.2s',
      '&.btn-download': {
        marginTop: '20px',
        width: 'auto',
        color: '#ffffff',
        background: ' #77d2b7',
        padding: '0 15px 0 40px',
        '& i': {
          width: '45px',
          height: '45px',
          display: 'inline-block',
          background: 'url(/images/icons/icon-download.svg) no-repeat center center',
          position: 'absolute',
          left: '0',
          top: '0',
        }
      },
    },
    '& .slider-caption': {
      color: '#AFBECD',
      fontSize: '12px',
      fontWeight: '500',
      margin: '15px 0 0 !important',
    },
    '& .slider-actions': {
      width: '300px',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginTop: '20px',
      '& a': {
        color: '#AFBECD',
      },
      '& span': {
        color: '#AFBECD',
        fontWeight: '900',
        fontSize: '18px'
      },
      '& .previous-icon': {
        transform: 'rotate(180deg)'
      }
    }
  },
});


class Slider extends React.Component {
  state = {
    totalSlides: 0,
    currentSlideNumber: 0,
    currentSlideURL: '',
    currentDownloadSlideURL: ''
  };

  slidePhotos = [];
  
  componentDidMount() {
    this.props.slides.forEach(slide => {
      if(slide.photoUrl) {
        this.slidePhotos.push(slide);
      }
    })

    if(this.slidePhotos.length) {
      this.setState({
        totalSlides: this.slidePhotos.length,
        currentSlideURL: this.slidePhotos[0].photoUrl,
        currentSlideCaption: this.slidePhotos[0].caption,
        currentDownloadSlideURL: this.slidePhotos[0].originalPhotoUrl
      });
    }
  }

  nextSlide = () => {
    let currentSlideNumber = this.state.currentSlideNumber + 1;
    if(this.state.currentSlideNumber ===  (this.state.totalSlides - 1)) {
      currentSlideNumber = 0;
    }
    this.setState({
      currentSlideURL: this.slidePhotos[currentSlideNumber].photoUrl,
      currentSlideCaption: this.slidePhotos[currentSlideNumber].caption,
      currentDownloadSlideURL: this.slidePhotos[currentSlideNumber].originalPhotoUrl,
      currentSlideNumber: currentSlideNumber
    });
  }

  previousSlide = () => {
    let currentSlideNumber = this.state.currentSlideNumber - 1;
    if(this.state.currentSlideNumber ===  0) {
      currentSlideNumber = this.state.totalSlides;
    }
    this.setState({
      currentSlideURL: this.slidePhotos[currentSlideNumber].photoUrl,
      currentSlideCaption: this.slidePhotos[currentSlideNumber].caption,
      currentDownloadSlideURL: this.slidePhotos[currentSlideNumber].originalPhotoUrl,
      currentSlideNumber: currentSlideNumber
    });
  }

  startSlide = () => {
    const currentSlideNumber = 0;
    this.setState({
      currentSlideURL: this.slidePhotos[currentSlideNumber].photoUrl,
      currentSlideCaption: this.slidePhotos[currentSlideNumber].caption,
      currentDownloadSlideURL: this.slidePhotos[currentSlideNumber].originalPhotoUrl,
      currentSlideNumber: currentSlideNumber
    });
  }

  handleDownload = (file) => {
    return async (e) => {
      e.preventDefault()
      downloadFile(file);
    }
  }

  endSlide = () => {
    const currentSlideNumber = this.state.totalSlides - 1;
    this.setState({
      currentSlideURL: this.slidePhotos[currentSlideNumber].photoUrl,
      currentSlideCaption: this.slidePhotos[currentSlideNumber].caption,
      currentDownloadSlideURL: this.slidePhotos[currentSlideNumber].originalPhotoUrl,
      currentSlideNumber: currentSlideNumber
    });
  }

  render() {
    const { classes } = this.props;
    const { totalSlides, currentSlideURL, currentSlideCaption, currentSlideNumber, currentDownloadSlideURL } = this.state;
    return (
      <div className={classes.root}>
        <div className="container">
          { totalSlides > 0 ?
            <a href className="btn btn-download" onClick={this.handleDownload(currentDownloadSlideURL)}>Download file <i></i></a>
            : '' }
          <img src={currentSlideURL} alt="" />
        </div>
        <div className="slider-actions">
          <a href className="start" onClick={this.startSlide}>
            <FastRewind />
          </a>
          <a href className="previous" onClick={this.previousSlide}>
            <PlayArrow className="previous-icon" />
          </a>
          { totalSlides > 0 ?
            <span>{currentSlideNumber + 1}/{totalSlides}</span>
            : '' }
          <a href className="next" onClick={this.nextSlide}>
            <PlayArrow />
          </a>
          <a href className="end" onClick={this.endSlide}>
            <FastForward />
          </a>
        </div>
        <div className="slider-caption">
          {currentSlideCaption}
        </div>
      </div>
    )
  }
}

export default withRoot(withStyles(styles)(Slider));

import React from 'react';
import BaseGraph from '../../components/charts/base-graph';

const S01c = ({ chart, simpleDatasetOptsTpl, chartStyleOptsTpl, pluginsTpl, colors }) => {

  const RenderGraphs = ({ chart }) => {

    const datasetOptions = chart.legend.map((label, i) =>
      simpleDatasetOptsTpl(
        label,
        chart.subTypes[i],
        colors[i],
        chart.secondYaxis && (chart.atSecondAxis ? chart.atSecondAxis[i] : i === 1)
      )
    )
    const options = chartStyleOptsTpl(chart.type, chart.isPercent, chart.isStacked, chart.isPercent2, chart.secondYaxis)
    if (datasetOptions.length > 1 || chart.subTypes.length > 1) {
      options.legend.display = true
    }
    const plugins = pluginsTpl(chart.type, chart.isPercent, chart.isPercent2, chart.secondYaxis, chart.atSecondAxis)

    return (
      <BaseGraph
        type={chart.type}
        tableTitle={chart.title}
        url={chart.url}
        chartOptions={options}
        datasetOptions={datasetOptions}
        plugins={plugins}
      />
    )
  }

  return <RenderGraphs chart={chart} key={chart.legend[0]}/>

}

export default S01c;

import React from 'react';
import { Form } from 'react-final-form';
import {
  Button,
  FormLabel,
  Grid,
  TextField 
} from '@material-ui/core';
import Field from 'components/form/Field';
import PartnerLogos from './PartnerLogos';

function SurveyForm(props) {
  return (
    <Form onSubmit={props.onSubmit} initialValues={props.initialValues}>
      {({ handleSubmit, submitting, submitSucceded, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} md={6}>
              <Field name="name">
                {({ input, meta }) => (
                  <TextField 
                    label="Name"
                    fullWidth
                    {...input}
                    error={meta.submitError && meta.touched}
                    helperText={meta.submitError}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} md={6}>
              <Field name="slug">
                {({ input, meta }) => (
                  <TextField
                    label="Slug"
                    fullWidth
                    {...input}
                    error={meta.submitError && meta.touched}
                    helperText={meta.submitError}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field name="foreword">
                {({ input, meta }) => (
                  <TextField
                    label="Foreword"
                    fullWidth
                    multiline
                    {...input}
                    error={meta.submitError && meta.touched}
                    helperText={meta.submitError}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field name="afterword">
                {({ input, meta }) => (
                  <TextField
                    label="Afterword"
                    fullWidth
                    multiline
                    {...input}
                    error={meta.submitError && meta.touched}
                    helperText={meta.submitError}
                  />
                )}
              </Field>
            </Grid>

            {values.id && (
              <Grid item xs={12}>
                <FormLabel>
                  Partner logos
                </FormLabel>
                <PartnerLogos images={values.partnerLogos} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Button 
                color="primary" 
                type="submit" 
                disabled={submitting || pristine} 
                variant="outlined"
              >
                Save
              </Button>
            </Grid>

          </Grid>
        </form>
      )}
    </Form>
  );
}

export default SurveyForm;

import { Field } from 'react-final-form';
import {
  FormControl,
  Input,
  FormLabel
} from '@material-ui/core';
import { required } from './validators';

function TextAnswerField(props) {
  const { question } = props;

  return (
    <FormControl fullWidth>
      <FormLabel> 
        {question.name}
      </FormLabel>
      <Field name={`question${question.id}.value`} validate={question.optional ? false : required}>
        {({ input, meta }) => 
          <Input {...input} />
        }
      </Field>
    </FormControl>
  );
}

export default TextAnswerField;

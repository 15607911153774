import { variables } from "./variables";

export const loginRoute = "/login";
export const forgotPasswordRoute = "/forgot-password";
export const resetPasswordRoute = "/reset-password";
export const preregistrationRoute = "/preregistration/:id";
export const activityRoute = "/legacy/activities";
export const participantRoute = "/participants";
export const characterRoute = "/characters";
export const characteristicRoute = "/characteristics";
export const formatRoute = "/formats";
export const categoryRoute = "/categories";
export const categoryGroupRoute = "/category-groups";
export const organizationsRoute = "/organizations";
export const networkMapRoute = "/network-map";
export const categoryResultRoute = "/category-results";

export const forumRoute = `https://${variables.forumHost}/c/tech-issues`;

export const publicRoutes = [
  loginRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
  preregistrationRoute,
  '/public/surveys/:name',
  '/public/thanks'
];

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: 8 
  }
};

function ListItem(props) {
  const { classes, children } = props;

  return ( 
    <div className={classes.root}>
      {children}
    </div>
  );
}

export default withStyles(styles)(ListItem);

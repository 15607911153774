export const API = `${process.env.REACT_APP_API_HOST}/api`;

export const GisData =
    {
        MappingTool:
            {
                center: [45.386268, 36.2646965],
                initialZoom: 5.6
            },
        AddressSearch:
            {
                initialPosition:[41.716667, 44.783333]
            }
    }

export const AppConfig =
    {
        User:
            {
                enableMultiProject: false,
            }
    }

export const Endpoints = {
    Category: {
        path: "/categories",
    },
    Participant: {
        path: "/participants",
    },
    Trainer: {
        path: "/trainers",
    },
    Facilitator: {
        path: "/facilitators",
    },
    FundingSource: {
        path: "/funding_sources",
    },
    EmployerLevel: {
        path: "/employer_levels",
    },
    EmployerType: {
        path: "/employer_types",
    },
    TargetGroup: {
        path: "/target_groups",
    },
    TargetLevel: {
        path: "/target_levels",
    },
    Organizer: {
        path: "/organizers",
    },
    GoodPractice: {
        path: "/good_practices",
    },
    CategoryResults: {
        path: "/category_results",
    },
};

import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { selectStyles } from '../../style/select-styles';
import AsyncSelect from 'react-select/lib/Async';
import {
  InputLabel,
  Typography
} from '@material-ui/core';
import { API } from '../../config.js';

export default class FormAsyncOrganizationSelectField extends React.Component {
  constructor(props) {
    super(props);
    
    this.loadOptions = this.loadOptions.bind(this);
  }

  loadOptions(input) {
    const url = `${API}/suggest/organizations?substr=${encodeURIComponent(input)}`;

    let headers = {
      accept: 'application/json',
      Authorization: localStorage.getItem('TOKEN'),
    };

    return fetch(url, {headers})
      .then((res) => res.json())
      .then((organizations) => organizations.map((org) => {
          return {
            value: org.id, 
            label: this.props.lang === 'Eng' ? org.nameEn : org.nameUa,
            object: org
          }
      }));
  }

  value() {
    const value = this.props.value;

    if (!value) {
      return;
    }

    return {
      value: value.id,
      label: this.props.lang === 'Eng' ? value.nameEn : value.nameUa
    }
  }

  render() {
    const {
      isEditable,
      fieldName,
      classes,
      label,
      isDisabled,
      tabIndex,
      defaultOptions
    } = this.props;

    const value = this.value();

    return isEditable ? (
      <Field name={fieldName}>
        {({ input, meta }) => (
          <>
            <InputLabel className={classNames(classes.label)}>
              {label}
            </InputLabel>
            <AsyncSelect
              cacheOptions={true}
              loadOptions={this.loadOptions}
              defaultOptions={defaultOptions}
              value={value}
              onChange={(e) => {
                this.props.action({ fieldName, value: e.object });
              }}
              styles={selectStyles}
              isDisabled={isDisabled}
              inputProps={{tabIndex}}
              className={classNames({
                errorSelect: meta.invalid
              })}
              helperText={meta.touched && meta.error ? meta.error : ''}
            />
          </>
        )}
      </Field>
    ) : (
      <div>
        <InputLabel>{label}</InputLabel>
        <Typography varian='body2'>{value?.label}</Typography>
      </div>
    );
  }
}

import { DatePicker } from 'material-ui-pickers';

function DateField(props) {
  return (
    <DatePicker
      autoOk
      clearable
      InputLabelProps={{ shrink: true }}
      format="DD.MM.YYYY"
      {...props}
    />
  );
}

export default DateField;

import { useState } from 'react';
import NotificationsContext from './context';
import Notification from './Notification';

const queue = [];

function Provider(props) {
  const { children } = props;
  const [ message, setMessage ] = useState({ open: false });

  function open(variant, text, autoHideDuration = 3000) {
    const newMessage = { 
      open: true, 
      key: new Date().getTime(),
      variant, 
      text, 
      autoHideDuration 
    };

    setMessage((prevMessage) => {
      if (!prevMessage.open) {
        return newMessage;
      }

      queue.push(newMessage);

      return { ...prevMessage, open: false };
    });
  }

  function processQueue() {
    if (queue.length < 1) return;
    const message = queue.shift();

    setMessage(message);
  }

  const methods = {
    error: (text, ms) => open('error', text, ms), 
    info: (text, ms) => open('info', text, ms), 
    success: (text, ms) => open('success', text, ms), 
    warning: (text, ms) => open('warning', text, ms)
  };

  return (
    <NotificationsContext.Provider value={methods}>
      <>
        <Notification 
          {...message}
          onClose={() => setMessage((prev) => ({ ...prev, open: false }))}
          onExited={processQueue}
        />
        {children}
      </>
    </NotificationsContext.Provider>
  );
}

export default Provider;

import ResourceDispatcher from 'support/ResourceDispatcher';

const host = `${process.env.REACT_APP_API_HOST}`;

const dispatcher = new ResourceDispatcher(host);

export default dispatcher;

export function useApi(auth = true) {
  const headers = {};
  const uri = host + '/' + (auth ? 'api' : 'public_api'); 

  if (auth) {
    headers.Authorization = localStorage.getItem('TOKEN');
  }

  return new ResourceDispatcher(uri, headers);
}

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.default,
    columnGap: theme.spacing.unit * 2 
  }
});

function ActionBar(props) {
  const { classes, children } = props;

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
}

export default withStyles(styles)(ActionBar);

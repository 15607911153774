import AppBar from 'components/AppBarWithBackButton';
import { withStyles } from '@material-ui/core/styles';
import Form from './Form';
import { useApi } from 'support/api';
import { useHistory } from 'react-router-dom';
import { useNotifications } from 'components/notifications';

const styles = {
  wrapper: {
    margin: 'auto',
    maxWidth: '800px'
  }
};

function CreateView(props) {
  const { classes } = props;
  const api = useApi();
  const history = useHistory();
  const notifications = useNotifications();

  async function handleSubmit(values) {
    const payload = new FormData();

    Object
      .entries(values)
      .forEach(([name, value]) => payload.append(name, value));

    const response = await api.videos.create(payload);

    const video = await response.json();

    if (video.errors) {
      return video.errors;
    }

    history.replace(`/v/${video.slug}`);

    notifications.success('Created');
  }

  return (
    <>
      <AppBar>
      </AppBar>
      <div className={classes.wrapper}>
        <Form onSubmit={handleSubmit} />
      </div>
    </>
  );
}

export default withStyles(styles)(CreateView);

import ItemService from "./ItemService";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import { map, capitalize } from "lodash";
import { Endpoints } from "../config.js";

export class PersonService extends ItemService {
  GetInitialState(userRoles) {
    let state = super.GetInitialState(userRoles);

    state = {
      ...state,
      genderOptions: [
        { value: 0, label: "" },
        { value: "MALE", label: "MALE" },
        { value: "FEMALE", label: "FEMALE" },
        { value: "UNKNOWN", label: "UNKNOWN" },
      ],
    };
    return state;
  }

  async Action(e, state) {
    // base class is not called and contains only default implementation
    // could be improved ...

    switch (e.fieldName) {
      case "name":
        state = this.SetName(state, e.value);
        break;
      default:
        // never modify state, always clone
        state = { ...state };
        state[e.fieldName] = e.value;
        break;
    }

    state = this.SetStateChanged(state);

    return state;
  }

  SetName(state, value) {
    let translit = cyrillicToTranslit({ preset: "uk" }).transform(value);
    translit = map(translit.split(" "), capitalize).join(" ");

    let result = { ...state, name: value, nameTranscription: translit };

    return result;
  }

  ValidateItem(state) {
    let result = super.ValidateItem(state);

    this.validationRequired(result, state, "name");
    this.validationRequired(result, state, "nameTranscription");
    this.validationSelectionRequired(result, state, "gender");
    // this.validationRequired(result, state, "ua");
    // this.validationRequired(result, state, "en");
    // this.validationRequired(result, state, "keywords");

    return result;
  }

  MapStateToModel(state) {
    let data = super.MapStateToModelMultiPart(state);

    const params = this.GetApiPutParams();

    for (let p of params) {
      if (p.name === "name_transcription" && state.nameTranscription) {
        data.append(p.name, state.nameTranscription);
      } //else if (p.name === "gender") {
      //   if (state.gender?.value) {
      //     data.append(p.name, state.gender.value);
      //   }
      else {
        this.MapProperty(data, state, p);
      }
    }

    return data;
  }

  GetApiPutParams() {
    return [
      {
        in: "formData",
        name: "name",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "name_transcription",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "gender",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "email",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "cellPhone",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "info",
        type: "string",
        required: false,
      },
      {
        in: "path",
        name: "id",
        type: "integer",
        format: "int32",
        required: true,
      },
    ];
  }
}

export class TrainerService extends PersonService {
  constructor(onUpdateState, lang) {
    super(Endpoints.Trainer.path, onUpdateState, lang);
  }
}

import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

function DeleteButton(props) {
  return (
    <IconButton 
      component={Link} 
      to={props.to}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
}

export default DeleteButton;

import React from "react";
import { observer } from "mobx-react";
import { Link, NavLink } from "react-router-dom";
import { AppBar, Typography, MenuItem, Select } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { StoreContext } from "../stores/context";
import withRoot from "../withRoot";
import {
  forumRoute,
  activityRoute,
  formatRoute,
  characterRoute,
  characteristicRoute,
  categoryRoute,
  categoryGroupRoute,
  networkMapRoute,
  categoryResultRoute
} from "../utils/routes";
import { config } from "../style/config";
import SurveysIcon from '@material-ui/icons/PollOutlined';
import OrganizationsIcon from '@material-ui/icons/Business';

const styles = (theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    minWidth: "1278px",
  },
  appBar: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: config.colors.primary,
    backgroundColor: config.colors.primaryBackground,
    padding: "5px 2.3vw 5px 1vw",
    height: "100px",
    boxShadow: "none",
    justifyContent: "space-between",
    [theme.breakpoints.down(1440)]: {
      height: "90px",
      justifyContent: "space-between",
    },

    "& nav": {
      display: "flex",
      justifyContent: "space-between",
      minWidth: "860px",
      width: "100%",
      padding: "7px 0 0 ",
      marginLeft: "43px",
      [theme.breakpoints.down(1440)]: {
        width: "77%",
        padding: "11px 0 0 42px",
        marginLeft: "0",
      },
    },
    "& .icon": {
      display: "block",
      height: "25px",
      width: "25px",
    },
    "& .icon-home": {
      background: "url(/images/icons/header-home.svg) no-repeat 0 0",
    },
    "& .icon-register": {
      background: "url(/images/icons/header-register.svg) no-repeat 0 0",
    },
    "& .icon-indicators": {
      background: "url(/images/icons/header-indicators.svg) no-repeat 0 0",
    },
    "& .icon-dashboard": {
      background: "url(/images/icons/header-dashboard.svg) no-repeat 0 0",
    },
    "& .icon-search": {
      background: "url(/images/icons/header-search.svg) no-repeat 0 0",
    },
    "& .icon-templates": {
      background: "url(/images/icons/header-templates.svg) no-repeat 0 0",
    },
    "& .icon-administration": {
      background: "url(/images/icons/header-administration.svg) no-repeat 0 0",
    },
    "& .icon-support": {
      background: "url(/images/icons/header-support.svg) no-repeat 0 0",
      height: "28px",
      width: "28px",
      marginBottom: "-3px",
    },
    "& .icon-user": {
      background: "url(/images/icons/header-user.svg) no-repeat 0 0",
      width: "17px",
      marginLeft: "14px",
      marginRight: "0",
      [theme.breakpoints.down(1440)]: {
        marginLeft: "4px",
      },
    },
  },
  appMenu: {
    "&.menu-popup": {
      position: "absolute",
      width: "225px",
      backgroundColor: config.colors.primaryBackground,
      top: "25px",
      paddingTop: "20px",
      left: "-20px",
      border: "1px solid #eef2f6",
      borderRadius: "5px",
      paddingBottom: "20px",
      display: "none",
      zIndex: 1
    },
    "&.menu-popup_dashboard": {
      width: "175px",
    },
    "& .icon": {
      display: "block",
      height: "14px",
      width: "26px",
      color: config.colors.primary,
      fontSize: "16px",
      margin: " 5px 0 0 7px",
      position: "absolute",
      left: "5px",
      top: "0",
      fill: config.colors.primary,
    },

    "& > div:last-child": {
      backgroundColor: "#6495ed",
      border: "none",
      boxShadow: "none",
      marginTop: "80px",
    },
    "& p": {
      outline: "none",
    },
    "& a": {
      color: "#add8e6",
      fontWeight: "700",
      fontSize: "13px",
      padding: "5px 10px 0",
      paddingLeft: "40px",
      position: "relative",
      letterSpacing: "0.1em",
    },
    "&.user-menu": {
      paddingBottom: "5px",
      width: "130px",
      top: "35px",
      left: "-19px",
      [theme.breakpoints.down(1440)]: {
        left: "-82px",
        top: "48px",
      },
      "& a": {
        padding: "10px 10px 0 40px",
        "&:last-child": {
          marginBottom: "15px",
        },
      },
      "& .icon": {
        height: "100%",
        position: "absolute",
        left: "5px",
      },
    },
    "&.register-menu": {
      "& a": {
        padding: "5px 10px 0 40px",
        "&.link-hromadas": {
          alignItems: "flex-start",
          textAlign: "left",
          "& i": {
            width: "30px",
            marginRight: "7px",
          },
        },
      },
      "& .icon": {
        height: "100%",
        marginLeft: "4px",
      },
    },
  },
  appBarLink: {
    color: config.colors.primary,
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "700",
    fontSize: "0.9vw",
    [theme.breakpoints.down(1440)]: {
      fontSize: "10pt",
    },
    cursor: "pointer",
    fontFamily: "Roboto, sans-serif",
    position: "relative",
    "& a": {
      color: config.colors.primary,
    },
    "nav &": {
      [theme.breakpoints.down(1440)]: {
        "&:last-child": {
          marginRight: "0",
        },
      },
    },

    "&:hover, &.active": {
      "& .menu-popup": {
        display: "block",
      },
    },

    "&:hover, &.active, &.active-link": {
      color: config.colors.primary,
      "& .icon-home": {
        background: "url(/images/icons/header-home.svg) no-repeat 0 0",
      },
      "& .icon-register": {
        background: "url(/images/icons/header-register.svg) no-repeat 0 0",
      },
      "& .icon-indicators": {
        background: "url(/images/icons/header-indicators.svg) no-repeat 0 0",
      },
      "& .icon-dashboard": {
        background: "url(/images/icons/header-dashboard.svg) no-repeat 0 0",
      },
      "& .icon-search": {
        background: "url(/images/icons/header-search.svg) no-repeat 0 0",
      },
      "& .icon-templates": {
        background: "url(/images/icons/header-templates.svg) no-repeat 0 0",
      },
      "& .icon-administration": {
        background:
          "url(/images/icons/header-administration.svg) no-repeat 0 0",
      },
      "& .icon-support": {
        background: "url(/images/icons/header-support.svg) no-repeat 0 0",
      },
      "& a": {
        color: config.colors.primary,
      },
    },
    "& svg": {
      height: "100%",
      width: "100%",
      maxHeight: "35px",
      maxWidth: "35px",
    },
    "& i": {
      marginRight: "10px",
      [theme.breakpoints.down(1440)]: {
        marginRight: "0",
      },
    },
    "&.active": {
      color: config.colors.primary,
    },
    "&.user-link": {
      padding: "10px 0 2px 0",
      marginLeft: "35px",
      [theme.breakpoints.down(1440)]: {
        flexDirection: "column-reverse",
        padding: "10px 0 2px 0",
        marginLeft: "42px",
      },
      "& .icon": {
        top: "5px",
      },
    },
    [theme.breakpoints.down(1440)]: {
      flexDirection: "column",
    },
  },
  appBarMenuLink: {
    color: "#fff",
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
    padding: "5px 10px 0",
    outline: "none",
    minWidth: "150px",
    backgroundColor: "transparent !important",
    textAlign: "left",
    "&:hover, &.active": {
      color: "#ffffff",
      opacity: "1",
      "& .icon-users": {
        background:
          "url(/images/icons/header-users.svg) no-repeat center center / 20px 20px",
      },
      "& .icon-graduation-cap": {
        background:
          "url(/images/icons/header-graduation-cap.svg) no-repeat center center / 20px 20px",
      },
      "& .icon-microphone": {
        background:
          "url(/images/icons/header-microphone.svg) no-repeat center center / 20px 20px",
      },
      "& .icon-hromadas": {
        background:
          "url(/images/icons/header-hromadas.svg) no-repeat center center / 26px 26px",
      },
      "& .icon-item": {
        background:
          "url(/images/icons/header-item.png) no-repeat center center / 12px 12px",
      },
      "& .icon-settings": {
        background:
          "url(/images/icons/header-settings.svg) no-repeat center center / 17px 17px",
      },
      "& .icon-password": {
        background:
          "url(/images/icons/header-password.svg) no-repeat center center / 15px 15px",
      },
      "& .icon-signout": {
        background:
          "url(/images/icons/header-signout.svg) no-repeat center center / 15px 15px",
      },
      "& .icon-calendar": {
        background:
          "url(/images/icons/header-calendar.svg) no-repeat center center / 17px 17px",
      },
      "& .icon-practice": {
        background:
          "url(/images/icons/header-practice.svg) no-repeat center center/ 17px 17px",
      },
    },
    "& .icon": {
      height: "100%",
      width: "100%",
      maxHeight: "20px",
      maxWidth: "20px",
      marginRight: "10px",
    },
    "& .icon-users": {
      background:
        "url(/images/icons/header-users.svg) no-repeat top center / 20px 20px",
      top: "-4px",
    },
    "& .icon-graduation-cap": {
      background:
        "url(/images/icons/header-graduation-cap.svg) no-repeat center center / 20px 20px",
    },
    "& .icon-microphone": {
      background:
        "url(/images/icons/header-microphone.svg) no-repeat center center / 20px 20px",
    },
    "& .icon-hromadas": {
      background:
        "url(/images/icons/header-hromadas.svg) no-repeat center center / 26px 26px",
    },
    "& .icon-item": {
      background:
        "url(/images/icons/header-item.png) no-repeat center center / 12px 12px",
    },
    "& .icon-settings": {
      background:
        "url(/images/icons/header-settings.svg) no-repeat center center/ 17px 17px",
    },
    "& .icon-password": {
      background:
        "url(/images/icons/header-password.svg) no-repeat center center / 15px 15px",
    },
    "& .icon-signout": {
      background:
        "url(/images/icons/header-signout.svg) no-repeat center center / 15px 15px",
      top: "1px",
    },
    "& .icon-calendar": {
      background:
        "url(/images/icons/header-calendar.svg) no-repeat center center/ 17px 17px",
      top: "-1px",
    },
    "& .icon-practice": {
      background:
        "url(/images/icons/header-practice.svg) no-repeat center center / 17px 17px",
    },
  },
  langSelector: {
    marginTop: "10px",
    marginLeft: "10px",
    "&> div": {
      background: "none!important",
    },
    "& .select": {
      float: "right",
    },
    "& .select:before": {
      display: "none",
    },
    "& .select:after": {
      display: "none",
    },
    "& .select > div": {
      padding: "6px 6px 7px",
    },
    "& .select > div > div": {
      height: "35px",
      minWidth: "25px",
      padding: "0 5px",
      textAlign: "center",
      backgroundColor: config.colors.primary,
      borderRadius: "8px",
      textTransform: "uppercase",
      fontWeight: "400",
      color: "#fff",
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down(1440)]: {
        height: "30px",
        width: "30px",
        margin: "4px 0 0 14px",
        borderRadius: "4px",
      },
    },
    "& .select > div > svg": {
      display: "none",
    },
  },
  logo: {
    marginTop: "8px",
    width: "220px",
  },
});

class Header extends React.Component {
  static contextType = StoreContext;

  state = {
    showHeader: true,
  };

  componentDidMount() {
    const currentPath = document.location.pathname;
    if (
      currentPath === "/login" ||
      currentPath === "/forgot-password" ||
      currentPath === "/reset-password" ||
      currentPath === "/search/oes" ||
      currentPath === "/search/xs"
    ) {
      this.setState({ showHeader: false });
    }
  }

  onSignOutClick = async () => {
    const { routerStore, authStore } = this.context;
    await authStore.signout();
    routerStore.goToLogin();
  };

  handleChangeLanguage = (e) => {
    this.context.userStore.updateLanguage(e.target.value);
  };

  handleChangeProject = async (e) => {
    const { userStore, routerStore } = this.context;
    await userStore.updateActiveProject(e.target.value);
    routerStore.refresh();
  };

  getRegisterActiveLinkClass = () => {
    const registerLinks = [
      activityRoute,
      "/participants",
      "/trainers",
      "/good-practices",
      "/communities",
    ];
    return !!registerLinks.find((item) => item === document.location.pathname)
      ? "active-link"
      : "";
  };

  getIndicatorActiveLinkClass = () => {
    const indicatorLinks = ["/indicators", "/indicator/"];
    return !!indicatorLinks.find((item) =>
      document.location.pathname.includes(item)
    )
      ? "active-link"
      : "";
  };

  getDashboardActiveLinkClass = () => {
    const dashboardLinks = [
      "/dashboard",
      "/graphs",
      "/indicator-graphs",
      "/map",
    ];

    return !!dashboardLinks.find((item) =>
      document.location.pathname.includes(item)
    )
      ? "active-link"
      : "";
  };

  getGoodPracticeActiveLinkClass = () => {
    const goodPracticeLink = "/good-practice";
    return !!document.location.pathname.includes(goodPracticeLink)
      ? "active-link"
      : "";
  };

  getAdministrationActiveLinkClass = () => {
    const administrationLinks = [
      "/user",
      "/maillog",
      "/team",
      "/funding-source",
      "/employer-level",
      "/employer-type",
      "/region",
      categoryRoute,
      categoryGroupRoute,
      "/target-group",
      "/organizer",
      formatRoute,
      characterRoute,
      characteristicRoute,
      "/europe-actions",
      "/thematic-packages",
    ];
    return !!administrationLinks.find(
      (item) => item === document.location.pathname
    )
      ? "active-link"
      : "";
  };

  render() {
    const { userStore, langStore } = this.context;
    const { login, isMinreg, data: currentUser } = userStore;
    let showHeader = this.state;

    if (userStore.id === null) {
      return null;
    }

    const { classes } = this.props;
    const dashboardAllowed = !userStore.isMinreg;
    const categoryTitle =
      currentUser && currentUser.categoryCaptions[langStore.lang.toLowerCase()];

    if (isMinreg && document.location.pathname === "/") {
      showHeader = false;
    }

    return (
      <Typography component="div">
        {showHeader && (
          <AppBar className={classes.appBar}>
            <Link to="/">
              <img
                src="/images/logo.jpg"
                alt="Logo"
                className={classes.logo}
              />
            </Link>
            <nav>
              {!isMinreg && (
                <NavLink exact className={classes.appBarLink} to="/">
                  <i className="icon icon-home"></i>
                  {langStore.header.home}
                </NavLink>
              )}

              <div
                className={classNames(
                  classes.appBarLink,
                  this.getRegisterActiveLinkClass(),
                  this.getGoodPracticeActiveLinkClass()
                )}
              >
                <i className="icon icon-register"></i>
                {langStore.header.registries}
                <div
                  className={classNames(
                    classes.appMenu,
                    "menu-popup",
                    "register-menu"
                  )}
                >
                  <Typography>
                    <Link 
                      to="/activities"
                      className={classes.appBarMenuLink} 
                    >
                      <i className="icon icon-calendar"></i>
                      {langStore.header.activities}
                    </Link>
                    <Link className={classes.appBarMenuLink} to={activityRoute}>
                      <i className="icon icon-calendar"></i>
                      {langStore.header.activities} (legacy)
                    </Link>
                    {!isMinreg && (
                      <>
                        <Link
                          className={classes.appBarMenuLink}
                          to="/participants"
                        >
                          <i className="icon icon-users"></i>
                          {langStore.header.participants}
                        </Link>
                        {userStore.showTrainers && (
                          <Link
                            className={classes.appBarMenuLink}
                            to="/trainers"
                          >
                            <i className="icon icon-graduation-cap"></i>
                            {langStore.header.trainers}
                          </Link>
                        )}
                        <Link
                          className={classes.appBarMenuLink}
                          to="/good-practices"
                        >
                          <i className="icon icon-practice"></i>
                          {langStore.header.practice}
                        </Link>
                        <Link
                          className={classNames(
                            classes.appBarMenuLink,
                            "link-hromadas"
                          )}
                          to="/organizations"
                        >
                          <OrganizationsIcon className="icon" />
                          {langStore.header.organizations}
                        </Link>

                        <Link
                          className={classes.appBarMenuLink}
                          to="/surveys"
                        >
                          <SurveysIcon className="icon" />
                          {langStore.header.surveys}
                        </Link>
                      </>
                    )}
                  </Typography>
                </div>
              </div>

              {!isMinreg && (
                <Link
                  className={classNames(
                    classes.appBarLink,
                    this.getIndicatorActiveLinkClass()
                  )}
                  to="/indicators"
                >
                  <i className="icon icon-indicators"></i>
                  {langStore.header.indicators}
                </Link>
              )}

              {!isMinreg && (
                <div
                  className={classNames(
                    classes.appBarLink,
                    this.getDashboardActiveLinkClass()
                  )}
                >
                  <i className="icon icon-dashboard" />
                  {langStore.header.dashboard}

                  {dashboardAllowed && (
                    <div
                      className={classNames(
                        classes.appMenu,
                        "menu-popup",
                        "menu-popup_dashboard"
                      )}
                    >
                      <Typography>
                        <Link className={classes.appBarMenuLink} to="/graphs">
                          <i className="icon icon-item"></i>
                          {langStore.header.graphs}
                        </Link>
                        <Link className={classes.appBarMenuLink} to="/map">
                          <i className="icon icon-item"></i>
                          {langStore.header.map}
                        </Link>
                        <Link
                          className={classes.appBarMenuLink}
                          to={networkMapRoute}
                        >
                          <i className="icon icon-item"></i>
                          Network Visualization
                        </Link>
                      </Typography>
                    </div>
                  )}
                </div>
              )}

              <a className={classes.appBarLink} href="/search/oes">
                <i className="icon icon-search"></i>
                {langStore.header.searchAnalyse}
              </a>

              {!userStore.isMinreg && !userStore.isGuest && (
                <NavLink
                  className={classes.appBarLink}
                  to="/templates"
                  activeClassName="active"
                >
                  <i className="icon icon-templates"></i>
                  {langStore.header.templates}
                </NavLink>
              )}

              {userStore.showAdminMenu && (
                <div
                  className={classNames(
                    classes.appBarLink,
                    this.getAdministrationActiveLinkClass()
                  )}
                >
                  <i className="icon icon-administration"></i>
                  {langStore.header.administration}
                  <div className={classNames(classes.appMenu, "menu-popup")}>
                    <Typography>
                      <Link className={classes.appBarMenuLink} to="/user">
                        <i className="icon icon-item"></i>
                        {langStore.header.userManagement}
                      </Link>
                      <Link className={classes.appBarMenuLink} to="/maillog">
                        <i className="icon icon-item"></i>
                        {langStore.header.mailLog}
                      </Link>
                      <Link className={classes.appBarMenuLink} to="/team">
                        <i className="icon icon-item"></i>
                        {langStore.header.team}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to="/funding-source"
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.fundingSource}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to="/employer-level"
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.employerLevel}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to="/employer-type"
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.employerType}
                      </Link>
                      <Link className={classes.appBarMenuLink} to="/region">
                        <i className="icon icon-item"></i>
                        Regions
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to={categoryGroupRoute}
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.categoryGroup}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to={categoryRoute}
                      >
                        <i className="icon icon-item"></i>
                        {categoryTitle || langStore.header.category}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to={categoryResultRoute}
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.categoryResults}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to="/target-group"
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.targetGroup}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to="/target-level"
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.targetLevel}
                      </Link>
                      <Link className={classes.appBarMenuLink} to="/organizer">
                        <i className="icon icon-item"></i>
                        {langStore.header.organizer}
                      </Link>
                      <Link className={classes.appBarMenuLink} to={formatRoute}>
                        <i className="icon icon-item"></i>
                        {langStore.header.activityFormat}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to={characterRoute}
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.activityCharacter}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to={characteristicRoute}
                      >
                        <i className="icon icon-item"></i>
                        {langStore.characteristic.header}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to="/europe-actions"
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.regionalInitiatives}
                      </Link>
                      <Link className={classes.appBarMenuLink} to="/cso-grants">
                        <i className="icon icon-item"></i>
                        {langStore.header.csoGrants}
                      </Link>
                      <Link
                        className={classes.appBarMenuLink}
                        to="/donor-projects"
                      >
                        <i className="icon icon-item"></i>
                        {langStore.header.donorProjects}
                      </Link>
                    </Typography>
                  </div>
                </div>
              )}

              {userStore.isGuest && (
                <NavLink
                  className={classes.appBarLink}
                  to="/support"
                  activeClassName="active"
                >
                  <i className="icon icon-support"></i>
                  {langStore.header.supportFeedback}
                </NavLink>
              )}
              {!userStore.isGuest && (
                <div className={classes.appBarLink}>
                  <i className="icon icon-support" />
                  {langStore.header.support}
                  <div className={classNames(classes.appMenu, "menu-popup")}>
                    <NavLink
                      to="/support"
                      className={classes.appBarMenuLink}
                      activeClassName="active"
                    >
                      <i className="icon icon-item"></i>
                      {langStore.header.contactForm}
                    </NavLink>
                    <a
                      className={classes.appBarMenuLink}
                      href={forumRoute}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="icon icon-item"></i>
                      {langStore.header.forum}
                    </a>
                    <Link
                      className={classes.appBarMenuLink}
                      to="/videos"
                    >
                      <i className="icon icon-item"></i>
                      Videos
                    </Link>
                  </div>
                </div>
              )}
            </nav>

            {config.enableMultiProject && userStore.projects.length > 1 && (
              <div className={classes.langSelector}>
                <Select
                  className="select"
                  value={userStore.currentProjectCode}
                  onChange={this.handleChangeProject}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: config.colors.primary,
                        border: "none",
                        boxShadow: "none",
                        marginTop: "55px",
                        marginLeft: "-30px",
                      },
                    },
                  }}
                >
                  {userStore.projects.map((item) => (
                    <MenuItem
                      key={item.code}
                      value={item.code}
                      className={classes.appBarMenuLink}
                    >
                      {item.code}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}

            {currentUser && (
              <div className={classNames(classes.appBarLink, "user-link")}>
                {login}
                <i className="icon icon-user"></i>
                <div
                  className={classNames(
                    classes.appMenu,
                    "menu-popup",
                    "user-menu"
                  )}
                >
                  <Typography>
                    <Link className={classes.appBarMenuLink} to="/settings">
                      <i className="icon icon-settings"></i>
                      Settings
                    </Link>
                    {!userStore.isGuest && (
                      <Link className={classes.appBarMenuLink} to="/password">
                        <i className="icon icon-password"></i>
                        Password
                      </Link>
                    )}
                    <Link
                      onClick={this.onSignOutClick}
                      className={classes.appBarMenuLink}
                      to="#"
                    >
                      <i className="icon icon-signout"></i>
                      Sign out
                    </Link>
                  </Typography>
                </div>
              </div>
            )}
          </AppBar>
        )}
      </Typography>
    );
  }
}

export const GIZHeader = withRoot(withStyles(styles)(observer(Header)));

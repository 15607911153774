import { useHistory } from 'react-router-dom';
import { withNotifications } from 'components/notifications';
import { useApi } from 'support/api';
import Form from './Form';

function Create(props) {
  const { notifications } = props;
  const history = useHistory();
  const api = useApi();

  const handleCreate = async (values) => {
    notifications.info('Saving', null);

    const { name } = values;

    const response = await api.v2.tags.create({ name });
    const tag = await response.json();

    notifications.success('Saved');

    history.replace(
      `/tags/${tag.id}/edit`, 
      {
        action: {
          name: 'create',
          params: { ...tag }
        }
      }
    );
  };

  return (
    <Form onSubmit={handleCreate} />
  );
}

export default withNotifications(Create);

import React from 'react';
import {
  Grid,
  Button,
} from '@material-ui/core';
import fetchData from '../../../utils/fetch';
import fetchMultiPartData from '../../../utils/fetchMultiPart';
import downloadFile from '../../../utils/fetchFile'
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../../withRoot';
import { Link, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { styles } from './styles';
import Milestone from './milestone'
import DataCollection from './data-collection'
import KeyData from './key-data'
import MilestoneData from './milestone-data'
import { stateLabel, milestoneDate, metricValue } from '../utils';
import FileUploadProgress from '../../../components/file-upload-progress';

const lang = 'Eng'

const Title = (props) => {
  const title = props.title;
  const size = props.size;
  const Component = props.component;

  return(
    <Grid item xs={size}>
      <Component> {title} </Component>
    </Grid>
  )
}
class IndicatorDetails extends React.Component {
  state = {
    loading: true,
    item: null,
    fileInput: null,
    deleteItem: false,
    currentMilestone: {},
    fileUploadModal: false,
    fileUploadStatus: '',
    print_info: true
  };
  componentDidMount() {
    this.getData();
  }

  async getData() {
    const itemId = window.location.href.match(/indicator\/(\d+)/)[1]
    const item = (await fetchData('get', `/indicators/${itemId}`))[0];

    if (!item || item.error){
      this.setState({ loading: false });
    }else{
      this.setState({ loading: false, item: item });
    }
  }

  handleItemSave = () => {
    this.getData()
  }

  handleDownload = (file) => {
    return async (e) => {
      e.preventDefault()
      downloadFile(file);
    }
  }

  openMilestone = ({ match: { params: { indicator_id, id } } }) => {
    const milestone = id === 'new' ? null : this.state.item.milestones.find(x => x.id == id)
    return <Milestone lang={lang} handleItemSave={this.handleItemSave} indicatorId={indicator_id} item={milestone}/>;
  }

  async deleteMilestone(milestone) {
    await fetchData('delete', `/measurements/milestones/${milestone.id}`);
    this.getData();
    this.setState({deleteItem: false});
  }

  showDeleteModal = (milestone) => {
    this.setState({deleteItem: true, currentMilestone: milestone});
  }

  closeModal = () => {
    this.setState({deleteItem: false});
  }

  openDataCollection = ({ match: { params: { indicator_id } } }) => {
    return <DataCollection lang={lang} handleItemSave={this.handleItemSave} indicator={this.state.item}/>;
  }

  openKeyData = ({ match: { params: { indicator_id } } }) => {
    return <KeyData lang={lang} handleItemSave={this.handleItemSave} indicator={this.state.item} />;
  }

  openMilestoneData = ({ match: { params: { indicator_id, id } } }) => {
    const milestone = this.state.item.milestones.find(x => x.id == id)
    return <MilestoneData lang={lang} handleItemSave={this.handleItemSave} indicator={this.state.item} item={milestone} user={this.props.user}/>;
  }

  measurementValueWithDescription = (value, index) => {
    let description = this.state.item['measurementDescription'+(index+1)]
    description = description.replace(/^%\s/, '')
    return `${value} ${description}`
  }

  renderMilestoneMetrics = (milestone) => {
    const indicator = this.state.item;
    const metricArray = Array(indicator.measurementCount).fill(0);
    return metricArray.map((el, i) => {
      const num = i + 1;
      let value = milestone[`quantitiveValue${num}`]
      if ((!value || isNaN(value)) && indicator.isAutomated){
        value = milestone[`autoQuantitiveValue${num}`]
      }
      value = metricValue(indicator, value, i);
      return (
        <span className='metric-value' key={i}>{`${num}) ${value}`}</span>
      )
    })
  }

  handlePDFDownload = async fileName => {
    if(this.state[fileName]) {
      const withLang = this.props.lang ? '?lang=' + this.props.lang : '';
      this.setState({ [fileName]: false });
      await downloadFile(`/indicators/${this.state.item.id}/${fileName}.pdf${withLang}`);
      this.setState({ [fileName]: true });
    }
  }

  unitName = (unit) => {
    return unit?.name || unit?.login.toUpperCase() || ""
  }

  fileInput = null

  clickFileUpload = (item) => {
    return async (event) => {
      event.preventDefault()
      this.fileInputs[item.id].click()
    }
  }

  handleFileUpload = (item) => {
    return async (event) => {
      this.setState({fileUploadModal: true, fileUploadStatus: 'loading'})
      const file = event.target.files[0]
      if (file.size < (20 * 1024 * 1024)){
        var data = new FormData();
        data.set('dataFile', file)
        await fetchMultiPartData('put', `/indicators/${item.id}/data_upload`, data);
        this.setState({fileUploadStatus: 'success'})
        this.getData();
      }
    }
  }

  render() {
    const { classes, user } = this.props;
    const { item, loading } = this.state;
    if (loading) return '';
    if (item && item.readOnly && !item.restrictedView) return (<Redirect to='/indicators' />);

    const asAdmin = user && user.isAdmin
    const asEditor = user && !item.restrictedView;
    const asGuest = user && (user.authorities.indexOf('ROLE_GUEST') >= 0)

    return(
      <div className={classes.root}>
        {this.state.deleteItem && (
          <div className={classes.modal}>
            <a href className="close" onClick={this.closeModal}>
              <Close/>
            </a>
            <div className="container">
              <div className="modal">
                <a href className="close" onClick={this.closeModal}><Close /></a>
                <h3>Attention!</h3>
                <p>Are you sure you want to delete this milestone?</p>
                <Grid container spacing={24} alignItems='flex-end'  className="buttons">
                  <Grid item xs={12} className={classes.alignRight}>
                    <Button variant="contained" className='button-submit' type="submit" color="primary" onClick={() => this.deleteMilestone(this.state.currentMilestone)}>
                      Yes
                    </Button>
                    <Button variant="contained" className='button-cancel' onClick={this.closeModal}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>

              </div>
            </div>
          </div>
        )}
        <div className={classNames(classes.block, 'block-title')}>
          <div className="column">
            <h3> {asEditor && item.fake ? 'Dummy ' : ''}{item.code}: {item.jhiIndicator}</h3>
            <p>Results: {item.result}</p>
          </div>
          <div className="column">
            <h4>Lead Unit:<br/>{this.unitName(item.leadUnit)}</h4>
            <p style={{ marginTop: -10 }}>Supporting Units:<br/>
              {item.supportUnits.map(unit => this.unitName(unit)).join(', ')}
            </p>
          </div>
          <div className="column btn-sections">
            {!asGuest && (
            <Link to={`/indicator-graphs/${item.id}`}>
              <Button variant="contained" className='button-graphs'>
                <i></i>
                Graphs
              </Button>
            </Link>
            )}
            <Link to='#' className={classNames(classes.button, { 'disabled': !this.state.print_info })} onClick={() => this.handlePDFDownload('print_info')}>
              <Button variant="contained" className='button-print'>
                <i/>
                Print
              </Button>
            </Link>
          </div>
        </div>

        <Grid container spacing={24} className={classNames(classes.block, 'block-info')}>
          <Grid item xs={5}>

            <Grid container spacing={24} className="block-info__baseline">

              <Grid item xs={12}>

                <Grid container spacing={24}>

                  <Title title="Baseline value(s)" size={4} component="h5"/>

                  <Grid item xs={8}>
                    <ul>
                      {item.baselineValues.map((baselineValue, i) => {
                        return (
                          <li key={i}>{this.measurementValueWithDescription(baselineValue, i)}</li>
                        )
                      })}
                    </ul>
                  </Grid>

                </Grid>

              </Grid>

              <Grid item xs={12}>

                <Grid container spacing={24}>

                  <Title title="Measurement" size={4} component="h5"/>
                  <Title title={item.keyDatum.measurement} size={8} component="pre"/>

                </Grid>
              </Grid>


              <Grid item xs={12}>

                <Grid container spacing={24}>

                  <Title title="Uploaded files" size={4} component="h5" />

                  <Grid item
                    xs={8}
                    style={{
                      display: 'flex',
                      paddingLeft: '20px',
                      flexWrap: 'wrap'
                    }}
                  >
                    {item.keyDatum.files.map((file, i) => {
                      return (
                        <Link
                          key={i}
                          title={`Download '${file.name}'`}
                          to='#' className="btn btn-word"
                          onClick={this.handleDownload(file.url)}
                        />
                      )
                    })}
                  </Grid>

                </Grid>

              </Grid>

            </Grid>

          </Grid>

          <Grid item xs={5}>

            <Grid container spacing={24} className="target block-info__target">

              <Grid item xs={12}>

                <Grid container spacing={24}>

                  <Title title="Target value(s)" size={4} component="h5" />

                  <Grid item xs={8}>
                    <ul>
                      {item.targetValues.map((targetValue, i) => {
                        return (
                          <li key={i}>{this.measurementValueWithDescription(targetValue, i)}</li>
                        )
                      })}
                    </ul>
                  </Grid>

                </Grid>

              </Grid>

              <Grid item xs={12}>

                <Grid container spacing={24}>

                  <Grid item xs={4} >
                    <h5>Explanatory notes</h5>
                  </Grid>

                  <Grid item xs={8}>
                    <pre>{item.keyDatum.explanatoryNotes}</pre>
                  </Grid>

                </Grid>

              </Grid>

            </Grid>

          </Grid>
          <Grid item xs={2}>

            <Grid container spacing={24} className="column block-info__column ">

              <Grid item xs={12} style={{ display: 'flex', padding: 0, justifyContent: 'flex-end' }}>
                {asEditor && (
                  <Link title="Edit Key Data" to={`/indicator/${item.id}/key-data`}>
                    <Button variant="contained" className='button-edit'>
                      <i></i>
                      Edit
                    </Button>
                  </Link>
                )}
              </Grid>

              <Grid item xs={12}>

                <Grid container spacing={24}>

                  <Grid item xs={12} className="p0 lh">
                    <h5>Frequency of measurement</h5>
                  </Grid>

                  <Grid item xs={12} className="p0">
                    <pre>{item.keyDatum.measurementFrequency}</pre>
                  </Grid>

                  <Grid item xs={12} className="p0 lh">
                    <h5 style={{ marginTop: 13 }}>External support</h5>
                  </Grid>

                  <Grid item xs={12} className="p0">
                    <pre>{item.keyDatum.externalSupport}</pre>
                  </Grid>

                </Grid>

              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>

            <Grid container spacing={24} className="indicators block-info__indicators">

              <Grid item xs={12}>

                <Grid container spacing={24}>

                  <Grid item xs={12} >
                    <h5>Indicator definition</h5>
                  </Grid>

                  <Grid item xs={12}>
                    <pre>{item.keyDatum.indicatorDefinition}</pre>
                  </Grid>

                </Grid>

              </Grid>

            </Grid>

          </Grid>

        </Grid>

        <Grid container spacing={24} className={classNames(classes.block, 'block-collection', 'bt')} >

          <Grid item xs={12}>
            <h2>DATA COLLECTION INFORMATION</h2>
          </Grid>

          <Grid item xs={12} className="df p0 h0 jce">
            {asEditor && (
              <Link title="Edit Data Collection Information" to={`/indicator/${item.id}/data-collection`}>
                <Button variant="contained" className='button-edit'>
                  <i></i>
                  Edit
                </Button>
              </Link>
            )}
          </Grid>

          <Grid item xs={6} className="pr150 pl0">
            <h5>Sources/Methods of data collection</h5>
            <pre>{item.sourcesAndMethods}</pre>
          </Grid>

          <Grid item xs={6} className="pr150">
            <h5>Validity/Data limitation</h5>
            <pre>{item.validityAndLimitation}</pre>
          </Grid>

          <Grid item xs={12} className="p0 data-collection">
            <h5>Data collection process</h5>
          </Grid>

        </Grid>
        <div className={classNames(classes.block, 'block-collection', 'p0')}>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Process step</th>
                  <th>Responsible</th>
                  <th>Due date</th>
                </tr>
              </thead>
              <tbody>
                {item.processSteps.map((step,i) => {
                  return (
                  <tr key={i}>
                    <td>{i+1}.</td>
                    <td>{step.description}</td>
                    <td>{step.responsible}</td>
                    <td className="date">{step.dueDate}</td>
                  </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Grid container spacing={24} className={classNames(classes.block, 'block-collection')}>

          <Grid item xs={6} className='pl0'>
            <h5>Partner involment</h5>
            <pre>{item.partnerInvolvment}</pre>
          </Grid>

          <Grid item xs={6} className='pl150'>
            <h5>Uploaded files</h5>
            <p>
              {item.files.map((file, i) =>
                <Link title={`Download '${file.name}'`}
                  to='#'
                  className="btn btn-word"
                  onClick={this.handleDownload(file.url)}
                  key={i}
                />
              )}
            </p>
          </Grid>

        </Grid>

        <Grid container spacing={24} className={classNames(classes.block, 'block-measurement', 'bt')}>

          <Grid item xs={12}>
            <h2> MEASUREMENT DATA </h2>
          </Grid>

          <Grid item xs={12} className="indicator-actions p0">
            {(asAdmin || asGuest) && (
              <Link to={`/indicator/${item.id}/milestone/new`}>
                <Button
                  variant="contained"
                  className="btn-create"
                  color="primary">
                  <img
                    src="/images/icons/add-icon.png"
                    alt="add"
                    className={classes.img}
                  />
                  Add Milestone
              </Button>
            </Link>)}

            {item.isImportable && (
              <>
                <a 
                  href
                  className="btn btn-upload"
                  onClick={event => { event.preventDefault(); this.fileInput.click() }}>
                  Upload datafile of indicator
                  <i></i>
                </a>
                <input
                  type="file"
                  name="dataFileInput"
                  ref={
                    input => {
                      if (!input) return;
                      input.addEventListener('change', this.handleFileUpload(item));
                      this.fileInput = input;
                    }
                  }
                  accept='.xlsx'
                  style={{ 'display': 'none' }}
                />
                {item.dataFileUrl && (
                  <a
                    href
                    className="btn btn-download"
                    onClick={this.handleDownload(item.dataFileUrl)}
                  >
                    Download datafile of indicator
                    <i></i>
                  </a>
                )}
              </>
            )}

          </Grid>

        </Grid>

        <div className={classNames(classes.block, 'block-measurement', 'p0')}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>Milestone</th>
                <th>Quantitative measurement</th>
                <th>Qualitative measurement</th>
                <th>Uploaded files</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {item.milestones.map((milestone, i) => {
                return (
                  <tr key={i}>
                    <td>{milestoneDate(milestone)}</td>
                    <td>
                      {milestone.status && (
                        <div className={classes.selector}>
                          {stateLabel(milestone.status)}
                        </div>
                      )}
                    </td>
                    <td>{milestone.description}</td>
                    <td className="light">
                      {this.renderMilestoneMetrics(milestone)}
                    </td>
                    <td>{milestone.qualitiveMeasurement}</td>
                    <td>
                      {milestone.files.map((file, i) =>
                        <Link
                          to='#'
                          title={`Download '${file.name}'`}
                          className="btn btn-word"
                          onClick={this.handleDownload(file.url)}
                          key={i}
                        />
                      )}
                    </td>
                    <td className='actions' style={{display: 'flex'}}>
                      {(asEditor || asAdmin) && (
                        <Link
                          title="Enter Milestone Data"
                          to={`/indicator/${item.id}/milestone-data/${milestone.id}`}
                          className={classNames('view', classes.btnTable)}
                        >
                          <img src="/images/icons/data-icon.png" alt="data" width='34' />
                        </Link>
                      )}
                      {(asAdmin || asGuest) ? (
                        <>
                          <Link
                            title="Edit Milestone"
                            to={`/indicator/${item.id}/milestone/${milestone.id}`}
                            className={classNames('edit', classes.btnTable)}
                          >
                            <img src="/images/icons/outline_edit_white_36dp.png" alt="edit" width='16' />
                          </Link>
                          <a
                            title="Delete Milestone"
                            onClick={(event) => {
                              event.preventDefault();
                              //this.deleteMilestone(milestone);
                              this.showDeleteModal(milestone);
                            }}
                            href
                            className={classNames('delete', classes.btnTable)}
                          >
                            <Close/>
                          </a>
                        </>
                      ) : (
                        // just to make first link blue (because of .action a:last-child is filled as delete)
                        <span></span>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        {this.state.fileUploadModal && (
          <FileUploadProgress status={this.state.fileUploadStatus} />
        )}

        <Route path="/indicator/:indicator_id/milestone/:id" render={this.openMilestone} />
        <Route path="/indicator/:indicator_id/data-collection" render={this.openDataCollection} />
        <Route path="/indicator/:indicator_id/key-data" render={this.openKeyData} />
        <Route path="/indicator/:indicator_id/milestone-data/:id" render={this.openMilestoneData} />
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(IndicatorDetails));

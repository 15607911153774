import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import GIZButton from "../components/GIZButton";
import GIZAlert from "../components/GIZAlert";
import GIZForm from "../components/GIZForm";
import GIZTextField from "../components/GIZTextField";
import GIZPhoneField from "../components/GIZPhoneField";
import GIZRadioGroup from "../components/GIZRadioGroup";
import GIZSelectField from "../components/GIZSelectField";

import { required } from "../validators/required";

const styles = () => ({
  root: {
    backgroundColor: "#fff",
    margin: "20px 0",
  },
  demoButton: {
    margin: "10px",
  },
  demoForm: {
    maxWidth: "500px",
    margin: "0 auto",
  },
});

const UIDemo = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography variant="h1">UI Demo</Typography>
      <Typography variant="h2">Buttons</Typography>
      <div>
        <GIZButton color="primary" className={classes.demoButton}>
          Primary
        </GIZButton>
        <GIZButton color="secondary" className={classes.demoButton}>
          Secondary
        </GIZButton>
        <GIZButton color="tertiary" className={classes.demoButton}>
          Tertiary
        </GIZButton>
        <GIZButton color="quaternary" className={classes.demoButton}>
          Quaternary
        </GIZButton>
        <GIZButton color="primary" disabled className={classes.demoButton}>
          Disabled
        </GIZButton>
      </div>
      <div>
        <GIZButton color="primary" icon="Edit" className={classes.demoButton}>
          Edit
        </GIZButton>
        <GIZButton
          color="secondary"
          icon="Delete"
          className={classes.demoButton}
        >
          Delete
        </GIZButton>
        <GIZButton
          color="tertiary"
          icon="ImportExport"
          className={classes.demoButton}
        >
          Import/Export
        </GIZButton>
      </div>
      <Typography variant="h2">Alerts</Typography>
      <div>
        <GIZAlert variant="error">This is an error message!</GIZAlert>
        <GIZAlert variant="success">This is a success message!</GIZAlert>
        <GIZAlert variant="info">This is an info message!</GIZAlert>
      </div>
      <Typography variant="h2">Forms and fields</Typography>
      <div>
        <GIZForm
          initialValues={{
            name: "Alex",
            phone: "+380123",
            position: "QA",
            gender: "OTHER",
          }}
          onSubmit={(values) => console.log(values)}
          className={classes.demoForm}
        >
          <GIZTextField name="name" label="Name" validator={required} />
          <GIZPhoneField name="phone" label="Phone" />
          <GIZSelectField
            name="type"
            label="Type"
            placeholder="Select"
            values={[
              { label: "International", value: "international" },
              { label: "National", value: "national" },
              { label: "Regional", value: "regional" },
            ]}
            validator={required}
          />
          <GIZRadioGroup
            name="gender"
            label="Gender"
            values={[
              { label: "Female", value: "FEMALE" },
              { label: "Male", value: "MALE" },
              { label: "Other", value: "OTHER" },
            ]}
            validator={required}
          />
          <GIZTextField name="position" label="Position" />
          <GIZTextField name="company" label="Company" validator={required} />
          <GIZButton color="primary" type="submit">
            Submit
          </GIZButton>
        </GIZForm>
      </div>
    </div>
  );
};
export default withStyles(styles)(UIDemo);

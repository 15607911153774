import { Route, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import DataSource from 'components/DataSource';
import DataSourceWithLoadMore from 'components/DataSourceWithLoadMore';
import Toolbar from './Toolbar';
import SortingBar from './SortingBar';
import FilterView from './Filter';
import ActivityView from './Activity';
import CalendarView from './calendar';

const styles = (theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  aside: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    minWidth: '300px',
  },
  main: {
    flex: 1,
  }
});

function Activities(props) {
  const { classes } = props;
  const { search } = useLocation();

  return (
    <div>
      <Toolbar />
      <div className={classes.content}>
        <div className={classes.aside}>
          <DataSource path={`v2/activities/filters${search}`}>
            {(data) => (
              <FilterView total={data.total} filters={data.filters} />
            )}
          </DataSource>
        </div>
        <div className={classes.main}>
          <Route exact path="/activities">
            <SortingBar />
            <DataSourceWithLoadMore path={`v2/activities${search}`}>
              {(activities) => (
                activities.map((activity) => (
                  <ActivityView key={activity.id} attributes={activity} />
                ))
              )}
            </DataSourceWithLoadMore>
          </Route>

          <Route path="/activities/calendar">
            <DataSource path={`v2/activities/calendar${search}`}>
              {(activities) => (
                <CalendarView events={activities} />
              )}
            </DataSource>
          </Route>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Activities);

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = {
  root: {
    flex: '1 1 auto'
  }
};

function ListItemText(props) {
  const { classes, children } = props;

  return (
    <Typography className={classes.root} variant="body1" align="left" noWrap={true}>
      {children}
    </Typography>
  );
}

export default withStyles(styles)(ListItemText);

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Route } from 'react-router-dom';
import NotificationsProvider from 'components/notifications/Provider';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DayjsUtils from '@date-io/dayjs';
import TagsView from 'views/tags';
import ActivitiesView from 'views/activities';
import Surveys from 'views/surveys';
import CreateSurvey from 'views/surveys/Create';
import EditSurvey from 'views/surveys/Edit';
import Questions from 'views/surveys/questions';
import SurveyAnswer from 'views/surveys/unauthorized/Survey';
import Calendar from 'views/activities/calendar';
import ParticipantsImport from 'views/activities/import/ParticipantsImport';
import OrganizationsList from 'views/activities/OrganizationsList';
import AddOrganizationsToActivity from 'views/activities/AddOrganizationsToActivity';
import ParticipantsListView from 'views/activities/ParticipantsListView';
import AddParticipantsView from 'views/activities/AddParticipantsView';
import VideoView from 'views/videos/VideoView';
import VideosListView from 'views/videos/ListView';
import CreateVideoView from 'views/videos/CreateView';
import Folders from 'views/folders/IndexView';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ff523a',
      main: '#c80f0f',
      dark: '#8f0000',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

function Routes() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <NotificationsProvider>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <Route path="/tags">
            <TagsView />
          </Route>
          <Route path="/activities">
            <ActivitiesView />
          </Route>

          <Route exact path="/surveys">
            <Surveys />
          </Route>
          <Route path="/surveys/create">
            <CreateSurvey />
          </Route>
          <Route path="/surveys/:id/edit">
            <EditSurvey />
          </Route>
          <Route path="/surveys/:surveyId/questions">
            <Questions />
          </Route>
          <Route path="/public/surveys/:slug">
            <SurveyAnswer />
          </Route>
          <Route exact path="/calendar">
            <Calendar />
          </Route>

          <Route path="/activity/:id/(participants|organizations)/import">
            <ParticipantsImport />
          </Route>

          <Route path="/activity/:id/organizations" exact>
            <OrganizationsList />
          </Route>

          <Route path="/activity/:id/organizations/add">
            <AddOrganizationsToActivity />
          </Route>

          <Route path="/activity/:id/participants" exact>
            <ParticipantsListView />
          </Route>

          <Route path="/activity/:id/participants/add">
            <AddParticipantsView />
          </Route>

          <Route path="/videos" exact>
            <VideosListView />
          </Route>

          <Route path="/videos/create">
            <CreateVideoView />
          </Route>

          <Route path="/v/:slug">
            <VideoView />
          </Route>

          <Route path="/folders">
            <Folders />
          </Route>
          
        </MuiPickersUtilsProvider>
      </NotificationsProvider>
    </MuiThemeProvider>
  );
}

export default Routes;

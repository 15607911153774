import React from "react";
import withRoot from "../../../withRoot";
import BaseGraph from "../base-graph";

const datasetOptions = [
  {
    label: "Number of Activities",
    type: "line",
    data: [51, 65, 40, 49, 60, 37, 40],
    fill: false,
    lineTension: 0,
    borderColor: "#6495ed",
    backgroundColor: "#6495ed", // 65,115,90
    pointBorderColor: "#6495ed",
    pointBackgroundColor: "#6495ed",
    pointHoverBackgroundColor: "#6495ed",
    pointHoverBorderColor: "#6495ed",
    yAxisID: "y-axis-1",
  },
  {
    type: "line",
    label: "% Female",
    data: [200, 185, 590, 621, 250, 400, 95],
    // borderDash: [10,5],
    fill: false,
    lineTension: 0,
    backgroundColor: "#fdbb2d",
    borderColor: "#fdbb2d",
    hoverBackgroundColor: "#fdbb2d",
    hoverBorderColor: "#fdbb2d",
    yAxisID: "y-axis-female-percent",
  },
];

const options = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    mode: "label",
  },
  elements: {
    line: {
      fill: false,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
      },
    ],
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        id: "y-axis-1",
        ticks: {
          precision: 0,
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
      {
        type: "linear",
        display: true,
        position: "right",
        id: "y-axis-female-percent",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
        ticks: {
          precision: 0,
          beginAtZero: true,
          callback: function (value, index, values) {
            return value + "%";
          },
        },
      },
    ],
  },
};

const plugins = [
  {
    afterDatasetDraw: (chartInstance, easing) => {
      const ctx = chartInstance.chart.ctx;
      ctx.font = chartInstance.scales.font;
      ctx.fillStyle = "#555";
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";

      chartInstance.data.datasets.forEach(function (dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
          var data = dataset.data[index];
          let label = "" + Math.round(data);
          if (i === 1) {
            label += "%";
            return ctx.fillText(label, bar._model.x, bar._model.y - 5);
          }
          ctx.fillText(label, bar._model.x, bar._model.y + 35);
        });
      });
    },
  },
];

class GenderBalanceChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type="bar"
        tableTitle="Number of activities and gender balance of participants"
        url="/graph/activity_gender_balance"
        chartOptions={options}
        datasetOptions={datasetOptions}
        filter={this.props.filter}
        plugins={plugins}
        noActions={this.props.noActions}
      />
    );
  }
}

export default withRoot(GenderBalanceChart);

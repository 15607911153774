import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

function BackButton() {
  const history = useHistory();

  return (
    <IconButton
      onClick={() => history.goBack() }
    >
      <ArrowBack />
    </IconButton>
  );
}

export default BackButton;

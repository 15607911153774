import { green, blue, amber } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = (theme) => ({
  root: {
    borderRadius: '3px',
    margin: '0 auto 10px',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    width: '100%'
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: blue[700],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    color: '#FFF'
  },
  text: {
    marginTop: '4px'
  }
});

function MessageBox(props) {
  const { classes, variant, children } = props;
  const Icon = variantIcon[variant || 'error'];
  
  return (
    <div className={clsx(classes.root, classes[variant])}>
      <div className={classes.message}>
        <Icon className={classes.icon} />
        <div className={classes.text}>{children}</div>
      </div>
    </div>
  );
}

export default withStyles(styles)(MessageBox);

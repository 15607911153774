import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FieldArray } from 'react-final-form-arrays'
import {
  Fab,
  Grid
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RadioOption from './RadioOption';
import CheckboxOption from './CheckboxOption';
import { useApi } from 'support/api';
import DndContainer from './List';
import { ItemTypes } from '../../components/dnd/Constants';

const uniqueID = () => new Date().valueOf();

function Options(props) {
  const { mutators: { push }, variant } = props;
  const { surveyId, id } = useParams();
  const [showQuestions, setShowQuestions] = useState([]);
  const api = useApi();

  useEffect(() => {
    if (!id) {
      return;
    }

    api.surveys(surveyId).questions.get(null, { afterId: id })
      .then((response) => response.json())
      .then((questions) => setShowQuestions(questions));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <FieldArray name="options">
          {({ fields }) => (
            <DndContainer
              cards={fields}
              onMove={fields.move}
              itemType={ItemTypes.OPTION}
            >
              {(name, index) => variant === 'radio' ? 
                <RadioOption name={name} showQuestions={showQuestions} onDelete={() => fields.remove(index)} /> : 
                <CheckboxOption name={name} showQuestions={showQuestions} onDelete={() => fields.remove(index)} />
              }
            </DndContainer>
          )}
        </FieldArray>
      </Grid>

      <Grid item xs={12}>
        <Fab color="primary" onClick={() => push('options', { key: uniqueID() })}>
          <AddIcon />
        </Fab>
      </Grid>

    </Grid>
  );
}

export default Options;

import React from 'react';
import { 
  Link,
  Route,
  useParams
} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import List from './List';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Create from './Create';
import Edit from './Edit';

function Editor(props) {
  const { surveyId } = useParams();
  const { items } = props;

  function handleCreate(item) {
    items.push(item);
  }

  function handleEdit(item) {
    const index = items.findIndex((i) => i.id === item.id);
    items[index] = item;
  }

  return (
    <Grid container spacing={16}>
      <Grid item xs={6}>
        <List items={items} />
        <Fab component={Link} to={`/surveys/${surveyId}/questions/create`} color="primary">
          <AddIcon />
        </Fab>
      </Grid>

      <Grid item xs={6}>
        <Route path="/surveys/:surveyId/questions/create">
          <Create onCreate={handleCreate} />
        </Route>
        <Route path="/surveys/:surveyId/questions/:id/edit">
          <Edit onEdit={handleEdit} />
        </Route>
      </Grid>
    </Grid>
  );
}

export default Editor;

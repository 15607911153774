import React from 'react';
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Typography
} from '@material-ui/core';
import { Add, Search, Edit, RemoveRedEye, Delete } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import fetchData from '../../utils/fetch';
import Loader from '../../components/loader';
import { Link, Route } from 'react-router-dom';
import history from '../../utils/history';
import { find } from 'lodash';
import { I18n } from '../../i18n/';
import ItemView from './item';
import ItemEdit from './item-edit';
import 'url-search-params-polyfill';
import TablePaginationActions from '../../components/pagination/CustomPagination';
import { config } from "../../style/config";

const styles = (theme) => ({
  root: {
    textAlign: "left",
    width: "94%",
    margin: "30px 3%",
  },
  pagination: {
    marginTop: 20,
    color: "#BDBEC0",
    "& div:first-child": {
      display: "flex",
      "flex-direction": "column",
      "align-items": "center",
    },
  },
  paper: {
    overflowX: "auto",
    boxShadow: "none",
  },
  table: {
    maxWidth: "100%",
    minWidth: 700,
    textAlign: "center",
    "& tbody tr": {
      verticalAlign: "top",
    },
    "& tbody td": {
      paddingTop: "10px",
      fontSize: "1rem",
      color: "#191970",
    },
  },
  alignRight: {
    textAlign: "right",
  },
  textField: {
    width: "100%",
    marginBottom: "20px",
  },
  actions: {
    whiteSpace: "nowrap",
    "& a": {
      display: "inline-flex",
      color: "#fff",
      fontSize: "12px",
      lineHeight: "25px",
      alignItems: "center",
      backgroundColor: "#6495ed",
      padding: "0 8px",
      "&:first-child": {
        backgroundColor: "#add8e6",
        borderRadius: "3px 0 0 3px",
      },
      "&:last-child": {
        backgroundColor: "#fe8368",
        borderRadius: "0 3px 3px 0",
      },
    },
    "& svg": {
      width: "15px",
      height: "15px",
      marginRight: "2px",
    },
  },
  actionDelete: {
    display: "inline-flex",
    color: config.colors.primaryBackground,
    fontSize: "12px",
    lineHeight: "25px",
    alignItems: "center",
    minHeight: "25px",
    height: "47px",
    width: "6vw",
    justifyContent: "center",
    backgroundColor: config.colors.primary,
    borderRadius: "0 6px 6px 0",
    border: "none",
    cursor: "pointer",
  },
});

const tableTitles = [
  {id: 'id', title: 'ID'},
  {id: 'createdAt', title: 'Created At'},
  {id: 'nameUa', title: 'Name UA'},
  {id: 'nameEn', title: 'Name EN'},
  {id: 'updatedAt', title: 'Updated At'},
  {id: 'cluster', title: 'Cluster'},
  {id: 'clusterNameEn', title: 'Cluster Name EN'},
  {id: 'clusterNameUa', title: 'Cluster Name UA'}
];

class ThematicPackages extends React.Component {
    state = {
      loading: true,
      items: [],
      page: 0,
      total: 0,
      rowsPerPage: 25,
      searchQuery: '',
      order: 'desc',
      orderBy: ''
    };
    componentDidMount() {
      const query = new URLSearchParams(document.location.search);
      const search = query.get('search');
      if (search) {
        this.setState({ searchQuery: search });
        this.getSearchResults(search);
      } else {
        this.getData();
      }
    }

    async getData() {
      const data = await fetchData('get', '/thematic_modules');
      this.setState({ loading: false, items: data[0] ?? [], total: data[1] ?? 0 });
    }

    async deleteItem(id) {
      const {lang} = this.props;
      if (window.confirm(`${I18n[lang].common.deleteConfirm} ${I18n[lang].thematicPackages.thematicPackage} ${id}?`)) {
        await fetchData('delete', `/thematic_modules/${id}`);
        this.getCurrentPage();
      }
    }

    handleChangePage = (event, page) => {
      this.getPage(page);
      this.setState({ loading: true, page: page });
      history.push(`${window.location.pathname}?page=${page}`);
    }

    async getPage(pageNumber) {
      const data = await fetchData('get', `/thematic_modules?page=${pageNumber + 1}`);
      this.setState({ loading: false, items: await data[0] });
    }

    async getCurrentPage() {
      const page = parseInt(
        new URLSearchParams(document.location.search).get("page")
      );
      this.getPage(page);
    }

    viewItem = ({ match: { params: { id } } }) => {
      if (this.state.loading) return null;
      const item = find(this.state.items, { id: Number(id) });

      return <ItemView item={item} lang={this.props.lang} />;
    }

    editItem = ({ match: { params: { id } } }) => {
      if (this.state.loading) return null;
      const item = find(this.state.items, { id: Number(id) });

      return <ItemEdit item={item} handleItemSave={this.handleItemSave} lang={this.props.lang} />;
    }

    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    }

    async getSearchResults(searchQuery) {
      const data = await fetchData('get', `/_search/thematic_modules?query=${searchQuery}`);
      this.setState({ loading: false, items: await data[0], total: data[1] });
    }

    updateInputValue = e => this.setState({ searchQuery: e.target.value });

    handleSubmit = (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      const search = this.state.searchQuery;
      this.getSearchResults(search);

      history.push({
        search: `?search=${this.state.searchQuery}`
      })
    }

    handleSort = orderBy => event => {
      let order = 'desc';
      if (this.state.orderBy === orderBy && this.state.order === 'desc') {
        order = 'asc';
      }
      this.setState({ loading: true, orderBy: orderBy, order: order });
      this.getSort(orderBy, order);

      history.push({
        search: `?sort[]=${orderBy},${order}`
      })

    }

    async getSort(orderBy, order) {
      const data = await fetchData('get', `/thematic_modules?sort[]=${orderBy},${order}`);
      this.setState({ loading: false, items: await data[0] });
    }

    handleItemSave = () => {
      this.setState({ loading: true });
      this.getData();
    }

    render() {
        const { classes, lang } = this.props;
        const { items, page, rowsPerPage, total, order, orderBy } = this.state;
        return (
          <div className={classes.root}>
            {this.state.loading && (
              <Loader />
            )}
            <Grid container spacing={24} alignItems='center'>
              <Grid item xs={6}>
                <Typography gutterBottom variant="h2" component="h2">
                  {I18n[lang].thematicPackages.thematicPackages}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <Link component={Button} to={`/thematic-packages/create/`}>
                  <Button variant="contained" className="btn-create" color="primary">
                    <Add />
                    {I18n[lang].thematicPackages.create}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <form method="get" action="" onSubmit={this.handleSubmit}>
              <TextField
                id="outlined-name"
                label={I18n[lang].thematicPackages.search}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="search"
                value={this.state.searchQuery}
                onChange={this.updateInputValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.handleSubmit}
                        aria-label={I18n[lang].thematicPackages.search}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {tableTitles.map( item => {
                      return(
                        <TableCell key={item.id}>
                          <TableSortLabel
                            active={orderBy === item.id}
                            direction={order}
                            onClick={this.handleSort(item.id)}
                           >
                            {I18n[lang].common[item.id]}
                          </TableSortLabel>
                        </TableCell>
                      )
                    })}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items
                    .map(row => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.createdAt}</TableCell>
                        <TableCell>{row.nameUa}</TableCell>
                        <TableCell>{row.nameEn}</TableCell>
                        <TableCell>{row.updatedAt}</TableCell>
                        <TableCell>{row.cluster}</TableCell>
                        <TableCell>{row.clusterNameEn}</TableCell>
                        <TableCell>{row.clusterNameUa}</TableCell>
                        <TableCell className="actions">
                          <Link to={`/thematic-packages/view/${row.id}`}>
                            <RemoveRedEye />
                            <span>{I18n[lang].common.view}</span>
                          </Link>
                          <Link to={`/thematic-packages/edit/${row.id}`}>
                            <Edit />
                            <span>{I18n[lang].common.edit}</span>
                          </Link>
                          <button className={classes.actionDelete} onClick={this.deleteItem.bind(this, row.id)}>
                            <Delete />
                            <span>{I18n[lang].common.delete}</span>
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                className={classes.pagination}
                labelDisplayedRows={({ from, to, count }) =>
                  `Showing ${from} - ${to} of ${count} items`}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page}
                onChangePage={this.handleChangePage}
                ActionsComponent = {TablePaginationActions}
              />
            </Paper>
            <Route path="/thematic-packages/view/:id" render={this.viewItem} />
            <Route path="/thematic-packages/edit/:id" render={this.editItem} />
            <Route path="/thematic-packages/create" render={this.editItem} />
          </div>
        );
    }
}

export default withRoot(withStyles(styles)(ThematicPackages));

import AppBar from 'components/AppBar';
import Wrapper from './components/Wrapper';
import Grid from './components/Grid';
import Folder from './components/Folder';
import {
  IconButton
} from '@material-ui/core';
import {
  CreateNewFolder as CreateNewFolderIcon
} from '@material-ui/icons';

function IndexView(props) {
  return (
    <>
      <AppBar>
        <IconButton>
          <CreateNewFolderIcon color="primary" />
        </IconButton>
      </AppBar>
      <Wrapper>
        <Grid heading="Folders">
          <Folder name="Folder 1" />
          <Folder name="Folder 2" />
          <Folder name="Folder 3" />
          <Folder name="Folder 4" />
          <Folder name="Folder 5" />
        </Grid>
      </Wrapper>
    </>
  );
}

export default IndexView;

import ItemService from "./ItemService";
import { Endpoints } from "../config.js";
import { each, sortBy, map, capitalize, trim } from "lodash";
import cyrillicToTranslit from "cyrillic-to-translit-js";

class ParticipantService extends ItemService {
  constructor(onUpdateState, lang) {
    super(Endpoints.Participant.path, onUpdateState, lang);
  }

  _dummyPhoneNumber = "+995000000000";

  NavigateAfterSave(state, response) {
    const path = state.id ? `/participants/view/${state.id}` : '/participants';

    if (!state.messages) {
      state.messages = [];
    }

    state.messages.push(
      this.GetRedirect(path)
    );

    return { ...state };
  }

  MapStateToModel(state) {
    let data = super.MapStateToModelMultiPart(state);

    const params = this.GetApiPutParams();

    // skips serverside doublette check
    data.append("force_new", true);

    for (let p of params) {
      if (p.name === "name" && state.name) {
        data.append(
          p.name,
          trim(map(state.name.split(" "), capitalize).join(" "))
        );
      } else if (p.name === "nameTranscription" && state.nameTranscription) {
        data.append(
          p.name,
          trim(map(state.nameTranscription.split(" "), capitalize).join(" "))
        );
      } else if (p.name === "employerLocationCoordinates" && state.location) {
        // api saves lat lng twisted
        data.append(
          "employerLocationCoordinates",
          `POINT(${state.location[1]} ${state.location[0]})`
        );
      } else {
        this.MapProperty(data, state, p);
      }
    }

    return data;
  }

  GetInitialState(userRoles) {
    let state = super.GetInitialState(userRoles);

    state = {
      showCellPhone: false,
      activities: [],
      usersList: [],
      initialLocation: [50.4501, 30.5234],
      zoom: 12,
      showConfirmDisconnect: false,
      showDialog: false,
      ...state,
    };

    return state;
  }

  UpdateStateFromItem(state, item) {
    state = super.UpdateStateFromItem(state, item);

    // api returns and keeps lat lng twisted
    state = this.SetLocation(state, {
      latLng: item.employerLocationCoordinates
        ? [
          item.employerLocationCoordinates.coordinates[1],
          item.employerLocationCoordinates.coordinates[0],
        ]
        : undefined,
    });

    state = {
      ...state,
      showCellPhone:
        item.cellPhone && item.cellPhone !== this._dummyPhoneNumber,
      name: map(item.name?.split(" "), capitalize).join(" "),
      nameTranscript: map(item.nameTranscription?.split(" "), capitalize).join(
        " "
      ),
      activities: item.shortActivities
        ? sortBy(item.shortActivities, [(ev) => -ev.id])
        : [],
    };

    return state;
  }

  async Action(e, state) {
    // base class is not called and contains only default implementation
    // could be improved ...

    switch (e.fieldName) {
      case "employerLocationCoordinates":
        state = this.SetLocation(state, e.value);
        break;
      case "employerLevelId":
        state = this.SetEmployerLevel(state, e.value);
        break;
      case "name":
        state = this.SetName(state, e.value);
        break;
      case "disconnectItemFromActivity":
        state = this.SetDisconnectItemFromActivity(state, e.value);
        break;
      case "rejectDisconnect":
        state = this.SetRejectDisconnect(state);
        break;
      case "confirmDisconnect":
        state = await this.SetConfirmDisconnect(state, e.value);
        break;
      case "checkParticipant":
        state = await this.CheckParticipant(state);
        break;
      case "acceptMatchDialog":
        state = await this.AcceptMatch(state);
        break;
      case "cancelMatchDialog":
        state = this.CancelMatch(state);
        break;
      case "organizationId":
        state = this.SetOrganization(state, e.value);
        break;
      default:
        // never modify state, always clone
        state = { ...state };
        state[e.fieldName] = e.value;
        break;
    }

    state = this.SetStateChanged(state);

    return state;
  }

  GetApiPutParams() {
    return [
      {
        in: "formData",
        name: "name",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "nameTranscription",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "gender",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "email",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "cellPhone",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "employerName",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "employerLocation",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "hromadaAmalgamated",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "contactAddress",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "position",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "regionId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "organizationId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "path",
        name: "id",
        type: "integer",
        format: "int32",
        required: true,
      },
      {
        in: 'formData',
        name: 'age',
        type: 'string',
        required: false
      },
      {
        in: 'formData',
        name: 'targetGroupIds',
        type: 'array',
        items: {
          type: 'integer',
          format: 'int32'
        },
        required: false
      }
    ];
  }

  async DisconnectParticipant(participantId, activityId) {
    let url = `${this.url}/participants/disconnect/${participantId}/${activityId}`;
    const result = await this.Fetch("delete", url);
    return result;
  }

  async ConnectParticipant(participantId, activityId, params) {
    let url = `${this.url}/participants/connect/${participantId}/${activityId}?${params}`;
    const result = await this.Fetch("post", url);
    return result;
  }

  ValidateItem(state) {
    let result = super.ValidateItem(state);

    this.validationRequired(result, state, "name");
    this.validationRequired(result, state, "nameTranscription");
    //this.validationRequired(result, state, "contactAddress");
    if (state.showCellPhone) {
      if (
        state.cellPhone === undefined ||
        state.cellPhone === "" ||
        state.cellPhone.indexOf("_") > -1
      ) {
        result.cellPhone = "Required";
      }
    }

    // this.validationRequired(result, state, "employerName");
    // this.validationRequired(result, state, "employerLocation");
    // this.validationRequired(result, state, "employerLocationCoordinatesString");
    // this.validationRequired(result, state, "employerLocationCoordinates");

    this.validationSelectionRequired(result, state, "regionId");
    // this.validationSelectionRequired(result, state, "organizationId");
    this.validationRequired(result, state, "gender");

    if (
      !state.cellPhone ||
      state.cellPhone === "" ||
      state.cellPhone === this._dummyPhoneNumber
    ) {
      this.validationRequired(result, state, "email");
    }

    return result;
  }

  async AcceptMatch(state) {
    let result = { ...state, loading: true };
    this.onUpdateState(result);

    let item = await this.GetItem(state.selectedMatch);

    try {
      item = await this.ConnectParticipant(
        state.selectedMatch,
        state.activityId,
        state.checkedExistedParams
      );
    } catch (error) {
      const message = this.GetError("Could not connect participant to activity!");
      result = {
        ...result,
        showDialog: false,
        isCheckDialog: false,
        loading: false,
        selectedMatch: undefined,
        messages: [message],
      };
      return result;
    }

    result = this.UpdateStateFromItem(result, item);

    result = {
      ...result,
      showDialog: false,
      isCheckDialog: false,
      loading: false,
      selectedMatch: undefined,
    };
    return result;
  }

  CancelMatch(state) {
    const result = {
      ...state,
      showDialog: false,
      isCheckDialog: false,
      loading: false,
    };
    return result;
  }

  SetOrganization(state, value) {
    const result = {
      ...state,
      organizationId: value.id,
      organization: value,
      regionId: value.regionId
    };
    return result;
  }

  async CheckParticipant(state) {
    let result = { ...state, loading: true };
    this.onUpdateState(result);

    const {
      //email,
      name,
      nameTranscript,
      cellPhone,
    } = state;

    const matchComponents = [
      { field: "name", value: name },
      { field: "nameTranscription", value: nameTranscript },
      //{field: 'email', value: email},
      { field: "cellPhone", value: cellPhone },
    ].filter((x) => (x.value || "").length);

    let params = [];
    each(matchComponents, (component) => {
      let { value, field } = component;
      if (field === "cellPhone") {
        value = value.replace(/_/g, "");
        field = "cell_phone";
      }
      params.push(`${field}=${value}`);
    });
    params = params.join("&");

    let matchedParticipants = await this.Fetch(
      "get",
      `${this.url}/participants/check?${params}`
    );
    const matchFound = matchedParticipants?.length > 0;

    result = {
      ...result,
      isCheckDialog: true,
      showDialog: true,
      checkedExistedParams: params,
      selectedMatch: matchFound ? matchedParticipants[0].id : undefined,
      usersList: matchedParticipants,
      loading: false,
    };

    return result;
  }

  SetDisconnectItemFromActivity(state, value) {
    const result = {
      ...state,
      showConfirmDisconnect: true,
      disconnectActivity: value,
    };
    return result;
  }

  SetRejectDisconnect(state) {
    const result = {
      ...state,
      showConfirmDisconnect: false,
      disconnectActivity: undefined,
    };
    return result;
  }

  async SetConfirmDisconnect(state) {
    //start loader, close dialog
    let result = { ...state, showConfirmDisconnect: false, loading: true };
    this.onUpdateState(result);

    const deleteResult = await this.DisconnectParticipant(
      state.id,
      state.disconnectActivity.id
    );

    if (deleteResult.id) {
      const activities = state.activities.filter(
        (e) => e.id !== state.disconnectActivity.id
      );
      result = {
        ...result,
        activities,
        loading: false,
        disconnectActivity: undefined,
      };
    } else {
      // participant was deleted by removing its last activity

      // show notification and cleanup index grid!
      result = {
        ...result,
        open: false,
        loading: false,
        disconnectActivity: undefined,
      };
    }

    return result;
  }

  SetLocation(state, value) {
    let location, employerLocationCoordinatesString;

    if (value?.latLng?.length === 2) {
      location = value.latLng;
      employerLocationCoordinatesString = `${value.latLng[0]} ${value.latLng[1]}`;
    } else {
      location = state.initialLocation;
      employerLocationCoordinatesString = "";
    }

    let result = { ...state, location, employerLocationCoordinatesString };

    if (value.addressParts) {
      var cityParts = [];
      var cityTypes = [
        "locality",
        "administrative_area_level_2",
        "administrative_area_level_1",
      ];
      each(value.addressParts, (part) => {
        if (cityTypes.indexOf(part.types[0]) < 0) {
          return;
        }
        cityParts.push(part.long_name);
      });

      result = {
        ...result,
        contactAddress: result.contactAddress || value.address,
        employerLocation: cityParts.join(", "),
      };
    }

    return result;
  }

  //impure!
  SetEmployerLevel(state, value) {
    const isRegionDisabled = value === 2 || value === 1;
    let result = { ...state };
    const naRegion = { id: 30, value: 30, label: "N/A" };

    if (isRegionDisabled === true && state.isRegionDisabled === false) {
      if (state.regionsList) {
        const regionsList = [...state.regionsList];
        regionsList.unshift(naRegion);

        result = { ...result, regionsList, regionId: naRegion.id };
      }
    } else if (isRegionDisabled === false && state.isRegionDisabled === true) {
      let regionsList = [...state.regionsList];
      regionsList = regionsList.filter((x) => x.id !== naRegion.id);

      result = { ...result, regionsList, regionId: undefined };
    }

    result = { ...result, employerLevelId: value, isRegionDisabled };
    return result;
  }

  SetName(state, value) {
    if (typeof value === 'object') {
      const organization = {
        id: value.organizationId,
        nameEn: value.employerNameEn,
        nameUa: value.employerNameUa,
        address: value.contactAddress,
        employerType: {
          en: value.employerTypeEn,
          ua: value.employerTypeUa
        },
        employerLevel: {
          en: value.employerLevelEn,
          ua: value.employerLevelUa
        },
        locationCoordinates: value.employerLocationCoordinates
      };

      return {
        ...state,
        id: value.id,
        name: value.name,
        gender: value.gender,
        nameTranscription: value.nameTranscription,
        email: value.email,
        cellPhone: value.cellPhone,
        showCellPhone: !!value.cellPhone,
        position: value.position,
        regionId: value.regionId,
        organizationId: value.organizationId,
        organization 
      };
    }

    let translit = cyrillicToTranslit({ preset: "uk" }).transform(value);
    translit = map(translit.split(" "), capitalize).join(" ");

    let result = { ...state, name: value, nameTranscription: translit };

    return result;
  }

  MessageSaveFailed(state, response, data) {
    state = { ...state };

    if (!state.messages) {
      state.messages = [];
    }

    if (response.status === 300) {
      let duplicateName = data[0].name;
      state.messages.push(
        this.GetInfoDialog(
          `Please correct name to: ${duplicateName} and select the 'Check' button in the Cell Phone field.`,
          "Duplicate found, participant not saved!"
        )
      );
      return state;
    } else if (data.error) {
      state.messages.push(this.GetError(data.error, ""));
      return state;
    } else {
      super.MessageSaveFailed(state, response);
    }
  }
}

export default ParticipantService;

export function arrayRemoveMutable(source, index) {
  source.splice(index, 1);
}

export function arrayRemoveImmutable(source, index) {
  const copy = [...source];

  arrayRemoveMutable(copy, index);

  return copy;
}

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

class EventsDecorator {
  weeks = [[], [], [], [], [], []];

  constructor(basis) {
    this.basis = basis;
  }

  static make(basis, events) {
    const instance = new EventsDecorator(basis);

    events
      .map((event) => ({
        ...event,
        startDate: dayjs(event.startDate).startOf('day'),
        endDate: dayjs(event.endDate).endOf('day')
      }))
      .filter((event) => !(event.endDate.isBefore(basis) || event.startDate.isAfter(basis.add(1, 'week').endOf('month').endOf('isoWeek'))))
      .sort((event, other) => event.startDate.diff(other.startDate, 'days'))
      .forEach((event) => instance.add(event));

    return instance;
  }

  get blocks() {
    return this.weeks.flat();
  }

  add(event) {
    const blocks = this.split(event);
    blocks.forEach((block) => this.insert(block));
  }

  split(event, continueing = false) {
    if (event.startDate.isBefore(this.basis)) {
      event.startDate = this.basis.clone();
      continueing = true;
    }

    if (event.endDate.isAfter(this.basis.endOf('isoWeek').endOf('month').endOf('isoWeek'))) {
      event.endDate = this.basis.endOf('isoWeek').endOf('month').endOf('isoWeek');
      event.trimmed = true;
    }

    if (event.startDate.isSame(event.endDate, 'isoWeek')) {
      return [{ 
        event, 
        week: event.startDate.diff(this.basis, 'weeks'), 
        day: event.startDate.diff(event.startDate.startOf('isoWeek'), 'days'),
        duration: event.endDate.diff(event.startDate, 'days') + 1,
        offset: 0,
        eventStart: !continueing,
        eventEnd: !event.trimmed
      }];
    }

    const head = { 
      event,
      week: event.startDate.diff(this.basis, 'weeks'), 
      day: event.startDate.diff(event.startDate.startOf('isoWeek'), 'days'),
      duration: event.startDate.endOf('isoWeek').diff(event.startDate, 'days') + 1,
      offset: 0,
      eventStart: !continueing
    };

    const tail = { 
      ...event, 
      startDate: event.startDate.endOf('isoWeek').add(1, 'day').startOf('day')
    };

    return [
      head,
      ...this.split(tail, true)
    ]
  }

  insert(block) {
    for (let i = 0; i < this.weeks[block.week].length; i++) {
      if (this.isSimilar(block, this.weeks[block.week][i])) {
        if (!this.weeks[block.week][i].events) {
          this.weeks[block.week][i].events = [this.weeks[block.week][i].event];
        } 

        this.weeks[block.week][i].events.push(block.event);

        return;
      }
    }

    this.weeks[block.week]
      .forEach((other) => this.isOverlapping(block, other) && block.offset++);
    
    this.weeks[block.week].push(block);
  }

  isOverlapping(block, other) {
    if (block.day === other.day) {
      return true;
    }

    [block, other] = block.day > other.day ? [other, block] : [block, other];

    return block.day + block.duration >= other.day && block.offset === other.offset;
  }

  isSimilar(block, other) {
    return block.event.name === other.event.name && 
      block.week === other.week && 
      block.day === other.day && 
      block.duration === other.duration;
  }
}

export default EventsDecorator;

import React from "react";
import { I18n } from "../../i18n";
import ParticipantService from "../../services/ParticipantService.js";
import Edit from "../../components/dialog/edit.js";
import {
  FormTextField,
  FormRadioField,
  FormPhoneField,
  FormSelectField,
  FormMultiSelectField
} from 'components/dialog/formField';
import {
    InputAdornment,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    InputLabel
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import { Search } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../style/styles.js";
import classNames from "classnames";
import { activityRoute } from "../../utils/routes";
import FormAsyncCreatableSelectField from '../../components/dialog/FormAsyncCreatableSelectField';
import FormAsyncOrganizationSelectField from '../../components/dialog/FormAsyncOrganizationSelectField';
import FormParticipantSelectField from '../../components/dialog/FormParticipantSelectField';
import ProjectCondition from 'components/conditions/ProjectCondition';

const ageGroups = [
  undefined,
  'Up 18',
  '18-23',
  '24-29',
  '30-35',
  '36-49',
  '50-80'
];

class Item extends React.Component {
    constructor(props) {
      super(props);

      this.handleGetData = this.handleGetData.bind(this);
    }

    async handleGetData(service, editorState) {
      const regionsList = await service.GetRegions();
      const recentOrganizations = await service.GetItems('organizations')
        .then((organizations) => organizations.map((org) => {
          return {
            value: org.id, 
            label: this.props.lang === 'Eng' ? org.nameEn : org.nameUa,
            object: org
          };
        }));
      const targetGroupsList = await service.GetSelectOptions('target_groups', editorState.targetGroups);

      let organization = null;

      if (this.props.item?.organization) {
        organization = await service.GetItems('organizations/' + editorState.organizationId);
      }

      return {
        regionsList,
        recentOrganizations,
        organization,
        targetGroupsList
      };
    }

    renderActivityLink = (activity, item) => {
        const name = `${this.props.lang === "Eng" ? activity.nameEn : activity.nameUa
            }`;
        if (
            (item.specialRightActivityIds &&
                item.specialRightActivityIds.indexOf(activity.id) > 0) ||
            !item.forbiddenActivityIds ||
            item.forbiddenActivityIds.indexOf(activity.id) === -1
        ) {
            return (
                <a target="_blank" rel="noreferrer" href={`${activityRoute}/view/${activity.id}`}>
                    {name}
                </a>
            );
        } else {
            return name;
        }
    };

    editAccessToActivity = (activity, item) => {
        return item.forbiddenActivityIds.indexOf(activity.id) < 0;
    };

    renderCheckButton = (action, formState) => {
        const { cellPhone } = formState;

        const { classes } = this.props;

        const isValid =
            typeof cellPhone === "string" &&
            cellPhone.length > 1 &&
            !~cellPhone.indexOf("_");

        return {
            endAdornment: isValid ? (
                <InputAdornment position="end" className={classes.searchInField}>
                    <IconButton
                        onClick={() => action({ fieldName: "checkParticipant" })}
                        className={classes.btnSearch}
                    >
                        <Search />
                        <span>check</span>
                        {/* <span>{I18n[lang].participants.checked}</span> */}
                    </IconButton>
                </InputAdornment>
            ) : null,
        };
    };

    render = () => {
        const i18n = I18n[this.props.lang].participants;
        const { classes, ...rest } = { i18n, ...this.props };
        const lang = this.props.lang;

        return (
            <Edit
                i18n={i18n}
                handleGetData={this.handleGetData}
                onAfterGetData={this.onAfterGetData}
                serviceClass={ParticipantService}
                title={!this.props.item ? I18n[lang].activities.addParticipant : undefined}
                {...rest}
                render={(fieldProps) => (
                  <Grid container spacing={24} alignItems="flex-start">
                      <Grid item container xs={12} md={6} spacing={24}>
                          <Grid item xs={12}>
                              <Paper className={classes.paper}>
                                  <Grid container spacing={16}>
                                      <Grid item xs={12}>
                                          <Typography variant="h3">
                                              {I18n[lang].participants.personalDetails}
                                          </Typography>
                                      </Grid>

                                      <Grid
                                          item
                                          container
                                          direction="column"
                                          xs={12}
                                          md={6}
                                          spacing={8}
                                      >
                                          <Grid item>
                                            {!this.props.activityId
                                              ? <FormTextField fieldName="name" {...fieldProps} />
                                              : <FormParticipantSelectField 
                                                  label={i18n.name}
                                                  fieldName="name" 
                                                  {...fieldProps} 
                                                />
                                            }
                                          </Grid>
                                          <Grid item>
                                            <FormRadioField
                                              fieldName="gender"
                                              selectList={[
                                                  { value: "MALE", label: "Male" },
                                                  { value: "FEMALE", label: "Female" },
                                                  { value: "N/A", label: "n/a" },
                                              ]}
                                              {...fieldProps}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <FormTextField
                                              fieldName="nameTranscription"
                                              {...fieldProps}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <FormAsyncCreatableSelectField
                                              fieldName="position"
                                              label={I18n[lang].common.position}
                                              {...fieldProps}
                                              value={fieldProps.formState.position && {label: fieldProps.formState.position}}
                                            />
                                          </Grid>
                                      </Grid>

                                      <Grid
                                          item
                                          container
                                          direction="column"
                                          xs={12}
                                          md={6}
                                          spacing={8}
                                      >
                                          <Grid item>
                                            <FormTextField
                                              fieldName="email"
                                              {...fieldProps}
                                              label={I18n[lang].common.email}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <FormPhoneField
                                              fieldName="cellPhone"
                                              {...fieldProps}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <FormSelectField
                                              fieldName="regionId"
                                              label="Region"
                                              selectList={fieldProps.formState.regionsList}
                                              {...fieldProps}
                                            />
                                          </Grid>
                                          <ProjectCondition abbr="AZP">
                                            <Grid item>
                                              <FormSelectField
                                                fieldName="age"
                                                label="Age"
                                                selectList={ageGroups.map((group) => ({ value: group, label: group }))}
                                                {...fieldProps}
                                              />
                                            </Grid>
                                          </ProjectCondition>

                                          <ProjectCondition abbr="AZP">
                                            <Grid item>
                                              <FormMultiSelectField
                                                fieldName="targetGroups"
                                                label="Vulnerable Groups"
                                                {...fieldProps}
                                              />
                                            </Grid>
                                          </ProjectCondition>
                                      </Grid>
                                  </Grid>
                              </Paper>
                          </Grid>
                          <Grid item xs={12}>
                              {!fieldProps.formState.isTransient && (
                                  <div>
                                      <Typography variant="h3">
                                          {I18n[lang].participants.activities}
                                      </Typography>
                                      <Table className={classes.inlineTable}>
                                          <TableHead>
                                              <TableRow>
                                                  <TableCell>{I18n[lang].activities.id}</TableCell>
                                                  <TableCell>{I18n[lang].activities.name}</TableCell>
                                                  <TableCell></TableCell>
                                              </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              {fieldProps.formState.activities.map((activity, index) => {
                                                  return (
                                                      <TableRow key={index}>
                                                          <TableCell>{activity.id}</TableCell>
                                                          <TableCell>
                                                              {this.renderActivityLink(
                                                                  activity,
                                                                  fieldProps.formState
                                                              )}
                                                          </TableCell>
                                                          <TableCell>
                                                              {this.editAccessToActivity(
                                                                  activity,
                                                                  fieldProps.formState
                                                              ) && (
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    className={classNames("button-cancel", [
                                                                        classes.buttonRemoveSmall,
                                                                    ])}
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        fieldProps.action({
                                                                            fieldName: "disconnectItemFromActivity",
                                                                            value: activity,
                                                                        })
                                                                    }
                                                                >
                                                                    <i></i>
                                                                    {I18n[lang].activities.deleteParticipant}
                                                                </Button>
                                                              )}
                                                          </TableCell>
                                                      </TableRow>
                                                  );
                                              })}
                                          </TableBody>
                                      </Table>
                                  </div>
                              )}
                          </Grid>
                      </Grid>

                      <Grid item xs={12} md={6}>
                          <Paper className={classes.paper}>
                              <Grid container spacing={16}>
                                  <Grid item xs={12}>
                                      <Typography variant="h3">
                                        Organization
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <FormAsyncOrganizationSelectField
                                          fieldName="organizationId"
                                          label="Name of Organization"
                                          {...fieldProps}
                                          value={fieldProps.formState.organization}
                                          defaultOptions={fieldProps.formState.recentOrganizations}
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                      <InputLabel>Region</InputLabel>
                                      <Typography varian="body2">
                                        {fieldProps.formState.organization?.region?.name}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <InputLabel>City/Municipality</InputLabel>
                                      <Typography varian="body2">
                                        {fieldProps.formState.organization?.address}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <InputLabel>{I18n[lang].organizations.employerTypeId}</InputLabel>
                                      <Typography varian="body2">
                                        {fieldProps.formState.organization?.employerType?.en}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <InputLabel>Size (number of employees)</InputLabel>
                                      <Typography varian="body2">
                                        {fieldProps.formState.organization?.size?.name}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <InputLabel>Type of Economic Activities</InputLabel>
                                      <Typography varian="body2">
                                        {fieldProps.formState.organization?.economicActivityType?.name}
                                      </Typography>
                                  </Grid>
                              </Grid>
                          </Paper>
                      </Grid>
                  </Grid>
                )}
            />
        );
    };
}

export default withStyles(styles)(Item);

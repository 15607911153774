import { Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DataSource from 'components/DataSourceWithLoadMore';
import Toolbar from './Toolbar';
import ListView from './List';
import CreateView from './Create';
import EditView from './Edit';
import DeleteView from './Delete';

const styles = (theme) => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});

function Tags(props) {
  const { classes } = props;

  return (
    <>
      <Toolbar />
      <Paper className={classes.paper}>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <DataSource path="v2/tags">
              {(tags) => (
                <ListView items={tags} />
              )}
            </DataSource>
          </Grid>
          <Grid item xs={6}>
            <Route exact path="/tags">
              <CreateView />
            </Route>
            <Route path="/tags/:id/edit">
              <EditView />
            </Route>
            <Route path="/tags/:id/delete">
              <DeleteView />
            </Route>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default withStyles(styles)(Tags);

import React from "react";
import { I18n } from "../../i18n";
import ActivityService from "../../services/ActivityService";
import Edit from "../../components/dialog/edit.js";
import AddressControl from "../../components/AddressControl.js";
import {
  FormNumberField,
  FormDateField,
  FormTimeField,
  FormTextField,
  FormTextAreaField,
  FormSelectField,
  FormMultiSelectField,
  FormFileInput,
  ReadOnlyField,
} from "../../components/dialog/formField";
import { Paper, InputLabel, Grid } from "@material-ui/core";
import { Map, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../style/styles";
import { StoreContext } from 'stores/context';
import ProjectCondition from 'components/conditions/ProjectCondition';
import { required, atLeastOne } from 'validators';

class Item extends React.Component {
  async handleGetData(service, editorState) {
    let [
      formats,
      regionsList,
      categoriesList,
      targetGroupsList,
      targetLevelsList,
      fundingSourcesList,
      charactersList,
      characteristics,
      trainingPackageTypesList,
      thematicModulesList,
      europeActionsList,
      trainersList,
      organizations,
      csoGrants,
      tagsList
    ] = await Promise.all([
      service.GetAllSelectOptions("formats", editorState.formatId),
      service.GetRegions(),
      service.getCategories(),
      service.GetSelectOptions("target_groups", editorState.targetGroups),
      service.GetSelectOptions("target_levels", editorState.targetLevels),
      service.GetSelectOptions("funding_sources", editorState.fundingSources),
      service.GetCharacters(editorState.activityCharacters, I18n),
      service.getCharacteristics(),
      service.GetTrainingPackageTypes(
        editorState.restrictCTP,
        editorState.trainingPackageType
      ),
      service.GetThematicModules(),
      service.GetAllSelectOptions("europe_actions", editorState.europeActions),
      service.GetTrainers(),
      service.getOrganizations(),
      service.GetSelectOptions("cso_grants", editorState.csoGrants, false, true),
      service.GetAllSelectOptions('v2/tags', editorState.tags),
    ]);

    formats = formats.filter((item) => {
      if (item.id !== editorState.formatId && !item.active) {
        return false;
      } else {
        return true;
      }
    });

    europeActionsList.unshift({
      id: 0,
      label: I18n[this.props.lang].common.no,
    });

    let users = [];
    if (editorState.showOwner) {
      users = await service.GetActivityOwners(editorState.fake);
    }

    const activeOgranization = service.getActiveOrganization(editorState.organizationId, organizations);

    const state = {
      formats,
      regionsList,
      categoriesList,
      targetGroupsList,
      targetLevelsList,
      fundingSourcesList,
      charactersList,
      trainingPackageTypesList,
      thematicModulesList,
      europeActionsList,
      users,
      trainersList,
      characteristics,
      organizations,
      activeOgranization,
      csoGrants,
      tagsList
    };

    return state;
  }

  // there are state modifications which depend on loaded lists
  // those may be executed here
  onAfterGetData(service, state) {
    let newState = service.SetTrainingPackageTypes(
      state,
      state.trainingPackageTypes
    );

    return newState;
  }

  formatGroupLabel = (data) => {
    let color = "#6495ed";

    if (data.label.includes("Cluster B") || data.label.includes("Кластер B")) {
      color = "rgb(253, 187, 45)";
    }
    if (data.label.includes("Cluster C") || data.label.includes("Кластер C")) {
      color = "grey";
    }

    return (
      <div className="groupStyles" style={{ backgroundColor: color }}>
        <span>{data.label}</span>
      </div>
    );
  };

  render = () => {
    const i18n = I18n[this.props.lang].activities;
    const { classes, ...rest } = { i18n, ...this.props };
    const { userStore } = this.context;

    return (
      <Edit
        i18n={i18n}
        handleGetData={this.handleGetData}
        onAfterGetData={this.onAfterGetData}
        serviceClass={ActivityService}
        {...rest}
        render={(fieldProps) => (
          <Grid container spacing={24} alignItems="flex-start">
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={16}>
                  <Grid item container direction="column" xs={3} spacing={16}>
                    <Grid item>
                      <FormTextAreaField fieldName="nameEn" {...fieldProps} />
                    </Grid>
                    <Grid item>
                      <FormTextAreaField fieldName="descEn" {...fieldProps} />
                    </Grid>
                    <Grid item>

                      <ProjectCondition not abbr="AZP">
                        <FormSelectField
                          fieldName="regionId"
                          selectList={fieldProps.formState.regionsList}
                          label={i18n.region}
                          validate={required}
                          {...fieldProps}
                        />
                      </ProjectCondition>

                      <ProjectCondition abbr="AZP">
                        <FormMultiSelectField
                          fieldName="regions"
                          label="Countries"
                          validate={atLeastOne}
                          {...fieldProps}
                        />
                      </ProjectCondition>

                    </Grid>
                    <Grid item>
                      <FormTextAreaField
                        fieldName="activityAddress"
                        {...fieldProps}
                      />
                    </Grid>
                    <Grid item>
                      <FormTextField
                        isDisabled={true}
                        fieldName="placeOfActivityCoordinates"
                        value={fieldProps.formState.coordString}
                        {...fieldProps}
                      />
                    </Grid>

                    <Grid item>
                      <Map
                        className={classes.map}
                        center={fieldProps.formState.position}
                        zoom={fieldProps.formState.zoom}
                      >
                        {this.props.isEditable && (
                          <AddressControl
                            className={classes.mapSearch}
                            onCoordinatesChange={(
                              latLng,
                              addressParts,
                              address
                            ) =>
                              fieldProps.action({
                                fieldName: "mapSearch",
                                value: { latLng, addressParts, address },
                              })
                            }
                          />
                        )}
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker
                          position={fieldProps.formState.position}
                        ></Marker>
                      </Map>
                    </Grid>
                  </Grid>

                  <Grid item container direction="column" xs={3} spacing={16}>
                    <Grid item>
                      <FormDateField fieldName="startDate" {...fieldProps} />
                    </Grid>
                    <Grid item>
                      <FormTimeField fieldName="startTime" {...fieldProps} />
                    </Grid>
                    <Grid item>
                      <FormSelectField
                        fieldName="formatId"
                        label={i18n.activityFormat}
                        selectList={fieldProps.formState.formats}
                        {...fieldProps}
                      />
                    </Grid>
                    {fieldProps.formState.isDigitalTool && (
                      <>
                        <Grid item>
                          <FormNumberField
                            fieldName="targetedPersons"
                            label={i18n.targetedPersons}
                            {...fieldProps}
                          />
                        </Grid>
                        <Grid item>
                          <FormNumberField
                            fieldName="uniqueUsers"
                            label={i18n.uniqueUsers}
                            {...fieldProps}
                          />
                        </Grid>
                      </>
                    )}

                    <ProjectCondition not abbr="AZP">
                      <Grid item>
                        <FormTextField
                          fieldName="linkToRegistration"
                          {...fieldProps}
                        />
                      </Grid>
                    </ProjectCondition>

                    <Grid item>
                      <InputLabel>
                        {I18n[this.props.lang].activities.mediaReferences}
                      </InputLabel>

                      <Grid item container direction="column" spacing={24}>
                        {fieldProps.formState.mediaLinks.map(
                          (mediaReference, idx) => (
                            <Grid
                              key={idx}
                              item
                              container
                              direction="column"
                              spacing={16}
                            >
                              <Grid
                                item
                                container
                                direction="column"
                                spacing={8}
                              >
                                <Grid item>
                                  <button
                                    className={classes.formButton}
                                    type="button"
                                    onClick={(e) => {
                                      fieldProps.action({
                                        fieldName: "mediaReference_Remove",
                                        value: { idx: idx },
                                      });
                                    }}
                                  >
                                    ✖
                                  </button>
                                </Grid>
                                <Grid item>
                                  <FormTextField
                                    fieldName={`URL_${idx}`}
                                    hideLabel={true}
                                    placeholder={`URL`}
                                    value={mediaReference.url}
                                    onChange={(e) => {
                                      fieldProps.action({
                                        fieldName: "mediaReference_ChangeUrl",
                                        value: {
                                          idx: idx,
                                          url: e.currentTarget.value,
                                        },
                                      });
                                    }}
                                    {...fieldProps}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <FormTextField
                                  hideLabel={true}
                                  fieldName={`TILE_${idx}`}
                                  placeholder={`Title`}
                                  value={mediaReference.title}
                                  onChange={(e) => {
                                    fieldProps.action({
                                      fieldName: "mediaReference_ChangeTitle",
                                      value: {
                                        idx: idx,
                                        title: e.currentTarget.value,
                                      },
                                    });
                                  }}
                                  {...fieldProps}
                                />
                              </Grid>
                            </Grid>
                          )
                        )}
                        <Grid item>
                          <button
                            type="button"
                            className={classes.formButton}
                            onClick={(e) => {
                              fieldProps.action({
                                fieldName: "mediaReference_Add",
                              });
                            }}
                          >
                            {I18n[this.props.lang].common.add}
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <ProjectCondition not abbr="AZP">
                      <Grid item>
                        <FormTextAreaField
                          fieldName="internalFeedback"
                          {...fieldProps}
                        />
                      </Grid>
                    </ProjectCondition>

                    <Grid item>
                      <FormTextAreaField
                        fieldName="responsiblePerson"
                        {...fieldProps}
                      />
                    </Grid>

                    {fieldProps.formState.showOwner && (
                      <Grid item>
                        <FormSelectField
                          fieldName="ownerId"
                          selectList={fieldProps.formState.users}
                          label={i18n.owner}
                          {...fieldProps}
                        />
                      </Grid>
                    )}

                    <Grid item>
                      <FormMultiSelectField
                        label="Tags"
                        fieldName="tags"
                        {...fieldProps}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    direction="column"
                    xs={3}
                    spacing={16}
                    justify="flex-start"
                  >
                    <Grid item>
                      <FormDateField fieldName="endDate" {...fieldProps} />
                    </Grid>
                    <Grid item>
                      <FormTimeField fieldName="endTime" {...fieldProps} />
                    </Grid>
                    {fieldProps.formState.isTraining && (
                      <>
                        <Grid item>
                          <FormSelectField
                            fieldName="characteristicId"
                            selectList={fieldProps.formState.characteristics}
                            label={i18n.characteristic}
                            {...fieldProps}
                          />
                        </Grid>
                        <Grid item>
                          <FormSelectField
                            fieldName="kind"
                            selectList={[
                              {
                                label: i18n.online,
                                value: 'online',
                                id: 'online',
                              },
                              {
                                label: i18n.offline,
                                value: 'offline',
                                id: 'offline',
                              },
                              {
                                label: i18n.hybrid,
                                value: 'hybrid',
                                id: 'hybrid',
                              },
                            ]}
                            {...fieldProps}
                          />
                        </Grid>
                      </>
                    )}
                    {fieldProps.formState.showPlace && (
                      <Grid item>
                        <FormSelectField
                          fieldName="place"
                          selectList={[
                            {
                              label: i18n.national,
                              value: "National",
                              id: "National",
                            },
                            {
                              label: i18n.international,
                              value: "International",
                              id: "International",
                            },
                          ]}
                          {...fieldProps}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <FormSelectField
                        fieldName="csoGrantId"
                        label={i18n.implementedVia}
                        selectList={fieldProps.formState.csoGrants}
                        getValueAndLabel={(option) => ({value: option.id, label: option.projectTitle})}
                        {...fieldProps}
                      />
                    </Grid>

                    <Grid item style={{ width: "100%" }}>
                      <FormMultiSelectField
                        fieldName="categories"
                        label={userStore.isCurrentProject('AZP') ? 'Outputs' : 'Categories' }
                        grouping={true}
                        {...fieldProps}
                      />
                    </Grid>

                    <ProjectCondition abbr="AZP">
                      <Grid item>
                        <FormMultiSelectField
                          fieldName="targetGroups"
                          label="Vulnerable Groups"
                          {...fieldProps}
                        />
                      </Grid>
                    </ProjectCondition>


                    {fieldProps.formState.isGrantVoucher && (
                      <>
                        <Grid item>
                          <FormTextField
                            fieldName="advisoryType"
                            {...fieldProps}
                          />
                        </Grid>
                        <Grid item>
                          <FormNumberField
                            fieldName="grantValue"
                            {...fieldProps}
                          />
                        </Grid>
                        <Grid item>
                          <FormSelectField
                            fieldName="grantCurrency"
                            selectList={[
                              {
                                label: i18n.grantCurrencyEur,
                                value: "EUR",
                                id: "EUR",
                              }
                            ]}
                            {...fieldProps}
                          />
                        </Grid>
                        <Grid item>
                          <FormSelectField
                            fieldName="organizationId"
                            selectList={fieldProps.formState.organizations}
                            label={i18n.organization}
                            {...fieldProps}
                          />
                        </Grid>
                        {fieldProps.formState.activeOgranization && (
                          <>
                            <Grid item>
                              <ReadOnlyField
                                label={i18n.organizationSectorOfActivities}
                                value={
                                  fieldProps.formState.activeOgranization
                                    .sectorOfActivities
                                }
                              />
                            </Grid>
                            <Grid item>
                              <ReadOnlyField
                                label={i18n.organizationNumberOfEmployees}
                                value={
                                  fieldProps.formState.activeOgranization
                                    .numberOfEmployees
                                }
                              />
                            </Grid>
                            <Grid item>
                              <ReadOnlyField
                                label={i18n.organizationAddress}
                                value={
                                  fieldProps.formState.activeOgranization
                                    .address
                                }
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {fieldProps.formState.showTrainers && (
                      <Grid item xs className={classes.noFlexGrow}>
                        <FormMultiSelectField
                          fieldName="trainers"
                          {...fieldProps}
                        />
                      </Grid>
                    )}
                    {fieldProps.formState.showConsultants && (
                      <Grid item xs className={classes.noFlexGrow}>
                        <FormMultiSelectField
                          fieldName="trainers"
                          label={i18n.consultants}
                          {...fieldProps}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid item container direction="column" xs={3} spacing={16}>
                    <ProjectCondition not abbr="AZP">
                      <Grid item xs className={classes.noFlexGrow}>
                        <FormMultiSelectField
                          fieldName="fundingSources"
                          validate={atLeastOne}
                          {...fieldProps}
                        />
                      </Grid>
                    </ProjectCondition>

                    <Grid item>
                      <FormFileInput
                        fieldName="activityReport"
                        message="Add report"
                        {...fieldProps}
                      />
                    </Grid>
                    <Grid item>
                      <FormFileInput
                        fieldName="activityFoto"
                        message="Add photo"
                        {...fieldProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      />
    );
  };
}

Item.contextType = StoreContext;

export default withStyles(styles)(Item);

import ItemService from "./ItemService";
import downloadFile from "../utils/fetchFile";
import { COMMUNITY_ENDPOINT } from "../utils/DataFetcher";

class CommunityService extends ItemService {
  constructor(onUpdateState, lang, user) {
    super(COMMUNITY_ENDPOINT, onUpdateState, lang, user);
  }

  GetInitialIndexState() {
    let state = super.GetInitialIndexState();
    state = {
      ...state,
      sortableColumns: ["id", "region", "initiatives", "support"],
    };

    return state;
  }

  GetInitialState(userRoles) {
    let state = super.GetInitialState(userRoles);

    const activitiesTableTitles = [
      { id: "id", label: "id" },
      { id: "startDate", label: "date" },
      { id: this._isEn ? "nameEn" : "nameUa", label: "eventTitle" },
      { id: this._isEn ? "format.en" : "format.ua", label: "eventFormat" },
      { id: "trainingPackageTypes", label: "support" },
    ];

    state = {
      europeActions: [],
      grants: [],
      projects: [],
      order: "desc",
      orderBy: "",
      participantsOrder: "desc",
      participantsOrderBy: "",
      participantsTableTitles: [
        {
          id: this._isEn ? "nameTranscription" : "name",
          label: "participantName",
        },
        { id: "gender", label: "gender" },
        { id: "position", label: "position" },
        {
          id: this._isEn ? "employerTypeEn" : "employerTypeUa",
          label: "employerType",
        },
        { id: "employerName", label: "employerName" },
      ],
      activitiesTableTitles,
      doughnutOptions: this.doughnutOptions,
      genderDistributionChart: this.emptyDoughnutState,
      employerTypeDistributionChart: this.emptyDoughnutState,
      activityFormatDistributionChart: this.emptyDoughnutState,

      ...state,
    };

    return state;
  }

  UpdateStateFromItem(state, item) {
    state = super.UpdateStateFromItem(state, item);

    const sourceTitle = item[this._isEn ? "titleEn" : "title"];
    const subString = sourceTitle?.trimRight().split(" ").splice(-3).join(" ");
    const resultTitle = sourceTitle?.replace(
      subString,
      this._isEn ? "AH" : "ОТГ"
    );

    state = {
      ...state,
      grants: item.csoGrants,
      projects: item.donorProjects,
      ahCentre: this._lang === "Ua" ? item.center : item.centerEn,
      region: this._lang === "Ua" ? item.region?.ua : item.region?.en,
      created: this.FormDate(item.created),
      population: item.population?.toLocaleString(),
      mapLink: this.MapLink(item.visualCenterLatLong),
      title: resultTitle,
      isUrban: item.titleEn?.includes("urban"),
      isMiska: item.titleEn?.includes("miska"),
    };

    state = this.GetGenderDistChart(state, item);
    state = this.GetDistChart(state, item, "employerTypeDistribution");
    state = this.GetDistChart(state, item, "activityFormatDistribution");

    return state;
  }

  // if day partis smaller 10 than id reduces by 1
  FormDate = (date) => {
    date = new Date(date);
    const dateMonth = [date.getUTCDate(), date.getMonth() + 1].map((int) =>
      int < 10 ? "0" + int : "" + int
    );
    return dateMonth[0] + "." + dateMonth[1] + "." + date.getUTCFullYear();
  };

  MapLink = (visualCenterLatLong) => {
    if (!visualCenterLatLong) {
      return "";
    }
    const [y, x] = visualCenterLatLong;
    const link = JSON.stringify([x, y]);
    return link;
  };

  async Action(e, state) {
    // base class is not called and contains only default implementation
    // could be improved ...

    switch (e.fieldName) {
      case "setEdit":
        state = { ...state, editMode: true };
        break;
      case "setSave":
        state = await this.SaveDetails(state);
        break;
      case "downloadPdf":
        state = await this.DownloadPdf(state);
        break;
      case "setDetailSorting":
        state = this.SetDetailSorting(state, e.data.orderBy, e.data.entity);
        break;
      default:
        // never modify state, always clone
        state = { ...state };
        state[e.fieldName] = e.value;
        state = this.SetStateChanged(state);
        break;
    }

    return state;
  }

  async SaveDetails(state) {
    state = { ...state, loading: true };
    this.onUpdateState(state);

    const { projects = [], practiceId } = state;
    const data = new FormData();

    const projectIds = [];
    projects.forEach((option) => {
      projectIds.push(parseInt(option.id));
    });
    data.set("donorProjectIds", "[" + projectIds + "]");

    if (practiceId) {
      data.set("goodPracticeId", practiceId);
    }
    const result = await this.Save(state.id, data);

    if (result.ok) {
      state = this.MessageSaveSucceeded(state);
      return { ...state, loading: false, editMode: false, hasChanges: false };
    } else {
      state = this.MessageSaveFailed(state, result);
      return { ...state, loading: false };
    }
  }

  async DownloadPdf(state) {
    state = { ...state, loading: true };
    this.onUpdateState(state);

    const withLang = this._lang ? "?lang=" + this._lang : "";

    await downloadFile(`/communities/${state.id}/print_info.pdf${withLang}`);

    return { ...state, loading: false };
  }

  GetGenderDistChart(state, item) {
    let result = { ...state };

    let data = { ...this.emptyDoughnutState };

    const backgroundColor = [
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 99, 132, 0.2)",
      "rgba(88, 84, 215, 0.2)",
    ];

    const borderColor = [
      "rgba(54, 162, 235, 1)",
      "rgba(255,99,132,1)",
      "rgba(88,84,215,1)",
    ];

    if (item.genderDistribution) {
      data.labels = ["Male", "Female", "Unknown"];

      data.labels.forEach((label, i) => {
        const dist = item.genderDistribution.find(
          (x) => x[0].toLowerCase() === label.toLowerCase()
        );
        data.datasets[0].data[i] = dist ? dist[1] : 0;
      });

      data.datasets[0].backgroundColor = backgroundColor;
      data.datasets[0].borderColor = borderColor;
    }

    return { ...result, genderDistributionChart: data };
  }

  SearchUrlFragment() {
    return "";
  }
}
export default CommunityService;

import React from 'react';
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Typography
} from '@material-ui/core';
import { Add, Search, Edit, RemoveRedEye, DeleteForever } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import fetchData from '../../utils/fetch';
import Loader from '../../components/loader';
import { Link, Route } from 'react-router-dom';
import { find } from 'lodash';
import ItemView from './item';
import ItemEdit from './item-edit';
import 'url-search-params-polyfill';
import history from '../../utils/history';
import { I18n } from '../../i18n/';
import TablePaginationActions from '../../components/pagination/CustomPagination';

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '94%',
    margin: '30px 3%',
  },
  pagination: {
    marginTop: 20,
    color: '#BDBEC0',
    '& div:first-child': {
      display: 'flex',
      'flex-direction': 'column',
      'align-items': 'center',
    }
  },
  paper: {
    overflowX: 'auto',
  },
  table: {
    maxWidth: '100%',
    minWidth: 700,
    textAlign: 'center',
    '& tbody tr': {
      verticalAlign: 'top'
    },
    '& tbody td': {
      paddingTop: '10px',
      fontSize: '1rem',
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  }
});

const tableTitles = [
  {id: 'id', title: 'ID'},
  {id: 'name', title: 'Name'},
  {id: 'description', title: 'Description'},
  {id: 'memberId', title: 'Members'}
];

class Team extends React.Component {
    state = {
      loading: true,
      items: [],
      page: 0,
      total: 0,
      rowsPerPage: 25,
      searchQuery: '',
      order: 'desc',
      orderBy: ''
    };
    componentDidMount() {
      const query = new URLSearchParams(document.location.search);
      const search = query.get('search');
      if (search) {
        this.setState({ searchQuery: search });
        this.getSearchResults(search);
      } else {
        this.getData();
      }
    }

    async getData() {
      const data = await fetchData('get', '/teams');
      this.setState({ loading: false, items: await data[0], total: data[1] });
    }

    async deleteItem(id) {
      const {lang} = this.props;
      if (window.confirm(`${I18n[lang].common.deleteConfirm} ${I18n[lang].team.team} ${id}?`)) {
        await fetchData('delete', `/teams/${id}`);
        this.getData();
      }
    }

    handleChangePage = (event, page) => {
      this.getPage(page);
      this.setState({ loading: true, page: page });
      history.push({
        search: `?page=${page}`
      })
    }

    async getPage(pageNumber) {
      const data = await fetchData('get', `/teams?page=${pageNumber + 1}`);
      this.setState({ loading: false, items: await data[0] });
    }

    viewItem = ({ match: { params: { id } } }) => {
      if (this.state.loading) return null;
      const item = find(this.state.items, { id: Number(id) });

      return <ItemView item={item} lang={this.props.lang} />;
    }

    editItem = ({ match: { params: { id } } }) => {
      if (this.state.loading) return null;
      const item = find(this.state.items, { id: Number(id) });

      return <ItemEdit item={item} handleItemSave={this.handleItemSave} lang={this.props.lang} />;
    }

    handleItemSave = () => {
      this.setState({ loading: true });
      this.getData();
    }

    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    }

    async getSearchResults(searchQuery) {
      const data = await fetchData('get', `/_search/teams?query=${searchQuery}`);
      this.setState({ loading: false, items: await data[0], total: data[1] });
    }

    updateInputValue = e => this.setState({ searchQuery: e.target.value });

    handleSubmit = (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      const search = this.state.searchQuery;
      this.getSearchResults(search);

      history.push({
        search: `?search=${this.state.searchQuery}`
      })
    }

    handleSort = orderBy => event => {
      let order = 'desc';
      if (this.state.orderBy === orderBy && this.state.order === 'desc') {
        order = 'asc';
      }
      this.setState({ loading: true, orderBy: orderBy, order: order });
      this.getSort(orderBy, order);

      history.push({
        search: `?sort[]=${orderBy},${order}`
      })

    }

    async getSort(orderBy, order) {
      const data = await fetchData('get', `/teams?sort[]=${orderBy},${order}`);
      this.setState({ loading: false, items: await data[0] });
    }

    render() {
        const { classes, lang } = this.props;
        const { items, page, rowsPerPage, total, order, orderBy } = this.state;
        return (
          <div className={classes.root}>
            {this.state.loading && <Loader />}
            <Grid container spacing={24} alignItems="center">
              <Grid item xs={6}>
                <Typography gutterBottom variant="h2" component="h2">
                  {I18n[lang].team.teams}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <Link to={`/team/create/`}>
                  <Button variant="contained" className="btn-create" color="primary">
                    <Add />
                    {I18n[lang].team.create}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <form method="get" action="" onSubmit={this.handleSubmit}>
              <TextField
                id="outlined-name"
                label={I18n[lang].team.search}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="search"
                value={this.state.searchQuery}
                onChange={this.updateInputValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.handleSubmit} aria-label={I18n[lang].team.search}>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {tableTitles.map((item, index) => {
                      return (
                        <TableCell key={index}>
                          <TableSortLabel
                            active={orderBy === item.id}
                            direction={order}
                            onClick={this.handleSort(item.id)}
                          >
                            {I18n[lang].common[item.id]}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          {row.members.map((member) => member.login).join(', ')}
                        </TableCell>
                        <TableCell className="actions">
                          <Link to={`/team/view/${row.id}`}>
                            <RemoveRedEye />
                            <span>{I18n[lang].common.view}</span>
                          </Link>
                          <Link to={`/team/edit/${row.id}`}>
                            <Edit />
                            <span>{I18n[lang].common.edit}</span>
                          </Link>
                          <Button onClick={() => this.deleteItem(row.id)}>
                            <DeleteForever />
                            {I18n[lang].common.delete}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                className={classes.pagination}
                labelDisplayedRows={({ from, to, count }) =>
                  `Showing ${from} - ${to} of ${count} items`
                }
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page}
                onChangePage={this.handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </Paper>

            <Route path="/team/view/:id" render={this.viewItem} />
            <Route path="/team/edit/:id" render={this.editItem} />
            <Route path="/team/create" render={this.editItem} />
          </div>
        );
    }
}

export default withRoot(withStyles(styles)(Team));

import { MapLayer, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
import fetchData from '../../utils/fetch';

const colors = ["#f44236", "#ea1e63", "#9c28b1", "#673bb7", "#3f51b5", "#2196f3", "#69c5ef", "#04adc3", "#009788", "#3fc244", "#99c24a", "#d8e64b", "#fae421", "#fec107", "#ff9700", "#b7a1ff", "#fb6231", "#9e9e9e", "#607d8b", "#34e2d3", "#c1114d", "#4d0759", "#1833cb", "#8449ae", "#357f38", "#d5c963", "#d48006", "#fe5722", "#327799"]
class OblastLayer extends MapLayer {

  state = {
    areasData: null
  }

  createLeafletElement() {
    return null;
  }

  async getAreas(){
    let data = await fetchData('get', '/map/regions')
    this.setState({areasData: data[0] ?? []}, ()=>{
      this.loadMapLayer()
    })
  }

  componentDidMount() {
    this.getAreas()
  }

  onEachFeature(feature, layer) {
    const props = feature.properties
    layer._leaflet_id = 1000000 + props.id
    layer.bindTooltip(props.en)
    layer.on('mouseover', function (_) {
      layer.setStyle({ weight: 3 });
    })
    layer.on('click', () => {
      // this.props.zoomToDetailedView(feature.properties.visualCenterLatLong)
    })
    layer.on("mouseout", function (e) {
      layer.setStyle({ weight: 0.5 });
    });
    layer.on('popupclose', (e)=> {
      layer.closeTooltip()
    })
  }

  componentWillUnmount(){
    if (this.overLayer) { this.props.leaflet.map.removeLayer(this.overLayer); }
  }

  loadMapLayer() {
    const map = this.props.leaflet.map
    const areasData = this.state.areasData
    if (areasData){
      const legendData = []
      this.overLayer = L.geoJson(areasData, {
        onEachFeature: this.onEachFeature.bind(this),
        style: function(feature){
          const areaId = areasData.features.indexOf(feature) + 1;
          const color = colors[areaId % colors.length]
          legendData.push([color, feature.properties.en])
          return {
            stroke: true,
            color: '#999999',
            weight: 0.5,
            fillColor: color,
            fillOpacity: 0.5
          }
        }
      }).addTo(map)

      const legendTitle = 'Regions'
      this.props.onLoad({ legendTitle, legendData, areasData, baseLayer: this.overLayer })
    }
  }
}

export default withLeaflet(OblastLayer)

import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  checkbox: {
    "& .checkbox": {
      width: "20px",
      height: "20px",
      borderRadius: "5px",
      backgroundColor: "#E1EDFB",
      "&--checked": {
        backgroundImage: "url(/images/icons/icon-check-mark.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      },
    },
  },
});

export const GIZCheckbox = ({ classes, label, checked, onChange }) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          className={classes.checkbox}
          checked={checked}
          onChange={onChange}
          icon={<div className="checkbox"></div>}
          checkedIcon={<div className="checkbox checkbox--checked"></div>}
        />
      }
    />
  );
};

export default withStyles(styles)(GIZCheckbox);

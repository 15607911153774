import BaseAppBar from '@material-ui/core/AppBar';
import { Toolbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  toolbar: {
    justifyContent: 'space-between'
  }
};

function AppBar(props) {
  const { classes, children } = props;

  return (
    <BaseAppBar position="static" color="default">
      <Toolbar className={classes.toolbar}>
        {children}
      </Toolbar>
    </BaseAppBar>
  );
}

export default withStyles(styles)(AppBar);

export const VertexTypes = {
  Activity: "activity",
  Organization: "organization",
};

export class Vertex {
  constructor({ id, type, position }) {
    this.id = id;
    this.type = type;
    this.position = position;
  }

  get mapId() {
    return `${this.type}-${this.id}`;
  }

  get isActivity() {
    return this.type === VertexTypes.Activity;
  }

  get isOrganization() {
    return this.type === VertexTypes.Organization;
  }
}

import BaseTable from '@material-ui/core/Table';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    overflowX: 'auto',
    '& th': {
      textTransform: 'uppercase'
    },
    '@media screen and (max-width: 600px)': {
      '& table thead': {
        border: 'none',
        clip: 'rect(0 0 0 0)',
        height: '1px',
        width: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: 0,
        position: 'absolute'
      },
      '& table tr': {
        display: 'block',
        marginBottom: '20px'
      },
      '& table td': {
        display: 'block',
        textAlign: 'right'
      },
      '& table td::before': {
        content: 'attr(data-label)',
        float: 'left',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }
    }
  } 
});

function Table(props) {
  const { classes, rows } = props;
  const columns = props.columns.filter(({ when }) => !when || (when && when()));

  return (
    <div className={classes.root}> 
      <BaseTable>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>{column.heading}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column, index) => (
                <TableCell 
                  key={index}
                  data-label={column.heading}
                >
                  {column.render(row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </BaseTable>
    </div>
  );
}

export default withStyles(styles)(Table);

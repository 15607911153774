import { config } from "./config";

const styles = (theme) => ({
  root: {
    textAlign: "left",
    backgroundColor: "#f7f9fb",

    "& .btnIndex": {
      display: "inline-block",
      width: "45px",
      height: "36px",
      lineHeight: "36px",
      marginRight: "12px",
      position: "relative",
      background: "#9BD9C8 no-repeat center center / 50px 50px",
      borderRadius: config.input.borderRadius,

      "&.btn-download": {
        fontSize: "14px",
        width: "auto",

        color: "#ffffff",
        background: config.colors.primary,
        padding: "0 16px 0 60px",
        letterSpacing: 0.3,
        fontWeight: "400",
        "&:hover": {
          opacity: 0.9,
        },

        "& i": {
          width: "60px",
          height: "100%",
          display: "inline-block",
          background:
            "url(/images/icons/icon-download.svg) no-repeat center center",
          position: "absolute",
          left: "0",
          top: "0",
        },
      },
    },
    "& div": {
      boxShadow: "none",
    },
    "& .block-measurement .btn .btn-word": {
      background: "#958DAE no-repeat center center !important",
    },
    "& .actions a i": {
      marginRight: "0 !important",
    },
    "& .actions a": {
      width: "52px !important",
      height: "42px !important",
      alignItems: "center !important",
      justifyContent: "center !important",
    },
  },
  controllButtons: {
    marginTop: "24px!important",
  },

  paper: {
    height: "100%",
    padding: "10px 20px",

    boxShadow: "none!important",
    borderRadius: "10px!important",
    "& ul": {
      color: config.input.color,
      fontFamily: config.fontFamily,
    },
    "& h2": {
      margin: "-4px -1px 5px !important",
    },
    "& h3": {
      marginLeft: 0,
    },

    "& div fieldset:hover": {
      borderColor: "rgba(0, 0, 0, 0) !important",
    },
    "& div fieldset": {
      borderColor: "rgba(0, 0, 0, 0) !important",
    },
  },

  title: {
    "& div h2": {
      fontSize: "5rem",
      marginBottom: "2rem",
      marginLeft: "28px",
      [theme.breakpoints.down(1440)]: {
        fontSize: "3.7rem",
      },
    },
  },

  buttonGrey: {
    backgroundColor: "#789",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#789",
      opacity: "0.9",
    },
    "& span svg": {
      width: "20px",
    },
    "& i": {
      display: "inline-block",
      width: "16px",
      height: "16px",
      marginRight: "10px",
      background: "url(/images/icons/print-icon.png) no-repeat 0 0 / 16px 16px",
    },
  },

  // general layouts

  buttonDialogHeader: {
    marginRight: 30,
  },

  indexHead: {
    textAlign: "center",
    color: "#88adf0",
    margin: "5px 0",
    letterSpacing: "0",
    fontSize: "36pt",
  },

  indexTable: {
    maxWidth: "100%",
    minWidth: "1280px",
    textAlign: "center",

    "& thead tr th": {
      padding: "4px 15px",
      whiteSpace: "nowrap",
      fontSize: "22px",
      [theme.breakpoints.down(config.breakpoints.small)]: {
        fontSize: "16px!important",
        padding: "4px!important",
      },
      color: config.colors.secondary,
      fontWeight: "300",
      [theme.breakpoints.down(1700)]: {
        paddingRight: "20px",
        fontSize: "16pt",
      },

      "& span:hover": {
        color: "#3366cc !important",
      },
      "& span:focus": {
        color: "#3366cc !important",
      },
      "& span:active": {
        color: "#3366cc !important",
      },
    },
    "& tbody tr": {
      verticalAlign: "top",
    },
    "& tbody tr td": {
      padding: "15px",

      fontSize: config.label.fontSize,
      [theme.breakpoints.down(config.breakpoints.small)]: {
        fontSize: config.small.label.fontSize,
        padding: "4px!important",
      },

      color: config.colors.secondary,

      "&.actions": {
        textAlign: "right",
        "& a": {
          width: "52px",
          height: "42px",
          alignItems: "center",
          justifyContent: "center",
          "& i": {
            marginRight: "0",
          },
        },
      },

      "& a": {
        display: "inline-block",
      },
    },
    "& thead tr th:first-child, tbody tr td:first-child": {
      paddingLeft: "28px!important",
    },

    "& thead tr th:last-child, tbody tr td:last-child": {
      paddingRight: "26px!important",
    },
    "& a": {
      color: config.colors.secondary,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },

  buttonRemoveSmall: {
    padding: "7px 11px!important",
    marginRight: "0!important",
    borderRadius: "6px!important",
  },

  inlineTable: {
    marginLeft: "24px",
    width: "-webkit-fill-available",
    textAlign: "center",
    "& td,& td a": {
      fontSize: config.label.fontSize,
      color: "#191970",
    },
    "& td a": {
      cursor: "pointer",
    },
    "& thead tr": {
      height: "32px",
    },
    "& thead tr th": {
      paddingBottom: "0!important",

      color: "#6495ed",
      fontSize: "22px",

      padding: "4px 15px",
      whiteSpace: "nowrap",
      [theme.breakpoints.down(config.breakpoints.small)]: {
        fontSize: "16px!important",
        padding: "4px!important",
      },
      fontWeight: "300",
      [theme.breakpoints.down(1700)]: {
        paddingRight: "20px",
        fontSize: "16pt",
      },
    },

    "& tbody tr td": {
      padding: "15px",

      fontSize: config.label.fontSize,
      [theme.breakpoints.down(config.breakpoints.small)]: {
        fontSize: config.small.label.fontSize,
        padding: "4px!important",
      },

      color: "#212161",
    },
  },

  evenCell: {
    color: "#778899!important",
  },
  centerCell: {
    textAlign: "center!important",
  },

  singleViewAction: {
    borderRadius: "0.5rem!important",
  },
  actions: {
    whiteSpace: "nowrap",
    "& a": {
      display: "inline-flex",
      color: "#fff",
      fontSize: "12px",
      lineHeight: "25px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#6495ed",
      padding: "12px 0",
      width: "auto",
      minWidth: "96px",
      "&:first-child": {
        backgroundColor: "#add8e6!important",
        borderRadius: "0.5rem 0 0 0.5rem",
      },
      "&:last-child": {
        backgroundColor: "#fe8368",
        borderRadius: "0 0.5rem 0.5rem 0",
      },
    },
    "& svg": {
      width: "15px",
      height: "15px",
      marginRight: "2px",
    },
  },

  tableWrapper: {
    overflow: "hidden",
    width: "100%",
    overflowX: "auto",
  },
  pagination: {
    marginTop: 20,
    color: "#BDBEC0",
    "& div:first-child": {
      display: "flex",
      "flex-direction": "column",
      "align-items": "center",
    },
  },
  alignRight: {
    textAlign: "right",
  },

  indexCreate: {
    textAlign: "right",
    "& a": {
      paddingRight: "2.3vw",
    },
    "& a button.btn-create": {
      boxShadow: "none",
      padding: "10px 15px",
      borderRadius: "2rem",
      marginRight: "0",
      "& svg": {
        width: "1.2em",
        height: "1.2em",
        marginRight: "5px",
      },
    },
  },

  error: {
    color: config.error.color,
  },

  // general editors
  //error on radios
  groupError: {
    "& label span:first-child": {
      "& svg": {
        fill: config.error.color,
      },
    },
  },

  // select

  //calendar
  calendar: {
    minWidth: "145px",
    maxHeight: "55px",

    "& .transparent input": {
      color: "transparent !important",
    },
  },
  dateField: {
    "& input": {
      padding: "7px",
    },

    '& input[type="date"]': {
      color: "#778899 !important",
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      color: "rgba(0, 0, 0, 0)",
      opacity: "1",
      display: "block",
      background:
        "#c80f0f url(/images/icons/calendar.svg) no-repeat center center",
      backgroundSize: "30px 30px",
      width: "35px",
      height: "35px",
      borderWidth: "thin",
      position: "absolute",
      right: "-15px",
      top: "-4px",
      borderRadius: "0 0.25rem 0.25rem 0",
    },

    '& input[type="date"]::placeholder': {
      color: "#778899 !important",
    },
    '& input[type="date"]::-webkit-datetime-edit-year-field': {
      color: "#778899 !important",
    },
    '& input[type="date"]::-webkit-datetime-edit-month-field': {
      color: "#778899 !important",
    },
    '& input[type="date"]::-webkit-datetime-edit-day-field': {
      color: "#778899 !important",
    },
    "& > div:before": {
      display: "none",
    },
    "& > div:after": {
      display: "none",
    },
    "& > div": {
      background: config.input.background,
    },
  },
  dataIcon: {
    color: "#6f6f6f",
    background: "#c80f0f",
    width: "30px",
    height: "30px",
    borderRadius: config.input.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "2",
    position: "relative",
    cursor: "pointer",
    "& img.date-icon": {
      fill: "#e6feff",
      width: "20px",
    },
    "&img mg.date-icon:hover": {
      fill: "#e0f3fe",
    },
  },

  errorField: {
    borderLeft: "7px solid #c80f0f",
    borderTopLeftRadius: config.input.borderRadius,
    borderBottomLeftRadius: config.input.borderRadius,

    "& input, fieldset": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },

  //for URLS in activities view
  formButton: {
    fontSize: "0.8rem",
    fontWeight: "700",
    color: "#fff",
    backgroundColor: "#c80f0f",
    display: "inline-block",
    marginBottom: "0",
    padding: "5px 10px",
    borderRadius: config.input.borderRadius,
    cursor: "pointer",
    border: "none",
    float: "right",
    fontFamily: config.input.fontFamily,
  },
  mediaReference: {},

  groupSelect: {
    "& .groupStyles": {
      color: "#fff",

      fontSize: config.input.fontSize,
    },
  },

  // grouping for radios
  group: {
    "&:hover": {
      boxShadow: "none !important",
    },
    "& label span:first-child": {
      padding: "5px 0px 5px 5px",
      "& svg": {
        height: "15px",
      },
    },
    "& label span:last-child": {
      color: "#191970",
      fontSize: config.input.fontSize,
      fontWeight: "400",
      letterSpacing: "0.5px",
    },
  },

  inputFile: {
    "& label": {
      display: "block",
      marginBottom: "10px",
    },
    "& input": {
      display: "block",
      width: "0.1px",
      height: "0.1px",
      opacity: "0",
      overflow: "hidden",
      zIndex: "-1",
    },
    "& input + label": {
      fontSize: "0.8rem",
      fontWeight: "700",
      color: "#fff",
      backgroundColor: "#c80f0f",
      display: "inline-block",
      marginBottom: "0",
      padding: "5px 10px!important",
      borderRadius: config.input.borderRadius,
      cursor: "pointer",
      border: "none",
      fontFamily: config.input.fontFamily,

      width: "120px",
      textAlign: "center",
    },
    "& span": {
      marginLeft: "5px",
      fontSize: "10px",
      color: "gray",
      fontWeight: "700",
    },
    "& p": {
      marginTop: "4px",
      fontSize: "10px",
      color: "gray",
      fontWeight: "700",
      width: "120px",
      textAlign: "center",
    },
  },

  modal: {
    overflowX: "hidden",
    backgroundColor: "#f7f9fb",
    boxShadow: theme.shadows[5],
    padding: "25px",
    width: "94%",
    height: "82vh !important",
    overflowY: "auto",
    borderRadius: "10px",
    "&:focus": {
      outline: "0",
    },
    [theme.breakpoints.up(1440)]: {
      height: "75vh",
    },
    "& .close": {
      marginTop: "-41px",
      top: "35px",
      color: "#b4c3d1",
      cursor: "pointer",
      position: "relative",
      float: "right",
    },
  },

  // button inside textbox
  checkBtn: {
    height: "30px",
    width: "160px",
    position: "relative",
    left: "18px",
    borderRadius: "0 10px 10px 0",
    fontFamily: "Roboto, sans-serif",
  },

  h84: {
    height: "65px",
    padding: "0 12px !important",
  },

  map: {
    height: "300px",
    "& .leaflet-control-geosearch.bar": {
      width: "90%",
    },
    "& .leaflet-top .leaflet-control": {
      marginTop: "225px",
    },
    "&.error-field": {
      border: "1px solid #fe8368 !important",
      borderLeftWidth: "5px !important",
    },
  },

  listItem: {
    display: "flex",
    alignItems: "flex-start",
    "& > div": {
      flexGrow: 1,
    },
    "& > a": {
      whiteSpace: "nowrap",
      color: "6495ed",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },

  phoneCell: {
    marginBottom: "6px",
    "& span": {
      "&:first-child": {
        color: "#77d2b7",
        paddingTop: "12px",
        paddingBottom: 0,
      },
      "&:last-child": {
        marginTop: "24px",
        marginBottom: "0px",
        lineHeight: "17px",
        color: config.label.color,
        fontSize: config.label.fontSize,
        fontWeight: config.label.fontWeight,
        letterSpacing: config.label.letterSpacing,
      },
    },
  },

  checkboxLabel: {
    "& span": {
      "&:first-child": {
        color: "#77d2b7",
      },
      "&:last-child": {
        color: config.label.color,
        fontSize: config.label.fontSize,
        fontWeight: config.label.fontWeight,
        letterSpacing: config.label.letterSpacing,
      },
    },
  },

  // search on index pages

  searchActivity: {
    // width: "37%",
    background: "#eef2f6",
    marginBottom: "20px",
    marginTop: "0",
    "& >div": {
      padding: "0",
      "& div": {
        height: "100%",
        maxHeight: "100%",
        position: "absolute",
        right: "0",
      },
    },
    "& label": {
      top: "-12px",
      fontWeight: 100,
      color: "#778899 !important",
      fontSize: "20pt",
    },

    "& fieldset": {
      border: "none",
    },

    marginLeft: "2.3vw",
    borderRadius: "0.5rem",
    fontWeight: "100",

    "& input": {
      background: "#eef2f6",

      fontWeight: 100,

      padding: "10px 14px",
      fontSize: "12pt",
    },
  },

  deleteSearch: {
    "& a": {
      color: "#9daab7",
      "& svg": {
        width: "1.5em",
        height: "1.5em",
        marginRight: "5px",
        background: "url(/images/trash.svg) no-repeat center center",
        backgroundSize: "80% 80%",
        "& path": {
          fill: "transparent",
        },
        [theme.breakpoints.down(1700)]: {
          width: "1em",
          height: "1em",
        },
      },
    },
  },
  btnSearch: {
    borderRadius: "0 0.5rem 0.5rem 0",
    background: "#c80f0f",
    padding: "5px 10px",
    fontSize: "13px",
    color: "#ffffff",
    height: "100%",

    "& path": {
      fill: "transparent",
    },

    "&:hover": {
      background: "#c80f0f",
      opacity: "0.9",
    },
    "& svg": {
      width: "1em",
      height: "1em",
      background: "url(/images/loupe.svg) no-repeat center center",
      backgroundSize: "250% 250%",
    },
  },

  // for communities view
  // indicator charts
  indicatorsCircle: {
    marginTop: "5px",
    height: "10px",
    width: "10px",
    backgroundColor: "#c9cfd6",
    borderRadius: "50%",
    display: "inline-block",
  },
  indicatorsCircleYellow: {
    backgroundColor: config.yellow,
  },
  indicatorsCircleRed: {
    backgroundColor: config.red,
  },

  detailTable: {
    "& thead tr th span": {
      color: "#88adf0",
      fontSize: "16px",
      fontFamily: "Roboto, sans-serif",

      fontWeight: 400,
      letterSpacing: "0.7px",
    },
    "& tbody tr td": {
      padding: "10px!important",
    },
  },

  activityTable: {
    "& thead tr th:first-child": {
      paddingLeft: 17,
      width: "77px",
    },
    "& thead tr th:nth-child(2)": {
      width: "100px",
    },

    "& thead tr th:nth-child(4)": {
      width: "150px",
    },
    "& thead tr": {
      height: "32px",
      verticalAlign: "bottom",
    },
    "& thead tr th": {
      padding: "0 15px 0 10px",
    },
    "& tbody tr td:first-child": {
      padding: "10px 15px",
    },
    "& tbody tr td:nth-child(2)": {
      width: "115px",
    },
    "& thead tr th:nth-child(3)": {
      width: "28.5%",
    },
    "& tbody tr td:nth-child(4)": {
      width: "150px",
    },
    "& thead tr th:nth-child(5)": {
      width: "17%",
    },
    "& thead tr th:last-child": {
      width: "20%",
    },
    "& tbody tr": {
      height: "54px",
      verticalAlign: "top",
    },
  },
  "& div.participants-table": {
    "& thead tr th:first-child": {
      width: "230px",
    },
    "& thead tr th:nth-child(2)": {
      width: "150px",
    },
    "& thead tr th:nth-child(3)": {
      width: "20%",
    },
    "& thead tr th:nth-child(4)": {
      width: "auto",
    },
    "& tbody tr td:first-child": {
      width: "230px",
      padding: "0 0 0 17px",
    },
    "& tbody tr td:nth-child(2)": {
      width: "150px",
    },
    "& thead tr td:nth-child(3)": {
      width: "20%",
    },
    "& tbody tr td:nth-child(4)": {
      width: "auto",
    },
  },
  wauto: {
    width: "auto !important",
  },
  rightSpace: {
    marginRight: "24px",
  },
  indexPageButton: {
    fontSize: "14px!important",
    borderRadius: config.input.borderRadius,
    marginTop: "-2px",
  },

  adjustTwoRight: {
    marginRight: "2px",
  },

  // by setting xs=true on griditem with multiselects, we need to switch off flex-grow
  noFlexGrow: {
    flexGrow: "unset",
  },
  searchInField: {
    height: "auto",
    marginLeft: 0,
    "& button": {
      paddingTop: "3px",
      paddingBottom: "3px",
    },
  },
  labelInGrid: {
    marginTop: "4px",
  },
  noCapitalize: {
    textTransform: "none!important",
  },
  infoMap: {
    "& a": {
      borderBottom: "1px solid #6495ed",
      fontFamily: config.fontFamily,
      color: config.label.color,
      fontWeight: config.label.fontWeight,
      fontSize: config.label.fontSize,
      [theme.breakpoints.down(1440)]: {
        fontSize: config.small.label.fontSize,
        fontWeight: config.small.label.fontWeight,
      },
    },
  },
  indexCreateLink: {
    paddingRight: "24px!important",
  },
});

export default styles;

import React from 'react';

const NotificationsContext = React.createContext({
  error: () => {},
  info: () => {},
  success: () => {},
  warning: () => {}
});

export default NotificationsContext;

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Form as FinalForm, Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';

const styles = {
  bottomBar: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

const required = (value) => (value ? undefined : 'Required');

function Form(props) {
  const { onSubmit, values, classes } = props;

  return (
    <FinalForm 
      onSubmit={onSubmit} 
      initialValues={values}
    >
      {({ 
        handleSubmit, 
        submitting, 
        submitSucceded,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Field name="name" validate={required}>
                {({ input, meta }) => (
                  <TextField 
                    label="Name"
                    fullWidth
                    {...input}
                    error={meta.error && meta.touched}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} className={classes.bottomBar}>
              <Button 
                color="primary" 
                type="submit" 
                disabled={submitting} 
                variant="outlined"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </FinalForm>
  );
}

export default withStyles(styles)(Form);

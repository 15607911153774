import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Map, TileLayer, Marker, Polyline } from "react-leaflet";
import classNames from "classnames";
import { styles } from "./styles";
import Legend from "./Legend";
import { markerActivityIcon, markerOrganizationIcon } from "./markers";
import { VertexPopup } from "./VertexPopup";
import { EdgePopup } from "./EdgePopup";
import Loader from "../../components/loader";
import GIZCheckbox from "../../components/GIZCheckbox";
import GIZDatePicker from "../../components/GIZDatePicker";
import notify from "../../utils/notifier";
import { useNetworkMapStore } from "../../stores/network-map";
import {GisData} from "../../config";

const center = GisData.MappingTool.center;
const defaultZoom = GisData.MappingTool.initialZoom;

const NetworkMap = ({ classes }) => {
  const store = useNetworkMapStore();

  const handleDateFrom = (from) => {
    store.setDateFrom(from);
  };
  const handleDateTo = (to) => {
    store.setDateTo(to);
  };

  const [polylineWeight, setPolylineWeight] = useState(1);
  const handleViewportChange = (vp) => {
    let weight = 1;
    if (vp.zoom > 8) {
      weight = 3;
    } else if (vp.zoom >= 7) {
      weight = 2;
    }
    setPolylineWeight(weight);
  };

  useEffect(() => {
    async function getData() {
      try {
        await store.getMapData();
      } catch (ex) {
        notify("Loading error. Try to reload page.");
      }
    }
    getData();
  }, [store, store.dateFrom, store.dateTo]);

  return (
    <>
      {store.isLoading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      <Grid
        container
        spacing={24}
        className={classNames("p0", "df", "hidden", classes.root)}
      >
        <Grid item xs={12} className={classes.controls}>
          <GIZDatePicker
            name="from"
            value={store.dateFrom}
            onChange={handleDateFrom}
          />
          <GIZDatePicker
            name="to"
            value={store.dateTo}
            onChange={handleDateTo}
          />
          <GIZCheckbox
            label="Activities"
            checked={store.showActivities}
            onChange={(e, checked) => store.setShowActivities(checked)}
          />
          <GIZCheckbox
            label="Organizations"
            checked={store.showOrganizations}
            onChange={(e, checked) => store.setShowOrganizations(checked)}
          />
        </Grid>
        <Grid item xs={12} className="p0">
          <Map
            className={classes.map}
            center={center}
            zoom={defaultZoom}
            minZoom={3}
            maxZoom={13}
            zoomSnap={0.5}
            zoomDelta={0.5}
            smoothZoom={true}
            smoothZoomDelay={1000}
            onViewportChanged={handleViewportChange}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://api.mapbox.com/styles/v1/ocode/cjxp8ay36363q1cnyq3eams8z/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib2NvZGUiLCJhIjoiY2p4cDZ4ZGIxMGRsbDNvcm5zdXlzOGlxNSJ9.zV4zT_qbBRzAoXzvzbvp3A"
            />
            <Legend />
            {store.vertexes.map((item) => (
              <Marker
                key={item.mapId}
                position={item.position}
                icon={
                  item.isOrganization
                    ? markerOrganizationIcon
                    : markerActivityIcon
                }
              >
                <VertexPopup vertex={item} />
              </Marker>
            ))}
            {store.edges.map((item) => (
              <Polyline
                key={item.mapId}
                weight={polylineWeight}
                color="#ccc"
                positions={item.positions}
              >
                <EdgePopup edge={item} />
              </Polyline>
            ))}
          </Map>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(observer(NetworkMap));

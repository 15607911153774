import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { I18n } from "../../i18n/";

class ConfirmationDialog extends React.Component {
  handleCancel = () => {
    this.props.onClose(false);
  }

  handleOk = () => {
    this.props.onClose(true);
  }

  render() {
    const { lang } = this.props;
    console.log(lang);
    const i18n = I18n[lang];
    const t = i18n.dialogs.deleteConfirmation;

    return (
      <Dialog
        open={this.props.open}
      >
        <DialogTitle>{t.title}</DialogTitle>
        <DialogContent>{t.content}</DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>{t.no}</Button>
          <Button onClick={this.handleOk}>{t.yes}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class DeleteButton extends React.Component {
  state = {
    showConfirmationDialog: false
  }

  constructor(props) {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({showConfirmationDialog: true});
  }

  handleClose = (confirmed) => {
    this.setState({showConfirmationDialog: false});

    if (confirmed) {
      this.props.onDelete(this.props.id);
    }
  }

  render() {
    const { lang } = this.props;
    return (
      <>
        <Button
          className="delete"
          onClick={this.handleClick}
        ></Button>
        <ConfirmationDialog 
          open={this.state.showConfirmationDialog} 
          onClose={this.handleClose}
          lang={lang}
        />
      </>
    );
  }
}

export default DeleteButton;

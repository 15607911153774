import React from 'react';
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Typography
} from '@material-ui/core';
import { Add, Search, Edit, RemoveRedEye, DeleteForever } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import fetchData from '../../utils/fetch';
import Loader from '../../components/loader';
import { Link, Route } from 'react-router-dom';
import { find } from 'lodash';
import ItemView from './item';
import ItemEdit from './item-edit';
import 'url-search-params-polyfill';
import history from '../../utils/history';
import { I18n } from '../../i18n/';
import TablePaginationActions from '../../components/pagination/CustomPagination';


const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '94%',
    margin: '30px 3%',
  },
  pagination: {
    marginTop: 20,
    color: '#BDBEC0',
    '& div:first-child': {
      display: 'flex',
      'flex-direction': 'column',
      'align-items': 'center',
    }
  },
  paper: {
    overflowX: 'auto',
  },
  table: {
    maxWidth: '100%',
    minWidth: 700,
    textAlign: 'center',
    '& tbody tr': {
      verticalAlign: 'top'
    },
    '& tbody td': {
      paddingTop: '10px',
      fontSize: '1rem',
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  },
  active: {
    color: '#fff',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'inline-block',
    borderRadius: '3px',
    padding: '3px 10px',
    background: '#77d2b7'
  },
  inactive: {
    color: '#fff',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'inline-block',
    borderRadius: '3px',
    padding: '3px 10px',
    background: '#fe8368'
  },
  label: {
    display: 'inline-block',
    background: '#add8e6',
    color: '#fff',
    borderRadius: '2px',
    padding: '0 5px',
    fontWeight: 'bold',
    fontSize: '10px'
  },
  actions: {
    whiteSpace: 'nowrap',
    '& a': {
      display: 'inline-flex',
      color: '#fff',
      fontSize: '12px',
      lineHeight: '25px',
      alignItems: 'center',
      backgroundColor: '#6495ed',
      padding: '0 8px',
      '&:first-child': {
        backgroundColor: '#add8e6',
        borderRadius: '3px 0 0 3px'
      },
      '&:last-child': {
        backgroundColor: '#fe8368',
        borderRadius: '0 3px 3px 0'
      }
    },
    '& svg': {
      width: '15px',
      height: '15px',
      marginRight: '2px'
    }
  }
});

const tableTitles = [
  {id: 'id', title: 'ID'},
  {id: 'login', title: 'Login'},
  {id: 'email', title: 'Email'},
  {id: 'authorities', title: 'Profiles'},
  {id: 'created_date', title: 'Created Date'},
  {id: 'last_modified_by', title: 'Modified By'},
  {id: 'last_modified_date', title: 'Modified Date'}
];

class User extends React.Component {
    state = {
      loading: true,
      items: [],
      page: 0,
      total: 0,
      rowsPerPage: 25,
      searchQuery: '',
      order: 'desc',
      orderBy: ''
    };
    componentDidMount() {
      const query = new URLSearchParams(document.location.search);
      const search = query.get('search');
      if (search) {
        this.setState({ searchQuery: search });
        this.getSearchResults(search);
      } else {
        this.getData();
      }
    }

    async getData() {
      const data = await fetchData('get', '/users?all=1');
      this.setState({ loading: false, items: data[0], total: data[1] });
    }

    async deleteItem(id) {
      const {lang} = this.props;
      if (window.confirm(`${I18n[lang].common.deleteConfirm} ${I18n[lang].user.user} ${id}?`)) {
        await fetchData('delete', `/users/${id}`);
        this.getData();
      }
    }

    handleChangePage = (event, page) => {
      let getParams = '?all=1&';
      if (this.state.orderBy !== '') {
        getParams += `sort[]=${this.state.orderBy},${this.state.order}&`
      }
      getParams += `page=${page + 1}`;
      this.getPage(getParams);
      this.setState({ loading: true, page: page });
      history.push({
        search: getParams
      })
    }

    async getPage(getParams) {
      const data = await fetchData('get', `/users${getParams}`);
      this.setState({ loading: false, items: data[0] });
    }

    viewItem = ({ match: { params: { id } } }) => {
      if (this.state.loading) return null;
      const item = find(this.state.items, { id: Number(id) });

      return <ItemView item={item} lang={this.props.lang} />;
    }

    editItem = ({ match: { params: { id } } }) => {
      if (this.state.loading) return null;
      const item = find(this.state.items, { id: Number(id) });

      return <ItemEdit item={item} handleItemSave={this.handleItemSave} lang={this.props.lang} />;
    }

    handleItemSave = () => {
      this.setState({ loading: true });
      this.getData();
    }

    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    }

    async getSearchResults(searchQuery) {
      if (searchQuery.length){
        const data = await fetchData('get', `/_search/users?all=1&query=${searchQuery}`)
        this.setState({ loading: false, items: data[0], total: data[1] });
      }else{
        this.getData();
      }
    }

    updateInputValue = e => this.setState({ searchQuery: e.target.value });

    handleSubmit = (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      const search = this.state.searchQuery;
      this.getSearchResults(search);

      history.push({
        search: `?search=${this.state.searchQuery}`
      })
    }

    handleSort = orderBy => event => {
      let order = 'desc';
      if (this.state.orderBy === orderBy && this.state.order === 'desc') {
        order = 'asc';
      }
      this.setState({ loading: true, orderBy: orderBy, order: order });
      this.getSort(orderBy, order);

      history.push({
        search: `?sort[]=${orderBy},${order}`
      })

    }

    async getSort(orderBy, order) {
      const data = await fetchData('get', `/users?all=1&sort[]=${orderBy},${order}`);
      this.setState({ loading: false, items: await data[0] });
    }

    render() {
        const { classes, lang } = this.props;
        const { items, page, rowsPerPage, total, order, orderBy } = this.state;
        return (
          <div className={classes.root}>
            {this.state.loading && (
              <Loader />
            )}
            <Grid container spacing={24} alignItems='center'>
              <Grid item xs={6}>
                <Typography gutterBottom variant="h2" component="h2">
                  {I18n[lang].user.users}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <Link component={Button} to={`/user/create/`}>
                  <Button variant="contained" className="btn-create" color="primary">
                    <Add/>
                    {I18n[lang].user.create}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <form method="get" action="" onSubmit={this.handleSubmit}>
              <TextField
                id="outlined-name"
                label={I18n[lang].user.search}
                className={classes.textField}
                margin="normal"
                value={this.state.searchQuery}
                onChange={this.updateInputValue}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={I18n[lang].user.search}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {tableTitles.map( item => {
                      return(
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === item.id}
                            direction={order}
                            onClick={this.handleSort(item.id)}
                           >
                            {I18n[lang].user[item.id]}
                          </TableSortLabel>
                        </TableCell>
                      )
                    })}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items
                    .map(row => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.login}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        {false && (<TableCell>
                          {(row.activated) ? (
                            <div className={classes.active}>Activated</div>
                          ) : (
                            <div className={classes.inactive}>Deactivated</div>
                          )}
                        </TableCell>)}
                        <TableCell>
                          {row.authorities.map( role => {
                            return(<div className={classes.label}>{role}</div>)
                          })}
                        </TableCell>
                        <TableCell>{row.createdDate}</TableCell>
                        <TableCell>{row.lastModifiedBy}</TableCell>
                        <TableCell>{row.lastModifiedDate}</TableCell>
                        <TableCell className='actions'>
                          <Link to={`/user/view/${row.id}`}>
                            <RemoveRedEye/>
                            <span>{I18n[lang].common.view}</span>
                          </Link>
                          <Link to={`/user/edit/${row.id}`}>
                            <Edit/>
                            <span>{I18n[lang].common.edit}</span>
                          </Link>
                          {/* <a onClick={() => this.deleteItem(row.id)}> */}
                          {/*   <DeleteForever/> */}
                          {/*   <span>{I18n[lang].common.delete}</span> */}
                          {/* </a> */}
                          <Button onClick={() => this.deleteItem(row.id)}>
                            <DeleteForever/>
                            {I18n[lang].common.delete}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                className={classes.pagination}
                labelDisplayedRows={({ from, to, count }) =>
                  `Showing ${from} - ${to} of ${count} items`}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page}
                onChangePage={this.handleChangePage}
                ActionsComponent = {TablePaginationActions}
              />
            </Paper>
            <Route path="/user/view/:id" render={this.viewItem} />
            <Route path="/user/edit/:id" render={this.editItem} />
            <Route path="/user/create" render={this.editItem} />
          </div>
        );
    }
}

export default withRoot(withStyles(styles)(User));

import { Field, useForm } from 'react-final-form';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import { optional, atLeastOne } from './validators';

function MultipleChoiceAnswerField(props) {
  const { question } = props;

  const disablingSiblings = question.options.filter((option) => option.disableSiblings).map((option) => option.id.toString());

  function isDisabled(option, selected) {
    if (disablingSiblings.includes(option.id.toString())) {
      return false;
    }

    return disablingSiblings.some((o) => selected.includes(o)); 
  }

  const form = useForm();

  function disableSiblings(event, onChange) {
    onChange(event);

    if (event.target.checked) {
      form.getFieldState(`question${question.id}.optionId`).change([event.target.value]);
    }
  }

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">{question.name}</FormLabel>
      <FormGroup>
        <Field
          name={`question${question.id}.optionId`} 
          subscription={{ value: true }}
        >
          {({ input: { value } }) => {
            return question.options.map((option, index) => ( 
              <Field 
                key={option.id}
                name={`question${question.id}.optionId`} 
                type="checkbox" 
                validate={question.optional ? optional : atLeastOne}
                value={option.id.toString()}
              >
                {({ input, meta }) => 
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        {...input} 
                        checked={isDisabled(option, value) ? false : input.checked}
                        onChange={(event) => option.disableSiblings ? disableSiblings(event, input.onChange) : input.onChange(event)}
                      />
                    } 
                    label={option.value} 
                    disabled={isDisabled(option, value)}
                  />
                }
              </Field>
            ));
          }}
        </Field>
      </FormGroup>
    </FormControl>
  );
}

export default MultipleChoiceAnswerField;

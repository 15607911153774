import L from "leaflet";
import markerActivityImg from "./marker-activity.svg";
import markerOrganizationImg from "./marker-organization.svg";

export const markerActivityIcon = new L.Icon({
  iconUrl: markerActivityImg,
  iconSize: [10, 10],
});

export const markerOrganizationIcon = new L.Icon({
  iconUrl: markerOrganizationImg,
  iconSize: [10, 10],
});

import React from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Search, DeleteForever } from "@material-ui/icons";

import { I18n } from "../../i18n/";
import "url-search-params-polyfill";
import classNames from "classnames";
import styles from "../../style/styles";

const CustomSearch = ({
  placeholder,
  props,
  value,
  updateInputValue,
  submit,
  state,
  clearSearchField,
  clearIcon = true,
}) => {
  const { classes, lang } = props;
  const { search } = I18n[lang].common;

  const handleSubmit = (e) => {
    submit(e);
  };

  const handleChange = (e) => {
    updateInputValue(e);
  };

  return (
    <form method="get" action="" onSubmit={handleSubmit}>
      <TextField
        label={search}
        className={classNames(classes.searchActivity)}
        margin="normal"
        variant="outlined"
        placeholder={placeholder}
        value={value}
        autoComplete="on"
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={classes.deleteSearch}>
              {clearIcon &&
              state &&
              state.searchQuery &&
              state.searchQuery.trim() !== "" ? (
                <a
                  href
                  onClick={() => clearSearchField()}
                  style={{ cursor: "pointer" }}
                >
                  <DeleteForever />
                </a>
              ) : null}
              <IconButton
                onClick={handleSubmit}
                className={classes.btnSearch}
                aria-label={search}
              >
                <Search />
                <span>{search}</span>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default withStyles(styles)(CustomSearch);

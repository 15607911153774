import {
  Grid as BaseGrid, 
  Typography
} from '@material-ui/core/';

function Grid(props) {
  const { heading, children } = props;

  return (
    Array.isArray(children) && children.length > 1 && (
      <div>
        {heading && (
          <Typography variant="h5" gutterBottom>
            {heading}
          </Typography>
        )}
        <BaseGrid container spacing={16}>
          {children.map((Item) => (
            <BaseGrid item xs={12} sm={3}>
              {Item}
            </BaseGrid>
          ))}
        </BaseGrid>
      </div>
  ));
}

export default Grid;

import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { selectStyles } from '../../style/select-styles';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import {
  InputLabel,
  Typography
} from '@material-ui/core';
import { API } from '../../config.js';

export default class FormParticipantSelectField extends React.Component {

  constructor(props) {
    super(props);
    
    this.loadOptions = this.loadOptions.bind(this);
  }

  loadOptions(input) {
    const url = `${API}/suggest/participants?substr=${encodeURIComponent(input)}`;
    let headers = {
      accept: 'application/json',
      Authorization: localStorage.getItem('TOKEN'),
    };

    return fetch(url, {headers})
      .then((res) => res.json())
      .then((participants) => participants.map((p) => {
        const name = this.props.lang === 'Eng' ? p.nameTranscription : p.name;

        return {
          value: name, 
          label: name,
          object: p
        }
      }));
  }

  styles() {
    return {
      ...selectStyles,
      dropdownIndicator: (provided) => ({
        display: 'none'
      })
    };
  }

  render() {
    const {
      isEditable,
      fieldName,
      classes,
      label,
      value,
      isDisabled,
      tabIndex
    } = this.props;

    return isEditable ? (
      <Field name={fieldName}>
        {({ input, meta }) => (
          <>
            <InputLabel className={classNames(classes.label)}>
              {label}
            </InputLabel>
            <AsyncCreatableSelect
              cacheOptions={true}
              loadOptions={this.loadOptions}
              value={value}
              onChange={(e) => {
                this.props.action({ fieldName, value: e.object ? e.object : e.value });
              }}
              styles={this.styles()}
              isDisabled={isDisabled}
              inputProps={{tabIndex}}
              className={classNames({
                errorSelect: meta.invalid
              })}
              openMenuOnClick={false}
              helperText={meta.touched && meta.error ? meta.error : ''}
              placeholder=''
            />
          </>
        )}
      </Field>
    ) : (
      <div>
        <InputLabel>{label}</InputLabel>
        <Typography varian='body2'>{value?.label}</Typography>
      </div>
    );
  }
}

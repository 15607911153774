import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import markdown from 'support/drawdown';

const styles = {
  root: {
    '& h1, h2, h3, h4, h5, h6': {
      textAlign: 'center'
    }
  }
};

function Markdown(props) {
  const { classes, children } = props;
  return (
    <Typography 
      className={classes.root}
      variant="body1" 
      align="left"
      gutterBottom
      dangerouslySetInnerHTML={{ __html: markdown(children) }}
    />
  );
}

export default withStyles(styles)(Markdown);

import React from 'react';
import { 
  Link,
  useParams
} from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Wrapper from '../components/Page';
import DataSource from 'components/DataSource';
import Editor from './Editor';

function Questions (props) {
  const { surveyId } = useParams();

  const navigation = (
    <IconButton 
      component={Link} 
      to={`/surveys/${surveyId}/edit`}
    >
      <ArrowBack />
    </IconButton>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Wrapper navigation={navigation}>
        <DataSource path={`surveys/${surveyId}/questions`}>
          {(questions) => (
            <Editor items={questions} />
          )}
        </DataSource>
      </Wrapper>
    </DndProvider>
  );
}

export default Questions;

function Filter(props) {
  const { questions, answers, children } = props;
  const chosenOptions = Object.values(answers || {}).map((i) => i.optionId).filter((i) => i).flat();

  const filtered = questions.filter((question) => {
    return question.weight === 0 || 
      question.showWhenChosenOptionIds.length === 0 || 
      question.showWhenChosenOptionIds.some((value) => chosenOptions.includes(value.toString()));
  });

  return filtered.length ? children(filtered) : null;
}

export default Filter;

import { useContext } from 'react';
import DataSource from 'components/DataSourceWithLoadMore';
import AppBar from 'components/AppBarWithBackButton';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Fab,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { StoreContext } from 'stores/context';

const styles = {
  wrapper: {
    margin: '20px auto',
    maxWidth: '800px'
  }
};

function ListView(props) {
  const { classes } = props;
  const { userStore } = useContext(StoreContext);

  return (
    <>
      <AppBar>
        {userStore.name === 'Admin' && (
          <Fab 
            size="small" 
            color="primary"
            component={Link}
            to={`/videos/create`}
          >
            <AddIcon />
          </Fab>
        )}
      </AppBar>
      <DataSource path="videos">
        {(videos) => (
          <Grid 
            container 
            className={classes.wrapper}
            spacing={16}
          >

            {videos.map((video, index) => (
              <Grid key={index} item md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h5">
                      {video.title}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      variant="outlined" 
                      color="primary"
                      component={Link}
                      to={`/v/${video.slug}`}
                    >
                      Watch
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </DataSource>
    </>
  );
}

export default withStyles(styles)(ListView);

import ItemService from "./ItemService";
import { each } from "lodash";
import { I18n } from "../i18n";
import {
  ACTIVITY_ENDPOINT,
  CHARACTER_ENDPOINT,
  CHARACTERISTIC_ENDPOINT,
  CATEGORY_GROUP_ENDPOINT,
  CATEGORY_ENDPOINT,
  ORGANIZATION_ENDPOINT,
} from "../utils/DataFetcher";
import { activityRoute } from "../utils/routes";
import dayjs from 'dayjs';
import {GisData} from "../config";

class ActivityService extends ItemService {
  constructor(onUpdateState, lang) {
    super(ACTIVITY_ENDPOINT, onUpdateState, lang);

    this.idFirstSteps = 1;
  }

  async GetTrainingPackageTypes(restrictCTP, selected) {
    const data = await this.GetItems("training_package_types?size=1000");

    const ctpId = 2;
    const result = [];
    each(data, (tsp) => {
      if (tsp.id === ctpId && restrictCTP && !selected) {
        return;
      }
      result.push({
        ...tsp,
        value: tsp.id.toString(),
        label: this._lang === "Eng" ? tsp.nameEn : tsp.nameUa,
      });
    });

    return result;
  }

  async GetThematicModules() {
    const data = await this.GetItems("thematic_modules?size=1000");
    return data;
  }

  async GetCharacters(selected, i18n) {
    const data = await this.GetItems(`${CHARACTER_ENDPOINT}?size=1000`);

    data.unshift({
      id: "",
      en: i18n["Eng"].common.no,
      ua: i18n["Ukr"].common.no,
    });

    const result = data
      .filter((x) => x.active || x.id === selected)
      .map(this.GetValueAndLabel.bind(this));

    return result;
  }

  async getCharacteristics() {
    const data = await this.GetItems(`${CHARACTERISTIC_ENDPOINT}?size=1000`);
    return data.map((item) => ({
      value: item.id,
      label: this._lang === "Eng" ? item.titleEn : item.titleUa,
    }));
  }

  async getCategories() {
    const [groups, categories] = await Promise.all([
      this.GetItems(`${CATEGORY_GROUP_ENDPOINT}?size=1000`),
      this.GetItems(`${CATEGORY_ENDPOINT}?size=1000`),
    ]);

    return groups
      .sort((a, b) => a.order - b.order)
      .map((group) => ({
        label: this._lang === "Eng" ? group.titleEn : group.titleUa,
        value: -1,
        options: categories
          .filter((category) => category.categoryGroupId === group.id)
          .map((category) => ({
            label: this._lang === "Eng" ? category.en : category.ua,
            value: category.id,
          })),
      }));
  }

  async getOrganizations() {
    const data = await this.GetItems(`${ORGANIZATION_ENDPOINT}?size=1000`);
    return data.map((item) => this.GetValueAndLabel(item));
  }

  getActiveOrganization(orgId, organizations) {
    return organizations.find((item) => item.id === orgId) ?? null;
  }

  setOrganizationId(state, value) {
    return {
      ...state,
      organizationId: value,
      activeOgranization: this.getActiveOrganization(
        value,
        state.organizations
      ),
    };
  }

  async GetActivityOwners(fake) {
    const data = await this.GetItems(
      `users/activity_owners${fake ? "?all=1" : ""}`
    );
    const result = data.map((item) => ({
      value: item.id,
      label: item.name,
      ...item,
    }));
    return result;
  }

  async GetTrainers() {
    const data = await this.GetItems("trainers?for_select=true&size=3000");
    const result = data.map((item) => ({
      value: item.id,
      label: `${item.name} ${item.cellPhone || ''} ${item.nameTranscription}`,
      ...item,
    }));
    return result;
  }

  MapStateToModel(state) {
    let data = super.MapStateToModelMultiPart(state);

    const params = this.GetApiPutParams();

    for (let p of params) {
      if (p.name === "mediaLinks") {
        data.append(p.name, JSON.stringify(state.mediaLinks));
      } else if (p.name === "categoryIds") {
        data.append(
          p.name,
          JSON.stringify(state.categories.map((item) => item.value))
        );
      } else if (p.name === "europeActionIds") {
        if (state.europeActions && state.europeActions !== 0) {
          data.append(p.name, JSON.stringify([Number(state.europeActions)]));
        }
      } else if (p.name === "trainingPackageTypeIds") {
        if (
          state.showThematicPackages &&
          state.trainingPackageTypes &&
          state.trainingPackageTypes !== 0
        ) {
          data.append(
            p.name,
            JSON.stringify([Number(state.trainingPackageTypes)])
          );
        } else {
          data.append(p.name, JSON.stringify([]));
        }
      } else if (p.name === "thematicModuleIds") {
        if (
          state.showThematicPackages &&
          state.thematicModules &&
          state.thematicModules !== 0
        ) {
          data.append(p.name, JSON.stringify([Number(state.thematicModules)]));
        } else {
          data.append(p.name, JSON.stringify([]));
        }
      } else if (p.name === "placeOfActivityCoordinates" && state.position) {
        // POINT(x y), lat = y, lng = x
        data.append(p.name, `POINT(${state.position[1]} ${state.position[0]})`);
      } else {
        this.MapProperty(data, state, p);
      }
    }

    return data;
  }

  GetInitialState(userRoles) {
    let state = super.GetInitialState(userRoles);

    const asUstanova = userRoles.indexOf("ROLE_PARTNER") >= 0;

    state = {
      ...state,
      showOwner: userRoles.indexOf("ROLE_ANALYST") >= 0,
      asOES:
        userRoles.indexOf("ROLE_OES") >= 0 ||
        userRoles.indexOf("ROLE_MIREG") >= 0 ||
        userRoles.indexOf("ROLE_GUEST") >= 0,
      restrictCTP: asUstanova,
      thematicModulesClustered: [],
      mediaLinks: [{ url: "", title: "" }],
      showThematicPackages: false,
      showThematicModules: false,
      oblast: "",
      format: "",
      asCreated: false,
      startDate: null,
      endDate: null,
      showCharacters: true,
      showActualNumberOfParticipants: this.showActualNumberOfParticipants(
        state
      ),
      showExpectedNumberOfParticipants: this.showExpectedNumberOfParticipants(
        state
      ),
      isDigitalTool: false,
      isTraining: false,
      showPlace: false,
      isGrantVoucher: false,
      formats: [],
      users: [],
      initialPosition: GisData.AddressSearch.initialPosition,
      zoom: 12,
      isStartUp: false,
      categories: [],
      characteristicId: null,
      organizations: [],
      organizationId: null,
      activeOgranization: null,
      westCooperation: false,
      csoGrantId: null
    };

    return state;
  }

  UpdateStateFromItem(state, item) {
    state = super.UpdateStateFromItem(state, item);

    let latLng = [];
    if (item?.placeOfActivityCoordinates?.coordinates) {
      latLng = [
        item.placeOfActivityCoordinates.coordinates[1],
        item.placeOfActivityCoordinates.coordinates[0],
      ];
    }

    // perform more complex state operations
    state = this.SetEndDate(state, item.endDate);
    state = this.SetFormat(state, item.formatId);
    state = this.SetPlaceCoordinates(state, {
      latLng,
    });

    if (!state.isTransient) {
      state = this.SetStartDate(state, item.startDate);
      state = this.SetMediaLinks(state, item.mediaLinks);
    } else {
      state = { ...state, startDate: null, endDate: null };
    }

    state = this.SetEuropeActions(state, item.europeActions);

    // if thematic modules come as array, we convert it to single value here
    if (item.thematicModules && Array.isArray(item.thematicModules)) {
      let thematicModules =
        item.thematicModules.length > 0 ? item.thematicModules[0].id : 0;
      state = { ...state, thematicModules };
    }

    // if trainingPackageTypes come as array, we convert it to single value here
    if (item.trainingPackageTypes && Array.isArray(item.trainingPackageTypes)) {
      let trainingPackageTypes =
        item.trainingPackageTypes.length > 0
          ? item.trainingPackageTypes[0].id
          : 0;
      state = { ...state, trainingPackageTypes };
    }

    let showThematicPackages = !!(
      (state.thematicModules && state.thematicModules > 0) ||
      (state.trainingPackageTypes && state.trainingPackageTypes > 0)
    );

    const categories = item.categories?.map((category) => ({
      value: category.id,
      label: this._lang === "Eng" ? category.en : category.ua,
    }));

    return {
      ...state,
      showThematicPackages,
      categories,
    };
  }

  NavigateAfterSave(state, response) {
    if (state.isTransient) {
      return super.NavigateAfterSave(state, response);
    }

    state = { ...state };

    if (!state.messages) {
      state.messages = [];
    }
    state.messages.push(this.GetRedirect(`${activityRoute}/view/${state.id}`));

    return state;
  }

  async Action(e, state) {
    // base class is not called and contains only default implementation
    // could be improved ...

    switch (e.fieldName) {
      case "startDate":
        state = this.SetStartDate(state, e.value);
        break;
      case 'startTime':
        state = this.SetStartTime(state, e.value);
        break;
      case 'endTime':
        state = this.SetEndTime(state, e.value);
        break;
      case "endDate":
        state = this.SetEndDate(state, e.value);
        break;
      case "formatId":
        state = this.SetFormat(state, e.value);
        break;
      // case "trainers":
      //   state = this.SetTrainers(state, e.value);
      //   break;
      case "showThematicPackages":
        state = this.SetShowThematicPackages(state, e.value);
        break;
      case "trainingPackageTypes":
        state = this.SetTrainingPackageTypes(state, e.value);
        break;
      case "europeActions":
        state = this.SetEuropeActions(state, e.value);
        break;
      case "mediaReference_Add":
        state = this.AddMediaReference(state);
        break;
      case "mediaReference_Remove":
        state = this.RemoveMediaReference(state, e.value);
        break;
      case "mediaReference_ChangeTitle":
        state = this.MediaReferenceChangeTitle(state, e.value);
        break;
      case "mediaReference_ChangeUrl":
        state = this.MediaReferenceChangeUrl(state, e.value);
        break;
      case "mapSearch":
        state = this.SetPlaceCoordinates(state, e.value);
        break;
      case "organizationId":
        state = this.setOrganizationId(state, e.value);
        break;
      default:
        // never modify state, always clone
        state = { ...state };
        state[e.fieldName] = e.value;
        break;
    }

    state = this.SetStateChanged(state);

    return state;
  }

  // api defines parameters in snake-case but actually expects camel case, this is from swagger-but cvonverted to camel-case
  GetApiPutParams() {
    return [
      {
        in: "formData",
        name: "nameEn",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "nameUa",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "descEn",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "descUa",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "startDate",
        type: "string",
        format: "date",
        required: false,
      },
      {
        in: "formData",
        name: "startTime",
        type: "string",
        format: "time",
        required: false,
      },
      {
        in: "formData",
        name: "endDate",
        type: "string",
        format: "date",
        required: false,
      },
      {
        in: "formData",
        name: "endTime",
        type: "string",
        format: "time",
        required: false,
      },
      {
        in: "formData",
        name: "partOfSeries",
        type: "boolean",
        required: false,
      },
      {
        in: "formData",
        name: "activityReport",
        type: "file",
        required: false,
      },
      {
        in: "formData",
        name: "activityFoto",
        type: "file",
        required: false,
      },
      {
        in: "formData",
        name: "internalFeedback",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "mediaReferences",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "ownerId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "regionId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "regionIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: true,
      },
      {
        in: "formData",
        name: "formatId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "targetGroupIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
      {
        in: "formData",
        name: "categoryIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
      {
        in: "formData",
        name: "targetLevelIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
      {
        in: "formData",
        name: "trainerIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
      {
        in: "formData",
        name: "fundingSourceIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
      {
        in: "formData",
        name: "activityCharacterIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
      {
        in: "formData",
        name: "activityAddress",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "expectedNumberOfParticipants",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "actualNumberOfParticipants",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "placeOfActivityCoordinates",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "marked",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "implementedBy3RdParty",
        type: "boolean",
        required: false,
      },
      {
        in: "formData",
        name: "nameOfImplementingPartner",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "europeActionIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
      {
        in: "formData",
        name: "linkToRegistration",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "linkToDescription",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "mediaLinks",
        type: "array",
        items: {
          type: "json",
        },
        required: false,
      },
      {
        in: "formData",
        name: "trainingPackageTypeIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
      {
        in: "formData",
        name: "thematicModuleIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
      {
        in: "formData",
        name: "responsiblePerson",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "characteristicId",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "kind",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "place",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "targetedPersons",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "uniqueUsers",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "advisoryType",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "grantValue",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "grantCurrency",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "organizationId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "path",
        name: "id",
        type: "integer",
        format: "int32",
        required: true,
      },
      {
        in: "formData",
        name: "westCooperation",
        type: "boolean",
        required: false,
      },
      {
        in: "formData",
        name: "csoGrantId",
        type: "int32",
        required: true,
      },
      {
        in: "formData",
        name: "tagIds",
        type: "array",
        items: {
          type: "integer",
          format: "int32",
        },
        required: false,
      },
    ];
  }

  SetFormat(state, value) {
    const payload = {
      showTrainers: [3, 4, 5, 8, 13].includes(value) && !state.asOES,
      isDigitalTool: value === 9,
      isTraining: [3, 13].includes(value),
      showPlace: [3, 4, 10, 11, 13].includes(value),
      isGrantVoucher: value === 10,
      formatId: value,
      showConsultants: value === 6
    };

    let result = {
      ...state,
      ...payload,
    };

    result = {
      ...result,
      showActualNumberOfParticipants: this.showActualNumberOfParticipants(
        result
      ),
    };

    return result;
  }

  SetEndDate(state, value) {
    let result = {
      ...state,
      endDate: new Date(value),
    };

    result = {
      ...result,
      showExpectedNumberOfParticipants: this.showExpectedNumberOfParticipants(),
    };

    return result;
  }

  SetStartDate(state, value) {
    const startDate = new Date(value);
    const today = new Date();

    let result = {
      ...state,
      startDate: startDate,
      showCharacters: state.isTransient || startDate > today,
    };

    result = {
      ...result,
      showActualNumberOfParticipants: this.showActualNumberOfParticipants(
        result
      ),
    };
    return result;
  }

  SetPlaceCoordinates(state, value) {
    let position, coordString;

    if (value?.latLng?.length === 2) {
      position = [value.latLng[0], value.latLng[1]];
      coordString = `${value.latLng[0]}|${value.latLng[1]}`;
    } else {
      position = state.initialPosition;
      coordString = "";
    }

    let result = {
      ...state,
      position,
      coordString,
    };
    if (
      (!result.activityAddress || result.activityAddress === "") &&
      value?.address
    ) {
      result = { ...result, activityAddress: value.address };
    }

    return result;
  }

  SetEuropeActions(state, value) {
    // transform array to single value
    let singleValue = value;
    if (value && Array.isArray(value)) {
      singleValue = value.length > 0 ? value[0].id : 0;
    }

    const result = { ...state, europeActions: singleValue };
    return result;
  }

  SetMediaLinks(state, value) {
    const result = { ...state, mediaLinks: value ?? [] };
    return result;
  }

  SetShowThematicPackages(state, value) {
    let result = { ...state, showThematicPackages: value };

    if (!value) {
      result = { ...result, trainingPackageTypes: null, thematicModules: null };
    }

    return result;
  }

  //impure
  SetTrainingPackageTypes(state, value) {
    // if tpt come as array, we convert it to single value here
    if (value && Array.isArray(value)) {
      value = value.length > 0 ? value[0].id : 0;
    }

    let newState = { trainingPackageTypes: parseInt(value) };

    if (newState.selectedTrainingPackageType === "1") {
      const thematicModulesClustered = [];

      for (let item of state.thematicModulesList) {
        if (item.trainingPackageTypeId === 1) {
          thematicModulesClustered.push({
            value: item.id.toString(),
            label: this._lang === "Eng" ? item.nameEn : item.nameUa,
            cluster: item.cluster,
          });
        }
      }

      newState = {
        ...newState,
        thematicModulesClustered,
        isStartUp: true,
      };
    } else {
      const thematicModulesListBuf = [];
      const thematicModulesClustered = [
        { label: I18n[this._lang].activities.clusterA, options: [] },
        { label: I18n[this._lang].activities.clusterB, options: [] },
        { label: I18n[this._lang].activities.clusterC, options: [] },
      ];
      each(state.thematicModulesList, (item) => {
        thematicModulesListBuf.push({
          value: item.id.toString(),
          label: this._lang === "Eng" ? item.nameEn : item.nameUa,
          cluster: item.cluster,
        });
      });
      each(thematicModulesListBuf, (item, index) => {
        if (item.cluster === "a") {
          thematicModulesClustered[0].options.push(item);
        }
        if (item.cluster === "b") {
          thematicModulesClustered[1].options.push(item);
        }
        if (item.cluster === "c") {
          thematicModulesClustered[2].options.push(item);
        }
      });

      newState = {
        ...newState,
        thematicModulesClustered,
        isStartUp: false,
      };
    }

    const ctpId = 2;
    const selectedCTP = state.trainingPackageTypesList?.find(
      (x) => x.id === ctpId
    );

    let showThematicModules =
      newState.trainingPackageTypes !== this.idFirstSteps;

    const result = {
      ...state,
      ...newState,
      isDisabledTSP: state.restrictCTP && selectedCTP,
      showThematicModules,
    };

    return result;
  }

  AddMediaReference(state) {
    const mediaLinks = state.mediaLinks.concat([{ url: "", title: "" }]);

    const result = { ...state, mediaLinks };
    return result;
  }

  RemoveMediaReference(state, item) {
    const mediaLinks = state.mediaLinks.filter((s, sidx) => item.idx !== sidx);

    const result = { ...state, mediaLinks };
    return result;
  }

  MediaReferenceChangeUrl(state, item) {
    const mediaLinks = state.mediaLinks.map((mediaReference, sidx) => {
      if (item.idx !== sidx) return mediaReference;
      return { ...mediaReference, url: item.url };
    });

    const result = { ...state, mediaLinks };
    return result;
  }

  MediaReferenceChangeTitle(state, item) {
    const mediaLinks = state.mediaLinks.map((mediaReference, sidx) => {
      if (item.idx !== sidx) return mediaReference;
      return { ...mediaReference, title: item.title };
    });

    const result = { ...state, mediaLinks };
    return result;
  }

  ValidateItem(state) {
    let result = super.ValidateItem(state);

    this.validationRequired(result, state, "nameEn");
    this.validationRequired(result, state, "descEn");
    this.validationRequired(result, state, "startDate");
    this.validationRequired(result, state, "endDate");
    this.validationRequired(result, state, "responsiblePerson");

    if (state.startDate && state.endDate) {
      if (state.startDate > state.endDate) {
        result.startDate = "Start date must be before end date";
      }
    }

    // react-select do not render input for isDisabled component
    //FIXME: correct html behavior, so we need to get the value from state ?!
    this.validationRequired(result, state, "showThematicPackages");

    if (state.showThematicPackages === true) {
      this.validationSelectionRequired(result, state, "trainingPackageTypes");
      if (state.showThematicModules === true) {
        this.validationSelectionRequired(result, state, "thematicModules");
      }
    }

    // this.validationSelectionRequired(result, state, "regionId");

    this.validationNotEmpty(result, state, "categories");
    // this.validationNotEmpty(result, state, "fundingSources");
    this.validationRequired(result, state, "activityAddress");
    this.validationRequired(result, state, "formatId");

    if (state.isTraining) {
      this.validationRequired(result, state, "characteristicId");
      this.validationRequired(result, state, "kind");
    }

    if (state.showPlace) {
      this.validationRequired(result, state, "place");
    }

    if (state.isDigitalTool) {
      if (state.targetedPersons?.length > 0) {
        this.validationNumberRequired(result, state, "targetedPersons");
      } else if (state.uniqueUsers?.length > 0) {
        this.validationNumberRequired(result, state, "uniqueUsers");
      } else {
        this.validationNumberRequired(result, state, "targetedPersons");
        this.validationNumberRequired(result, state, "uniqueUsers");
      }
    }

    if (state.isGrantVoucher) {
      this.validationRequired(result, state, "advisoryType");
      this.validationRequired(result, state, "grantValue");
      this.validationRequired(result, state, "grantCurrency");
      this.validationRequired(result, state, "organizationId");
    }

    const maxFileSize = 10485760; // 10mb
    this.validationFileSize(result, state, "activityFoto", maxFileSize);
    this.validationFileSize(result, state, "activityReport", maxFileSize);

    this.validationRequired(result, state, "csoGrantId");

    return result;
  }

  showActualNumberOfParticipants(state) {
    return true;
  }

  showExpectedNumberOfParticipants(state) {
    return true;
  }

  MessageSaveSucceeded(state, r) {
    state = { ...state };

    if (!state.messages) {
      state.messages = [];
    }

    state.messages.push(this.GetInfo("Activity sucessfully saved"));
    state.messages.push({ type: "triggerSaveSucceeded" });
    return state;
  }

  SetStartTime(state, value) {
    if (!value) {
      return {
        ...state,
        startTime: value
      }
    }

    return {
      ...state,
      startTime: dayjs(value.setSeconds(0)).toISOString()
    }
  }

  SetEndTime(state, value) {
    if (!value) {
      return {
        ...state,
        endTime: value
      }
    }

    return {
      ...state,
      endTime: dayjs(value.setSeconds(0)).toISOString()
    }
  }
}

export default ActivityService;

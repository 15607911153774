import {
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';

function Dialog(props) {
  const { open, onClose, onConfirm, title, children } = props;

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle 
        id="dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="dialog-description"
        >
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          No
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}

export default Dialog;

import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataSourceWithLoadMore from 'components/DataSourceWithLoadMore';
import {
  InputAdornment,
  TextField
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddOrganizationButton from './AddOrganizationButton';
import { debounce } from 'lodash';
import AppBar from 'components/AppBarWithBackButton';
import Table from 'components/tables/Table';
import { StoreContext } from 'stores/context';

function AddOrganizationsToActivity(props) {
  const { id } = useParams();
  const [query, setQuery] = useState('');
  const { userStore } = useContext(StoreContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(
      (value) => setQuery(value.length > 1 ? `?search=${value}` : '')
      , 500
    )
  , []);

  const columns = [
    {
      heading: 'Name',
      render: (organization) => organization.name
    },
    {
      heading: 'Region',
      render: (organization) => organization.region?.name
    },
    {
      heading: 'City/Municipality',
      render: (organization) => organization.address
    },
    {
      heading: 'Type of Economic Activities',
      render: (organization) => organization.economicActivityType?.name
    },
    {
      heading: 'Size',
      render: (organization) => organization.size?.name,
      when: () => !userStore.isCurrentProject('AZP')
    },
    {
      heading: 'Number of Employees',
      render: (organization) => organization.numberOfEmployees,
      when: () => userStore.isCurrentProject('AZP')
    },
    {
      heading: 'Relocated?',
      render: (organization) => organization.relocated ? 'Yes' : 'No',
      when: () => userStore.isCurrentProject('AZP')
    },
    {
      heading: 'Email',
      render: (organization) => organization.email
    },
    {
      heading: 'Phone',
      render: (organization) => organization.phone
    },
    {
      heading: 'Website/Social media',
      render: (organization) => (
        organization.url && (
          <a 
            href={organization.url}
            target="_blank"
            rel="noreferrer"
          >
            Open
          </a>
        )
      )
    },
    {
      heading: 'Actions',
      render: (organization) => (
        <AddOrganizationButton 
          activityId={id}
          organization={organization}
        />
      )
    }
  ];

  return (
    <>
      <AppBar>
        <TextField
          placeholder="Search..."
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </AppBar>
      <DataSourceWithLoadMore path={`ul-activities/${id}/organizations${query}`}>
        {(organizations) => (
          <Table
            columns={columns}
            rows={organizations}
          />
        )}
      </DataSourceWithLoadMore>
    </>
  );
}

export default AddOrganizationsToActivity;

import { useContext } from 'react';
import NotificationsContext from './context';

function useNotifications() {
  return useContext(NotificationsContext);
}

function withNotifications(Component) {
  const WithNotifications = (props) => {
    const notifications = useNotifications();

    return (
      <Component notifications={notifications} {...props} />
    );
  }

  return WithNotifications;
}

export {
  useNotifications,
  withNotifications
};

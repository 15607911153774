import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    position: 'relative'
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0
  }
};

function Image(props) {
  const { src, alt, onDelete, classes } = props;

  return (
    <div className={classes.root}>
      <img src={src} alt={alt} />
      <IconButton 
        className={classes.button} 
        onClick={onDelete}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

export default withStyles(styles)(Image);

import { makeAutoObservable, runInAction } from "mobx";
import { LangStore } from "../LangStore";
import { DataFetcher } from "../../utils/DataFetcher";
import { REQUEST_STATES } from "../../utils/RequestStates";
import { Vertex, VertexTypes } from "./Vertex";
import { Edge, EdgeTypes } from "./Edge";

const getPosition = (location) => {
  if (location?.type === "Point") {
    return {
      lat: location.coordinates[1],
      lng: location.coordinates[0],
    };
  } else if (location?.type === "MultiPoint") {
    return {
      lat: location.coordinates[0][1],
      lng: location.coordinates[0][0],
    };
  }
  return null;
};

const spiralify = (() => {
  const a = 0.003;
  const b = 0.001;
  const angle = Math.PI / 6;
  let d = {};
  
  return (point) => {
    let t = d[point.lat + ' ' + point.lng];

    if (t === undefined) {
      d[point.lat + ' ' + point.lng] = 0;
      
      return point;
    }

    let x = (a + b * t) * Math.cos(t);
    let y = (a + b * t) * Math.sin(t);
    
    d[point.lat + ' ' + point.lng] += angle;
    
    return {
      lat: point.lat + x,
      lng: point.lng + y
    }
  }
})();

export class NetworkMapStore {
  dataFetcher: DataFetcher = null;
  langStore: LangStore = null;
  loadState = REQUEST_STATES.Initial;
  loadError = "";
  showActivities = true;
  showOrganizations = true;
  vertexMap = null;
  edgeMap = null;
  dateFrom = null;
  dateTo = null;

  constructor(dataFetcher: DataFetcher, langStore: LangStore) {
    this.dataFetcher = dataFetcher;
    this.langStore = langStore;
    this.reset();
    makeAutoObservable(this, {
      dataFetcher: false,
      langStore: false,
    });
  }

  reset() {
    this.vertexMap = new Map();
    this.edgeMap = new Map();
  }

  setDateFrom(from) {
    this.dateFrom = from;
  }

  setDateTo(to) {
    this.dateTo = to;
  }

  setShowActivities(show) {
    this.showActivities = show;
  }

  setShowOrganizations(show) {
    this.showOrganizations = show;
  }

  get vertexes() {
    const vxs = [];
    for (const vx of this.vertexMap.values()) {
      if (vx.type === VertexTypes.Activity && this.showActivities) {
        vxs.push(vx);
      } else if (
        vx.type === VertexTypes.Organization &&
        this.showOrganizations
      ) {
        vxs.push(vx);
      }
    }
    return vxs;
  }

  get edges() {
    const edges = [];
    for (const edge of this.edgeMap.values()) {
      if (
        this.showActivities &&
        this.showOrganizations &&
        edge.isActivityToOrganization
      ) {
        edges.push(edge);
      } else if (
        !this.showActivities &&
        this.showOrganizations &&
        edge.isOrganizationToOrganization
      ) {
        edges.push(edge);
      } else if (
        this.showActivities &&
        this.showOrganizations &&
        edge.isActivityToActivity
      ) {
        edges.push(edge);
      }
    }
    return edges;
  }

  processActivities(activities) {

    for (let activity of activities) {
      const position = getPosition(activity.location);
      if (!position) {
        continue;
      }
      this.vertexMap.set(
        `activity-${activity.id}`,
        new Vertex({ id: activity.id, type: VertexTypes.Activity, position: spiralify(position) })
      );
    }

    for (let act of activities) {
      const {
        participantOrganizations = [],
        organization = null,
        relatedActivityIds = [],
      } = act;
      delete act.participantOrganizations;
      const actPos = getPosition(act.location);
      const hostOrgPos = getPosition(organization?.location);

      if (organization) {
        const vertexId = `organization-${organization.id}`;
        if (!this.vertexMap.has(vertexId) && hostOrgPos) {
          this.vertexMap.set(
            vertexId,
            new Vertex({
              ...organization,
              type: VertexTypes.Organization,
              position: hostOrgPos,
            })
          );
        }
        const edgeId = `activity-to-organization-${act.id}-${organization.id}`;
        if (!this.edgeMap.has(edgeId) && actPos && hostOrgPos) {
          this.edgeMap.set(
            edgeId,
            new Edge({
              fromId: act.id,
              toId: organization.id,
              type: EdgeTypes.ActivityToOrganization,
              positions: [actPos, hostOrgPos],
            })
          );
        }
      }

      for (let org of participantOrganizations) {
        const orgPos = getPosition(org.location);
        const vertexId = `organization-${org.id}`;
        if (!this.vertexMap.has(vertexId) && orgPos) {
          this.vertexMap.set(
            vertexId,
            new Vertex({
              ...org,
              type: VertexTypes.Organization,
              position: orgPos,
            })
          );
        }

        const edgeId = `activity-to-organization-${act.id}-${org.id}`;
        if (!this.edgeMap.has(edgeId) && actPos && orgPos) {
          this.edgeMap.set(
            edgeId,
            new Edge({
              fromId: act.id,
              toId: org.id,
              type: EdgeTypes.ActivityToOrganization,
              positions: [actPos, orgPos],
            })
          );
        }

        if (organization && hostOrgPos && orgPos) {
          const edgeId = `organization-to-organization-${organization.id}-${org.id}`;
          if (!this.edgeMap.has(edgeId)) {
            this.edgeMap.set(
              edgeId,
              new Edge({
                fromId: organization.id,
                toId: org.id,
                type: EdgeTypes.OgranizationToOrganization,
                positions: [hostOrgPos, orgPos],
              })
            );
          }
        }
      }

      for (let relatedId of relatedActivityIds) {
        const relatedActivity = this.vertexMap.get(`activity-${relatedId}`);
        const edgeId = `activity-to-activity-${act.id}-${relatedId}`;
        if (!this.edgeMap.has(edgeId) && actPos && relatedActivity?.position) {
          this.edgeMap.set(
            edgeId,
            new Edge({
              fromId: act.id,
              toId: relatedId,
              type: EdgeTypes.ActivityToActivity,
              positions: [actPos, relatedActivity?.position],
            })
          );
        }
      }
    }
  }

  async getMapData() {
    try {
      this.reset();
      this.loadState = REQUEST_STATES.Pending;
      const activities = await this.dataFetcher.getNMActivities(
        this.dateFrom,
        this.dateTo
      );
      this.processActivities(activities);
      runInAction(() => {
        this.loadState = REQUEST_STATES.Success;
      });
    } catch (ex) {
      runInAction(() => {
        this.loadState = REQUEST_STATES.Error;
      });
      throw ex;
    }
  }

  get isLoading() {
    return this.loadState === REQUEST_STATES.Pending;
  }

  async getVertexData(vertex) {
    if (vertex.isOrganization) {
      return this.dataFetcher.getNMOrganization(vertex.id);
    } else if (vertex.isActivity) {
      return this.dataFetcher.getNMActivity(vertex.id);
    }
    return Promise.reject("Invalid vertex type");
  }

  async getEdgeData(edge) {
    if (edge.isActivityToOrganization) {
      return this.dataFetcher.getNMActivityToOrganizationEdge(
        edge.fromId,
        edge.toId
      );
    } else if (edge.isOrganizationToOrganization) {
      return this.dataFetcher.getNMOrganizationToOrganizationEdge(
        edge.fromId,
        edge.toId
      );
    } else if (edge.isActivityToActivity) {
      return this.dataFetcher.getNMActivityToActivityEdge(
        edge.fromId,
        edge.toId
      );
    }
    return Promise.reject("Invalid edge type");
  }
}
